import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Button, Modal, Row, Col, Input } from "antd";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";
import Container from "../../../Components/Container";
import PageTitle from "../../../Components/PageTitle";
import PageText from "../../../Components/PageText";
import Pagination from "../../../Components/Pagination";
import RecommendedLinks from "../../../Components/RecommendedLinks";
import Anticorpos from "../../Backoffice/Anticorpos";
import { Helmet } from "react-helmet";
const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: 1.2vw;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  > .content {
    margin-bottom: 5.729vw;
  }

  img {
    width: 100%;
  }

  @media (max-width: 992px) {
    margin-top: 120px;
    font-size: 16px;
    line-height: 4.555vw;
  }
`;

const Filter = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2.604vw;
  margin: 1.563vw 0 4.01vw;
`;

const FilterTitle = styled.h2`
  background-color: #0c1151;
  color: #ffffff;
  padding: 2.344vw 7.552vw;
  font-size: 2.083vw;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 2.5vw;
  margin: auto;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 4.555vw;
  }

  @media (min-width: 1920px) {
    font-size: 40px;
    line-height: 48px;
  }
`;

const FilterForm = styled.form`
  display: grid;
  gap: 2.188vw;
  grid-template-columns: 1fr 1fr 1fr;

  .select {
    color: #000000;
    text-align: left;
  }

  label {
    color: red;
    text-decoration: underline;
    cursor: pointer;
    place-self: flex-end flex-start;
    transition: 0.3s ease;
  }

  .ant-input-suffix {
    background: transparent;
    height: 100%;

    span.ant-input-clear-icon {
      display: flex;
    }
  }

  #name {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    outline: 0 !important;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    color: #222222;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 1920px) {
    .css-13cymwt-control {
      height: 48px;
    }
  }

  @media (min-width: 1920px) {
    font-size: 18px;
    line-height: 42px;

    .ant-input-suffix svg {
       {
        font-size: 18px;
      }
    }
  }
`;

const FilterSubmit = styled.button`
  max-width: 8.281vw;
  background-color: #f88436;
  border: none;
  border-radius: 0.521vw;
  font-size: 1.146vw;
  font-weight: bold;
  line-height: 1.51vw;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  transition: 0.3s ease;
  padding: 0.781vw 1.563vw;

  :hover {
    background-color: #e87426;
    color: #ececec;
  }

  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 4.555vw;
    border-radius: 8px;
    padding: 10px;
    max-width: 100%;
  }

  @media (min-width: 1920px) {
    font-size: 22px;
    line-height: 27px;
    max-width: 169px;
  }
`;

const ListItens = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.365vw;
  margin-bottom: 5.729vw;
  width: 100%;

  .item {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr auto;
  }

  p {
    text-align: left;
    font-size: 1.354vw;
    font-weight: 300;
    line-ehight: 3.333vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0;
  }

  .item__cta {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    gap: 2.604vw;
    text-align: center;

    a {
      background: #f88436 0% 0% no-repeat padding-box;
      border: none;
      border-radius: 0.521vw;
      cursor: pointer;
      opacity: 1;
      font-size: 1.146vw;
      font-weight: bold;
      letter-spacing: 0px;
      line-height: 1.51vw;
      color: #ffffff;
      opacity: 1;
      padding: 12px 15px;
      max-width: 8.281vw;
      transition: 0.3s ease;
      text-decoration: none;
    }

    a:hover {
      background-color: #e87426;
      color: #ececec;
    }
  }

  @media (max-width: 990px) {
    p {
      font-size: 12px;
    }
  }

  @media (min-width: 1920px) {
    p {
      font-size: 26px;
      line-height: 30px;
    }

    .item__cta {
      a {
        font-size: 22px;
        line-height: 27px;
        max-width: 169px;
      }
    }
  }
`;

const NoResults = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  * {
    text-align: center;
    font-size: 16px;
    line-height: inherit;
    font-weight: bold;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    margin: 1.094vw 0;
  }

  @media (max-width: 992px) {
    * {
      font-size: 16px;
      line-height: 4.555vw;
    }
  }

  @media (min-width: 1920px) {
    * {
      font-size: 24px;
      line-height: 28px;
    }
  }
`;

const HeaderListItens = styled.section`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 8.281vw;
  margin-bottom: 1.302vw;

  .column {
  }

  .column__title {
    text-align: left;
    font-size: 1.8vw;
    font-weight: bold;
    line-height: 3.333vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0;
  }

  @media (min-width: 1920px) {
    .column__title {
      font-size: 42px;
      line-height: 64px;
    }
  }
`;

export default function PesquisarAnticorpo(props) {
  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const [clones, setClones] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [instituicoes, setInstituicoes] = useState([]);
  const [anticorpos, setAnticorpos] = useState([]);
  const [optionsName, setOptionsName] = useState([]);
  const [optionsLocal, setOptionsLocal] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [listaAnticorpos, setListaAnticorpos] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [telefone, setTelefone] = useState(false);
  const [email, setEmail] = useState(false);
  const [responsavel, setResponsavel] = useState(false);
  const [contacto, setContacto] = useState(false);
  const [noResults, setNoResults] = useState("");
  const [current, setCurrent] = useState(1);
  const onChange = (page) => {
    console.log(page);
    setCurrent(page);
  };
  const showModal = (item) => {
    setTelefone(item.telefone);
    setEmail(item.email);
    setContacto(item.nomeInstituicao);
    setResponsavel(item.responsavel);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    fetch(`https://spapapi.phormuladev.com/api/all-anticorpos`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.anticorpos.length === 0) {
          setNoResults("Nenhum Anticorpo encontrado..");
          return;
        }

        setAnticorpos(data.anticorpos);
        setInstituicoes(data.instituicoes);
        setMarcas(data.marcas);
        setClones(data.clones);

        let allAnticorpos = data.anticorpos;
        allAnticorpos.forEach((anticorpo) => {
          let nomeClone = data.clones
            .filter((x) => x.id === Number(anticorpo.clone))
            .map((x) => x.nome);
          let nomeMarca = data.marcas
            .filter((x) => x.id === Number(anticorpo.marca))
            .map((x) => x.nome);
          let instituicao = data.instituicoes
            .filter((x) => x.id === Number(anticorpo.instituicao))
            .map((x) => x);
          anticorpo.nomeClone = nomeClone[0];
          anticorpo.nomeMarca = nomeMarca[0];
          anticorpo.nomeInstituicao = instituicao[0].nome;
          anticorpo.email = instituicao[0].email;
          anticorpo.responsavel = instituicao[0].responsavel;
          anticorpo.telefone = instituicao[0].telefone;
        });

        setListaAnticorpos(allAnticorpos);

        setAllItems(allAnticorpos);
        setCurrentItems(allAnticorpos.slice(0, 50));
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    let optionName = [];
    anticorpos.filter((anticorpo) => {
      optionName.push({ value: anticorpo.nome, label: anticorpo.nome });
    });

    setOptionsName(optionName);
  }, [anticorpos]);

  useEffect(() => {
    let optionLocal = [];

    instituicoes.filter((instituicao) => {
      optionLocal.push({ value: instituicao.nome, label: instituicao.nome });
    });

    setOptionsLocal(optionLocal);
  }, [instituicoes]);

  const [selectedName, setSelectedName] = useState("");
  const [selectedLocal, setSelectedLocal] = useState("");
  const [currentFilter, setCurrentFilter] = useState(null);
  /* b */

  const getAnticorpos = () => {
    console.log(anticorpos);
    let allAanticorpos = anticorpos;

    anticorpos.forEach((anticorpo) => {
      let nomeClone = clones
        .filter((x) => x.id === Number(anticorpo.clone))
        .map((x) => x.nome);
      let nomeMarca = marcas
        .filter((x) => x.id === Number(anticorpo.marca))
        .map((x) => x.nome);
      let nomeInstituicao = instituicoes
        .filter((x) => x.id === Number(anticorpo.instituicao))
        .map((x) => x.nome);

      anticorpo.nomeClone = nomeClone[0];
      anticorpo.nomeMarca = nomeMarca[0];
      anticorpo.nomeInstituicao = nomeInstituicao[0];
    });

    setListaAnticorpos(allAanticorpos);
    setCurrentItems(allAanticorpos.slice(0, 50));
  };

  useEffect(() => {
    if (currentFilter) {
      let inputInstituicao =
        currentFilter.instituicao != ""
          ? currentFilter.instituicao.toLowerCase()
          : "";
      let inputNome =
        currentFilter.nome != "" ? currentFilter.nome.toLowerCase() : "";

      let itemsPosFilter = listaAnticorpos.filter((anticorpo) => {
        if (
          anticorpo.nomeInstituicao.toLowerCase().includes(inputInstituicao) &&
          anticorpo.nome.toLowerCase().includes(inputNome)
        ) {
          return anticorpo;
        }
      });
      setAllItems(itemsPosFilter);
    }
  }, [currentFilter]);

  useEffect(() => {
    setCurrentItems(allItems.slice(0, 50));
  }, [allItems]);

  const animatedComponents = makeAnimated();

  const refSelectName = useRef(null);
  const refSelectLocal = useRef(null);

  return (
    <Wrapper>
      <Helmet>
        <title>
          Pesquisar um anticorpo | Sociedade Portuguesa de Anatomia Patológica -
          SPAP
        </title>
        <meta
          name="description"
          content="Pode pesquisar os anticorpos disponíveis a nível nacional. Use o contacto disponivel de cada instituição para saber as condições."
        />
        <meta
          name="keywords"
          content="spap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <Container className="content">
        <PageTitle
          title="Pesquisar um anticorpo"
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "Ferramentas úteis",
              link: "/ferramentas-uteis",
            },
            {
              text: "Pesquisar um anticorpo",
            },
          ]}
          imageDesktop={require("../../../Assets/Images/icon-top-pesquisar-anticorpo@2x.png")}
          imageMobile={require("../../../Assets/Images/icon-top-pesquisar-anticorpo.png")}
          imageAlt="Pesquisar um anticorpo"
        />

        <PageText
          title="Pode pesquisar os anticorpos disponíveis a nível nacional. Use o contacto disponivel de cada instituição para saber as condições."
          text="Ainda não vê aqui o seu laboratório? <a href='mailto:site@spap.phormuladev.com' target='_blank'>Contacte-nos</a>!"
        />

        <Filter>
          <FilterTitle>
            UTILIZE OS FILTROS PARA ENCONTRAR UM ANTICORPO
          </FilterTitle>
          <FilterForm
            onSubmit={(e) => {
              e.preventDefault();
              setCurrentFilter({
                nome: selectedName,
                instituicao: selectedLocal,
              });
            }}
          >
            <Input
              key={"filtro-nome"}
              id="name"
              type="text"
              placeholder="Introduza o Anticorpo"
              allowClear
              onChange={(e) => setSelectedName(e.target.value)}
            />
            <Select
              ref={refSelectLocal}
              id="select-2"
              className="select"
              placeholder="Selecione Instituição"
              closeMenuOnSelect={true}
              components={animatedComponents}
              options={optionsLocal}
              onChange={(e) => setSelectedLocal(e ? e.value : "")}
            />
            <FilterSubmit type="submit" className="submit-filter">
              Pesquisar
            </FilterSubmit>
            {(selectedName || selectedLocal) && (
              <label
                onClick={(e) => {
                  setSelectedName("");
                  refSelectLocal.current.clearValue();
                  setCurrentFilter({
                    nome: "",
                    instituicao: "",
                  });
                }}
              >
                Limpar filtros
              </label>
            )}
          </FilterForm>
        </Filter>

        {currentItems.length > 0 ? (
          <>
            <HeaderListItens>
              <div className="column">
                <h3 className="column__title">Anticorpo</h3>
              </div>
              <div className="column">
                <h3 className="column__title">Marca</h3>
              </div>
              <div className="column">
                <h3 className="column__title">Clone</h3>
              </div>
              <div className="column">
                <h3 className="column__title">Instituição</h3>
              </div>
              <div className="column" />
            </HeaderListItens>
            <ListItens>
              {currentItems.map((item, index) => {
                return (
                  <div className={"item item-" + (index + 1)}>
                    <div className="item__title">
                      <p>{item.nome}</p>
                    </div>
                    <div className="item__brand">
                      <p>{item.nomeMarca}</p>
                    </div>
                    <div className="item__clone">
                      <p>{item.nomeClone}</p>
                    </div>
                    <div className="item__local">
                      <p>{item.nomeInstituicao}</p>
                    </div>
                    <div className="item__cta">
                      <a onClick={() => showModal(item)}>Contactar</a>
                    </div>
                  </div>
                );
              })}
            </ListItens>
            <Pagination
              current={current}
              items={allItems}
              itemsPerPage={50}
              onchange={(e) => {
                setCurrentItems(e);
              }}
            />
          </>
        ) : (
          <NoResults>
            <h3>{noResults}</h3>
          </NoResults>
        )}
      </Container>

      <Modal
        className="modal-contactar"
        width={500}
        title={"Contactos da instituição " + contacto}
        open={isModalOpen}
        onCancel={handleCancel}
      >
        <Row gutter={[12, 18]}>
          <Col className="div-contactar-nome-responsavel" span={24}>
            <span
              style={{
                textAlign: "center",
                fontSize: "16px",
                color: "#F88436",
                fontWeight: "bold",
              }}
            >
              Responsável{" "}
            </span>

            <a className="link-contacto" style={{ textDecoration: "none" }}>
              {responsavel ? responsavel : "N/A"}
            </a>
          </Col>
        </Row>
        <Row gutter={[12, 18]}>
          <Col className="div-contactar" span={11}>
            <PhoneOutlined
              style={{ color: "#F88436" }}
              twoToneColor="#F88436"
            />
            <span
              style={{
                textAlign: "center",
                fontSize: "16px",
                color: "#F88436",
                fontWeight: "bold",
              }}
            >
              Telefone
            </span>

            <a className="link-contacto" href={"tel:" + telefone}>
              {telefone ? telefone : "N/A"}
            </a>
          </Col>
          <Col className="div-contactar" span={11} offset={2}>
            <MailOutlined style={{ color: "#F88436" }} twoToneColor="#F88436" />
            <span
              style={{
                textAlign: "center",
                fontSize: "16px",
                color: "#F88436",
                fontWeight: "bold",
              }}
            >
              Email
            </span>

            <a className="link-contacto" href={"mailto:" + email}>
              {email ? email : "N/A"}
            </a>
          </Col>
        </Row>
      </Modal>

      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={[
          {
            title: "História",
            link: "/spap/historia",
          },
          {
            title: "Órgãos sociais",
            link: "/spap/orgaos-sociais",
          },
          {
            title: "Bolsas e prémios",
            link: "/spap/bolsas-e-premios",
          },
          {
            title: "Vantagens de ser sócio",
            link: "/socios/conheca-as-vantagens",
          },
        ]}
      />
    </Wrapper>
  );
}
