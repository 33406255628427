import styled from "styled-components";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Container from "../../../../Components/Container";
import FlexInRow from "../../../../Components/FlexInRow";
import FlexInColumn from "../../../../Components/FlexInColumn";
import PageTitle from "../../../../Components/PageTitle";
import SupportedBySpap from "../../../../Components/SupportedBySpap";

import { Button, Divider, Space, Image, Row, Col } from "antd";
import JuntarseClube from "../../../../Components/JuntarseClube";
import SidebarMenu from "../../../../Components/SidebarMenu";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #000000;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: left;
  width: 100%;
  margin-top: 7.813vw;

  > .content {
    padding-bottom: 5.208vw;
  }

  .ant-image {
    width: 100% !important;
    height: 175px !important;
  }

  img {
    width: 100%;
  }

  :where(.css-dev-only-do-not-override-j0nf2s).ant-image .ant-image-img {
    opacity: 0;
  }

  .card-galeria {
    border-radius: 10px !important;

    overflow: hidden;
    :hover {
      background: rgba(0, 0, 0, 0.5);
      cursor: pointer;
    }
  }

  .row-wrap {
    flex-wrap: wrap !important;
  }

  .link {
    color: ${(props) => props.theme.orange};
    transition: 0.3s ease;
  }

  .link:hover {
    color: ${(props) => props.theme.orangeHover};
  }

  .cta {
    background-color: ${(props) => props.theme.orange};
    border: none;
    color: ${(props) => props.theme.white};
    transition: 0.3s ease;
  }

  .cta:hover {
    background-color: ${(props) => props.theme.orangeHover};
    color: ${(props) => props.theme.whiteHover};
  }

  .bar {
    height: 1px;
    width: 66.854vw;
    background-color: #d6cdf7;
    opacity: 1;
    margin: auto;
  }

  .ant-space-item {
    width: 100%;
  }

  .manager__image {
    display: block;
    width: 89px;
  }

  .item {
    width: 100% !important;
  }

  .item__image {
    border-radius: 18px;
    overflow: hidden;
    height: 175px;
    background-size: cover;
    width: 100%;

    img {
      object-fit: fill;
    }
  }

  .item__title {
    font-weight: bold;
    font-size: 1.0416vw;
    line-height: 1.25vw;
    font-family: "Fira Sans";
    letter-spacing: 0px;
    color: #0c1151;
    text-align: center;
    margin-top: 20px;
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    .item__title {
      font-size: 11px;
      line-height: 16px;
    }
  }
`;

const Title = styled.h2`
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;

  @media (max-width: 992px) {
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 50px;

  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export default function ClubeNovoGaleria(props) {
  const [visible, setVisible] = useState(false);

  const [galleryItens, setGalleryItens] = useState([]);

  const { slug } = useParams();

  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const [usersList, setUsersList] = useState(session_storage.all_data.users);

  const [clube, setClube] = useState("");
  const [galeriasClube, setGaleriasClube] = useState("");

  useEffect(() => {
    fetch(`https://spapapi.phormuladev.com/api/all-galerias-clubes`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.clubes.length === 0) {
          return;
        }

        getClube(data.clubes);
        getGaleriasClube(data.galerias, data.imagensGalerias);
      })
      .catch((error) => console.error(error));
  }, []);

  const getClube = (clubes) => {
    let myClube = [];
    clubes?.length > 0 &&
      clubes.map((clube) => {
        if (clube.id == slug.split("-").pop()) {
          myClube.push(clube);
          clube.url = clube.url
            ? "https://spapapi.phormuladev.com/clubes/" + clube.url
            : "https://spapapi.phormuladev.com/clubes/clube-estudo-default.svg";
        }
      });

    setClube(myClube[0]);
  };

  const getGaleriasClube = (galeriasClubes, imagensGaleriasClubes) => {
    if (galeriasClubes.length == 0) return [];

    let galeriasDesteClube = [];

    galeriasDesteClube = galeriasClubes.filter((galeria) => {
      if (galeria.club_id == slug.split("-").pop() && galeria.status == 1) {
        return galeria;
      }
    });

    galeriasDesteClube.map((galeria) => {
      let date = galeria.updated_at.substring(0, 10);
      let [year, month, day] = date.split("-");
      galeria.dataToShow = day + "/" + month + "/" + year;
      galeria.imagens = [];
      if (imagensGaleriasClubes.length > 0) {
        imagensGaleriasClubes.map((galeriaPic) => {
          if (
            galeriaPic.gallery_id == galeria.id &&
            galeriaPic.club_id == slug.split("-").pop()
          ) {
            let uid_imagem = galeriaPic.id;
            let status_imagem = "done";
            let url_imagem =
              "https://spapapi.phormuladev.com/clubes/" +
              slug.split("-").pop() +
              "/galerias/" +
              galeria.id +
              "/" +
              galeriaPic.url;
            galeria.imagens.push({
              uid: uid_imagem,
              status: status_imagem,
              url: url_imagem,
            });
          }
        });
      }
    });

    setGaleriasClube(galeriasDesteClube);
  };

  console.log(galeriasClube);

  const ListCards = [
    {
      title: "Fetoplacentar",
      desktop: require("../../../../Assets/Images/img-fetoplacentar-03@2x.png"),
      mobile: require("../../../../Assets/Images/img-fetoplacentar-03.png"),
      alt: "Fetoplacentar",
      link: "evento/21st-meeting-of-the-eahp",
    },
    {
      title: "Fetoplacentar 2",
      desktop: require("../../../../Assets/Images/img-fetoplacentar-04@2x.png"),
      mobile: require("../../../../Assets/Images/img-fetoplacentar-04.png"),
      title: "Fetoplacentar 2",
      link: "evento/21st-meeting-of-the-eahp",
    },
    {
      title: "Fetoplacentar 3",
      desktop: require("../../../../Assets/Images/img-fetoplacentar-05@2x.png"),
      mobile: require("../../../../Assets/Images/img-fetoplacentar-05.png"),
      title: "Fetoplacentar 3",
      link: "evento/21st-meeting-of-the-eahp",
    },
    {
      title: "Fetoplacentar",
      desktop: require("../../../../Assets/Images/img-fetoplacentar-03@2x.png"),
      mobile: require("../../../../Assets/Images/img-fetoplacentar-03.png"),
      alt: "Fetoplacentar",
      link: "evento/21st-meeting-of-the-eahp",
    },
    {
      title: "Fetoplacentar 2",
      desktop: require("../../../../Assets/Images/img-fetoplacentar-04@2x.png"),
      mobile: require("../../../../Assets/Images/img-fetoplacentar-04.png"),
      title: "Fetoplacentar 2",
      link: "evento/21st-meeting-of-the-eahp",
    },
    {
      title: "Fetoplacentar 3",
      desktop: require("../../../../Assets/Images/img-fetoplacentar-05@2x.png"),
      mobile: require("../../../../Assets/Images/img-fetoplacentar-05.png"),
      title: "Fetoplacentar 3",
      link: "evento/21st-meeting-of-the-eahp",
    },
    {
      title: "Fetoplacentar",
      desktop: require("../../../../Assets/Images/img-fetoplacentar-03@2x.png"),
      mobile: require("../../../../Assets/Images/img-fetoplacentar-03.png"),
      alt: "Fetoplacentar",
      link: "evento/21st-meeting-of-the-eahp",
    },
    {
      title: "Fetoplacentar 2",
      desktop: require("../../../../Assets/Images/img-fetoplacentar-04@2x.png"),
      mobile: require("../../../../Assets/Images/img-fetoplacentar-04.png"),
      title: "Fetoplacentar 2",
      link: "evento/21st-meeting-of-the-eahp",
    },
    {
      title: "Fetoplacentar 3",
      desktop: require("../../../../Assets/Images/img-fetoplacentar-05@2x.png"),
      mobile: require("../../../../Assets/Images/img-fetoplacentar-05.png"),
      title: "Fetoplacentar 3",
      link: "evento/21st-meeting-of-the-eahp",
    },
  ];

  const SidebarMenuLinks = [
    {
      title: clube.name,
      link: "/socios/clubes-de-estudo/" + slug,
    },
    {
      title: "Membros",
      link: "/socios/clubes-de-estudo/" + slug + "/membros",
    },
    {
      title: "Eventos",
      link: "/socios/clubes-de-estudo/" + slug + "/eventos",
    },
    {
      title: "Galeria",
      link: "/socios/clubes-de-estudo/" + slug + "/galeria",
    },
    {
      title: "Documentos",
      link: "/socios/clubes-de-estudo/" + slug + "/documentos",
    },
  ];

  return (
    <Wrapper>
      <Container className="content">
        <PageTitle
          title={clube.name}
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "Sócios",
              link: "/socios",
            },
            {
              text: "Clubes de estudo",
              link: "/socios/clubes-de-estudo",
            },
            {
              text: clube.name,
              link: "/socios/clubes-de-estudo/" + slug + "",
            },
            {
              text: "Galeria",
            },
          ]}
          imageDesktop={clube.url}
          imageMobile={clube.url}
          imageAlt={clube.name}
          border={false}
          sidebar={
            <SidebarMenu
              navItens={SidebarMenuLinks}
              className="sidebar"
              margin="-60px"
            />
          }
          clubes={true}
        />

        <FlexInRow>
          <Row gutter={[30, 8]}>
            <Col span={19}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Divider orientation="left" orientationMargin="0">
                  <Title>
                    {galeriasClube.length
                      ? "Galeria"
                      : "Nenhuma galeria disponível"}
                  </Title>
                </Divider>
                <Row align="initial" className="row-wrap" gutter={[20, 20]}>
                  <>
                    {galeriasClube.length > 0
                      ? galeriasClube.map((item, index) => {
                          return (
                            <Col
                              span={8}
                              style={{ width: "100%" }}
                              key={"col-galeria-" + item.id}
                            >
                              <Space
                                align="start"
                                style={{ width: "100%", borderRadius: "10px" }}
                                direction="vertical"
                                size={5}
                              >
                                <div
                                  className={"card-galeria item item-" + index}
                                  style={{
                                    backgroundImage:
                                      "url(" + item.imagens[0].url + ")",
                                    backgroundSize: "cover",
                                    height: "175px",
                                  }}
                                  onClick={(e) => {
                                    setGalleryItens(item.imagens);
                                    setVisible(true);
                                  }}
                                >
                                  <>
                                    <Image
                                      key={"galeria-image-" + item.id}
                                      preview={{
                                        visible: false,
                                      }}
                                      width={"100%"}
                                      src={""}
                                    />
                                  </>
                                </div>
                                <div className="item__title">{item.titulo}</div>
                              </Space>
                            </Col>
                          );
                        })
                      : ""}
                    <div
                      style={{
                        display: "none",
                      }}
                    >
                      <Image.PreviewGroup
                        preview={{
                          visible: visible,
                          onVisibleChange: (vis) => {
                            setVisible(vis);
                          },
                        }}
                      >
                        {galleryItens.map((image_item, index) => {
                          return (
                            <>
                              <Image
                                key={"image-galeria-" + image_item.id}
                                src={image_item.url}
                                style={{ width: "100%" }}
                              />
                            </>
                          );
                        })}
                      </Image.PreviewGroup>
                    </div>
                  </>
                </Row>
              </Space>
            </Col>
          </Row>
        </FlexInRow>
      </Container>

      <SupportedBySpap title="Veja Também" />
    </Wrapper>
  );
}
