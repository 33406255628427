import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import styled from "styled-components";
import { Button, Modal, Row, Col, Input } from "antd";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";
import Container from "../../Components/Container";
import FlexInRow from "../../Components/FlexInRow";
import FlexInColumn from "../../Components/FlexInColumn";
import PageTitle from "../../Components/PageTitle";
import PageText from "../../Components/PageText";
import Pagination from "../../Components/Pagination";
import RecommendedLinks from "../../Components/RecommendedLinks";
import urlSlug from "url-slug";
import { Helmet } from "react-helmet";
const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  > .content {
    padding-bottom: 4vw;
  }

  img {
    width: 100%;
  }

  @media (max-width: 992px) {
    margin-top: 120px;
  }
`;

const FilterForPosts = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2.604vw;
  justify-content: center;
  padding: 3.646vw 0 4vw;

  .title {
    background: #0c1151 0% 0% no-repeat padding-box;
    padding: 2.344vw 6.823vw;
    text-align: center;
    font-size: 2.083vw;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 2.5vw;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
  }

  .content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 2.188vw;
    grid-row-gap: 2.188vw;
    width: 100%;

    > span {
      border-radius: 4px;
    }

    .ant-input-suffix {
      background: transparent;
      height: 100%;

      span.ant-input-clear-icon {
        display: flex;
      }
    }

    ::placeholder,
    input {
      font-size: 14px !important;
    }

    #name,
    #select-1,
    #select-2 {
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background-color: hsl(0, 0%, 100%);
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      outline: 0 !important;
      position: relative;
      -webkit-transition: all 100ms;
      transition: all 100ms;
      box-sizing: border-box;
      color: #222222;
      height: 28px;
    }

    .content__select {
      text-align: left;
      color: #222222;
    }

    #submitFilter {
      background: #f88436 0% 0% no-repeat padding-box;
      border: none;
      border-radius: 0.521vw;
      opacity: 1;
      font-size: 1.146vw;
      font-weight: bold;
      letter-spacing: 0px;
      line-height: 1.51vw;
      color: #ffffff;
      opacity: 1;
      padding: 0.677vw 1.563vw;
      max-width: 8.281vw;
      transition: 0.3s ease;
    }

    #submitFilter:hover {
      background-color: #e87426;
      color: #ececec;
    }
  }

  @media (max-width: 992px) {
    .title {
      font-size: 14px;
      line-height: 18px;
    }

    .content {
      grid-template-columns: 1fr;
      font-size: 12px;
      line-height: 16px;

      #submitFilter {
        font-size: 3.611vw;
        line-height: 5vw;
        padding: 2.778vw 4.167vw;
        max-width: 40vw;
        border-radius: 10px;
      }
    }
  }

  @media (min-width: 1920px) {
    .title {
      font-size: 40px;
      line-height: 48px;
    }

    .content {
      font-size: 18px;
      line-height: 42px;

      .ant-input-suffix {
        svg {
          font-size: 18px;
        }
      }

      #name,
      #select-1,
      #select-2 {
        height: 42px;
        font-size: 20px;
        line-height: 24px;
      }

      #submitFilter {
        font-size: 22px;
        line-height: 27px;
        max-width: 169px;
      }
    }
  }
`;

const ListPosts = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 3.021vw;
  grid-row-gap: 3.646vw;
  padding: 0 0 5.208vw;
  width: 100%;

  .item {
    border: 3px solid #0c1151;
    border-top: 12px solid #0c1151;
    border-radius: 0px 0px 20px 20px;
    opacity: 1;
  }

  .item__content {
    color: #0c1151;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 1.1vw;
    padding: 1.042vw 1.042vw 1.563vw;
    text-align: left;
  }

  .item__title {
    align-items: center;
    justify-content: flex-start;
    gap: 1.042vw;
    display: flex;
    flex-direction: row;

    img {
      width: 5.208vw;
      margin-top: -2.604vw;
    }

    .title {
      text-align: left;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0px;
      line-height: inherit;
      letter-spacing: 0px;
      color: #0c1151;
      opacity: 1;
    }
  }

  p {
    text-align: left;
    font-size: 0.938vw;
    letter-spacing: 0px;
    line-height: 1.25vw;
    color: #000000;
    margin: 0;
  }

  .item__cta {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    gap: 2.604vw;
    text-align: center;

    a {
      background: #f88436 0% 0% no-repeat padding-box;
      border: none;
      cursor: pointer;
      border-radius: 0.521vw;
      opacity: 1;
      font-size: 1.146vw;
      font-weight: bold;
      letter-spacing: 0px;
      line-height: 1.51vw;
      color: #ffffff;
      opacity: 1;
      padding: 0.677vw 1.563vw;
      max-width: 8.281vw;
      transition: 0.3s ease;
      text-decoration: none;
    }

    a:hover {
      background-color: #e87426;
      color: #ececec;
    }
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;

    p {
    }

    .item__title {
      img {
        width: 16.8vw;
      }

      .title {
      }
    }

    .item__content {
      gap: 20px;
    }

    .title {
      font-size: 22px !important;
    }

    .item__content p {
      font-size: 18px;
      line-height: 1.5;
    }

    .item__cta {
      a {
        border-radius: 2.778vw;
        font-size: 3.611vw;
        line-height: 5vw;
        padding: 2.778vw 4.167vw;
        max-width: 40.278vw;
      }
    }
  }

  @media (min-width: 1920px) {
    .item__cta a {
      font-size: 20px;
      line-height: 26px;
    }
  }
`;

const NoResults = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  * {
    text-align: center;
    font-size: 16px;
    line-height: inherit;
    font-weight: bold;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    margin: 1.094vw 0;
  }
`;

const optionsType = [
  { value: "Patologia geral", label: "Patologia geral" },
  { value: "Citologia", label: "Citologia" },
  { value: "Patologia cutânea", label: "Patologia cutânea" },
  {
    value: "Patologia da cabeça e pescoço",
    label: "Patologia da cabeça e pescoço",
  },
  { value: "Patologia das partes moles", label: "Patologia das partes moles" },
  {
    value: "Patologia do sistema nervosa",
    label: "Patologia do sistema nervoso",
  },
  { value: "Patologia digital", label: "Patologia digital" },
  { value: "Patologia endócrina", label: "Patologia endócrina" },
  { value: "Patologia fetoplacentar", label: "Patologia fetoplacentar" },
  { value: "Patologia gastrointestinal", label: "Patologia gastrointestinal" },
  { value: "Patologia ginecológica", label: "Patologia ginecológica" },
  { value: "Patologia hematolinfoide", label: "Patologia hematolinfoide" },
  { value: "Patologia hepatobiliar", label: "Patologia hepatobiliar" },
  { value: "Patologia mamária", label: "Patologia mamária" },
  { value: "Patologia molecular", label: "Patologia molecular" },
  { value: "Patologia osteoarticular", label: "Patologia osteoarticular" },
  { value: "Patologia pancreática", label: "Patologia pancreática" },
  { value: "Patologia pulmonar", label: "Patologia pulmonar" },
  { value: "Patologia urogenital", label: "Patologia urogenital" },
];

const animatedComponents = makeAnimated();

export default function FerramentasUteis(props) {
  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const [inputName, setInputName] = useState("");
  const [selectedType, setSelectedType] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [optionsInstituicao, setOptionsInstituicao] = useState([]);
  const [listUsersToShow, setListUsersToShow] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [telefone, setTelefone] = useState(false);
  const [email, setEmail] = useState(false);
  const [userContacto, setUserContacto] = useState(false);
  const [noResults, setNoResults] = useState("");
  const [mostraEmail, setMostraEmail] = useState(false);
  const [mostraTelefone, setMostraTelefone] = useState(false);

  useEffect(() => {
    fetch(`https://spapapi.phormuladev.com/api/all-users`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          setNoResults("Nenhum Patologista encontrado..");
          return;
        }
        let users2opiniao = data.users.filter((user) => {
          if (
            user.segunda_opiniao == 1 &&
            user.tipo_utilizador == "socio_aprovado"
          ) {
            return user;
          }
        });

        // Lista de usuarios para remover
        const emailsToRemove = [
          "willian.almeida@phormulagroup.com",
          "hugo.oliveira@phormulagroup.com",
          "hugomanuelpintooliveira@gmail.com",
          "nuno.simoes@phormulagroup.com",
        ];
        const updatedSocios = users2opiniao.filter(
          (item) => !emailsToRemove.includes(item.email)
        );
        users2opiniao = updatedSocios;

        setAllItems(users2opiniao);
        setListUsersToShow(users2opiniao);
        setCurrentItems(users2opiniao.slice(0, 9));

        let instituitionsToShow = [];

        users2opiniao.map((user) => {
          if (user.name && user.apelido) {
            user.slug = urlSlug(user.name + " " + user.apelido);
          } else {
            user.slug = urlSlug(user.nome_completo);
          }

          user.slug += "-" + user.id;

          if (user.instituicao != null && user.instituicao_2 != "") {
            if (
              !Object.values(instituitionsToShow).includes(user.instituicao)
            ) {
              instituitionsToShow.push({
                value: user.instituicao,
                label: user.instituicao,
              });
            }
          }

          if (
            user.instituicao_2 != null &&
            user.instituicao_2 != "" &&
            user.instituicao_2 != user.instituicao
          ) {
            if (
              !Object.values(instituitionsToShow).includes(user.instituicao_2)
            ) {
              instituitionsToShow.push({
                value: user.instituicao_2,
                label: user.instituicao_2,
              });
            }
          }
        });

        const uniqueArray = instituitionsToShow.filter((value, index) => {
          const _value = JSON.stringify(value);
          return (
            index ===
            instituitionsToShow.findIndex((obj) => {
              return JSON.stringify(obj) === _value;
            })
          );
        });

        setOptionsInstituicao(uniqueArray);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleSearch = () => {
    let allUsers = allItems;

    if (
      inputName.length == 0 &&
      selectedType.length == 0 &&
      selectedTeam.length == 0 &&
      setSelectedCity.length == 0
    ) {
      setListUsersToShow(allItems);
      setCurrentItems(listUsersToShow.slice(0, 9));
      return;
    }

    let name = inputName.length > 0 ? inputName.toLowerCase() : "";
    let instituicao = selectedTeam;
    let area = selectedType;
    let cidade = selectedCity;

    const normalizeString = (str) => {
      if (str)
        return str
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();
      else return "";
    };

    let novosUsersAListar = allUsers.filter((user) => {
      let showUser = true;
      if (name.length > 0) {
        if (
          !normalizeString(user.name).includes(normalizeString(name)) &&
          !normalizeString(user.apelido).includes(normalizeString(name))
        ) {
          showUser = false;
        }
      }

      if (instituicao.length > 0) {
        if (
          !normalizeString(user.instituicao).includes(
            normalizeString(instituicao)
          ) &&
          !normalizeString(user.instituicao_2).includes(
            normalizeString(instituicao)
          )
        ) {
          showUser = false;
        }
      }

      if (area.length > 0) {
        if (
          !normalizeString(user.area_subespecializacao).includes(
            normalizeString(area)
          ) &&
          !normalizeString(user.area_subespecializacao_2).includes(
            normalizeString(area)
          )
        ) {
          showUser = false;
        }
      }

      if (cidade.length > 0) {
        if (cidade !== user.cidade) {
          showUser = false;
        }
      }

      if (showUser) return user;
    });

    setListUsersToShow(novosUsersAListar);
    setCurrentItems(novosUsersAListar.slice(0, 9));

    if (!novosUsersAListar?.length > 0)
      setNoResults(
        "Por favor tente colocar outros critérios de pesquisa, não encontramos nenhum associado com os filtros colocados."
      );
  };

  const [optionsCity, setOptionsCity] = useState(() => {
    let citiesToShow = [];

    session_storage.cidades.map((cidade) => {
      citiesToShow.push({ value: cidade.nome, label: cidade.nome });
    });

    return citiesToShow;
  });

  const showModal = (user) => {
    setMostraEmail(user.mostra_dados_socios);
    setMostraTelefone(user.mostra_dados_publico);
    setTelefone(user.telefone);
    setEmail(user.email);
    setUserContacto(user.name + " " + user.apelido);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const setCapitalize = (str) => {
    var splitStr = str.toLowerCase().split(" ");

    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you

      // Assign it back to the array

      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }

    // Directly return the joined string

    return splitStr.join(" ");
  };

  return (
    <Wrapper>
      <Helmet>
        <title>
          Pedir uma segunda opinião | Sociedade Portuguesa de Anatomia
          Patológica - SPAP
        </title>
        <meta
          name="description"
          content="Pode pesquisar por patologistas de várias áreas de diferenciação para solicitar uma 2ª opinião."
        />
        <meta
          name="keywords"
          content="spap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>

      <Container className="content">
        <PageTitle
          title="Pedir uma 2ª opinião"
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "Ferramentas úteis",
              link: "/ferramentas-uteis",
            },
            {
              text: "Pedir uma 2ª opinião",
            },
          ]}
          imageDesktop={require("../../Assets/Images/img-opiniao@2x.png")}
          imageMobile={require("../../Assets/Images/img-opiniao.png")}
          imageAlt="Ferramentas úteis"
        />

        <PageText
          title="Pode pesquisar por patologistas de várias áreas de diferenciação para solicitar uma 2ª opinião. "
          text="Ainda não vê o seu nome nesta listagem? Preencha os dados de perfil <a href='/socios/dados-de-perfil/'>aqui</a>."
        />

        <FilterForPosts
          onSubmit={(e) => {
            e.preventDefault();
            handleSearch();
          }}
        >
          <h2 className="title">
            UTILIZE OS FILTROS PARA ENCONTRAR UM PATOLOGISTA
          </h2>
          <div className="content">
            <Input
              id="name"
              type="text"
              placeholder="Nome de patologista"
              allowClear
              onChange={(e) => setInputName(e.target.value)}
            />
            <Input
              id="select-1"
              type="text"
              placeholder="Área de Diferenciação"
              allowClear
              onChange={(e) => {
                setSelectedType(e.target.value);
              }}
            />
            <Input
              id="select-2"
              type="text"
              placeholder="Instituição"
              allowClear
              onChange={(e) => {
                setSelectedTeam(e.target.value);
              }}
            />

            {/*

  #TODO: deletar apos validação com cliente, caso formos utilizar a solução com input
}
            <Select
              id="select-1"
              className="content__select"
              placeholder="Área de Diferenciação"
              closeMenuOnSelect={true}
              components={animatedComponents}
              options={optionsType}
              isMulti
              hideSelectedOptions={true}
              onChange={(e) => {setSelectedType(e.map(item => {return item = item.value}))}}
            />
            <Select
              id="select-2"
              className="content__select"
              placeholder="Instituição"
              closeMenuOnSelect={true}
              options={optionsInstituicao}
              isClearable={true}
              onChange={(e) => {setSelectedTeam((e) ? e.value : '')}}
            />
{*/}
            <Select
              id="select-3"
              className="content__select"
              placeholder="Cidade"
              closeMenuOnSelect={true}
              components={animatedComponents}
              options={optionsCity}
              isClearable={true}
              onChange={(e) => {
                setSelectedCity(e ? e.value : "");
              }}
            >
              {Array.isArray(session_storage.cidades) &&
                session_storage.cidades.map((cidade) => (
                  <option value={cidade.nome}> {cidade.nome} </option>
                ))}
            </Select>
            <button id="submitFilter" type="submit">
              Pesquisar
            </button>
          </div>
        </FilterForPosts>
        {currentItems.length > 0 ? (
          <>
            <ListPosts>
              {currentItems.map((item, index) => {
                return (
                  <div className={"item item-" + (index + 1)}>
                    <div className="item__content">
                      <div className="item__title">
                        {item.avatar ? (
                          <>
                            <div
                              style={{
                                borderRadius: "10px",
                                border: "3px solid #0C1151",
                                marginTop: "-10px",
                                backgroundPosition: "center center",
                                backgroundSize: "cover",
                                backgroundImage:
                                  "url(https://spapapi.phormuladev.com/avatars/" +
                                  item.avatar +
                                  ")",
                                width: "75px",
                                height: "65px",
                              }}
                            ></div>
                          </>
                        ) : (
                          <>
                            <div
                              style={{
                                borderRadius: "10px",
                                border: "3px solid #0C1151",
                                marginTop: "-10px",
                                backgroundPosition: "center center",
                                backgroundSize: "contain",
                                backgroundImage: `url(https://spapapi.phormuladev.com/socio-default.svg)`,
                                width: "75px",
                                height: "65px",
                              }}
                            ></div>
                          </>
                        )}
                        <h4 className="title">
                          {item.name} {item.apelido ? item.apelido : ""}
                        </h4>
                      </div>

                      {(item.mostra_apresentacao_socios == 1 &&
                        item.tipo_utilizador == "socio_aprovado") ||
                      item.mostra_apresentacao_publico == 1 ? (
                        <>
                          <div className="item__content">
                            <p>
                              <b>Instituição: </b>
                              {item.instituicao != null &&
                              item.instituicao != ""
                                ? setCapitalize(item.instituicao)
                                : "N/A"}
                            </p>
                            <p>
                              <b>Grau: </b>
                              {item.grau && item.grau}
                              {item.grau_2 && item.grau
                                ? ", " + item.grau_2
                                : item.grau_2}
                              {!item.grau && !item.grau_2 && "N/A"}
                            </p>
                            <p>
                              <b>Diferenciação: </b>
                              {item.area_subespecializacao &&
                                item.area_subespecializacao}
                              {item.area_subespecializacao_2 &&
                              item.area_subespecializacao
                                ? ", " + item.area_subespecializacao_2
                                : item.area_subespecializacao_2}
                              {item.area_diferenciacao_3 &&
                              (item.area_subespecializacao ||
                                item.area_subespecializacao_2)
                                ? ", " + item.area_diferenciacao_3
                                : item.area_diferenciacao_3}
                              {!item.area_subespecializacao &&
                                !item.area_subespecializacao_2 &&
                                !item.area_diferenciacao_3 &&
                                "N/A"}
                            </p>
                            <p>{item.text}</p>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="item__content">
                            <p>
                              <b>
                                Dados de apresentação não partilhados pelo
                                sócio.
                              </b>
                            </p>
                          </div>
                        </>
                      )}
                      <div className="item__cta">
                        <a onClick={() => showModal(item)}>Contactar</a>
                        <Link to={"/socios/perfil/" + item.slug}>
                          Ver perfil
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </ListPosts>
            <Pagination
              items={listUsersToShow}
              itemsPerPage={9}
              onchange={(e) => {
                setCurrentItems(e);
              }}
            />
          </>
        ) : (
          <NoResults>
            <h3>{noResults}</h3>
          </NoResults>
        )}
      </Container>

      <Modal
        className="modal-contactar"
        width={500}
        title={"Contactos do utilizador " + userContacto}
        open={isModalOpen}
        onCancel={handleCancel}
      >
        <Row gutter={[12, 18]}>
          <Col className="div-contactar" span={24}>
            {mostraEmail == 1 && (
              <>
                <div
                  style={{
                    margin: "10px 0",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <MailOutlined
                    style={{ color: "#F88436" }}
                    twoToneColor="#F88436"
                  />
                  <span
                    style={{
                      textAlign: "center",
                      fontSize: "16px",
                      color: "#F88436",
                      fontWeight: "bold",
                    }}
                  >
                    Email
                  </span>
                  <a className="link-contacto" href={"mailto:" + email}>
                    {email}
                  </a>{" "}
                </div>
              </>
            )}
            {mostraTelefone == 1 && (
              <>
                <div
                  style={{
                    margin: "10px 0",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <PhoneOutlined
                    style={{ color: "#F88436" }}
                    twoToneColor="#F88436"
                  />
                  <span
                    style={{
                      textAlign: "center",
                      fontSize: "16px",
                      color: "#F88436",
                      fontWeight: "bold",
                    }}
                  >
                    Telefone
                  </span>
                  <a className="link-contacto" href={"tel:" + telefone}>
                    {telefone ? telefone : "N/A"}
                  </a>
                </div>
              </>
            )}
          </Col>
        </Row>
      </Modal>

      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={[
          {
            title: "História",
            link: "/spap/historia",
          },
          {
            title: "Órgãos sociais",
            link: "/spap/orgaos-sociais",
          },
          {
            title: "Bolsas e prémios",
            link: "/spap/bolsas-e-premios",
          },
          {
            title: "Vantagens de ser sócio",
            link: "/socios/conheca-as-vantagens",
          },
        ]}
      />
    </Wrapper>
  );
}
