import { useState, useEffect } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

import {
  Button,
  Row,
  Col,
  Drawer,
  Input,
  Space,
  DatePicker,
  Divider,
  Select,
  InputNumber,
  notification,
  Modal,
} from "antd";
import {
  CloseCircleOutlined,
  SearchOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
const { RangePicker } = DatePicker;

const VoucherCard = styled.div`
  margin: 15px;
  background: #e8e9f3;
  border-radius: 15px;
  padding: 30px;

  .voucher-btn,
  .ant-btn {
    border: 1px solid #494d7d !important;
    color: #494d7d !important;
    height: 45px !important;
    background: transparent !important;
    width: 100% !important;
    display: flex;
    align-iems: center;
    justify-content: center;
  }

  h5 {
    font-size: 20px !important;
    margin-bottom: 0;
  }

  .inputDados .ant-select-selection-item {
    color: #494d7d !important;
  }

  .inputDados.ant-select-disabled,
  .inputDados.ant-select-outlined.ant-select-disabled:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    background: #fff !important;
  }

  .apply-code-btn {
    border-radius: 12px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .input-voucher,
  .input-voucher .ant-input,
  .input-voucher ::placeholder {
    background: #494d7d !important;
    border: 1px solid #494d7d !important;
    color: #fff !important;
  }

  ::placeholder {
    color: #fff !important;
    opacity: 1 !important; /* Firefox */
  }

  .apply-code-btn,
  .inputCode {
    border: 1px solid #494d7d !important;
    color: #494d7d;
    height: 35px;
  }

  .inputDados {
    border: 1px solid #dfdfe4 !important;
    color: #494d7d;
    height: 45px !important;
    line-height: 45px;
    width: 100%;
  }

  .inputDados .ant-select-selector {
    border: 1px solid #dfdfe4 !important;
    color: #494d7d;
    min-height: 45px !important;
    width: 100%;
    height: auto !important;
  }

  .total {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
  }

  h6 {
    font-size: 16px;
  }

  .subtitle {
    font-size: 17px;
  }

  .view-btn {
    color: #fff;
    background: #494d7d;
    padding: 10px 30px;
    border-radius: 5px;
    text-decoration: none !important;
    margin-top: 20px;
  }

  .view-more {
    text-decoration: underline;
  }

  .delete-icon {
    font-size: 15px;
  }

  .margin-vertical-auto {
    margin: auto 0;
  }

  .next-btn,
  .next-btn span {
    background: #494d7d !important;
    color: #ffffff !important;
  }

  .pre-btn,
  .pre-btn span {
    background: #ffffff !important;
    color: #494d7d !important;
  }

  .ant-input-suffix {
    background: #494d7d !important;
    color: #fff !important;
  }

  .next-btn,
  pre-btn {
    height: auto !important;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .rangePicker {
    height: 45px;
    border: 1px solid #dfdfe4 !important;
    width: 100%;
  }

  .ant-input-disabled,
  .ant-select-disabled,
  .ant-picker-disabled {
    background: #fff !important;
  }

  .ant-select {
    min-height: 45px !important;
    height: auto !important;
  }

  .ant-picker-input input {
    color: #494d7d !important;
  }

  .voucher-manage {
    padding: 0 !important;
    background: #fff !important;
  }
`;

const ManageVoucher = styled.div`
  .voucher-btn,
  .ant-btn {
    border: 1px solid #494d7d !important;
    color: #494d7d !important;
    height: 45px !important;
    background: transparent !important;
    width: 100% !important;
    display: flex;
    align-iems: center;
    justify-content: center;
  }

  * {
    color: #494d7d;
  }

  h5 {
    font-size: 20px !important;
    margin-bottom: 0;
  }

  .apply-code-btn {
    border-radius: 12px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .input-voucher,
  .input-voucher .ant-input,
  .input-voucher.ant-input-affix-wrapper,
  .input-voucher input::placeholder {
    background: #494d7d !important;
    border: 1px solid #494d7d !important;
    color: #fff !important;
  }

  ::placeholder {
    color: #fff !important;
    opacity: 1 !important; /* Firefox */
  }

  .apply-code-btn,
  .inputCode {
    border: 1px solid #494d7d !important;
    color: #494d7d;
    height: 35px;
  }

  .inputDados .ant-select {
    height: 45px;
  }
  .inputDados:not(.ant-select) {
    border: 1px solid #dfdfe4 !important;
    color: #494d7d;
    height: 45px !important;
    width: 100%;
  }

  .inputDados .ant-select-selector {
    border: 1px solid #dfdfe4 !important;
    color: #494d7d;
    min-height: 45px !important;
    width: 100%;
    height: auto !important;
  }

  .inputDados .ant-select-selection-placeholder {
    line-height: 35px !important;
    height: 45px !important;
  }

  .total {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
  }

  h6 {
    font-size: 16px;
  }

  .subtitle {
    font-size: 17px;
  }

  .view-btn {
    color: #fff;
    background: #494d7d;
    padding: 10px 30px;
    border-radius: 5px;
    text-decoration: none !important;
    margin-top: 20px;
  }

  .view-more {
    text-decoration: underline;
  }

  .delete-icon {
    font-size: 15px;
  }

  .margin-vertical-auto {
    margin: auto 0;
  }

  .next-btn,
  .next-btn span {
    background: #494d7d !important;
    color: #ffffff !important;
  }

  .pre-btn,
  .pre-btn span {
    background: #ffffff !important;
    color: #494d7d !important;
  }

  .ant-input-suffix {
    background: #494d7d !important;
    color: #fff !important;
  }

  .next-btn,
  pre-btn {
    height: auto !important;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .rangePicker {
    height: 45px;
    border: 1px solid #dfdfe4 !important;
    width: 100%;
  }

  .voucher-manage {
    padding: 0 !important;
    background: #fff !important;
  }

  .inputDados .ant-input-number-input-wrap input {
    height: 45px !important;
  }
`;

export default function DrawerVouchers(props) {
  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const [openVoucherDrawer, setOpenVoucherDrawer] = useState(false);
  const [valueSearch, setValueSearch] = useState("");
  const [id, setId] = useState("");
  const [titulo, setTitulo] = useState("");
  const [codigo, setCodigo] = useState("");
  const [qtd, setQtd] = useState("");
  const [tipoValor, setTipoValor] = useState("");
  const [valor, setValor] = useState("");
  const [dataInicio, setDataInicio] = useState("");
  const [dataFecho, setDataFecho] = useState("");
  const [voucherEvents, setVoucherEvents] = useState([]);
  const [optionsEvents, setOptionsEvents] = useState([]);
  const [allVouchers, setAllVouchers] = useState([]);
  const [voucherId, setVoucherId] = useState("");
  const [allVouchersBkp, setAllVouchersBkp] = useState([]);
  const [editVoucherDrawer, setEditVoucherDrawer] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [voucherToDelete, setVoucherToDelete] = useState([]);

  useEffect(() => {
    setAllVouchers(props.vouchers);
    setAllVouchersBkp(props.vouchers);
    let eventsToShow = [];
    props.events?.map((event) => {
      eventsToShow.push({ value: event.id, label: event.titulo });
    });

    setOptionsEvents(eventsToShow);
  }, [props.vouchers]);

  const handleSearchClear = () => {
    setValueSearch("");
  };

  const onCloseDrawer = () => {
    props.onChange(false);
  };

  const handleDatePicker = (date, dateString) => {
    setDataInicio(dateString[0]);
    setDataFecho(dateString[1]);
  };

  const handleOkDelete = (voucher) => {
    const formData = new FormData();
    formData.append("id", voucher.id);
    fetch("https://spapapi.phormuladev.com/api/admin/delete-voucher", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    }).then((response) => {
      response
        .json()
        .then((data) => {
          console.log(data);
          setOpenDeleteModal(false);
          setAllVouchersBkp(data.vouchers);
          setAllVouchers(data.vouchers);
          notification.success({
            description: "O voucher foi apagado com sucesso.",
          });
        })
        .catch((err) => {});
    });
  };

  const handleSubmit = () => {
    const formData = new FormData();

    if (
      titulo.length == "" ||
      codigo.length == "" ||
      qtd.length == "" ||
      valor.length == "" ||
      tipoValor.length == "" ||
      dataInicio.length == "" ||
      dataFecho.length == ""
      // voucherEvents.length == ""
    ) {
      notification.error({
        description: "Preencha todos os campos.",
      });
      return;
    }

    formData.append("id", voucherId);
    formData.append("titulo", titulo);
    formData.append("codigo", codigo);
    formData.append("qtd", qtd);
    formData.append("valor", valor);
    formData.append("tipo", tipoValor);
    formData.append("dataInicio", dataInicio);
    formData.append("dataFecho", dataFecho);
    formData.append("events", voucherEvents);

    let url = !editVoucherDrawer ? "store-voucher" : "update-voucher";
    fetch("https://spapapi.phormuladev.com/api/admin/" + url, {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    }).then((response) => {
      response
        .json()
        .then((data) => {
          if (data?.message == "already_exists") {
            notification.error({
              description: "Já existe um voucher com o nome inserido.",
            });
          } else {
            setOpenVoucherDrawer(false);
            setAllVouchersBkp(data.vouchers);
            setAllVouchers(data.vouchers);

            let notifMessage = editVoucherDrawer
              ? "O voucher foi atualizado com sucesso."
              : "O voucher foi criado com sucesso.";
            notification.success({
              description: notifMessage,
            });
          }
        })
        .catch((err) => {});
    });
  };

  notification.config({
    placement: "bottomRight",
    bottom: 10,
    duration: 5,
    rtl: false,
  });

  const editVoucher = (voucher) => {
    setEditVoucherDrawer(true);
    setVoucherId(voucher.id);
    setTitulo(voucher.titulo);
    setCodigo(voucher.codigo);
    setQtd(voucher.qtd);
    setValor(voucher.valor);
    setTipoValor(voucher.tipo);
    setDataInicio(voucher.data_inicio);
    setDataFecho(voucher.data_fecho);
    setVoucherEvents(voucher?.events);

    setOpenVoucherDrawer(true);
  };

  const handleSearchVoucher = (value) => {
    let allVouchers = allVouchersBkp;

    setValueSearch(value);

    if (value.length == 0) {
      setAllVouchers(allVouchers);
      return;
    }

    let input = value.toLowerCase();

    let novosVouchers = allVouchers.filter((voucher) => {
      if (
        (voucher.titulo && voucher.titulo.toLowerCase().includes(input)) ||
        (voucher.codigo && voucher.codigo.toLowerCase().includes(input))
      ) {
        return voucher;
      }
    });

    setAllVouchers(novosVouchers);
  };

  const deleteVoucher = (voucher) => {};

  return (
    <>
      <Drawer
        title="Vouchers"
        width={900}
        onClose={onCloseDrawer}
        open={props.show}
      >
        <VoucherCard>
          <Row gutter={[16, 16]} className="mb-20">
            <Col span={7}>
              <Button
                onClick={() => {
                  setVoucherId("");
                  setTitulo("");
                  setCodigo("");
                  setQtd("");
                  setValor("");
                  setTipoValor([]);
                  setDataInicio("");
                  setDataFecho("");
                  setVoucherEvents([]);
                  setEditVoucherDrawer(false);
                  setOpenVoucherDrawer(true);
                }}
                className="voucher-btn"
              >
                <PlusOutlined /> Criar Voucher
              </Button>
            </Col>
            <Col span={17}>
              <Input
                style={{ color: "#fff" }}
                className="input-voucher"
                placeholder="Procurar Voucher"
                onChange={(e) => {
                  handleSearchVoucher(e.target.value);
                }}
                suffix={
                  valueSearch.length > 0 ? (
                    <CloseCircleOutlined
                      onClick={handleSearchClear}
                      style={{ color: "#EE5555" }}
                    />
                  ) : (
                    <SearchOutlined style={{ color: "#555" }} />
                  )
                }
              />
            </Col>
          </Row>
          {allVouchers.map((voucher) => {
            return (
              <>
                <Row key={"voucher" + voucher.id}>
                  <Col span={21}>
                    <Row gutter={[8, 8]}>
                      <Col span={16}>
                        <Input
                          value={voucher.titulo}
                          className="inputDados"
                          placeholder="Título"
                          disabled
                        />
                      </Col>
                      <Col span={8}>
                        <Input
                          value={voucher.codigo}
                          className="inputDados"
                          placeholder="Código"
                          disabled
                        />
                      </Col>
                      <Col span={4}>
                        <Input
                          value={voucher.qtd}
                          className="inputDados"
                          placeholder="Qtd."
                          disabled
                        />
                      </Col>
                      <Col span={4}>
                        <Input
                          value={voucher.valor}
                          className="inputDados"
                          disabled
                        />
                      </Col>
                      <Col span={6}>
                        <Select
                          value={voucher.tipo}
                          className="inputDados"
                          options={[
                            { value: "perc", label: "Percentagem (%)" },
                            { value: "euro", label: "Euro (€)" },
                          ]}
                          disabled
                        />
                      </Col>
                      <Col span={10}>
                        <RangePicker
                          className="rangePicker"
                          placeholder={["Dia de inicio", "Dia de fecho"]}
                          key={"range-data-event"}
                          format={"DD/MM/YYYY"}
                          showTime={false}
                          value={[
                            dayjs(voucher.data_inicio, "DD/MM/YYYY"),
                            dayjs(voucher.data_fecho, "DD/MM/YYYY"),
                          ]}
                          placement="bottomRight"
                          disabled
                        />
                      </Col>
                      <Col span={24}>
                        <Select
                          showSearch
                          mode="multiple"
                          className="inputDados"
                          placeholder="Eventos associados"
                          value={voucher?.events?.split(",").map(Number)}
                          options={optionsEvents}
                          disabled
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={3} align="right">
                    <Space size={20}>
                      <EditOutlined
                        className="delete-icon"
                        onClick={() => editVoucher(voucher)}
                      />
                      <DeleteOutlined
                        className="delete-icon"
                        onClick={() => {
                          setVoucherToDelete(voucher);
                          setOpenDeleteModal(true);
                        }}
                      />
                    </Space>
                  </Col>
                </Row>

                <Modal
                  open={openDeleteModal}
                  icon={<ExclamationCircleOutlined />}
                  title="Title"
                  onOk={() => handleOkDelete(voucherToDelete)}
                  okText="Confirmar"
                  cancelText="Cancelar"
                  onCancel={() => {
                    setOpenDeleteModal(false);
                  }}
                  footer={(_, { OkBtn, CancelBtn }) => (
                    <>
                      <CancelBtn></CancelBtn>
                      <OkBtn></OkBtn>
                    </>
                  )}
                >
                  <p style={{ fontSize: "16px" }}>
                    Tem a certeza que pretender apagar o voucher{" "}
                    <b>{voucherToDelete.titulo}</b>?
                  </p>
                </Modal>
                <Divider />
              </>
            );
          })}
          {allVouchers.length == 0 && valueSearch.length > 0 && (
            <p className="align-center mt-5">
              Não foram encontrados vouchers para a sua pesquisa.
            </p>
          )}
          {allVouchers.length == 0 && valueSearch.length == 0 && (
            <p className="align-center mt-5">
              Comece por criar um voucher no botão a cima "Criar Voucher".
            </p>
          )}
        </VoucherCard>
      </Drawer>
      <Drawer
        key={editVoucherDrawer ? "edit-" + voucherId : "voucher"}
        title={editVoucherDrawer ? "Editar Voucher" : "Criar Voucher"}
        width={600}
        onClose={() => {
          setOpenVoucherDrawer(false);
        }}
        open={openVoucherDrawer}
        extra={
          <Space>
            <Button
              onClick={() => {
                setOpenVoucherDrawer(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              onClick={handleSubmit}
              type="submit"
              style={{
                borderRadius: "6px",
                background: "#494D7D",
                color: "#fff",
              }}
            >
              Guardar
            </Button>
          </Space>
        }
      >
        <ManageVoucher>
          <Row gutter={[16, 16]} className="mb-20">
            <Col span={24} align="center">
              <label>Título do Voucher</label>
              <Input
                value={titulo}
                className="inputDados"
                placeholder="Título"
                onChange={(e) => setTitulo(e.target.value)}
              />
            </Col>
            <Col span={19} align="center">
              <label>Código do Voucher</label>
              <Input
                value={codigo}
                className="inputDados"
                placeholder="Código"
                onChange={(e) => setCodigo(e.target.value)}
              />
            </Col>
            <Col span={5} align="center">
              <label>Qtd.</label>
              <InputNumber
                value={qtd}
                className="inputDados"
                placeholder="Qtd."
                min={1}
                onChange={(e) => setQtd(e)}
              />
            </Col>
            <Col span={8} align="center">
              <label>Valor</label>
              <InputNumber
                value={valor}
                className="inputDados"
                placeholder="Valor"
                onChange={(e) => setValor(e)}
                min={1}
                max={tipoValor == "perc" ? "100" : ""}
              />
            </Col>
            <Col span={16} align="center">
              <label>Tipo de Valor</label>
              <Select
                showSearch
                value={tipoValor}
                className="inputDados"
                placeholder="Tipo de Valor"
                allowClear
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  setTipoValor(e);
                  if (e == "perc" && valor > 100) {
                    setValor(100);
                  }
                }}
                options={[
                  { value: "perc", label: "Percentagem (%)" },
                  { value: "euro", label: "Euro (€)" },
                ]}
              />
            </Col>
            <Col span={24} align="center">
              <label>Datas Limite</label>
              <RangePicker
                className="rangePicker"
                placeholder={["Dia de inicio", "Dia de fecho"]}
                key={"range-data-event"}
                format={"DD/MM/YYYY"}
                showTime={false}
                onChange={(date, dateString) =>
                  handleDatePicker(date, dateString)
                }
                value={
                  dataInicio.length > 0 && dataFecho.length > 0
                    ? [
                        dayjs(dataInicio, "DD/MM/YYYY"),
                        dayjs(dataFecho, "DD/MM/YYYY"),
                      ]
                    : undefined
                }
                placement="bottomRight"
              />
            </Col>
            <Col span={24} align="center">
              <label>Eventos associados</label>
              <Select
                showSearch
                mode="multiple"
                className="inputDados"
                placeholder="Eventos associados"
                onChange={(e) => {
                  setVoucherEvents(e.toString());
                }}
                defaultValue={
                  voucherEvents?.length > 0
                    ? voucherEvents?.split(",").map(Number)
                    : []
                }
                options={optionsEvents}
              />
            </Col>
          </Row>
          <Divider />
        </ManageVoucher>
      </Drawer>
    </>
  );
}
