import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Container from "../../../../Components/Container";
import PageTitle from "../../../../Components/PageTitle";
import PageText from "../../../../Components/PageText";
import FlexInColumn from "../../../../Components/FlexInColumn";
import FlexInRow from "../../../../Components/FlexInRow";
import SidebarMenu from "../../../../Components/SidebarMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import urlSlug from "url-slug";
import { Helmet } from "react-helmet";
import { parse } from "date-fns";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  img {
    width: 100%;
  }

  .link {
    color: ${(props) => props.theme.orange};
    font-weight: bold;
    place-self: flex-start;
    transition: 0.3s ease;
    text-decoration: none;
    text-align: left;
  }

  .link:hover {
    color: ${(props) => props.theme.orangeHover};
  }

  .link .icon {
    color: ${(props) => props.theme.blue};
    margin-right: 5px;
  }

  .link:hover .icon {
    color: ${(props) => props.theme.blueHover};
  }

  @media (max-width: 992px) {
    margin-top: 120px;
  }
`;

const SectionCategories = styled.section`
  padding: 5.208vw 0 0;
`;

const CategoriesNav = styled.div`
  display: grid;
  grid-template-columns: 18.594vw 18.594vw;
  grid-column-gap: 2.083vw;

  .item {
    align-items: center;
    background-color: #cbe0ea;
    border-radius: 1.042vw 1.042vw 0 0;
    color: #287092;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    line-height: inherit;
    letter-spacing: 0px;
    transition: 0.3s ease;
    cursor: pointer;
    padding: 1.563vw 1.042vw;
  }

  .item:hover {
    background-color: #97c1d5;
    color: #494d7d;
  }

  .item-${(props) => props.itemOffset} {
    background-color: #97c1d5;
    color: #494d7d;
  }

  @media (max-width: 992px) {
    grid-template-columns: auto auto;
    grid-column-gap: 10px;

    .item {
      font-size: 4.333vw;
      line-height: 5.444vw;
    }
  }
`;

const CategoriesContent = styled.div`
  background-color: #97c1d5;
  padding: 4.167vw 0;

  .item {
    display: none;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s ease;
  }

  .item-${(props) => props.itemOffset} {
    display: flex;
    opacity: 1;
    pointer-events: auto;
  }

  .item-1 picture {
    max-width: 30.417vw;
  }
`;

const SectionCards = styled.div`
  display: grid;
  gap: 2.604vw;
  grid-template-columns: 1fr 1fr;

  .card_desc p {
    color: #000;
  }

  .card {
    border: 0.156vw solid #494d7d;
    border-radius: 1.042vw;
    justify-content: space-between;
    overflow: hidden;
    display: grid;
    grid-template-columns: 2fr 1fr;
  }

  .card__content {
    padding: 1.042vw;
  }

  .card__image {
    border: none;
    overflow: hidden;
    width: 13.542vw;

    img {
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }

  .card__title {
    text-align: left;
    font-size: 16px;
    line-height: inherit;
    font-weight: bold;
    letter-spacing: 0px;
    color: ${(props) => props.theme.blue};
    opacity: 1;
    margin: 1.094vw 0;
  }

  .card__text {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 0.729vw;
  }

  .card__cta {
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: ${(props) => props.theme.blue};
    border-radius: 0.521vw;
    opacity: 1;
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    text-decoration: none;
    color: #ffffff;
    opacity: 1;
    width: 8.021vw;
    height: 2.396vw;
    transition: 0.3s ease;
  }

  .card__cta:hover {
    background-color: ${(props) => props.theme.blueHover};
    color: #e5e5e5;
  }

  .card__date {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.521vw;
  }

  .card__date svg {
    font-size: 1.302vw;
  }

  .card__date svg path {
    fill: #000000;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;

    .card {
      grid-template-columns: 1fr;
      gap: 20px;
      border-radius: 12px;
      overflow: hidden;
    }

    .card__image {
      border-radius: 0;
      grid-row: 1;
      height: 220px;
      width: 100%;
    }

    .card__text,
    .card__cta,
    .card__date,
    .card__date svg {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }

    .card__cta {
      border-radius: 8px;
      height: 40px;
      margin: 10px 20px;
      width: auto;
    }

    .card__title {
      font-size: 4.333vw;
      line-height: 5.444vw;
    }
  }

  @media (min-width: 1920px) {
    .card__text,
    .card__cta,
    .card__date,
    .card__date svg {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

const NoResults = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  * {
    text-align: center;
    font-size: 16px;
    line-height: inherit;
    font-weight: bold;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    margin: 1.094vw 0;
  }

  @media (max-width: 992px) {
    * {
      font-size: 4.333vw;
      line-height: 5.444vw;
    }
  }
`;

export default function Eventos(props) {
  const [allItems, setAllItems] = useState([]);
  const [eventosProximos, setEventosProximos] = useState([]);
  const [eventosArquivados, setEventosArquivados] = useState([]);
  const [noResults, setNoResults] = useState("");

  const month = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const parseDMY = (s) => {
    let [d, m, y] = s.split(/\D/);
    return new Date(y, m - 1, d);
  };

  const getEventos = (allEventos) => {
    let eventosArquivadosToShow = [];
    let eventosProximosToShow = [];

    const customFormat = "dd/MM/yyyy";

    let today = new Date().toLocaleDateString();

    if (allEventos?.length > 0) {
      allEventos.map((evento) => {
        let mostraEvento = true;
        evento.slug = urlSlug(evento.titulo);
        evento.slug += "-" + evento.id;
        if (evento.agendar_post != null) {
          let date = evento.agendar_post.trim();
          if (date > today) {
            mostraEvento = false;
          }
        }
        if (mostraEvento) {
          if (evento.data_inicio) {
            const [dateInicioValues, timeInicioValues] =
              evento.data_inicio.split(" ");
            const [dayInicio, monthInicio, yearInicio] =
              dateInicioValues.split("/");
            const [dateFechoValues, timeFechoValues] =
              evento.data_fecho.split(" ");
            const [dayFecho, monthFecho, yearFecho] =
              dateFechoValues.split("/");
            evento.dataAOrdenar =
              monthInicio + "/" + dayInicio + "/" + yearInicio;
            const monthInicioToString = month[Number(monthInicio - 1)];
            const monthFechoToString = month[Number(monthFecho - 1)];
            evento.monthInicio = monthInicio;
            evento.yearInicio = yearInicio;
            evento.url = evento.imagem_destaque
              ? "https://spapapi.phormuladev.com/eventos/" +
                evento.id +
                "/" +
                evento.imagem_destaque
              : "https://spapapi.phormuladev.com/clubes/clube-estudo-default.svg";
            if (
              monthInicio == monthFecho &&
              yearInicio === yearFecho &&
              dayInicio === dayFecho
            ) {
              evento.date =
                dayInicio + " " + monthInicioToString + " " + yearInicio;
            } else if (monthInicio == monthFecho && yearInicio === yearFecho) {
              evento.date =
                dayInicio +
                "-" +
                dayFecho +
                " " +
                monthInicioToString +
                " " +
                yearInicio;
            } else if (
              dayInicio == dayFecho &&
              monthInicio == monthFecho &&
              yearInicio === yearFecho
            ) {
              evento.date =
                dayInicio + " " + monthInicioToString + " " + yearInicio;
            } else if (monthInicio != monthFecho) {
              evento.date =
                dayInicio +
                " " +
                monthInicioToString +
                " a " +
                dayFecho +
                " " +
                monthFechoToString +
                " " +
                yearFecho;
            }
            let dataInicioCmp = parse(
              evento.data_inicio.split(" ")[0],
              customFormat,
              new Date()
            );
            let dataFechoCmp = parse(
              evento.data_fecho.split(" ")[0],
              customFormat,
              new Date()
            );
            let todayCmp = parse(
              new Date().toLocaleDateString(),
              customFormat,
              new Date()
            );

            console.log(dataInicioCmp);
            console.log(todayCmp);

            if (dataInicioCmp < todayCmp && dataFechoCmp < todayCmp) {
              eventosArquivadosToShow.push(evento);
            } else {
              eventosProximosToShow.push(evento);
            }

            console.log(eventosProximosToShow);
          }
        }
      });
    }
    eventosProximosToShow.sort(function (a, b) {
      return new Date(a.dataAOrdenar) - new Date(b.dataAOrdenar);
    });

    setEventosArquivados(eventosArquivadosToShow);
    setEventosProximos(eventosProximosToShow);
  };

  useEffect(() => {
    fetch(`https://spapapi.phormuladev.com/api/all-nispap-eventos`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          console.log("nenhum evento");
          setNoResults("Nenhum evento encontrado..");
        }
        getEventos(data.eventos);
      })
      .catch((error) => console.error(error));
  }, []);

  const SidebarMenuLinks = [
    {
      title: "Eventos NISPAP",
      link: "/nispap/novidades/eventos-nispap",
    },
    {
      title: "Galeria de fotos",
      link: "/nispap/novidades/galeria-de-fotos",
    },
  ];

  const [itemOffset, setItemOffset] = useState(0);

  return (
    <Wrapper>
      <Helmet>
        <title>
          Novidades e Eventos NISPAP | Núcleo de Internos Sociedade Portuguesa
          de Anatomia Patológica - NISPAP
        </title>
        <meta
          name="description"
          content="O Núcleo tem como principal objetivo organizar Cursos, Palestras, Congressos e Encontros Nacionais que proporcionem momentos formativos, quer teóricos quer práticos, para complementação da formação. Também temos interesse em proporcionar momentos de convívio fora do contexto de trabalho."
        />
        <meta
          name="keywords"
          content="nispap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <Container>
        <PageTitle
          title="Eventos NISPAP"
          nispap={true}
          path={[
            {
              text: "NISPAP",
              link: "/nispap",
            },
            {
              text: "Novidades",
              link: "/nispap/novidades",
            },
            {
              text: "Eventos NISPAP",
            },
          ]}
          imageDesktop={require("../../../../Assets/Images/NISPAP/img-eventos@2x.png")}
          imageMobile={require("../../../../Assets/Images/NISPAP/img-eventos.png")}
          imageAlt="Eventos NISPAP"
          sidebar={<SidebarMenu navItens={SidebarMenuLinks} nispap={true} />}
        />
        <FlexInRow gap="6.146vw">
          <FlexInColumn gap="12.396vw" mobile="column">
            <FlexInRow gap="0">
              <PageText text="O Núcleo tem como principal objetivo organizar Cursos, Palestras, Congressos e Encontros Nacionais que proporcionem momentos formativos, quer teóricos quer práticos, para complementação da formação. Também temos interesse em proporcionar momentos de convívio fora do contexto de trabalho." />
            </FlexInRow>
          </FlexInColumn>
        </FlexInRow>
      </Container>

      <SectionCategories>
        <Container>
          <CategoriesNav itemOffset={itemOffset}>
            <div className="item item-0" onClick={(e) => setItemOffset(0)}>
              Eventos Futuros
            </div>
            <div className="item item-1" onClick={(e) => setItemOffset(1)}>
              Eventos Passados
            </div>
          </CategoriesNav>
        </Container>
        <CategoriesContent itemOffset={itemOffset}>
          <Container>
            <div className="item item-0">
              {eventosProximos.length > 0 ? (
                <>
                  <SectionCards>
                    {eventosProximos.map((item, index) => {
                      let titlePreview = item.titulo.slice(0, 60);
                      if (item.titulo.length > 60) titlePreview += " (...)";
                      let textPreview = item.descricao
                        .replace(/<(h2|h3|img|b|strong)[^>]*>/g, "<p>")
                        .slice(0, 120);
                      if (item.descricao.length > 120) textPreview += " (...)";
                      return (
                        <div className={"card card-" + index}>
                          <div className="card__content">
                            <div className="card__date">
                              <FontAwesomeIcon icon={faCalendarDay} />
                              {item.date}
                            </div>
                            <h3 className="card__title">{titlePreview}</h3>
                            <div
                              className="card_desc"
                              dangerouslySetInnerHTML={{ __html: textPreview }}
                            ></div>
                            <Link
                              to={"/nispap/novidades/evento/" + item.slug}
                              className="card__cta"
                            >
                              Saber mais »
                            </Link>
                          </div>
                          <div
                            className="item__image"
                            style={{
                              backgroundImage: "url(" + item.url + ")",
                              backgroundSize: "cover",
                              backgroundPosition: "center center",
                            }}
                          >
                            <picture style={{ opacity: "0" }}>
                              <source
                                srcSet={item.url}
                                media="(min-width: 990px)"
                              />
                              <img src={item.url} alt={item.alt} />
                            </picture>{" "}
                          </div>
                        </div>
                      );
                    })}
                  </SectionCards>
                </>
              ) : (
                <NoResults>
                  <h3>{noResults}</h3>
                </NoResults>
              )}
            </div>
            <div className="item item-1">
              {eventosArquivados.length > 0 ? (
                <>
                  <SectionCards>
                    {eventosArquivados.map((item, index) => {
                      let titlePreview = item.titulo.slice(0, 60);
                      if (item.titulo.length > 60) titlePreview += " (...)";
                      let textPreview = item.descricao
                        .replace(/<(h2|h3|img|b|strong)[^>]*>/g, "<p>")
                        .slice(0, 120);
                      if (item.descricao.length > 120) textPreview += " (...)";
                      return (
                        <div className={"card card-" + index}>
                          <div className="card__content">
                            <div className="card__date">
                              <FontAwesomeIcon icon={faCalendarDay} />
                              {item.date}
                            </div>
                            <h3 className="card__title">{titlePreview}</h3>
                            <div
                              className="card_desc"
                              dangerouslySetInnerHTML={{ __html: textPreview }}
                            ></div>
                            <Link
                              to={"/nispap/novidades/evento/" + item.slug}
                              className="card__cta"
                            >
                              Saber mais »
                            </Link>
                          </div>
                          <div
                            className="item__image"
                            style={{
                              backgroundImage: "url(" + item.url + ")",
                              backgroundSize: "cover",
                              backgroundPosition: "center center",
                            }}
                          >
                            <picture style={{ opacity: "0" }}>
                              <source
                                srcSet={item.url}
                                media="(min-width: 990px)"
                              />
                              <img src={item.url} alt={item.alt} />
                            </picture>{" "}
                          </div>
                        </div>
                      );
                    })}
                  </SectionCards>
                </>
              ) : (
                <NoResults>
                  <h3>{noResults}</h3>
                </NoResults>
              )}
            </div>
          </Container>
        </CategoriesContent>
      </SectionCategories>
    </Wrapper>
  );
}
