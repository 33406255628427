import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Slider from "react-slick";
import urlSlug from "url-slug";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDay,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import Container from "../Container";
import FlexInRow from "../FlexInRow";
import FlexInColumn from "../FlexInColumn";

const Wrapper = styled.div`
  background-image: url(${require("../../Assets/Images/BG-iniciativas-apoiadas@2x.png")});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 4.167vw 0;
  overflow-x: hidden;

  .container {
    margin-right: 0;
    max-width: 100%;
  }

  .title {
    width: calc(100vw - 14.584vw);
  }

  .descricao {
    font-size: 16px;
    line-height: 1.4;
    color: #212529;
  }

  .slick-slide > div {
    height: 100%;
  }

  .card-swipe {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1.302vw;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-bottom: 20px;

    ::-webkit-scrollbar {
      height: 8px;
    }
    ::-webkit-scrollbar-thumb:horizontal {
      background: #888;
      border-radius: 10px;
    }

    .content {
      background-color: #ffffff;
      border-radius: 1.042vw;
      opacity: 1;
      width: 39.166vw;
      flex-grow: 0;
      flex-shrink: 0;
      height: 100% !important;
    }

    .item__content {
      padding: 0.781vw 1.042vw;
      width: 25.625vw;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .item__image {
      border: none;
      border-radius: 1.042vw 0px 0px 1.042vw;
      overflow: hidden;
      width: 15.625vw;

      img {
        height: 100%;
        object-fit: cover;
      }
    }

    .item__title {
      text-align: left;
      font-size: 21px;
      line-height: inherit;
      font-weight: bold;
      letter-spacing: 0px;
      color: #0c1151;
      opacity: 1;
      margin-top: 10px;
    }

    .item__text {
      text-align: left;
      font-size: 0.938vw;
      line-height: 1.25vw;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
    }

    .item__cta {
      align-items: center;
      justify-content: center;
      display: flex;
      background-color: #0c1151;
      border-radius: 0.521vw;
      opacity: 1;
      text-align: left;
      font-size: 0.938vw;
      line-height: 1.25vw;
      font-weight: bold;
      letter-spacing: 0px;
      text-decoration: none;
      color: #ffffff;
      opacity: 1;
      width: 8.021vw;
      height: 2.396vw;
      transition: 0.3s ease;
    }

    .item__cta:hover {
      background-color: #8688a8;
      color: #e5e5e5;
    }

    .item__date {
      text-align: left;
      font-size: 16px;
      line-height: 1.25vw;
      font-weight: bold;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 0.417vw;
    }

    .item__date svg {
      font-size: 0.938vw;
    }
  }

  .slick-list,
  .slick-track {
    width: 100%;
  }

  .slick-track {
    display: flex;
    width: 175% !important;
  }

  .slick-slider {
    position: relative;
    padding-bottom: 50px;
  }

  .slick-arrow {
    position: absolute;
    bottom: 0;
    font-size: 32px;
    cursor: pointer;
  }

  .slick-prev {
    left: 40vw;
  }

  .slick-next {
    left: calc(40vw + 50px);
  }

  .slick-arrow path {
    fill: ${(props) => props.theme.orange};
    transition: 0.3s ease;
  }

  .slick-arrow:hover path {
    fill: ${(props) => props.theme.orangeHover};
  }

  @media (max-width: 992px) {
    .title {
      width: calc(100% - 40px);
    }

    .card-swipe {
      p {
        font-size: 3.333vw;
        line-height: 4.444vw;
      }

      .content {
        border-radius: 2.778vw;
        width: calc(100vw - 40px);
      }

      .descricao p {
        text-align: center;
      }

      .item__content {
        padding: 6.944vw;
        width: 100%;
      }

      .item__image {
        border-radius: 2.778vw;
        width: 100%;
        height: 300px !important;
      }

      .item__title {
        font-size: 6.111vw;
        line-height: 7.5vw;
        text-align: center;
        margin-top: 3.889vw;
      }

      .item__text {
        text-align: center;
      }

      .item__date {
        font-size: 14px;
        line-height: 18px;
        place-content: center;

        svg {
          font-size: 2.778vw;
          margin-right: 1.389vw;
        }
      }

      .item__cta {
        border-radius: 2.778vw;
        font-size: 3.611vw;

        margin: 7.222vw auto 0;
        height: 10.278vw;
        width: 32.778vw;
      }
    }

    .slick-track {
      width: max-content !important;
    }

    .slick-prev {
      left: 35vw;
    }

    .slick-next {
      left: calc(35vw + 50px);
    }

    .slick-slider {
      padding-bottom: 60px;
      margin-bottom: 15px;
    }
  }

  @media (min-width: 1920px) {
    .title {
      width: auto;
    }

    .slick-track .slick-slide,
    .slick-track .slick-slide-active {
      padding: 10px;
    }

    .card-swipe {
      .content {
        width: 100%;
      }
    }
  }
`;

const Title = styled.h2`
  color: #0c1151;
  font-size: 2.083vw;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 2.5vw;
  opacity: 1;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 992px) {
    font-size: 5.556vw;
    line-height: 6.667vw;
  }
`;

export default function SupportedBySpap(props) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [eventos, setEventos] = useState([]);
  const month = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const parseDMY = (s) => {
    let [d, m, y] = s.split(/\D/);
    return new Date(y, m - 1, d);
  };

  const getEventos = (allEventos) => {
    let eventosToShow = [];
    let today = new Date().toLocaleDateString();

    if (allEventos?.length > 0) {
      allEventos.map((evento) => {
        evento.slug = urlSlug(evento.titulo);
        evento.slug += "-" + evento.id;
        let mostraEvento = true;
        if (evento.agendar_post != null) {
          let date = evento.agendar_post.trim();
          if (date > today) {
            mostraEvento = false;
          }
        }
        if (parseDMY(evento.data_inicio) < parseDMY(today)) {
          mostraEvento = false;
        }
        if (mostraEvento) {
          if (evento.data_inicio) {
            const [dateInicioValues, timeInicioValues] =
              evento.data_inicio.split(" ");
            const [dayInicio, monthInicio, yearInicio] =
              dateInicioValues.split("/");
            const [dateFechoValues, timeFechoValues] =
              evento.data_fecho.split(" ");
            const [dayFecho, monthFecho, yearFecho] =
              dateFechoValues.split("/");
            const monthInicioToString = month[Number(monthInicio - 1)];
            const monthFechoToString = month[Number(monthFecho - 1)];
            evento.monthInicio = monthInicio;
            evento.yearInicio = yearInicio;
            evento.url = evento.imagem_destaque
              ? "https://spapapi.phormuladev.com/eventos/" +
                evento.id +
                "/" +
                evento.imagem_destaque
              : "https://spapapi.phormuladev.com/clubes/clube-estudo-default.svg";
            if (
              monthInicio == monthFecho &&
              yearInicio === yearFecho &&
              dayInicio === dayFecho
            ) {
              evento.date =
                dayInicio + " " + monthInicioToString + " " + yearInicio;
            } else if (monthInicio == monthFecho && yearInicio === yearFecho) {
              evento.date =
                dayInicio +
                "-" +
                dayFecho +
                " " +
                monthInicioToString +
                " " +
                yearInicio;
            } else if (
              dayInicio == dayFecho &&
              monthInicio == monthFecho &&
              yearInicio === yearFecho
            ) {
              evento.date =
                dayInicio + " " + monthInicioToString + " " + yearInicio;
            } else if (monthInicio != monthFecho) {
              evento.date =
                dayInicio +
                " " +
                monthInicioToString +
                " a " +
                dayFecho +
                " " +
                monthFechoToString +
                " " +
                yearFecho;
            }
            eventosToShow.push(evento);
          }
        }
      });
    }
    eventosToShow.sort(function (a, b) {
      let aDate = parseDMY(a.data_inicio);
      let bDate = parseDMY(b.data_inicio);
      return aDate - bDate;
    });
    setEventos(eventosToShow);
  };

  useEffect(() => {
    fetch(`https://spapapi.phormuladev.com/api/all-eventos`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        getEventos(data.eventos);
      })
      .catch((error) => console.error(error));
  }, []);

  const settings = {
    className: "center card-swipe",
    arrows: true,
    prevArrow: <FontAwesomeIcon icon={faChevronLeft} />,
    nextArrow: <FontAwesomeIcon icon={faChevronRight} />,
    infinite: false,
    slidesToShow: windowWidth > 992 ? 2 : 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    afterChange: function (index) {},
  };

  return (
    <>
      {eventos.length > 0 && (
        <Wrapper>
          <Container className="container">
            <FlexInRow gap="3.594vw">
              <Title className="title">
                {props.title ? props.title : "DESTAQUES PARA SI"}
              </Title>
              <Slider {...settings}>
                {eventos.slice(0, 3).map((item, index) => {
                  let titlePreview = item.titulo.slice(0, 40);
                  if (item.titulo.length > 40) titlePreview += " (...)";
                  let textPreview = item.descricao
                    ?.replace(/<(h2|h3|img|b|strong)[^>]*>/g, "<p>")
                    .replace(/[\r\n]+/g, "")
                    .slice(0, 120);
                  if (item.length > 120) textPreview += " (...)";
                  return (
                    <FlexInColumn className={"item-" + index + " content"}>
                      {windowWidth > 992 && (
                        <div
                          className="item__image"
                          style={{
                            backgroundImage: "url(" + item.url + ")",
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                          }}
                        ></div>
                      )}
                      <div className="item__content">
                        <div>
                          <div className="item__date">
                            <FontAwesomeIcon icon={faCalendarDay} />
                            {item.date}
                          </div>
                          <h3 className="item__title">{titlePreview}</h3>
                          {titlePreview.length < 37 && (
                            <p style={{ paddingBottom: "6px" }} />
                          )}
                          {windowWidth > 992 ? (
                            <>
                              <div
                                className="descricao"
                                dangerouslySetInnerHTML={{
                                  __html: textPreview,
                                }}
                              ></div>
                            </>
                          ) : (
                            <>
                              <div
                                className="descricao"
                                style={{}}
                                dangerouslySetInnerHTML={{
                                  __html: textPreview,
                                }}
                              ></div>
                            </>
                          )}
                          {windowWidth <= 992 && (
                            <div
                              className="item__image"
                              style={{
                                backgroundImage: "url(" + item.url + ")",
                                backgroundSize: "cover",
                                backgroundPosition: "center center",
                                height: "185px",
                              }}
                            >
                              {/* <picture style={{opacity: '0'}}>
                                <source srcSet={item.url} media="(min-width: 990px)"  />
                                <img src={item.url} alt={item.alt} />
                              </picture>        */}{" "}
                            </div>
                          )}
                        </div>
                        <Link
                          to={"/agenda/evento/" + item.slug}
                          className="item__cta"
                        >
                          Saber mais »
                        </Link>
                      </div>
                    </FlexInColumn>
                  );
                })}
              </Slider>
            </FlexInRow>
          </Container>
        </Wrapper>
      )}
    </>
  );
}
