import styled from "styled-components";
import { Link, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";

import Container from "../../../../../Components/Container";
import FlexInColumn from "../../../../../Components/FlexInColumn";
import FlexInRow from "../../../../../Components/FlexInRow";
import PageTitle from "../../../../../Components/PageTitle";

import IconSubscribe from "../../../../../Assets/Images/NISPAP/icon-inscrever.svg";
import IconSeeMore from "../../../../../Assets/Images/NISPAP/icon-saber-mais.svg";
import IconShare from "../../../../../Assets/Images/NISPAP/icon-partilhar.svg";
import IconReturn from "../../../../../Assets/Images/NISPAP/icon-voltar.svg";
import { notification } from "antd";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #000000;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: left;
  width: 100%;
  margin-top: 7.813vw;

  img {
    width: 100%;
  }

  ul {
    list-style: none;
    width: 8.333vw;
    padding: 0;
  }

  li {
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    gap: 0.729vw;
    text-align: left;
    font-size: 1.146vw;
    font-weight: bold;
    line-height: 1.51vw;
    letter-spacing: 0px;
    opacity: 1;
    margin: 1.042vw 0;

    img {
      width: 1.667vw;
    }
  }

  li a {
    color: ${(props) => props.theme.orange};
    text-decoration: none;
  }

  .event__content a {
    display: initial;
  }

  .event__content img {
    max-width: 60%;
    display: block;
    margin-right: auto;
  }

  .event__content iframe {
    height: 30vw;
    width: 60%;
  }

  .event__content ul {
    width: 100%;
  }

  .icon {
    margin-right: 8px;
  }

  .icon-file {
    width: 3.438vw;
  }

  .share {
    cursor: pointer;
    color: ${(props) => props.theme.orange};
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    .event__content iframe {
      height: 400px;
      width: 100%;
    }

    .event__content p {
      font-size: 16px;
      line-height: 22px;
    }

    ul {
      width: auto;
    }

    li {
      font-size: 14px;
      line-height: 20px;
      margin: 20px 0;

      img {
        width: 20px;
      }
    }
  }

  @media (min-width: 1920px) {
    li {
      font-size: 22px;
      line-height: 29px;
      margin: 20px 0;
    }
  }
`;

const Tab = styled.div`
  margin-bottom: 30px;
  width: 100%;

  span {
    text-align: left;
    font-size: 1.146vw;
    font-weight: bold;
    line-height: 1.51vw;
    letter-spacing: 0px;
    color: ${(props) => props.theme.blue};
    opacity: 1;
  }
`;

const Title = styled.h3`
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: ${(props) => props.theme.blue};
  opacity: 1;

  @media (max-width: 992px) {
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;

  p {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  a {
    justify-content: flex-start;
    margin-top: 0.938vw;
    font-size: 0.938vw;
    font-weight: bold;
    line-height: 1.25vw;
    text-align: left;
    letter-spacing: 0px;
    color: ${(props) => props.theme.blue};
    opacity: 1;
    text-decoration: none;
    transition: 0.3s ease;
  }

  a:hover {
    color: ${(props) => props.theme.blueHover};
  }

  @media (max-width: 992px) {
    p,
    a {
    }
  }

  @media (min-width: 1920px) {
    p,
    a {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

const SectionShareContent = styled.section`
  margin-top: 30px;
  padding: 3.646vw 0;
  background-color: #cbe0ea;
  opacity: 1;

  .title {
    text-align: center;
    font-size: 2.083vw;
    font-weight: bold;
    line-height: 2.5vw;
    letter-spacing: 0px;
    color: ${(props) => props.theme.blue};
    text-transform: uppercase;
    opacity: 1;
  }

  .text {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    line-height: inherit;
    letter-spacing: 0px;
    color: ${(props) => props.theme.blue};
    opacity: 1;
    margin-bottom: 4.167vw;
  }

  @media (max-width: 992px) {
    .title {
      font-size: 24px;
      line-height: 30px;
    }

    .text {
      font-size: 18px;
      line-height: 24px;
    }
  }

  @media (min-width: 1920px) {
    .title {
      font-size: 40px;
      line-height: 48px;
    }

    .text {
      font-size: 24px;
      line-height: 28px;
    }
  }
`;

const SectionMembers = styled.section``;

const SectionMembersContent = styled.div`
  background-color: #ffffff;
  border: 3px solid ${(props) => props.theme.blue};
  border-radius: 1.042vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "image title"
    "image text"
    "image cta";
  opacity: 1;
  overflow: hidden;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "title"
      "image"
      "text"
      "cta";
    gap: 10px;
    padding: 10px 0;
    border-radius: 8px;
  }

  @media (min-width: 1920px) {
    border-radius: 10px;
  }
`;

const SectionMembersImage = styled.div`
  border-radius: 1.042vw 0px 0px 1.042vw;
  grid-area: image;
  overflow: hidden;
`;

const SectionMembersTitle = styled.h3`
  grid-area: title;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: ${(props) => props.theme.blue};
  opacity: 1;
  margin: 1.042vw;
  place-self: flex-end flex-start;
`;

const SectionMembersText = styled.p`
  grid-area: text;
  text-align: left;
  font-size: 0.938vw;
  line-height: 1.25vw;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin: 0 1.042vw;
  place-self: center flex-start;

  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 22px;
  }

  @media (min-width: 1920px) {
    font-size: 22px;
    line-height: 29px;
  }
`;

export default function Evento(props) {
  const { slug } = useParams();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [evento, setEvento] = useState([]);

  const month = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const getEvento = (myEvento) => {
    let eventoAMostrar = myEvento[0];

    if (eventoAMostrar.data_inicio) {
      const [dateInicioValues, timeInicioValues] =
        eventoAMostrar.data_inicio.split(" ");
      const [dayInicio, monthInicio, yearInicio] = dateInicioValues.split("/");
      const [dateFechoValues, timeFechoValues] =
        eventoAMostrar.data_fecho.split(" ");
      const [dayFecho, monthFecho, yearFecho] = dateFechoValues.split("/");

      const monthInicioToString = month[Number(monthInicio - 1)];
      const monthFechoToString = month[Number(monthFecho - 1)];
      eventoAMostrar.monthInicio = monthInicio;
      eventoAMostrar.yearInicio = yearInicio;

      eventoAMostrar.url = eventoAMostrar.imagem_destaque
        ? "https://spapapi.phormuladev.com/eventos/" +
          eventoAMostrar.id +
          "/" +
          eventoAMostrar.imagem_destaque
        : "https://spapapi.phormuladev.com/clubes/clube-estudo-default.svg";

      if (
        monthInicio == monthFecho &&
        yearInicio === yearFecho &&
        dayInicio === dayFecho
      ) {
        eventoAMostrar.date =
          dayInicio + " " + monthInicioToString + " " + yearInicio;
      } else if (monthInicio == monthFecho && yearInicio === yearFecho) {
        eventoAMostrar.date =
          dayInicio +
          "-" +
          dayFecho +
          " " +
          monthInicioToString +
          " " +
          yearInicio;
      } else if (
        dayInicio == dayFecho &&
        monthInicio == monthFecho &&
        yearInicio === yearFecho
      ) {
        eventoAMostrar.date =
          dayInicio + " " + monthInicioToString + " " + yearInicio;
      } else if (monthInicio != monthFecho) {
        eventoAMostrar.date =
          dayInicio +
          " " +
          monthInicioToString +
          " a " +
          dayFecho +
          " " +
          monthFechoToString +
          " " +
          yearFecho;
      }
    }

    setEvento(eventoAMostrar);
  };

  useEffect(() => {
    fetch(
      `https://spapapi.phormuladev.com/api/get-evento/` + slug.split("-").pop(),
      {
        method: "GET",
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          console.log("nenhum evento");
        }
        getEvento(data.evento);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleShare = (link) => {
    navigator.clipboard.writeText(link);
    notification.info({ description: "Link copiado: " + link });
  };

  return (
    <Wrapper>
      <Container>
        <PageTitle
          title={evento?.titulo}
          nispap={true}
          path={[
            {
              text: "NISPAP",
              link: "/nispap",
            },
            {
              text: "Novidades",
              link: "/nispap/novidades",
            },
            {
              text: "Eventos NISPAP",
              link: "/nispap/novidades/eventos-nispap",
            },
            {
              text: evento?.titulo,
            },
          ]}
          imageDesktop={evento?.url}
          imageMobile={evento?.url}
          imageAlt={evento?.titulo}
        />

        <Tab>
          <FlexInColumn gap="1.823vw">
            <FlexInColumn gap="0.833vw">
              <span>
                <FontAwesomeIcon
                  icon={faLocationDot}
                  className="icon icon-location"
                />
                {evento?.localizacao}
              </span>
            </FlexInColumn>
            <FlexInColumn gap="0.833vw">
              <span>
                <FontAwesomeIcon
                  icon={faCalendarDay}
                  className="icon icon-location"
                />
                {evento?.date}
              </span>
            </FlexInColumn>
          </FlexInColumn>
        </Tab>

        <FlexInRow gap="3.646vw">
          <FlexInColumn gap="230px" mobile="column">
            <FlexInRow className="event-content">
              <div
                className="event__content"
                dangerouslySetInnerHTML={{ __html: evento?.descricao }}
              ></div>
            </FlexInRow>
            <div>
              <ul>
                <li className="link-disabled">
                  <Link to="/">
                    <img
                      src={IconSubscribe}
                      alt="Icon Subscribe"
                      className="icon icon-subscribe"
                    />
                    Inscrever
                  </Link>
                </li>
                <li className="link-disabled">
                  <Link to="/">
                    <img
                      src={IconSeeMore}
                      alt="Icon See More"
                      className="icon icon-see-more"
                    />
                    Saber mais
                  </Link>
                </li>
                <li>
                  <span
                    className="share"
                    onClick={() => handleShare(window.location.href)}
                  >
                    <img
                      src={IconShare}
                      alt="Icon Share"
                      className="icon icon-share"
                    />
                    Partilhar
                  </span>
                </li>
                <li>
                  <a href="javascript:history.back()">
                    <img
                      src={IconReturn}
                      alt="Icon Return"
                      className="icon icon-return"
                    />
                    Voltar
                  </a>
                </li>
              </ul>
            </div>
          </FlexInColumn>

          {/* <FlexInRow>
            <Title>
              <FontAwesomeIcon icon={faFileArchive} className="icon icon-file-archive" />
              Trabalhos Realizados por Sócios
            </Title>
            <FlexInRow gap="3.646vw">
              <FlexInColumn>
                <Text>
                  <img src={IconFile} alt="Icon File" className="icon icon-file" />
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam uiof nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
                    <Link to="/">Clique para visualizar</Link>
                  </p>
                </Text>
                <Text>
                  <img src={IconFile} alt="Icon File" className="icon icon-file" />
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam uiof nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
                    <Link to="/">Clique para visualizar</Link>
                  </p>
                </Text>
              </FlexInColumn>
            </FlexInRow>
          </FlexInRow> */}
        </FlexInRow>
      </Container>

      <SectionShareContent>
        <Container>
          <FlexInRow gap="0">
            <h2 className="title">AJUDE-NOS A DIVULGAR EVENTOS E CONTEÚDOS</h2>
            <p className="text">
              Contribua para a divulgação de eventos científicos
            </p>

            <FlexInColumn gap="2.500vw">
              <SectionMembers>
                <SectionMembersContent>
                  <SectionMembersImage>
                    <picture>
                      <source
                        srcSet={require("../../../../../Assets/Images/NISPAP/Eventos/Pretende-divulgar-um-evento-NISPAP@2x.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../../../Assets/Images/NISPAP/Eventos/Pretende-divulgar-um-evento-NISPAP.png")}
                        alt="Seja um Membro"
                      />
                    </picture>
                  </SectionMembersImage>
                  <SectionMembersTitle>
                    Pretende divulgar um evento?
                  </SectionMembersTitle>
                  <SectionMembersText>
                    Tem conhecimento de um evento que poderá ter interesse para
                    os nossos associados? Partilhe connosco.
                  </SectionMembersText>
                  <Link to="/nispap/contactos">Preencher formulário »</Link>
                </SectionMembersContent>
              </SectionMembers>

              <SectionMembers>
                <SectionMembersContent>
                  <SectionMembersImage>
                    <picture>
                      <source
                        srcSet={require("../../../../../Assets/Images/NISPAP/Eventos/Quer-partilhar-um-trabalho-NISPAP@2x.png")}
                        media="(min-width: 990px)"
                      />
                      <img
                        src={require("../../../../../Assets/Images/NISPAP/Eventos/Quer-partilhar-um-trabalho-NISPAP.png")}
                        alt="Seja um Membro"
                      />
                    </picture>
                  </SectionMembersImage>
                  <SectionMembersTitle>
                    Quer partilhar um trabalho?
                  </SectionMembersTitle>
                  <SectionMembersText>
                    Pretende divulgar um trabalho que apresentou num evento?
                    Preencha o formulário e submeta o seu trabalho.
                  </SectionMembersText>
                  <Link to="/nispap/contactos">Preencher formulário »</Link>
                </SectionMembersContent>
              </SectionMembers>
            </FlexInColumn>
          </FlexInRow>
        </Container>
      </SectionShareContent>
    </Wrapper>
  );
}
