import { React, useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";

import Container from "../../../../Components/Container";
import FlexInColumn from "../../../../Components/FlexInColumn";
import PageTitle from "../../../../Components/PageTitle";
import RecommendedLinks from "../../../../Components/RecommendedLinks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  > .content {
    padding-bottom: 4vw;
  }

  img {
    width: 100%;
  }

  @media (max-width: 992px) {
    margin-top: 120px;
  }

  div > .item {
    border: 2px solid rgb(12, 17, 81);
    border-radius: 10px;
    opacity: 1;
  }

  .item__content {
    padding: 0.781vw 1.042vw;
  }

  .item__image {
    border: none;
    border-radius: 10px 0px 0px 10px;
    overflow: hidden;
    width: 13.541vw;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .item__title {
    text-align: left;
    font-size: 16px;
    line-height: inherit;
    font-weight: bold;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    margin-top: 10px;
  }

  .item__text {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .item__cta {
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #0c1151;
    border-radius: 0.521vw;
    opacity: 1;
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    text-decoration: none;
    color: #ffffff;
    opacity: 1;
    width: 8.021vw;
    height: 2.396vw;
    transition: 0.3s ease;
  }

  .item__cta:hover {
    background-color: #8688a8;
    color: #e5e5e5;
  }

  .item__date {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.417vw;
  }

  .item__date svg {
    font-size: 0.938vw;
  }

  @media (min-width: 992px) {
    .item__content {
      width: 25.625vw;
    }
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    gap: 50px;

    .item__image {
      border-radius: 0;
      margin-bottom: 20px;
      width: 100%;
    }

    .item__title {
      line-height: 4.45vw;
    }

    .item__text,
    .item__cta,
    .item__date,
    .item__date svg {
      font-size: 2.222vw;
      line-height: 3.333vw;
    }

    .item__cta {
      border-radius: 8px;
      height: 30px;
      width: 150px;
    }
  }

  @media (min-width: 1920px) {
    .item__text,
    .item__cta,
    .item__date,
    .item__date svg {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

const Grid = styled.section`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export default function TodasNoticias(props) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [eventos, setEventos] = useState([]);

  const month = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const getEventos = (allEventos) => {
    let eventosToShow = [];

    let today = new Date().toLocaleDateString();

    if (allEventos?.length > 0) {
      allEventos.map((evento) => {
        let mostraEvento = true;

        if (evento.agendar_post != null) {
          let date = evento.agendar_post.trim();

          if (date > today) {
            mostraEvento = false;
          }
        }

        if (mostraEvento) {
          if (evento.data_inicio && evento.is_noticia == 1) {
            console.log(evento.data_inicio);
            console.log(evento.data_inicio > today);

            const [dateInicioValues, timeInicioValues] =
              evento.data_inicio.split(" ");
            const [dayInicio, monthInicio, yearInicio] =
              dateInicioValues.split("/");
            const [dateFechoValues, timeFechoValues] =
              evento.data_fecho.split(" ");
            const [dayFecho, monthFecho, yearFecho] =
              dateFechoValues.split("/");

            evento.dataAOrdenar =
              monthInicio + "/" + dayInicio + "/" + yearInicio;

            const monthInicioToString = month[Number(monthInicio - 1)];
            const monthFechoToString = month[Number(monthFecho - 1)];

            evento.monthInicio = monthInicio;
            evento.yearInicio = yearInicio;

            evento.url = evento.imagem_destaque
              ? "https://spapapi.phormuladev.com/eventos/" +
                evento.id +
                "/" +
                evento.imagem_destaque
              : "https://spapapi.phormuladev.com/clubes/clube-estudo-default.svg";

            if (
              monthInicio == monthFecho &&
              yearInicio === yearFecho &&
              dayInicio === dayFecho
            ) {
              evento.date =
                dayInicio + " " + monthInicioToString + " " + yearInicio;
            } else if (monthInicio == monthFecho && yearInicio === yearFecho) {
              evento.date =
                dayInicio +
                "-" +
                dayFecho +
                " " +
                monthInicioToString +
                " " +
                yearInicio;
            } else if (
              dayInicio == dayFecho &&
              monthInicio == monthFecho &&
              yearInicio === yearFecho
            ) {
              evento.date =
                dayInicio + " " + monthInicioToString + " " + yearInicio;
            } else if (monthInicio != monthFecho) {
              evento.date =
                dayInicio +
                " " +
                monthInicioToString +
                " a " +
                dayFecho +
                " " +
                monthFechoToString +
                " " +
                yearFecho;
            }
            //if(evento.data_inicio > today) {
            eventosToShow.push(evento);
            //}
          }
        }
      });
    }

    const eventosOrdenados = eventosToShow.sort(function (a, b) {
      return new Date(b.dataAOrdenar) - new Date(a.dataAOrdenar);
    });

    setEventos(eventosOrdenados);
  };

  useEffect(() => {
    fetch(`https://spapapi.phormuladev.com/api/all-eventos`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          console.log("nenhum evento");
        }
        getEventos(data.eventos);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <Wrapper>
      <Container className="content">
        <PageTitle
          title={props.title ? props.title : "Todos Destaques e Notícias"}
          path={
            props.title
              ? "Homepage • SPAP • Bolsas e Prémios • Ver Todas"
              : [
                  {
                    text: "Homepage",
                    link: "/",
                  },
                  {
                    text: "Sócios",
                    link: "/socios",
                  },
                  {
                    text: "Destaques e Notícias",
                    link: "/socios/destaques",
                  },
                  {
                    text: "Ver Todos",
                  },
                ]
          }
          imageDesktop={require("../../../../Assets/Images/img-destaques@2x.png")}
          imageMobile={require("../../../../Assets/Images/img-destaques.png")}
          imageAlt={props.title ? props.title : "Destaques"}
        />
        <Row
          gutter={[8, 8]}
          style={{ flexWrap: "wrap", justifyContent: "space-between" }}
        >
          {eventos.map((item, index) => {
            let textPreview = item.descricao
              .replace(/<(h2|h3|img|b|strong)[^>]*>/g, "<p>")
              .slice(0, 120);
            if (item.descricao.length > 120) textPreview += " (...)";
            return (
              <Col
                className={"item item-" + index}
                style={{
                  border: "2px solid rgb(12, 17, 81)",
                  display: "flex",
                  paddingLeft: "0",
                  marginBottom: "15px",
                  width: "49%",
                }}
              >
                {windowWidth > 992 && (
                  <div
                    className="item__image"
                    style={{
                      backgroundImage: "url(" + item.url + ")",
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                    }}
                  ></div>
                )}
                <div
                  className="item__content"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <div className="item__date">
                      <FontAwesomeIcon icon={faCalendarDay} />
                      {item.date}
                    </div>
                    <h3 className="item__title">{item.titulo}</h3>

                    <div
                      style={{
                        fontSize: "12px",
                        lineHeight: "1.4",
                        color: "#212529",
                      }}
                      dangerouslySetInnerHTML={{ __html: textPreview }}
                    ></div>
                    {windowWidth <= 992 && (
                      <div className="item__image">
                        <picture>
                          <source
                            srcSet={item.url}
                            media="(min-width: 990px)"
                          />
                          <img src={item.url} alt={item.titulo} />
                        </picture>
                      </div>
                    )}
                  </div>
                  <div>
                    <Link
                      to={"/agenda/evento/" + item.id}
                      className="item__cta"
                    >
                      Saber mais »
                    </Link>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>

      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={[
          {
            title: "História",
            link: "/spap/historia",
          },
          {
            title: "Órgãos sociais",
            link: "/spap/orgaos-sociais",
          },
          {
            title: "Bolsas e prémios",
            link: "/spap/bolsas-e-premios",
          },
          {
            title: "Vantagens de ser sócio",
            link: "/socios/conheca-as-vantagens",
          },
        ]}
      />
    </Wrapper>
  );
}
