import styled from "styled-components";
import { Link } from "react-router-dom";
import Container from "../Container";
import FlexInColumn from "../FlexInColumn";
import FlexInRow from "../FlexInRow";
import axios from "axios";
import { Button, Modal, Result } from "antd";
import { useState } from "react";

const Wrapper = styled.section`
  background: #d6d7e2 0% 0% no-repeat padding-box;
  padding: 4.167vw 0;
`;

const Title = styled.h2`
  text-align: center;
  font-size: 2.083vw;
  font-weight: bold;
  line-height: 2.5vw;
  letter-spacing: 0px;
  color: #0c1151;
  text-transform: uppercase;
  opacity: 1;

  @media (max-width: 992px) {
    font-size: 5.556vw;
    line-height: 6.667vw;
  }
`;

const Form = styled.form`
  opacity: 1;
  padding: 1.823vw;

  input[type="text"],
  input[type="email"],
  textarea {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 0.521vw;
    opacity: 1;
    padding: 1.146vw 1.302vw;
    width: 100%;
    outline: 0;
    transition: 0.3s ease;
  }

  input[type="text"]:focus,
  input[type="email"]:focus,
  textarea:focus {
    box-shadow: 0 8px 24px #00000025;
  }

  textarea {
    height: 13.75vw;
  }

  input[type="checkbox"] {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: none;
    cursor: pointer;
    opacity: 1;
    height: 1.302vw;
    width: 1.302vw;
  }

  label {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    display: inline-flex;
    gap: 2.5px;
    transition: 0.3s ease;

    a {
      color: ${(props) => props.theme.orange};
    }

    a:hover {
      color: ${(props) => props.theme.orangeHover};
      text-decoration: none;
    }
  }

  input[type="submit"] {
    background-color: #f88436;
    border: none;
    border-radius: 0.521vw;
    opacity: 1;
    text-align: center;
    font-size: 1.146vw;
    font-weight: bold;
    line-height: 1.51vw;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    padding: 0.729vw 1.563vw;
    place-self: center;
    margin-bottom: -2.968vw;
    transition: 0.3s ease;
  }

  input[type="submit"]:hover {
    background-color: #e87426;
    color: #ececec;
  }

  @media (max-width: 992px) {
    input[type="text"],
    input[type="email"],
    textarea {
      border-radius: 2.778vw;
      font-size: 3.889vw;
      line-height: 5.278vw;
      padding: 3.333vw 4.167vw;
    }

    textarea {
      height: 43.611vw;
    }

    input[type="checkbox"] {
      height: 6.944vw;
      width: 6.944vw;
    }

    label,
    label a {
      font-size: 2.778vw;
      line-height: 3.611vw;
      display: initial;
    }

    label a {
      text-decoration: underline;
    }

    input[type="submit"] {
      border-radius: 2.778vw;
      font-size: 3.611vw;
      line-height: 5vw;
      padding: 2.778vw 4.167vw;
      margin-top: 5.556vw;
    }
  }

  @media (min-width: 1920px) {
    label,
    label * {
      font-size: 18px;
      line-height: 24px;
    }

    label,
    label * {
      font-size: 18px;
      line-height: 24px;
    }

    input[type="text"],
    input[type="email"],
    textarea {
      font-size: 18px;
      line-height: 24px;
      padding: 25px;
    }

    input[type="checkbox"] {
      height: 25px;
      width: 25px;
    }

    input[type="submit"] {
      border-radius: 10px;
      font-size: 22px;
      line-height: 29px;
      padding: 15px 30px;
    }
  }
`;

export default function PatologistaForm(props) {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleContact = (e) => {
    e.preventDefault();

    var bodyFormData = new FormData();

    bodyFormData.append("email", e.target.email.value);
    bodyFormData.append("assunto", e.target.subject.value);
    bodyFormData.append("nome", e.target.name.value);
    bodyFormData.append("mensagem", e.target.message.value);

    axios
      .post("https://spapapi.phormuladev.com/api/send-contact", bodyFormData)
      .then((res) => {
        let secondsToGo = 5;
        if (res.status == 200) {
          document.getElementById("contact-form").reset();
          setModalOpen(true);
          setTimeout(() => {
            setModalOpen(false);
          }, 3000);
        }
      })
      .catch((error) => {
        /* if( error.response ){
      //   notification.error({description: 'Erro ao atualizar o perfil'});
       }*/
      });
  };

  return (
    <Wrapper>
      <Container>
        <FlexInRow gap="1.167vw" placeself="center">
          <Title>
            ENVIE-NOS AS SUAS SUGESTÕES DE PATOLOGISTAS OU NOVAS PERGUNTAS
          </Title>
          <Form
            method="post"
            action="#"
            id="contact-form"
            onSubmit={(e) => handleContact(e)}
          >
            <FlexInRow gap="42px">
              <FlexInColumn gap="42px" mobile="column">
                <input
                  type="text"
                  name="subject"
                  placeholder="Assunto*"
                  required
                />
                <input type="text" name="name" placeholder="Nome*" required />
                <input
                  type="email"
                  name="email"
                  placeholder="E-mail*"
                  required
                />
              </FlexInColumn>
              <textarea name="message" placeholder="Mensagem*" required />
              <FlexInColumn gap="20px">
                <input type="checkbox" name="confirm" required />
                <FlexInRow gap="0">
                  <label>
                    Ao submeter este formulário de contacto declaro conhecer a{" "}
                    <Link to="/politica-de-privacidade">
                      política de privacidade
                    </Link>{" "}
                    deste website bem como os{" "}
                    <Link to="/termos-e-condicoes">termos e condições</Link> do
                    mesmo.
                  </label>
                  <label>* Campos de preenchimento obrigatório.</label>
                </FlexInRow>
              </FlexInColumn>
              <input type="submit" name="submit" value="Enviar mensagem" />
            </FlexInRow>
          </Form>
        </FlexInRow>
      </Container>
      <Modal
        centered
        open={isModalOpen}
        footer={null}
        onCancel={() => setModalOpen(false)}
      >
        <Result
          style={{ background: "#fff" }}
          status="success"
          title="Mensagem enviada com sucesso!"
          subTitle="Obrigado pela sua mensagem. Entraremos em contacto consigo assim que possível!"
        />
      </Modal>
    </Wrapper>
  );
}
