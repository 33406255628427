import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import Container from "../../../Components/Container";
import FlexInRow from "../../../Components/FlexInRow";
import FlexInColumn from "../../../Components/FlexInColumn";
import PageTitle from "../../../Components/PageTitle";
import Pagination from "../../../Components/Pagination";
import RecommendedLinks from "../../../Components/RecommendedLinks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.main`
  background-color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  > .content {
    padding-bottom: 4vw;
  }

  img {
    width: 100%;
  }

  .item__title {
    text-align: left;
    font-size: 46px;
    line-height: 52px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #c2c4d4;
    opacity: 1;
    margin-top: 20px;
  }

  .item__text {
    text-align: left;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 20px;
  }

  .item__cta {
    align-items: center;
    justify-content: center;
    display: flex;
    background: #0c1151 0% 0% no-repeat padding-box;
    border-radius: 0.521vw;
    opacity: 1;
    text-align: left;
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    letter-spacing: 0px;
    text-decoration: none;
    color: #ffffff;
    opacity: 1;
    width: 180px;
    height: 45px;
    transition: 0.3s ease;
    margin-top: 1.458vw;
  }

  .item__cta:hover {
    background-color: #8688a8;
    color: #e5e5e5;
  }

  .item__date {
    text-align: left;
    font-size: 1.146vw;
    line-height: 1.51vw;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.521vw;
  }

  .item__date svg {
    font-size: 1.302vw;
  }

  #select-year {
    color: #000;
    cursour: pointer;
    z-index: 8;
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    .item__text,
    .item__cta,
    .item__date,
    .item__date svg {
    }

    #select-year {
      font-size: 16px;
    }
  }

  @media (min-width: 1920px) {
    .item__text,
    .item__cta,
    .item__date,
    .item__date svg {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

const CardPicture = styled.picture`
  border: none;
  overflow: hidden;
  height: 350px;

  img {
    border-radius: 8px;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }

  @media (max-width: 992px) {
    height: 220px;
  }
`;

const Title = styled.h2`
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;

  @media (max-width: 992px) {
    font-size: 22px;
    line-height: 29px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    flex-direction: row-reverse;
  }
`;

const SectionCards = styled.div`
  display: grid;
  grid-column-gap: 3.021vw;
  grid-row-gap: 7.292vw;
  grid-template-columns: 1fr 1fr 1fr;

  .item {
    border: 0.156vw solid #0c1151;
    border-top: 0.625vw solid #0c1151;
    border-radius: 0px 0px 1.042vw 1.042vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    text-decoration: none;
  }

  p {
    color: #000000;
    padding: 0 1.042vw;
    text-align: left;
  }

  .item__image {
    border: none;
    overflow: hidden;
    position: relative;
    height: 11.979vw;
    margin-bottom: 1.042vw;

    picture {
      z-index: 1;
    }

    .overlay {
      background: transparent
        linear-gradient(180deg, #452aa71a 0%, #452aa7ea 100%) 0% 0% no-repeat
        padding-box;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      z-index: 2;
    }

    img {
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .item__title {
    text-align: left;
    font-size: 16px;
    line-height: inherit;
    font-weight: bold;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    margin: 1.094vw 0;
  }

  .item__text {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 0.729vw;
  }

  .item__cta {
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #0c1151;
    border: none;
    border-radius: 0;
    opacity: 1;
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    text-decoration: none;
    color: #ffffff;
    opacity: 1;
    width: 100%;
    height: 2.396vw;
    transition: 0.3s ease;
    margin: 0;
  }

  .item:hover .item__cta {
    background-color: #8688a8;
    color: #e5e5e5;
  }

  .item__date {
    text-align: left;
    font-size: 2.031vw;
    font-weight: bold;
    line-height: 2.708vw;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.521vw;
    position: absolute;
    bottom: 1.042vw;
    left: 1.042vw;
    z-index: 3;
  }

  .item__date svg {
    font-size: 2.708vw;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;

    .item__image {
      height: 220px;
    }

    .item__title {
      font-size: 22px;
    }

    .item__text,
    .item__date,
    .item__date svg,
    .item__cta,
    p {
      font-size: 16px;
    }

    .item__cta {
      height: 45px;
    }
  }
`;

const NoResults = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  * {
    text-align: center;
    font-size: 16px;
    line-height: inherit;
    font-weight: bold;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    margin: 1.094vw 0;
  }
`;

export default function CasoDoMes(props) {
  const month = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const [listCasos, setListCasos] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [noResults, setNoResults] = useState("");
  const [allYears, setAllYears] = useState([
    { value: "2017", label: "2017" },
    { value: "2018", label: "2018" },
    { value: "2019", label: "2019" },
    { value: "2020", label: "2020" },
    { value: "2021", label: "2021" },
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
  ]);

  const formatDate = (date) => {
    const parts = date.split("T")[0].split("-");

    // Obtendo os componentes da data
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];

    // Formatando a data como "DD/MM/YYYY"
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  const getCasos = (allItens) => {
    if (allItens?.length > 0) {
      let newArray = [];
      allItens.map((item) => {
        item.url = item.imagem_destaque
          ? "https://spapapi.phormuladev.com/caso_do_mes/" +
            item.id +
            "/" +
            item.imagem_destaque
          : "https://spapapi.phormuladev.com/clubes/clube-estudo-default.svg";
        item.date = formatDate(item.created_at);
        newArray.push(item);
      });

      setAllItems(newArray);
      const anos = Array.from(
        new Set(newArray.map((obj) => new Date(obj.created_at).getFullYear()))
      );
      newArray = [];
      anos.map((a) => {
        const year = a;
        if (!newArray.includes(year)) {
          newArray.push({ value: year, label: year });
        }
      });
      setAllYears(newArray);
    }
  };

  useEffect(() => {
    fetch(`https://spapapi.phormuladev.com/api/all-casos-do-mes`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          console.log("nenhum evento");
          setNoResults("Nenhum caso encontrado..");
        }
        setListCasos(data.caso_do_mes);
        getCasos(data.caso_do_mes);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    setCurrentItems(allItems?.slice(0, 6));
  }, [allItems]);

  useEffect(() => {
    if (selectedYear) {
      let itemsPosFilter = allItems.filter((item) => {
        return selectedYear === new Date(item.created_at).getFullYear();
      });
      setAllItems(itemsPosFilter);
    } else {
      setAllItems(listCasos);
    }
  }, [selectedYear]);

  useEffect(() => {
    setCurrentItems(allItems.slice(0, 6));
  }, [allItems]);

  const animatedComponents = makeAnimated();

  let textPreview = allItems[0]?.descricao
    .replace(/<(h2|h3|img|b|strong)[^>]*>/g, "<p>")
    .slice(0, 200);
  if (allItems[0]?.descricao.length > 200) textPreview += " (...)";

  return (
    <Wrapper>
      <Container className="content">
        <PageTitle
          title="Caso do mês"
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "Ferramentas úteis",
              link: "/ferramentas-uteis",
            },
            {
              text: "Caso do mês",
            },
          ]}
          imageDesktop={require("../../../Assets/Images/img-caso-do-mes@2x.png")}
          imageMobile={require("../../../Assets/Images/img-caso-do-mes.png")}
        />

        {allItems?.length > 0 && (
          <FlexInRow gap="4.688vw">
            {allItems?.length > 0 && (
              <Grid>
                <FlexInRow gap="0" width="100%">
                  {/*}
                    <p className="item__date">
                      <FontAwesomeIcon icon={faCalendarDay} />{allItems[0].date}
                    </p>
                  {*/}
                  <h3 className="item__title">{allItems[0].titulo}</h3>
                  <p className="item__text">
                    <div
                      className="event__content"
                      dangerouslySetInnerHTML={{ __html: textPreview }}
                    ></div>
                  </p>
                  <Link
                    to={"/ferramentas-uteis/caso-do-mes/" + allItems[0].id}
                    className="item__cta"
                  >
                    Saber mais »
                  </Link>
                </FlexInRow>
                <CardPicture width="100%">
                  {/*}
                  <source srcSet={require('../../../Assets/Images/img-bolsa-detalhe@2x.png')} media="(min-width: 990px)" />
                  <img src={require('../../../Assets/Images/img-bolsa-detalhe.png')} alt="SPAP Bolsa" />
                  */}
                  <source
                    srcSet={
                      "https://spapapi.phormuladev.com/caso_do_mes/" +
                      allItems[0].id +
                      "/" +
                      allItems[0].imagem_destaque
                    }
                    media="(min-width: 990px)"
                  />
                  <img
                    src={
                      "https://spapapi.phormuladev.com/caso_do_mes/" +
                      allItems[0].id +
                      "/" +
                      allItems[0].imagem_destaque
                    }
                    alt="SPAP Bolsa"
                  />
                </CardPicture>
              </Grid>
            )}

            <FlexInRow gap="4.167vw">
              <FlexInColumn gap="1.563vw" mobile="column">
                <Title>Casos Arquivados</Title>
                <Select
                  id="select-year"
                  placeholder="Selecione um ano"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={allYears}
                  hideSelectedOptions={true}
                  isClearable
                  onChange={(e) => {
                    if (e === null) {
                      setSelectedYear("");
                    } else {
                      setSelectedYear(e.value);
                    }
                  }}
                />
              </FlexInColumn>
              {currentItems.length > 0 ? (
                <>
                  <SectionCards>
                    {currentItems.map((item, index) => {
                      return (
                        <Link
                          to={"/ferramentas-uteis/caso-do-mes/" + item.id}
                          className={"item item-" + index}
                        >
                          <div className="item__content">
                            <div className="item__image">
                              <div className="item__date">
                                <FontAwesomeIcon icon={faCalendarDay} />
                                {item.date}
                              </div>
                              <picture>
                                <source
                                  srcSet={item.url}
                                  media="(min-width: 990px)"
                                />
                                <img src={item.url} alt={item.alt} />
                              </picture>
                              <div className="overlay" />
                            </div>
                            <p>
                              <h4>{item.titulo}</h4>
                            </p>
                            <p>
                              <b>Autor: </b>
                              {item.autor}
                            </p>
                            <p>
                              <b>Instituição: </b>
                              {item.instituicao}
                            </p>
                            <Link
                              to={"/ferramentas-uteis/caso-do-mes/" + item.id}
                              className="item__cta"
                            >
                              Ver caso clínico
                            </Link>
                          </div>
                        </Link>
                      );
                    })}
                  </SectionCards>
                  <Pagination
                    items={allItems}
                    itemsPerPage={6}
                    onchange={(e) => {
                      setCurrentItems(e);
                    }}
                  />
                </>
              ) : (
                <NoResults>
                  <h3>{noResults}</h3>
                </NoResults>
              )}
            </FlexInRow>
          </FlexInRow>
        )}
      </Container>

      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={[
          {
            title: "História",
            link: "/spap/hsitoria",
          },
          {
            title: "Órgãos sociais",
            link: "/spap/orgaos-sociais",
          },
          {
            title: "Bolsas e prémios",
            link: "/spap/bolsas-e-premios",
          },
          {
            title: "Vantagens de ser sócio",
            link: "/socios/conheca-as-vantagens",
          },
        ]}
      />
    </Wrapper>
  );
}
