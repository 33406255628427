import { useNavigate } from "react-router-dom";
import { useEffect, useState, useForm } from "react";
import {
  Col,
  Modal,
  Row,
  Button,
  Radio,
  Spin,
  Form,
  notification,
  Input,
} from "antd";
import { Steps } from "antd";
import axios from "axios";
import dayjs from "dayjs";

import "./style.css";
import mbImage from "../../Assets/Images/Pagamentos/MB.svg";
import mbWayImage from "../../Assets/Images/Pagamentos/MB-Way.svg";
import bankImage from "../../Assets/Images/Pagamentos/Bank-transfer.svg";
import endpoints from "../../Utils/endoinpts";

export default function Payment({
  user,
  listQuotas,
  show,
  close,
  price,
  paymentType,
  phone,
  tipo,
  planId,
  planDescription,
  planEntity,
  planReference,
  expiryDate,
}) {
  const [paymentOptionsModal, setPaymentOptionsModal] = useState(show);
  const [prices, setPrices] = useState([]);
  const [userData, setUserData] = useState(user);
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const [disabledMB, setDisabledMB] = useState(false);
  const [disabledMBWAY, setDisabledMBWAY] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [disabledMonth, setDisabledMonth] = useState(false);
  const [disabledDebit, setDisabledDebit] = useState(false);
  const [paymentMBEntity, setPaymentMBEntity] = useState({});
  const [paymentMBReference, setPaymentMBReference] = useState({});
  const [paymentMBWay, setPaymentMBWay] = useState({});
  const [noteText, setNoteText] = useState(false);

  const navigate = useNavigate();

  const [formPaymentStepOne] = Form.useForm();
  const [formPaymentStepTwo] = Form.useForm();

  function handleChangeStep(value) {
    setCurrentStep(value);
  }

  notification.config({
    placement: "bottomRight",
    bottom: 10,
    duration: 5,
    rtl: false,
  });

  function handleGenerateMB() {
    return new Promise((resolve, reject) => {
      let value_price = price;

      let userId = userData.id;

      do {
        userId = `0${userId}`;
      } while (userId.length < 4);

      let orderId = `${dayjs().format("YYYYMMDDHHmmss")}${userId}`;

      let data = {
        userId: userData.id,
        orderId: orderId,
        amount: price,
        description: planDescription,
        userName: userData.name + " " + userData.apelido,
        userEmail: userData.email,
        listQuotas: listQuotas,
        tipo: tipo,
        expiryDays: "3",
      };

      axios
        .post(
          "https://spapapi.phormuladev.com/api/" +
            endpoints.purchase.purchaseMB,
          {
            data: data,
          }
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  function handleGenerateMBWay() {
    return new Promise((resolve, reject) => {
      let userId = userData.id;
      do {
        userId = `0${userId}`;
      } while (userId.length < 4);
      let orderId = `${dayjs().format("YYYYMMDDHHmmss")}${userId}`;
      let data = {
        userId: userData.id,
        orderId: orderId,
        amount: price,
        description: planDescription,
        phone: phone,
        userEmail: userData.email,
        listQuotas: listQuotas,
        tipo: tipo,
      };
      setIsButtonLoading(true);

      axios
        .post(
          "https://spapapi.phormuladev.com/api/" +
            endpoints.purchase.purchaseMBWay,
          {
            data: data,
          }
        )
        .then((result) => {
          if (result.data.Message == "Declined") {
            notification.error({
              message: `Erro`,
              description:
                "O pagamento não foi processado, por favor verifique se está a indicar o seu número corretamente.",
            });
            resolve(result.data);
            return;
          }
          setPaymentMBWay(result.data);
          setTimeout(() => {
            setIsButtonLoading(false);
            notification.success({
              message: `Sucesso`,
              description:
                "O pagamento está a ser processado, quando estiver concluído iremos atualizar suas quotas.",
            });
            setCurrentStep(0);
          }, 500);
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  function handleUpdateUserPaymentMethod(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "https://spapapi.phormuladev.com/api/" +
            endpoints.purchase.updateUserPaymentMethod,
          {
            data: data,
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  function handleSubmitNewPayment(res) {
    return new Promise((resolve, reject) => {
      let valuesStepOne = formPaymentStepOne.getFieldsValue(true);
      let valuesStepTwo = formPaymentStepTwo.getFieldsValue(true);
      let value_price = valuesStepOne.price;
      let priceFiltered = prices.filter(
        (value, index) => value.price === value_price
      )[0];

      let userId = userData.id;

      do {
        userId = `0${userId}`;
      } while (userId.length < 4);

      let order_id = `${dayjs().format("YYYYMMDDHHmmss")}${userId}`;
      let invoice_item_quantity = 1;

      if (user.valid_access_date) {
        let valid_access_date = dayjs(user.valid_access_date);
        let today = dayjs();
        let durationAccesss = today.diff(valid_access_date);
        let yearsAccess = dayjs.duration(durationAccesss).asYears();
        let monthsAccess = dayjs.duration(durationAccesss).asMonths();

        if (yearsAccess >= 1 && yearsAccess < 2) {
          invoice_item_quantity = 2;
        } else if (yearsAccess >= 2) {
          invoice_item_quantity = 3;
        }

        if (monthsAccess > 1 && monthsAccess < 36) {
          invoice_item_quantity = parseInt(monthsAccess);
        } else if (monthsAccess >= 36) {
          invoice_item_quantity = 36;
        }
      }

      let auxData = {
        id_user: userData.id,
        id_payment_plan: priceFiltered.id_payment_plan,
        date: moment().format("YYYY-MM-DD"),
        type: "quotes",
        method: valuesStepOne.payment_method,
        entity: valuesStepOne.payment_method === "mb" ? res.Entity : null,
        reference: valuesStepOne.payment_method === "mb" ? res.Reference : null,
        iban: valuesStepOne.payment_method === "debit" ? res.iban : null,
        bank_swift:
          valuesStepOne.payment_method === "debit"
            ? valuesStepTwo.bank_swift
            : null,
        bank_name:
          valuesStepOne.payment_method === "debit"
            ? valuesStepTwo.bank_name
            : null,
        bank_user_name:
          valuesStepOne.payment_method === "debit"
            ? valuesStepTwo.bank_user_name
            : null,
        phone_number:
          valuesStepOne.payment_method === "mbway" ? valuesStepTwo.phone : null,
        value: value_price.replace(",", "."),
        process_id: order_id,
        payment_id:
          valuesStepOne.payment_method === "mbway"
            ? res.IdPedido
            : valuesStepOne.payment_method === "mb"
            ? res.RequestId
            : null,
        payment_expiry_date:
          valuesStepOne.payment_method === "mb" ? res.ExpiryDate : null,
        status: "em validação",
        id_invoice_item: priceFiltered.id_invoice_item,
        invoice_item_quantity: invoice_item_quantity,
      };

      axios
        .post(
          "https://spapapi.phormuladev.com/api/" +
            endpoints.purchase.postPurchase,
          {
            data: auxData,
          }
        )
        .then((res) => {
          if (valuesStepOne.payment_method === "debit") {
            handleUpdateUserPaymentMethod(auxData)
              .then((result) => {
                console.log(result);
                resolve(res);
              })
              .catch((error) => {
                console.log(error);
                reject(error);
              });
          } else {
            resolve(res);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };

  function handleSubmitFormStepOne() {
    setIsButtonLoading(true);
    if (paymentType === "mb") {
      handleGenerateMB().then((res) => {
        console.log("ok generate mb");
        setPaymentMBEntity(res.Entity);
        setPaymentMBReference(res.Reference);
        setCurrentStep("1");
        setIsButtonLoading(false);
        console.log(res);
      });
    } else {
      setIsButtonLoading(true);
      if (paymentType === "mbway") {
        handleGenerateMBWay()
          .then((res) => {
            console.log(res);
            if (res.Estado === "000") {
              handleSubmitNewPayment(res)
                .then((result) => {
                  setTimeout(() => {
                    setIsButtonLoading(false);
                    notification.success({
                      message: `Sucesso`,
                      description:
                        "O pagamento está a ser processado, quando estiver concluído iremos atualizar suas quotas.",
                    });
                    close();
                    setCurrentStep(0);
                  }, 2000);
                })
                .catch((err) => {
                  setIsButtonLoading(false);
                  console.log(err);
                });
            } else {
              /*
            notification.error({
              message: `Erro`,
              description: 'O pagamento não foi processado, por favor verifique se está a indicar o seu número corretamente.'
            });
            */
              close();
              setCurrentStep(0);
              setIsButtonLoading(false);
            }
          })
          .catch((err) => {
            setIsButtonLoading(false);
            console.log(err);
          });
      }
      setIsButtonLoading(false);
    }
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: "Faltam alguns campos para preencher!",
    });
  }

  function handleCompleteSteps() {
    setIsButtonLoading(true);
    setTimeout(() => {
      setIsButtonLoading(false);
      notification.success({
        message: `Sucesso`,
        description: "Estamos à espera que conclua o pagamento.",
      });
      close();
      setCurrentStep(0);
    }, 1500);
  }

  function handleChangePrice(e) {
    let value = e.target ? e.target.value : e;
    if (value === prices[1].price) {
      setDisabledMB(true);
      setDisabledMBWAY(true);
      formPaymentStepOne.setFieldValue("payment_method", "");
    } else {
      setDisabledMB(false);
      setDisabledMBWAY(false);
    }
    formPaymentStepOne.setFieldValue("price", value);
  }

  return (
    <>
      <Modal
        className="payment_options"
        open={show}
        onCancel={close}
        closable={false}
        footer={[
          <>
            <Row gutter={24}>
              {currentStep === 0 && (
                <Col span={12}>
                  <Button
                    className="payment_button back"
                    key="submit"
                    type="primary"
                    onClick={close}
                  >
                    Cancelar
                  </Button>
                </Col>
              )}
              {currentStep === 0 && (
                <Col span={12} className={"d-flex justify-content-start"}>
                  <Button
                    className="payment_button"
                    key="submit"
                    type="primary"
                    style={{ background: "rgb(12, 17, 81)" }}
                    onClick={() => {
                      enterLoading(1);
                      formPaymentStepOne.submit();
                    }}
                    loading={loadings[1]}
                  >
                    Seguir
                  </Button>
                </Col>
              )}
              {currentStep === 1 &&
                (paymentType === "debit" ? (
                  <>
                    <Col span={12}>
                      <Button
                        className="payment_button back"
                        key="submit"
                        type="primary"
                        onClick={() => setCurrentStep(currentStep - 1)}
                      >
                        Cancelar
                      </Button>
                    </Col>
                    <Col span={12} className={"d-flex justify-content-start"}>
                      <Button
                        className="payment_button"
                        key="submit"
                        type="primary"
                        onClick={() => formPaymentStepTwo.submit()}
                        loading={isButtonLoading}
                      >
                        Concluir
                      </Button>
                    </Col>
                  </>
                ) : (
                  <Col span={24} className={"d-flex justify-content-center"}>
                    <Button
                      className="payment_button"
                      key="submit"
                      type="primary"
                      onClick={() =>
                        paymentType === "mbway"
                          ? formPaymentStepTwo.submit()
                          : handleCompleteSteps()
                      }
                      loading={isButtonLoading}
                    >
                      Concluir
                    </Button>
                  </Col>
                ))}
            </Row>
          </>,
        ]}
        maskClosable={false}
      >
        {isLoading ? (
          <Spin />
        ) : (
          <>
            <Steps
              type="navigation"
              size="small"
              current={currentStep}
              onChange={handleChangeStep}
              className="payment-steps"
              items={[
                {
                  title: "1",
                },
                {
                  title: "2",
                },
                {
                  title: "3",
                },
              ]}
            />
            {currentStep === 0 && (
              <Form
                form={formPaymentStepOne}
                id="payment-form-step-one"
                className="form-payment"
                layout="vertical"
                onFinish={handleSubmitFormStepOne}
                onFinishFailed={handleWarningMissingFields}
              >
                <Row className="d-flex justify-content-center align-items-center">
                  <Col
                    span={24}
                    className="d-flex flex-column justify-content-center align-items-center"
                  >
                    <Row className="d-flex justify-content-center align-items-center w-100">
                      <Col
                        span={18}
                        className="d-flex flex-column justify-content-center align-items-center"
                      >
                        <p className="payment_title text-center">
                          Deseja continuar para etapa de pagamento?
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            )}
            {currentStep == 1 && (
              <>
                <Row>
                  <Col span={24}>
                    <p className="title">Dados para pagamento:</p>
                    <Row>
                      <Col span={24}>
                        {paymentType === "mb" && (
                          <Row className="step_reference">
                            <Col
                              span={4}
                              className="d-flex justify-content-start align-items-center"
                            ></Col>
                            <Col span={20}>
                              <p className="payment_info">
                                <b>Entidade:</b> {paymentMBEntity}
                              </p>
                              <p className="payment_info">
                                <b>Referência:</b> {paymentMBReference}
                              </p>
                              <p className="payment_info">
                                <b>Montante:</b> {price}€
                              </p>
                              <p className="payment_info date_expiry">
                                <b>Data limite de pagamento:</b> {expiryDate}{" "}
                              </p>
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>
                    <Button
                      className="payment_button"
                      key="submit"
                      type="primary"
                      style={{
                        width: "100%",
                        marginTop: "20px",
                        fontSize: "16px",
                        background: "rgb(12, 17, 81)",
                      }}
                      onClick={() => {
                        close();
                        setIsButtonLoading(false);
                        setCurrentStep(0);
                      }}
                    >
                      Ok
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </Modal>
    </>
  );
}
