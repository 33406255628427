import { useState, useEffect, react } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Tabs,
  Dropdown,
  message,
  Switch,
  Divider,
  Space,
  Row,
  Col,
  DatePicker,
  Form,
  Drawer,
  Tooltip,
  Input,
  Radio,
  Checkbox,
  Modal,
  Message,
  Table,
  Upload,
  Select,
  Pagination,
  Avatar,
  Typography,
} from "antd";
import {
  DownOutlined,
  UserOutlined,
  CheckOutlined,
  LoadingOutlined,
  PlusOutlined,
  InboxOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { ReactComponent as IconUpload } from "../../../Assets/Images/Backoffice/Upload-off.svg";
const { Dragger } = Upload;
const { Text } = Typography;
const { TextArea } = Input;
import authService from "../../../Services/auth.service";

import { saveAs } from "file-saver";

var styles = {
  h6_label: {
    color: "#809FB8",
    fontFamily: "Roboto",
    fontSize: "15px",
  },
  input_class: {
    width: "100%",
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
  },
  switch_label: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    borderRadius: "8px",
    padding: "0px 16px",
    color: "#809FB8",
    border: "1px solid #809FB8",
    textAlign: "center",
  },
  upload: {
    width: "100%",
  },
  input_class2: {
    width: "100%",
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #ccc",
    color: "#0C1151",
  },
};

export default function ThirdTab(props) {
  let userDrawer = props.user;

  const session_storage = JSON.parse(localStorage.getItem("session_data"));

  const [usersList, setUsersList] = useState(session_storage.all_data.users);

  const tableOnChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const [quotas, setQuotas] = useState(function (params) {
    let quotas = session_storage.all_data.quotas;
    let files = session_storage.all_data.files;

    let quotasByUser = [];

    quotas.map((quota) => {
      if (userDrawer.id == quota.user_id) {
        files.map((file) => {
          if (quota.recibo == file.id) {
            console.log(file);
            quota.fileName = file.file;
          }
        });
        quotasByUser.push(quota);
      }
    });

    console.log(quotasByUser);

    return quotasByUser;
  });

  const menuProps = {
    onClick: false,
  };

  const [tablePagination, setTablePagination] = useState(5);
  const selectHandleChange = (value) => {
    console.log(`selected ${value}`);
    setTablePagination(value);
  };

  /* Table */

  const columns = [
    {
      title: "Quota",
      dataIndex: "quota",
    },
    {
      title: "Ano",
      dataIndex: "ano",
      sorter: {
        compare: (a, b) => a.number - b.number,
      },
    },
    {
      title: "Valor",
      dataIndex: "valor",
      sorter: {
        compare: (a, b) => a.type - b.type,
      },
    },
    {
      title: "PDF",
      dataIndex: "pdf",
    },
  ];

  const t = 1;
  const data = [];

  quotas.map((item) =>
    data.push({
      key: item.id,
      quota: (
        <Space className="item__icons">
          <b className="user-name">{item.descricao}</b>{" "}
        </Space>
      ),
      ano: (
        <Button className="user-number">
          <b>{item.data}</b>
        </Button>
      ),
      valor: <b style={{ textAlign: "center " }}>70€</b>,
      pdf: (
        <IconUpload
          className="icon icon-upload"
          onClick={() =>
            saveFile("https://spapapi.phormuladev.com/recibos/" + item.fileName)
          }
        />
      ),
    })
  );

  const saveFile = (url) => {
    console.log(url);
    saveAs(url, "recibo.pdf");
  };

  return (
    <>
      <Col
        style={{ padding: "30px", borderRadius: "16px", background: "#D6D7E2" }}
      >
        <h5 style={{ marginBottom: "10px", fontSize: "18px" }}>Quotas</h5>
        <p>
          Histórico de pagamento de quotas, com os recibos em PDF para consulta
        </p>
        <Divider style={{ background: "#fff" }}></Divider>
        <Table
          columns={columns}
          dataSource={data}
          onChange={tableOnChange}
          pagination={{ pageSize: tablePagination }}
        />
      </Col>
    </>
  );
}
