import styled from "styled-components";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import urlSlug from "url-slug";
import { Helmet } from "react-helmet";
import Container from "../../../Components/Container";
import FlexInRow from "../../../Components/FlexInRow";
import FlexInColumn from "../../../Components/FlexInColumn";
import PageTitle from "../../../Components/PageTitle";
import PageText from "../../../Components/PageText";
import RecommendedLinks from "../../../Components/RecommendedLinks";

import IconFile from "../../../Assets/Images/icon-awesome-file-alt.svg";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  > .content {
    padding-bottom: 4vw;
  }

  img {
    width: 100%;
  }

  .download-file {
    width: 33.52vw;

    .icon {
      margin: auto;
      width: 3.437vw;
    }

    .text,
    .link {
      font-weight: bold;
      font-size: 0.937vw;
      line-height: 1.25vw;
      margin: 0;
    }

    .text {
      color: #000000;
      text-align: center;
    }

    .link {
      color: #f88436;
    }
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    .download-file {
      margin: auto;
      width: 50vw;

      .icon {
        width: 66px;
      }

      .text,
      .link {
        font-size: 3.222vw;
        line-height: 4.333vw;
      }
    }
  }
`;

const SectionClubs = styled.section`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0.833vw;

  .item {
    transition: 0.3s ease;
    border-radius: 14px;
    background-repeat: no-repeat;
    background-position: center center;
    border: 3px solid #0c1151;
    position: relative;
    height: 155px;
    background-size: cover;
    color: #0c1151;
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: none;
  }

  .item.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .item:hover {
    color: #0c1151;
    box-shadow: 0 8px 24px #00000055;
  }

  @media (max-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
    margin-top: 50px;

    .item {
      span {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  @media (min-width: 1920px) {
    grid-template-columns: repeat(6, 1fr);
    gap: 30px;

    .item {
      height: 180px;

      span {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
`;

const SectionMembers = styled.section`
  background-color: #fde0cd;
  margin: 3.645vw 0 1.56vw;
  padding: 3.906vw 0;
  width: 100%;

  > div {
    align-items: center;
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 992px) {
    > div {
      grid-template-columns: 1fr;
    }
  }
`;

const SectionMembersContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  opacity: 1;

  a {
    text-align: center;
    font-size: 1.14vw;
    font-weight: bold;
    line-height: 1.51vw;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    background-color: ${(props) => props.theme.orange};
    border-radius: 0.521vw;
    opacity: 1;
    transition: 0.3s ease;
    margin: 16px;
    place-self: center;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    padding: 0.677vw 1.563vw;

    :hover {
      background-color: ${(props) => props.theme.orangeHover};
      color: #e5e5e5;
    }
  }

  @media (max-width: 992px) {
    a {
      border-radius: 8px;
      font-size: 3.333vw;
      line-height: 4.444vw;
      padding: 10px 20px;
    }
  }
`;

const SectionMembersImage = styled.div`
  place-self: center;
  width: 29.947vw;

  img {
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 992px) {
    width: 100%;
  }
`;

const SectionMembersTitle = styled.h3`
  text-align: center;
  font-size: 2.083vw;
  font-weight: bold;
  line-height: 2.5vw;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;
  margin: 1.042vw;
  place-self: center;

  @media (max-width: 992px) {
    font-size: 4.333vw;
    line-height: 5.444vw;
  }
`;

//backgroundRepeat: 'no-repeat',
//                   backgroundPosition: 'center center', border: '3px solid #0C1151', position: 'relative', height: '140px', backgroundSize: 'cover'
export default function ClubesEstudo(props) {
  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const [clubes, setClubes] = useState([]);

  useEffect(() => {
    fetch(`https://spapapi.phormuladev.com/api/all-clubes`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage?.token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.clubes.length === 0) {
          return;
        }

        let clubes = data.clubes;

        let clubesToShow = [];

        clubes?.length > 0 &&
          clubes.map((clube) => {
            if (clube.status == 1) {
              clube.slug = urlSlug(clube.name);
              clube.slug += "-" + clube.id;
            }
            clube.url = clube.url
              ? "https://spapapi.phormuladev.com/clubes/" + clube.url
              : "https://spapapi.phormuladev.com/clubes/clube-estudo-default.svg";

            const clubesComStatus1 = clubesToShow.filter(
              (club) => club.status == 1
            );
            const clubesComStatus0 = clubesToShow.filter(
              (club) => club.status == 0
            );

            clubesComStatus1.push(clube);

            clubesToShow = clubesComStatus1.concat(clubesComStatus0);
          });

        setClubes(clubesToShow);
      })
      .catch((error) => console.error(error));
  }, []);

  const ListClubs = [
    {
      imageDesktop: require("../../../Assets/Images/btn-clube-05@2x.png"),
      imageMobile: require("../../../Assets/Images/btn-clube-05.png"),
      imageAlt: "Patalogia Digestiva",
      link: "/socios/clubes-de-estudo/patologia-digestiva",
    },
    {
      imageDesktop: require("../../../Assets/Images/btn-clube-02@2x.png"),
      imageMobile: require("../../../Assets/Images/btn-clube-02.png"),
      imageAlt: "Patalogia Fetoplacentar",
      link: "/socios/clubes-de-estudo/patologia-fetoplacentar",
    },
    {
      imageDesktop: require("../../../Assets/Images/btn-clube-03@2x.png"),
      imageMobile: require("../../../Assets/Images/btn-clube-03.png"),
      imageAlt: "Patalogia Cutânea",
      link: "/socios/clubes-de-estudo/patologia-cutânea",
    },
    {
      imageDesktop: require("../../../Assets/Images/btn-clube-04@2x.png"),
      imageMobile: require("../../../Assets/Images/btn-clube-04.png"),
      imageAlt: "Patalogia Hematolinfoide",
      link: "/socios/clubes-de-estudo/patologia-hematolinfoide",
    },
    {
      imageDesktop: require("../../../Assets/Images/btn-clube-06@2x.png"),
      imageMobile: require("../../../Assets/Images/btn-clube-06.png"),
      imageAlt: "Patalogia Ginecológica",
      link: "/socios/clubes-de-estudo/patologia-ginecologica",
    },
    {
      imageDesktop: require("../../../Assets/Images/btn-clube-07@2x.png"),
      imageMobile: require("../../../Assets/Images/btn-clube-07.png"),
      imageAlt: "Patalogia Mamária",
      link: "/socios/clubes-de-estudo/patologia-mamaria",
    },
    {
      imageDesktop: require("../../../Assets/Images/btn-clube-08@2x.png"),
      imageMobile: require("../../../Assets/Images/btn-clube-08.png"),
      imageAlt: "Patalogia Endócrina",
      link: "/socios/clubes-de-estudo/patologia-endocrina",
    },
    {
      imageDesktop: require("../../../Assets/Images/btn-clube-09@2x.png"),
      imageMobile: require("../../../Assets/Images/btn-clube-09.png"),
      imageAlt: "Patalogia Partes Moles e Osso",
      link: "/socios/clubes-de-estudo/patologia-partes-mole-e-osso",
    },
    {
      imageDesktop: require("../../../Assets/Images/btn-clube-10@2x.png"),
      imageMobile: require("../../../Assets/Images/btn-clube-10.png"),
      imageAlt: "Patalogia Pulmonar",
      link: "/socios/clubes-de-estudo/patologia-pulmonar",
    },
    {
      imageDesktop: require("../../../Assets/Images/btn-clube-11@2x.png"),
      imageMobile: require("../../../Assets/Images/btn-clube-11.png"),
      imageAlt: "Patalogia Urogenital",
      link: "/socios/clubes-de-estudo/patologia-urogenital",
    },
  ];

  return (
    <Wrapper>
      <Helmet>
        <title>
          Clubes de Estudo | Sociedade Portuguesa de Anatomia Patológica - SPAP
        </title>
        <meta
          name="description"
          content="Faça parte dos clubes de patologia. Os Clubes são constituídos por grupos de sócios especializados ou com particular interesse numa determinada área da Anatomia Patológica e que pretendem executar no âmbito da SPAP, um aprofundamento conjunto e um intercâmbio de conhecimentos e experiências nessa mesma área."
        />
        <meta
          name="keywords"
          content="spap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <Container className="content">
        <PageTitle
          title="Clubes de estudo"
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "Sócios",
              link: "/socios",
            },
            {
              text: "Clubes de estudo",
            },
          ]}
          imageDesktop={require("../../../Assets/Images/img-clubes-01@2x.png")}
          imageMobile={require("../../../Assets/Images/img-clubes-01.png")}
          imageAlt="Clubes de estudo"
        />

        <FlexInRow gap="3.646vw">
          <FlexInColumn gap="3.646vw" mobile="column">
            <FlexInRow gap="0">
              <PageText
                title="Faça parte dos clubes de patologia"
                text="Os Clubes são constituídos por grupos de sócios especializados ou com particular interesse numa determinada área da Anatomia Patológica e que pretendem executar no âmbito da SPAP, um aprofundamento conjunto e um intercâmbio de conhecimentos e experiências nessa mesma área."
              />
              <PageText text="Actualmente estão oficialmente constituídos apenas dois clubes de estudo. Vamos reactivar os outros clubes, pelo que convidamos os sócios a manifestar o seu interesse, inscrevendo-se no máximo em três clubes na sua área de sócio." />
            </FlexInRow>
            <FlexInRow gap="0.521vw" className="download-file">
              <img src={IconFile} alt="Icon File" className="icon icon-file" />
              <p className="text">Regulamento dos Clubes</p>
              <a
                href={require("../../../Assets/Docs/REGULAMENTO DOS CLUBES - APROVADO AG 25.11.2022.pdf")}
                target="_blank"
                className="link"
              >
                Clique para visualizar
              </a>
            </FlexInRow>
          </FlexInColumn>
          {clubes && clubes.length > 0 && (
            <SectionClubs>
              {clubes.map((item, index) => {
                return item.status == 1 ? (
                  <Link
                    to={"/socios/clubes-de-estudo/" + item.slug}
                    className={"item item-" + index}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "end",
                      textDecoration: "none",
                      padding: "10px",
                      backgroundImage: "url(" + item.url + ")",
                    }}
                  >
                    <span
                      style={{
                        bottom: 0,
                        padding: "0 5px",
                        textDecoration: "none",
                      }}
                    >
                      {item.name}
                    </span>{" "}
                  </Link>
                ) : (
                  <div
                    className={"item item-" + index}
                    style={{
                      display: "flex",
                      opacity: "0.5",
                      justifyContent: "center",
                      alignItems: "end",
                      textDecoration: "none",
                      padding: "10px",
                      backgroundImage: "url(" + item.url + ")",
                    }}
                  >
                    <span
                      style={{
                        bottom: 0,
                        padding: "0 5px",
                        textDecoration: "none",
                      }}
                    >
                      {item.name}
                    </span>{" "}
                  </div>
                );
              })}
            </SectionClubs>
          )}
        </FlexInRow>
      </Container>

      <SectionMembers>
        <Container>
          <SectionMembersImage>
            <picture>
              <source
                srcSet={require("../../../Assets/Images/img-clubes-02@2x.png")}
                media="(min-width: 990px)"
              />
              <img
                src={require("../../../Assets/Images/img-clubes-02.png")}
                alt="Seja um Membro"
              />
            </picture>
          </SectionMembersImage>
          <SectionMembersContent>
            <SectionMembersTitle>
              TEM IDEIAS PARA UM NOVO CLUBE? <br />
              NÓS AJUDAMOS!
            </SectionMembersTitle>
            <Link to="/spap/contactos">Contacte-nos</Link>
          </SectionMembersContent>
        </Container>
      </SectionMembers>

      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={[
          {
            title: "História",
            link: "/spap/historia",
          },
          {
            title: "Órgãos sociais",
            link: "/spap/orgaos-sociais",
          },
          {
            title: "Bolsas e prémios",
            link: "/spap/bolsas-e-premios",
          },
          {
            title: "Vantagens de ser sócio",
            link: "/socios/conheca-as-vantagens",
          },
        ]}
      />
    </Wrapper>
  );
}
