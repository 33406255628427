import { Link, useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Input, Modal, Result } from "antd";
import axios from "axios";

import { SmileOutlined } from "@ant-design/icons";

import styled from "styled-components";

const Wrapper = styled.section`
  color: #ffffff;
  display: flex;
  flex-direction: column;
  font-size: 1.146vw;
  gap: 8px;
  letter-spacing: 0px;
  line-height: 1.51vw;
  text-align: center;
  min-width: 16.354vw;
  max-width: 314px;
  z-index: 9;
  padding: 40px 0px 20px;
  position: sticky;
  top: 130px;

  a {
    background-color: ${(props) => props.theme.blue};
    border: 0.156vw solid transparent;
    border-radius: 0.521vw;
    color: #ffffff;
    opacity: 1;
    text-decoration: none;
    text-align: center;
    letter-spacing: 0px;
    transition: 0.3s ease;
    font-size: 1.146vw;
    font-weight: bold;
    line-height: 1.51vw;
    padding: 0.781vw 1.563vw;
    transition: 0.3s ease;
  }

  a.selected {
    background-color: #ffffff;
    border: 0.156vw solid ${(props) => props.theme.blue};
    border-radius: 0.521vw;
    color: ${(props) => props.theme.blue};
    opacity: 1;
    text-decoration: none;
    text-align: center;
    letter-spacing: 0px;
    transition: 0.3s ease;
    font-size: 1.146vw;
    font-weight: bold;
    line-height: 1.51vw;
    padding: 0.781vw 1.563vw;
  }

  a:hover {
    background-color: #8688a8;
    border-color: #8688a8;
    color: #e5e5e5;
  }

  @media (max-width: 992px) {
    margin: auto;
    min-width: calc(100vw - 40px);
    max-width: calc(100vw - 40px);
    place-self: center;

    a,
    a.selected {
      border-radius: 8px;
      font-size: 3.333vw;
      line-height: 4.444vw;
      padding: 15px 30px;
    }
  }

  @media (min-width: 992px) {
    ${(props) =>
      props.margin &&
      `
      margin-bottom: ${props.margin};
    `}
  }

  @media (min-width: 1920px) {
    gap: 5px;
    min-width: 314px;
    max-width: 314px;

    a,
    a.selected {
      border-radius: 10px;
      font-size: 18px;
      line-height: 29px;
      padding: 10px 30px;
    }
  }
`;

export default function SidebarMenu(props) {
  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const [userEnrolled, setUserEnrolled] = useState(false);
  const [gestorClube, setGestorClube] = useState(false);

  const { slug } = useParams();

  const checkUserEnrolled = (membros) => {
    let myUser = session_storage.all_data.user_session;
    let encontrouUser = false;
    membros &&
      membros.length > 0 &&
      membros.map((membro) => {
        if (
          membro.user_id == myUser.id &&
          membro.club_id == slug.split("-").pop()
        ) {
          encontrouUser = true;
        }
      });

    setUserEnrolled(encontrouUser);
  };

  const checkGestorClube = (membrosClube) => {
    let myUser = session_storage.all_data.user_session;
    let myRole = session_storage.all_data.role;

    console.log("aqui");
    console.log(myRole);

    if (myRole != "admin") {
      let encontrouUser = false;
      membrosClube.length > 0 &&
        membrosClube.map((membro) => {
          if (membro.user_id == myUser.id && membro.member_type == 2) {
            setGestorClube(true);
            encontrouUser;
          }
        });

      if (gestorClube) {
        session_storage.all_data.role = "moderator";
      } else {
        session_storage.all_data.role = myUser.tipo_utilizador;
      }
      localStorage.setItem("session_data", JSON.stringify(session_storage));
    }
  };

  useEffect(() => {
    if (slug) {
      fetch(
        `https://spapapi.phormuladev.com/api/get-clube/` +
          slug.split("-").pop(),
        {
          method: "GET",
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Erro ao buscar eventos");
          }
          return response.json();
        })
        .then((data) => {
          if (data.length === 0) {
            console.log("nenhum clube");
          }
          checkUserEnrolled(data.membrosClube);
          checkGestorClube(data.membrosClube);
        })
        .catch((error) => console.error(error));
    }
  }, []);

  const [isModalEnrollOpen, setModalEnrollOpen] = useState(false);
  const [isModalSuccessOpen, setModalSuccessOpen] = useState(false);
  const [isModalNoEnrollOpen, setModalNoEnrollOpen] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [subtitleModal, setSubtitleModal] = useState(false);

  const handleEnrollUserToClube = () => {
    let usersSelected = [];
    usersSelected.push(session_storage.all_data.user_session.id);
    let type = 1; // member
    let clubeId = slug.split("-").pop();

    axios
      .post(
        "https://spapapi.phormuladev.com/api/set-members",
        { clubeId, usersSelected, type },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        if (res.data.membrosClube.length) {
          setUserEnrolled(true);
          setModalNoEnrollOpen(false);
          setTitleModal("Inscreveu-se com sucesso ao clube.");
          setSubtitleModal(
            "Esteja a par das novidades que temos para si, tais como os eventos a não perder."
          );
          setModalSuccessOpen(true);
          setTimeout(() => {
            setModalSuccessOpen(false);
          }, 3000);
        }
        props.enrolled(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRemoveUserFromClube = () => {
    let userSelected = session_storage.all_data.user_session.id;
    let type = 1; // member
    let clubeId = slug.split("-").pop();

    axios
      .post(
        "https://spapapi.phormuladev.com/api/remove-clube-member",
        { clubeId, userSelected },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        if (res.data.membrosClube.length) {
          setUserEnrolled(false);
          setModalNoEnrollOpen(false);
          setTitleModal("Abandonou o clube com sucesso.");
          setSubtitleModal(
            "Esperamos que volte brevemente para se manter a par das novidades que temos para si."
          );
          setModalSuccessOpen(true);
          setTimeout(() => {
            setModalSuccessOpen(false);
          }, 3000);
          props.enrolled(false);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInscricao = (e) => {
    if (userEnrolled) setModalNoEnrollOpen(true);
    else handleEnrollUserToClube();
  };

  const location = useLocation();
  let navigate = useNavigate();

  if (props.eventClick) {
    handleEnrollUserToClube();
    props.cancelClick();
  }

  return (
    <Wrapper
      key={"sidebarMenu"}
      className={props.className}
      margin={props.margin}
    >
      {props.clube && gestorClube == true && (
        <>
          <Link
            target="_blank"
            to={"/dashboard/clubes/" + slug.split("-").pop()}
            className="selected"
          >
            Gerir este clube{" "}
          </Link>
        </>
      )}
      {props.clube && !gestorClube && (
        <>
          <a
            href="javascript:void(0);"
            onClick={handleInscricao}
            className="selected"
          >
            {userEnrolled ? "Abandonar clube" : "Pertencer ao clube"}{" "}
          </a>
          <Modal
            centered
            open={isModalSuccessOpen}
            footer={null}
            onCancel={() => setModalSuccessOpen(false)}
          >
            <Result
              icon={<SmileOutlined style={{ color: "#0C1151" }} />}
              title={titleModal}
              subTitle={subtitleModal}
            />
          </Modal>
          <Modal
            centered
            open={isModalNoEnrollOpen}
            footer={null}
            onCancel={() => setModalNoEnrollOpen(false)}
          >
            <Result
              className="modal-clube"
              style={{ background: "#fff", padding: "18px 30px" }}
              status="warning"
              title="Tem a certeza que pretendes abandonar este clube? "
              subTitle="Caso abandone, deixará de poder ter acesso às últimas novidades do mesmo até nova inscrição."
            />
            <Input
              onClick={handleRemoveUserFromClube}
              className="sidebar__cta"
              type="submit"
              style={{
                fontWeight: "600",
                width: "50%",
                display: "block",
                margin: "0 auto",
                background: "#0C1151",
                color: "#fff",
                height: "45px",
              }}
              value="Abandonar clube"
            />
            {/* <a onClick={() => setModalNoEnrollOpen(true)} className="sidebar__cta">
                    Abandonar clube
                  </a> */}
          </Modal>
        </>
      )}
      {props.navItens.map((item, index) => {
        return (
          <Link
            key={"navItems" + index}
            to={item.link}
            className={
              "item item-" +
              (index + 1) +
              (item.link === location.pathname || item.selected
                ? " selected"
                : "")
            }
          >
            {item.title}
          </Link>
        );
      })}
    </Wrapper>
  );
}
