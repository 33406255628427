import styled from "styled-components";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import { notification, Input, Result, Modal, Col } from "antd";
import axios from "axios";
import Container from "../../../Components/Container";
import FlexInRow from "../../../Components/FlexInRow";
import FlexInColumn from "../../../Components/FlexInColumn";
import PageTitle from "../../../Components/PageTitle";
import PageText from "../../../Components/PageText";
import SupportedBySpap from "../../../Components/SupportedBySpap";
import { SmileOutlined } from "@ant-design/icons";

import IconFile from "../../../Assets/Images/icon-awesome-file-alt.svg";
import { SELECTION_ALL } from "antd/es/table/hooks/useSelection";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  > .content {
    padding-bottom: 5.208vw;
  }

  img {
    width: 100%;
  }

  .sidebar__cta,
  .ant-modal-body a {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0.521vw;
    color: #0c1151;
    opacity: 1;
    text-decoration: none !important;
    text-align: center;
    letter-spacing: 0px;
    transition: 0.3s ease;
    font-size: 1.146vw;
    font-weight: bold;
    line-height: 1.51vw;
    border: 0.156vw solid #0c1151;
    padding: 0.781vw 1.563vw;
  }

  .sidebar__cta:hover {
    background-color: #0c1151;
    border-color: transparent;
    color: #e5e5e5;
  }

  .sidebar__cta.blue {
    background: #0c1151 0% 0% no-repeat padding-box;
    border: 0.156vw solid transparent;
    color: #ffffff;
  }

  .sidebar__cta.blue:hover {
    background-color: #8688a8;
    color: #e5e5e5;
  }

  .bar {
    height: 1px;
    width: 66.854vw;
    background-color: #d6cdf7;
    opacity: 1;
    margin: auto;
  }

  @media (max-width: 992px) {
    margin-top: 120px;
  }
`;

const Title = styled.h2`
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;

  @media (max-width: 992px) {
    font-size: 3.333vw;
    line-height: 4.444vw;
  }
`;

const ListPosts = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 3.021vw;
  grid-row-gap: 3.646vw;
  padding: 0 0 5.208vw;
  width: 100%;

  .item {
    border: 3px solid #0c1151;
    border-top: 12px solid #0c1151;
    border-radius: 0px 0px 20px 20px;
    opacity: 1;
  }

  .item__content {
    color: #0c1151;
    display: flex;
    flex-direction: column;
    gap: 1.458vw;
    padding: 1.042vw 1.042vw 1.563vw;
    text-align: left;
  }

  .item__title {
    align-items: center;
    justify-content: flex-start;
    gap: 1.042vw;
    display: flex;
    flex-direction: row;

    img {
      width: 5.208vw;
      margin-top: -2.604vw;
    }

    .title {
      text-align: left;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0px;
      line-height: inherit;
      letter-spacing: 0px;
      color: #0c1151;
      opacity: 1;
    }
  }

  p {
    text-align: left;
    font-size: 0.938vw;
    letter-spacing: 0px;
    line-height: 1.25vw;
    color: #000000;
    margin: 0;
  }

  .item__cta {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    gap: 2.604vw;
    text-align: center;

    a {
      background: #f88436 0% 0% no-repeat padding-box;
      border: none;
      border-radius: 0.521vw;
      opacity: 1;
      font-size: 1.146vw;
      font-weight: bold;
      letter-spacing: 0px;
      line-height: 1.51vw;
      color: #ffffff;
      opacity: 1;
      padding: 0.677vw 1.563vw;
      max-width: 8.281vw;
      transition: 0.3s ease;
      text-decoration: none;
    }

    a:hover {
      background-color: #e87426;
      color: #ececec;
    }
  }

  @media (max-width: 992px) {
    p,
    .item__title .title,
    .item__cta a {
    }
  }
`;

const SectionCards = styled.div`
  display: grid;
  gap: 2.604vw;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 5.208vw;

  .item {
    border: 0.156vw solid #494d7d;
    border-radius: 1.042vw;
    justify-content: space-between;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr auto;
  }

  .item__content {
    padding: 1.042vw;
  }

  .item__image {
    border: none;
    overflow: hidden;
    width: 13.542vw;

    img {
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }

  .item__title {
    text-align: left;
    font-size: 16px;
    line-height: inherit;
    font-weight: bold;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    margin: 1.094vw 0;
  }

  .item__text {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 0.729vw;
  }

  .item__cta {
    align-items: center;
    justify-content: center;
    display: flex;
    background: #0c1151 0% 0% no-repeat padding-box;
    border-radius: 0.521vw;
    opacity: 1;
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    text-decoration: none;
    color: #ffffff;
    opacity: 1;
    width: 8.021vw;
    height: 2.396vw;
    transition: 0.3s ease;
  }

  .item__cta:hover {
    background-color: #8688a8;
    color: #e5e5e5;
  }

  .item__date {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.521vw;
  }

  .item__date svg {
    font-size: 1.302vw;
  }

  @media (max-width: 992px) {
    .item__text,
    .item__cta,
    .item__date,
    .item__date svg {
      font-size: 2.222vw;
      line-height: 3.333vw;
    }
  }

  @media (min-width: 1920px) {
    .item__text,
    .item__cta,
    .item__date,
    .item__date svg {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  p {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  a {
    justify-content: flex-start;
    margin-top: 0.938vw;
    font-size: 0.938vw;
    font-weight: bold;
    line-height: 1.25vw;
    text-align: left;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    text-decoration: none;
  }

  .icon {
    margin-right: 8px;
  }

  .icon-file {
    width: 3.438vw;
  }

  @media (max-width: 992px) {
    p,
    a {
      font-size: 3.333vw;
      line-height: 3.333vw;
    }
  }

  @media (min-width: 1920px) {
    p,
    a {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

export default function Clube(props) {
  const { slug } = useParams();

  const [isModalSuccessOpen, setModalSuccessOpen] = useState(false);
  const [isModalEnrollOpen, setModalEnrollOpen] = useState(false);
  const [isModalNoEnrollOpen, setModalNoEnrollOpen] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [subtitleModal, setSubtitleModal] = useState(false);
  const [clube, setClube] = useState([]);

  const checkUserEnrolled = () => {
    let myUser = session_storage.all_data.user_session;
    let membrosClubes = session_storage.all_data.membrosClubes;
    let encontrouUser = false;
    membrosClubes &&
      membrosClubes.length > 0 &&
      membrosClubes.map((membrosClube) => {
        if (membrosClube.club_id == slug && membrosClube.user_id == myUser.id) {
          encontrouUser = true;
        }
      });

    return encontrouUser;
  };

  useEffect(() => {
    fetch(
      `https://spapapi.phormuladev.com/api/get-clube/` + slug.split("-").pop(),
      {
        method: "GET",
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar clubes");
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          console.log("nenhum clube");
        }
        getClube(data.clube);
        checkUserEnrolled(data.membrosClube);
        getMembrosClube(data.membrosClube);
        getGestoresClube(data.gestoresClube);
      })
      .catch((error) => console.error(error));
  }, []);

  const [usersList, setUsersList] = useState(session_storage.all_data.users);
  const [userEnrolled, setUserEnrolled] = useState(checkUserEnrolled);

  const getClube = (thisClub) => {
    let myClube = thisClub[0];
    myClube.url = myClube.url
      ? "https://spapapi.phormuladev.com/clubes/" + myClube.url
      : "https://spapapi.phormuladev.com/clubes/clube-estudo-default.svg";

    setClube(myClube);
  };

  const getGestoresClube = () => {
    let membrosClubes = session_storage.all_data.membrosClubes;
    let gestoresDesteClube = [];
    membrosClubes &&
      membrosClubes.length > 0 &&
      membrosClubes.map((membrosClube) => {
        if (membrosClube.club_id == slug) {
          usersList &&
            usersList.length > 0 &&
            usersList.map((user) => {
              if (
                user.id == membrosClube.user_id &&
                membrosClube.member_type == 2
              ) {
                gestoresDesteClube.push(user);
              }
            });
        }
      });

    return gestoresDesteClube;
  };

  const [gestoresClube, setGestoresClube] = useState(getGestoresClube);

  const syncData = () => {
    setGestoresClube(getGestoresClube);
    setUserEnrolled(checkUserEnrolled);
  };

  const handleEnrollUserToClube = () => {
    let usersSelected = [];
    usersSelected.push(session_storage.all_data.user_session.id);
    let type = 1; // member
    let clubeId = clube.id;

    axios
      .post(
        "https://spapapi.phormuladev.com/api/user/set-members",
        { clubeId, usersSelected, type },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        if (res.data.membrosClubes.length) {
          session_storage.all_data.membrosClubes = res.data.membrosClubes;
          localStorage.setItem("session_data", JSON.stringify(session_storage));
          syncData();
          setModalNoEnrollOpen(false);
          setTitleModal(
            'Inscreveu-se com sucesso ao clube "' + clube.name + '".'
          );
          setSubtitleModal(
            "Esteja a par das novidades que temos para si, tais como os eventos a não perder."
          );
          setModalSuccessOpen(true);
          setHasError(true);
          setTimeout(() => {
            setModalSuccessOpen(false);
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRemoveUserFromClube = () => {
    let userSelected = session_storage.all_data.user_session.id;
    let type = 1; // member
    let clubeId = clube.id;

    axios
      .post(
        "https://spapapi.phormuladev.com/api/user/remove-clube-member",
        { clubeId, userSelected },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        if (res.data.membrosClubes.length) {
          session_storage.all_data.membrosClubes = res.data.membrosClubes;
          localStorage.setItem("session_data", JSON.stringify(session_storage));
          syncData();
          setModalNoEnrollOpen(false);
          setTitleModal('Abandonou o clube "' + clube.name + '" com sucesso.');
          setSubtitleModal(
            "Esperamos que volte brevemente para se manter a par das novidades que temos para si."
          );
          setModalSuccessOpen(true);
          setHasError(true);
          setTimeout(() => {
            setModalSuccessOpen(false);
          }, 3000);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ListCards = [
    {
      date: "17-22 setembro 2022 | Florença, Itália",
      title: "21st Meeting of the EAHP",
      text: "Join us for six fabulous days of insight, live interaction, and inspiration - on site in Florence or virtually from all over the world! (…)",
      desktop: require("../../../Assets/Images/img-agenda-01@2x.png"),
      mobile: require("../../../Assets/Images/img-agenda-01.png"),
      alt: "21st Meeting of the EAHP",
      link: "evento/21st-meeting-of-the-eahp",
      attr: {
        type: "all",
        local: "international",
        year: "2022",
        month: "09",
      },
    },
    {
      date: "2-4 novembro 2022 | Online",
      title: "61st IAP-Thailand Annual Meeting 2022",
      text: "The annual pathology academic meeting, organized by IAP-Thailand Association. The meeting is for promoting knowledge for pathologists (…)",
      desktop: require("../../../Assets/Images/img-agenda-02@2x.png"),
      mobile: require("../../../Assets/Images/img-agenda-02.png"),
      alt: "61st IAP Thailand Annual Meeting 2022",
      link: "evento/61st-iap-thailand-annual-meeting-2022",
      attr: {
        type: "new",
        local: "online",
        year: "2022",
        month: "11",
      },
    },
  ];

  console.log(clube);

  return (
    <Wrapper>
      <Container className="content">
        <PageTitle
          title={clube.name}
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "Sócios",
              link: "/socios",
            },
            {
              text: "Clubes de estudo",
              link: "/socios/clubes-de-estudo",
            },
            {
              text: clube.name,
            },
          ]}
          imageDesktop={clube.url}
          imageMobile={clube.url}
          imageAlt={clube.name}
          border={false}
        />

        <FlexInRow gap="2.083vw">
          <FlexInColumn>
            <Col span={18}>
              <PageText
                title={
                  userEnrolled
                    ? "Já pertence a este clube!"
                    : "Faça parte do clube!"
                }
                text={clube.descricao}
              />
            </Col>

            <Col span={6}>
              <FlexInRow gap="1.042vw" className="sidebar">
                {userEnrolled == false ? (
                  <>
                    <a
                      onClick={handleEnrollUserToClube}
                      className="sidebar__cta blue"
                    >
                      Pertencer ao clube
                    </a>
                  </>
                ) : (
                  <>
                    <a
                      onClick={() => setModalNoEnrollOpen(true)}
                      className="sidebar__cta"
                    >
                      Abandonar clube
                    </a>
                  </>
                )}
              </FlexInRow>
            </Col>
          </FlexInColumn>

          {gestoresClube.length > 0 && (
            <>
              <FlexInColumn gap="1.042vw">
                <Title>Gestores de clube</Title>
                <div className="bar"></div>
              </FlexInColumn>
              {gestoresClube && gestoresClube.length > 0 && (
                <ListPosts>
                  {gestoresClube.map((item, index) => {
                    return (
                      <div className={"item item-" + (index + 1)}>
                        <div className="item__content">
                          <div className="item__title">
                            <img
                              src={require("../../../Assets/Images/foto-default@2x.png")}
                              alt="Default"
                            />
                            <h4 className="title">{item.name}</h4>
                          </div>
                          <p>
                            <b>Instituição: </b>
                            {item.instituicao}
                          </p>
                          <p>
                            <b>Cidade: </b>
                            {item.cidade ? item.cidade : "N/A"}
                          </p>
                          <p>
                            <b>Diferenciação: </b>
                            {item.area_subespecializacao
                              ? item.area_subespecializacao
                              : "N/A"}
                          </p>
                          <p>{item.text}</p>
                          <div className="item__cta">
                            <a href="#">Contactar</a>
                            <Link to={"/socios/perfil/" + item.id}>
                              Ver perfil
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </ListPosts>
              )}
            </>
          )}

          <FlexInColumn gap="1.042vw">
            <Title>Eventos a não perder</Title>
            <div className="bar"></div>
          </FlexInColumn>
          {ListCards && ListCards.length > 0 && (
            <SectionCards>
              {ListCards.map((item, index) => {
                return (
                  <div className={"item item-" + index}>
                    <div className="item__content">
                      <div className="item__date">
                        <FontAwesomeIcon icon={faCalendarDay} />
                        {item.date}
                      </div>
                      <h3 className="item__title">{item.title}</h3>
                      <p className="item__text">{item.text}</p>
                      <Link to={item.link} className="item__cta">
                        Saber mais »
                      </Link>
                    </div>
                    <div className="item__image">
                      <picture>
                        <source
                          srcSet={item.desktop}
                          media="(min-width: 990px)"
                        />
                        <img src={item.mobile} alt={item.alt} />
                      </picture>
                    </div>
                  </div>
                );
              })}
            </SectionCards>
          )}

          <FlexInColumn gap="1.042vw">
            <Title>Recursos para si</Title>
            <div className="bar"></div>
          </FlexInColumn>

          <FlexInRow gap="3.646vw">
            <FlexInColumn>
              <Text>
                <img
                  src={IconFile}
                  alt="Icon File"
                  className="icon icon-file"
                />
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam uiof nonumy eirmod tempor invidunt ut labore et dolore
                  magna aliquyam
                  <Link to="/">Clique para visualizar</Link>
                </p>
              </Text>
              <Text>
                <img
                  src={IconFile}
                  alt="Icon File"
                  className="icon icon-file"
                />
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam uiof nonumy eirmod tempor invidunt ut labore et dolore
                  magna aliquyam
                  <Link to="/">Clique para visualizar</Link>
                </p>
              </Text>
            </FlexInColumn>
            <FlexInColumn>
              <Text>
                <img
                  src={IconFile}
                  alt="Icon File"
                  className="icon icon-file"
                />
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam uiof nonumy eirmod tempor invidunt ut labore et dolore
                  magna aliquyam
                  <Link to="/">Clique para visualizar</Link>
                </p>
              </Text>
              <Text>
                <img
                  src={IconFile}
                  alt="Icon File"
                  className="icon icon-file"
                />
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam uiof nonumy eirmod tempor invidunt ut labore et dolore
                  magna aliquyam
                  <Link to="/">Clique para visualizar</Link>
                </p>
              </Text>
            </FlexInColumn>
          </FlexInRow>
        </FlexInRow>
      </Container>

      <SupportedBySpap title="Veja Também" />

      <Modal
        centered
        open={isModalSuccessOpen}
        footer={null}
        onCancel={() => setModalSuccessOpen(false)}
      >
        <Result
          icon={<SmileOutlined style={{ color: "#0C1151" }} />}
          title={titleModal}
          subTitle={subtitleModal}
        />
      </Modal>
      <Modal
        centered
        open={isModalNoEnrollOpen}
        footer={null}
        onCancel={() => setModalNoEnrollOpen(false)}
      >
        <Result
          className="modal-clube"
          style={{ background: "#fff", padding: "18px 30px" }}
          status="warning"
          title="Tem a certeza que pretendes abandonar este clube? "
          subTitle="Caso abandone, deixará de poder ter acesso às últimas novidades do mesmo até nova inscrição."
        />
        <Input
          onClick={handleRemoveUserFromClube}
          className="sidebar__cta"
          type="submit"
          style={{
            fontWeight: "600",
            width: "50%",
            display: "block",
            margin: "0 auto",
            background: "#0C1151",
            color: "#fff",
            height: "45px",
          }}
          value="Abandonar clube"
        />
        {/* <a onClick={() => setModalNoEnrollOpen(true)} className="sidebar__cta">
                Abandonar clube
              </a> */}
      </Modal>
    </Wrapper>
  );
}
