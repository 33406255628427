import { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { Navigate, Link, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Popconfirm,
  Drawer,
  DatePicker,
  notification,
  Divider,
  Button,
  Dropdown,
  message,
  Space,
  Switch,
  Tooltip,
  Input,
  Radio,
  Checkbox,
  Modal,
  Message,
  Table,
  Upload,
  Select,
  Pagination,
} from "antd";
import {
  DownOutlined,
  UserOutlined,
  CheckOutlined,
  LoadingOutlined,
  PlusOutlined,
  InboxOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import authService from "../../../Services/auth.service";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import UserService from "../../../Services/user.service";
import Sidebar from "../../../Components/Backoffice/Sidebar";
import Card from "../../../Components/Backoffice/Card";
import Title from "../../../Components/Backoffice/Title";
import Header from "../../../Components/Backoffice/Header";
import Footer from "../../../Components/Backoffice/Footer";
import DrawerPerfil from "../../../Pages/Backoffice/Socios/drawerPerfil";
import ClubeHeader from "../../../Components/Backoffice/ClubeHeader";
import dayjs from "dayjs";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import { EyeOutlined } from "@ant-design/icons";

import { ReactComponent as IconSearch } from "../../../Assets/Images/Backoffice/Search.svg";
import { ReactComponent as IconOptions } from "../../../Assets/Images/Backoffice/Options.svg";
import { ReactComponent as IconUpload } from "../../../Assets/Images/Backoffice/Upload-off.svg";
import { ReactComponent as IconMessage } from "../../../Assets/Images/Backoffice/Message.svg";
import { ReactComponent as IconSettings } from "../../../Assets/Images/Backoffice/Settings.svg";
import { ReactComponent as IconInfo } from "../../../Assets/Images/Backoffice/Notifications/Info.svg";
import { ReactComponent as IconClose } from "../../../Assets/Images/Backoffice/Close.svg";
import { ReactComponent as IconEdit } from "../../../Assets/Images/Backoffice/Edit.svg";
import { ReactComponent as IconCalendar } from "../../../Assets/Images/Backoffice/Calendar.svg";
import { ReactComponent as IconOk } from "../../../Assets/Images/Backoffice/Ok.svg";
import { ReactComponent as IconHours } from "../../../Assets/Images/Backoffice/Hours.svg";

import axios, { all } from "axios";
import CardEventos from "../../../Components/Backoffice/CardEventos";
import RecursosParaSi from "../../../Components/Backoffice/RecursosParaSi";

const { TextArea } = Input;
const { Dragger } = Upload;
const { RangePicker } = DatePicker;

const Wrapper = styled.main`
  background-color: #e8e9f3;
  display: grid;
  grid-template-columns: 1fr;
  min-height: 100vh;

  &.isAdmin {
    grid-template-columns: 7.083vw 1fr;
  }

  .btn-descricao {
    margin-top: 10px;
    height: 45px !important;
    padding: 0 15px;
    font-weight: bold;
  }

  .icon-edit {
    cursor: pointer;
  }

  .cta.publish {
    margin-right: 0;

    svg path {
      fill: #fff;
    }
  }

  .text {
    margin-bottom: 0 !important;
  }

  @media (max-width: 992px) {
  }
`;

const WrapperCardList = styled.section`
  display: flex;
  flex-direction: column;

  p {
    color: #c2c4d4;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 20px;
    text-align: left;
  }

  .text {
    color: #809fb8;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0px;
  }

  .icon-close {
    cursor: pointer;
  }

  .action-edit {
    cursor: pointer !important;
    color: #494d7d;
  }
`;

const WrapperCardEvento = styled.section`
  display: flex;
  flex-direction: column;

  .grid {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 20px;
  }

  .text {
    margin-bottom: 0% !important;
  }

  .ql-editor p {
    font-size: 14px !important;
  }

  h4 {
    text-align: center;
    font: normal normal bold 20px/20px Roboto;
    letter-spacing: 0px;
    color: #050505;
  }

  .ant-upload-wrapper .ant-upload-drag {
    max-height: -webkit-fill-available;
    height: 91%;
    display: flex;
    justify-content: center;
  }

  .ant-upload-wrapper .ant-upload-drag .ant-upload-btn {
    height: -webkit-fill-available;
  }

  .text {
    color: #809fb8;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0px;
  }

  .center {
    text-align: center;
  }

  .cta {
    display: block !important;
    font-size: 15px;
    font-weight: bold;
    height: 45px !important;
    line-height: 18px;

    i {
      margin: 0 3px;
    }

    a {
      text-decoration: none;
    }
  }

  .cta.publish {
    margin-right: 0;

    svg path {
      fill: #fff;
    }
  }
`;

const Files = styled.section`
  display: flex;
  flex-direction: column;

  h4 {
    text-align: left;
    font: normal normal bold 20px/20px Roboto;
    letter-spacing: 0px;
    color: #050505;
  }

  .text {
    color: #809fb8;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0px;
  }

  .center {
    text-align: center;
  }

  .cta {
    display: block !important;
    font-size: 15px;
    font-weight: bold;
    height: 45px;
    line-height: 18px;

    i {
      margin: 0 3px;
    }

    a {
      text-decoration: none;
    }
  }

  .cta.publish {
    margin-right: 0;

    svg path {
      fill: #fff;
    }
  }
`;

const Content = styled.div`
  padding: 1.771vw;
`;

const CardHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  // gap: 60px;
  grid-template-columns: 1fr auto auto;
`;

const Search = styled.div`
  position: relative;

  input {
    background-color: #f1f5f700;
    border: 2px solid #d9e1e7;
    border-radius: 16px;
    color: #8688a8;
    font-size: 16px;
    font-style: italic;
    letter-spacing: 0px;
    line-height: 18px;
    margin: 0;
    padding: 12px 58px 12px 26px;
    outline: 0;
  }

  .icon {
    position: absolute;
    right: 26px;
    top: 8px;
  }
`;

const CardContent = styled.div`
  margin: 40px 0;
  position: relative;

  table,
  .ant-table-cell p {
    text-align: center;
  }

  table th,
  table li {
    color: #809fb8 !important;
    text-align: center !important;
  }

  .ant-checkbox-checked span.ant-checkbox-inner,
  .ant-checkbox-checked:hover span.ant-checkbox-inner {
    background-color: #1ad598 !important;
    border-color: #1ad598 !important;
  }

  .ant-checkbox:hover span.ant-checkbox-inner,
  .ant-checkbox-wrapper.item__checkbox:hover .ant-checkbox-inner {
    border-color: #1ad598 !important;
  }

  .user-name {
    color: #494d7d;
  }

  .icon-upload {
    cursor: pointer;
    height: 46px;
    width: 46px;
  }

  .user-number {
    border-color: #d9e1e7cc;
    color: #809fb8;
    text-align: center;
  }

  .user-active {
    background-color: #1ad5984d;
    border: none;
    color: #1ad598;
  }

  .user-desactive {
    background-color: #ea3a3d4d;
    border: none;
    color: #ea3a3d;
  }

  .user-disabled {
    background-color: #d9e1e7;
    border: none;
    color: #99b2c6;
  }

  li.ant-pagination-item {
    border: 1px solid #d9e1e7cc;
  }

  li.ant-pagination-item.ant-pagination-item-active {
    background-color: #8688a8;
    border-color: #8688a8;
    color: #ffffff;
  }
`;

const TableFooter = styled.div`
  bottom: 16px;
  left: 0;
  position: absolute;
`;

const RadioGroup = styled.div`
  button {
    background-color: #494d7d;
    border: none;
    color: #ffffff;
    transition: 0.3s ease;
  }

  button:hover {
    background-color: #1ad598;
    color: #ffffff !important;
  }
`;

const UploadButtonStyle = styled.button`
  background-color: #494d7d;
  border: none;
  color: #ffffff;
  transition: 0.3s ease;
  font-size: 14px;
  height: 40px;
  padding: 4px 15px;
  border-radius: 6px;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);

  :hover {
    background-color: #1ad598;
    color: #ffffff !important;
  }
`;

const AlertMesssage = styled.div`
  background: #f1f5f700 0% 0% no-repeat padding-box;
  border: 2px solid #ffffff;
  border-radius: 16px;
  text-align: left;
  letter-spacing: 0px;
  padding: 10px;
  color: #0c1151;
  font-style: italic;
  width: 100%;

  circle {
    fill: #0c1151;
  }

  svg path {
    fill: #fff;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 18px;

  .form {
    place-self: flex-start;
    width: 100%;
  }
`;

const GridCardsPDF = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 18px;

  .item {
    margin: 0;
  }

  .item__title {
    color: #494d7d;
    letter-spacing: 0px;
    font-size: 18px;
    font-weight: bold;
    line-height: 18px;
    text-align: left;
  }

  .item__text {
    color: #c2c4d4;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }

  .card-gallery {
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .header-gallery-div {
    display: block;
  }

  .gallery-title {
    display: flex;
    justify-content: space-between;
  }
`;

const GridCards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 18px;

  .item {
    margin: 0;
  }

  .disabled {
    opacity: 0.7 !important;
  }

  .item__title {
    color: #494d7d;
    letter-spacing: 0px;
    font-size: 18px;
    font-weight: bold;
    line-height: 18px;
    text-align: left;
  }

  .item__text {
    color: #c2c4d4;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }

  .card-gallery {
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .header-gallery-div {
    display: block;
  }

  .gallery-title {
    display: flex;
    justify-content: space-between;
  }
`;

const ContentFooter = styled.div`
  align-items: center;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  margin-top: 18px;

  button {
    display: block !important;
    font-size: 15px;
    font-weight: bold;
    height: 45px;
    line-height: 18px;

    i {
      margin: 0 3px;
    }
  }

  a {
    text-decoration: none;
  }
`;

var styles = {
  h6_label: {
    color: "#809FB8",
    fontFamily: "Roboto",
    fontSize: "15px",
    marginTop: "1%",
  },
  input_class_disabled: {
    marginBottom: "2%",
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
    opacity: 0.6,
    pointerEvents: "none",
  },
  input_class: {
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
    width: "100%",
  },
  textarea_class: {
    borderRadius: "6px",
    border: "1px solid #809FB8",
    color: "#0C1151",
  },
  switch_label: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    borderRadius: "8px",
    padding: "0px 16px",
    color: "#809FB8",
    border: "1px solid #809FB8",
    textAlign: "center",
  },
  upload: {
    width: "100%",
  },
};

export default function Clubes() {
  document.title = "SPAP - Clubes";
  const session_storage = JSON.parse(localStorage.getItem("session_data"));

  const { slug } = useParams();
  const [isModalremoveClubeOpen, setIsModalremoveClubeOpen] = useState(false);
  const [clube, setClube] = useState([]);
  const [descricaoClube, setDescricaoClube] = useState([]);

  const [hasError, setHasError] = useState(false);
  const [fileUploadList, setFileUploadList] = useState("");
  const [tituloPDF, setTituloPDF] = useState("");
  const [descricaoPDF, setDescricaoPDF] = useState("");
  const [showPDFDrawer, setShowPDFDrawer] = useState(false);
  const [pdfFileEdit, setPDFFileEdit] = useState("");
  const [tituloPDFEdit, setTituloPDFEdit] = useState("");
  const [descricaoPDFEdit, setDescricaoPDFEdit] = useState("");
  const [removePDFModalOpen, setRemovePDFModalOpen] = useState(false);
  const [filesPDF, setFilesPDF] = useState([]);

  const [fileEventList, setfileEventList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [dataEvent, setDataEvent] = useState([]);
  const [descricaoEvent, setDescricaoEvent] = useState("");
  const [noticiaEvent, setNoticiaEvent] = useState(false);
  const [bolsaPremioEvent, setBolsaPremioEvent] = useState(false);
  const [tituloEvent, setTituloEvent] = useState("");
  const [localizacaoEvent, setLocalizacaoEvent] = useState("");
  const [areaEvent, setAreaEvent] = useState("");
  const [agendarPost, setAgendarPostEvent] = useState("");
  const [showEventDrawer, setShowEventDrawer] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [currentItemsEvent, setCurrentItemsEvent] = useState([]);

  // editar evento
  const [fileEventListEdit, setfileEventListEdit] = useState([]);
  const [eventIdEdit, setEventIdEdit] = useState("");
  const [pdfIdEdit, setPdfIdEdit] = useState("");
  const [dataEditEvent, setDataEditEvent] = useState([]);
  const [descricaoEventEdit, setDescricaoEventEdit] = useState("");
  const [tituloEventEdit, setTituloEventEdit] = useState("");
  const [localizacaoEdit, setLocalizacaoEdit] = useState("");
  const [imagemDestaqueEdit, setImagemDestaqueEdit] = useState("");
  const [areaEdit, setAreaEdit] = useState("");
  const [removeEventModalOpen, setRemoveEventModalOpen] = useState(false);
  const [idToRemove, setIdToRemove] = useState(false);
  const [titleToRemove, setTitleToRemove] = useState(false);

  // galeria
  const [tituloGallery, setTituloGallery] = useState("");
  const [galleryImagesUploadList, setGalleryImagesUploadList] = useState("");
  const [removeImageModalOpen, setRemoveImageModalOpen] = useState(false);
  const [removeGalleryModalOpen, setRemoveGalleryModalOpen] = useState(false);
  const [imageIdToRemove, setImageIdToRemove] = useState("");
  const [galleryIdToRemove, setGalleryIdToRemove] = useState("");
  const [galleryNameToRemove, setGalleryNameToRemove] = useState("");
  const [newGalleryImage, setNewGalleryImage] = useState("");

  const getGaleriasClube = (galeriasClube, imagensGaleriasClube) => {
    if (!galeriasClube) return [];

    let thisGalerias = galeriasClube.filter((galeria) => {
      if (galeria.club_id == slug) return galeria;
    });

    thisGalerias?.map((galeria) => {
      let date = galeria.updated_at.substring(0, 10);
      let [year, month, day] = date.split("-");
      galeria.dataToShow = day + "/" + month + "/" + year;
      galeria.imagens = [];
      if (imagensGaleriasClube?.length > 0) {
        imagensGaleriasClube.map((galeriaPic) => {
          if (
            galeriaPic.gallery_id == galeria.id &&
            galeriaPic.club_id == slug
          ) {
            let uid_imagem = galeriaPic.id;
            let status_imagem = "done";
            let url_imagem =
              "https://spapapi.phormuladev.com/clubes/" +
              slug +
              "/galerias/" +
              galeria.id +
              "/" +
              galeriaPic.url;
            galeria.imagens.push({
              uid: uid_imagem,
              status: status_imagem,
              url: url_imagem,
            });
          }
        });
      }
    });

    setallItemsGallery(thisGalerias);
    const endOffset = itemOffsetGallery + itemsPerPageGallery;
    setCurrentItemsGallery(thisGalerias.slice(itemOffsetGallery, endOffset));
  };

  // const [galeriasClube, setGaleriasClube] = useState();

  const getClube = (thisClub) => {
    let myClube = thisClub[0];
    myClube.url = myClube.url
      ? "https://spapapi.phormuladev.com/clubes/" + myClube.url
      : "https://spapapi.phormuladev.com/clubes/clube-estudo-default.svg";
    setClube(myClube);
    setDescricaoClube(myClube.descricao);
  };

  const dateFormat = "DD/MM/YYYY";
  const rangeFormat = "DD/MM/YYYY";

  const [allItemsGallery, setallItemsGallery] = useState(() =>
    getGaleriasClube()
  );
  const [currentItemsGallery, setCurrentItemsGallery] = useState(
    allItemsGallery.slice(0, 4)
  );
  const [itemOffsetGallery, setItemOffsetGallery] = useState(0);
  const itemsPerPageGallery = 4;

  const handlePageGallery = (page) => {
    const newOffset =
      ((page - 1) * itemsPerPageGallery) % allItemsGallery?.length;
    setItemOffsetGallery(newOffset);
  };

  useEffect(() => {
    const endOffset = itemOffsetGallery + itemsPerPageGallery;
    setCurrentItemsGallery(allItemsGallery.slice(itemOffsetGallery, endOffset));
  }, [itemOffsetGallery, allItemsGallery]);

  const [itemSwitchGallery, setItemSwitchGallery] = useState([]);
  const onChangeSwitchGallery = (checked, id) => {
    let newItemSwitch = itemSwitch;
    newItemSwitch[id] = checked;
    setItemSwitch(newItemSwitch);
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  useEffect(() => {
    fetch(`https://spapapi.phormuladev.com/api/get-eventos-clube/` + slug, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.clube?.length === 0) {
          return;
        }
        getClube(data.clube);
        getEventosClube(data.eventos);
      })
      .catch((error) => console.error(error));

    fetch(`https://spapapi.phormuladev.com/api/all-ficheiros-clubes`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.clubes?.length === 0) {
          return;
        }

        const itensFiltrados = data.ficheiros.filter(
          (item) => item.club_id === slug
        );
        getFilesPDF(itensFiltrados);
      })
      .catch((error) => console.error(error));

    fetch(`https://spapapi.phormuladev.com/api/all-galerias-clubes`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.clubes.length === 0) {
          return;
        }

        getGaleriasClube(data.galerias, data.imagensGalerias);
      })
      .catch((error) => console.error(error));
  }, []);

  const getEventosClube = (allEventos) => {
    const clubeEventos = allEventos.filter((evento) => {
      if (evento.club_id == slug) return evento;
    });
    if (!clubeEventos) return [];
    if (clubeEventos?.length == 0) return [];

    let eventosDesteClube = [];

    clubeEventos.map((evento) => {
      if (evento != null && evento.club_id == slug) {
        const [dateInicioValues, timeInicioValues] =
          evento.data_inicio.split(" ");
        const [dayInicio, monthInicio, yearInicio] =
          dateInicioValues.split("/");

        evento.date = dateInicioValues;
        evento.time = timeInicioValues;

        eventosDesteClube.push(evento);
      }
    });

    setAllItems(eventosDesteClube);
    setCurrentItemsEvent(eventosDesteClube.slice(0, 2));
  };

  const [itemOffsetEvent, setItemOffsetEvent] = useState(0);
  const itemsPerPageEvent = 2;

  const handlePageEvent = (page) => {
    const newOffset = ((page - 1) * itemsPerPageEvent) % allItems?.length;
    setItemOffsetEvent(newOffset);
  };

  useEffect(() => {
    const endOffset = itemOffsetEvent + itemsPerPageEvent;
    setCurrentItemsEvent(allItems.slice(itemOffsetEvent, endOffset));
  }, [itemOffsetEvent, allItems]);

  const getFilesPDF = (ficheiros) => {
    if (!ficheiros?.length) {
      return [];
    }

    let clubeFiles = ficheiros.filter((file) => {
      if (file != null && file.club_id == slug) return file;
    });
    clubeFiles.map((clubeFile) => {
      let date = clubeFile.updated_at.substring(0, 10);
      let [year, month, day] = date.split("-");
      clubeFile.dataToShow = day + "/" + month + "/" + year;
    });

    setFilesPDF(clubeFiles);
    const endOffset = itemOffsetPDF + itemsPerPagePDF;
    setCurrentItemsPDF(clubeFiles.slice(itemOffsetPDF, endOffset));
  };

  const [userDrawer, setUserDrawer] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const handleCancel = () => setPreviewOpen(false);

  notification.config({
    placement: "bottomRight",
    bottom: 10,
    duration: 5,
    rtl: false,
  });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleNewGalleryImage = (galleryId) => {
    setNewGalleryImage(galleryId);
    const formData = new FormData();

    galleryImagesUploadList.map((file, index) => {
      formData.append("imagem_" + index, file);
    });

    formData.append("galleryId", galleryId);
    formData.append("clubeId", slug);

    galleryImagesUploadList.length = 0;
    setGalleryImagesUploadList(galleryImagesUploadList);

    fetch("https://spapapi.phormuladev.com/api/admin/set-clube-gallery-image", {
      method: "POST",
      body: formData,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((res) => {
        if (res.status == 200) {
          res.json().then((data) => {
            getGaleriasClube(data.galeriasClube, data.imagensGaleriasClube);
            setGalleryImagesUploadList(() => []);
          });
        }
        // notification.success({description: 'A galeria foi criada com sucesso!'});
      })
      .catch(() => {
        // notification.error({description: 'Erro ao inserir imagens. Tente novamente!'});
      })
      .finally(() => {});
  };

  const clickToEventDrawer = (item) => {
    setEventIdEdit(item.id);
    setDataEditEvent([item.data_inicio, item.data_fecho]);
    setDescricaoEventEdit(item.descricao);
    setTituloEventEdit(item.titulo);
    setLocalizacaoEdit(item.localizacao);
    setImagemDestaqueEdit(item.imagem_destaque);
    setShowEventDrawer(true);
    setAreaEdit(item.area_subespecializacao);
  };

  const clickToPDFDrawer = (item) => {
    setPdfIdEdit(item.id);
    setDescricaoPDFEdit(item.descricao);
    setTituloPDFEdit(item.titulo);
    setPDFFileEdit(item.url);
    setShowPDFDrawer(true);
  };

  const [tablePagination, setTablePagination] = useState(5);
  const selectHandleChange = (value) => {
    setTablePagination(value);
  };

  const propEvent = {
    onRemove: (file) => {
      const index = fileEventList.indexOf(file);
      const newfileEventList = fileEventList.slice();
      newfileEventList.splice(index, 1);
      setfileEventList(newfileEventList);
    },
    beforeUpload: (file) => {
      const isImage =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml";
      if (!isImage) {
        notification.error({
          description: "Apenas é possível fazer upload de imagens.",
        });
      }
      setfileEventList([...fileEventList, file]);
      return false;
    },
    fileEventList,
  };

  /* Table */

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
    },
    {
      title: "Nr. Sócio",
      dataIndex: "number",
      sorter: {
        compare: (a, b) => a.number - b.number,
      },
    },
    {
      title: "Tipo de Sócio",
      dataIndex: "type",
      sorter: {
        compare: (a, b) => a.type - b.type,
      },
    },
    {
      title: "Tipo de Adesão",
      dataIndex: "active",
      sorter: {
        compare: (a, b) => a.active - b.active,
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: {
        compare: (a, b) => a.status - b.status,
      },
    },
  ];

  const handleDescricao = () => {
    if (descricaoClube?.length == 0) {
      setHasError(true);
      setTimeout(() => {
        setHasError(false);
      }, 3000);
      return;
    }

    axios
      .post(
        "https://spapapi.phormuladev.com/api/admin/update-clube-descricao",
        { slug, descricaoClube },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        if (res.data.clube.length) {
          getClube(res.data.clube);
          clube.descricao = descricaoClube;
          notification.success({
            description: "A descrição do clube foi atualizada com sucesso.",
          });
        }
      })
      .catch((error) => {
        notification.error({
          description:
            "Erro ao atualizar a descrição do clube. Por favor, tente de novo.",
        });
      });
  };

  const t = 1;
  const data = [];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const propPDF = {
    onRemove: (file) => {
      const index = fileUploadList.indexOf(file);
      const newFileList = fileUploadList.slice();
      newFileList.splice(index, 1);
      setFileUploadList(newFileList);
    },
    beforeUpload: (file) => {
      const isPDF = file.type === "application/pdf";
      if (!isPDF) {
        message.error(`${file.name} não é um ficheiro PDF`);
        setFileUploadList([]);
        return isPDF || Upload.LIST_IGNORE;
      }

      setFileUploadList([...fileUploadList, file]);
      return false;
    },
    fileUploadList,
  };

  const [currentItemsPDF, setCurrentItemsPDF] = useState(filesPDF.slice(0, 8));
  const [itemOffsetPDF, setItemOffsetPDF] = useState(0);
  const itemsPerPagePDF = 8;

  const handlePagePDF = (page) => {
    const newOffset = ((page - 1) * itemsPerPagePDF) % filesPDF?.length;
    setItemOffsetPDF(newOffset);
  };

  useEffect(() => {
    const endOffset = itemOffsetPDF + itemsPerPagePDF;

    setCurrentItemsPDF(filesPDF.slice(itemOffsetPDF, endOffset));
  }, [itemOffsetPDF, filesPDF]);

  const [itemSwitch, setItemSwitch] = useState([]);
  const onChangePDFStatus = (checked, id) => {
    let status = checked ? 1 : 0;

    axios
      .post(
        "https://spapapi.phormuladev.com/api/admin/set-clube-pdf-status",
        { status, id, slug },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        if (res.data.ficheirosClube.length) {
          getFilesPDF(res.data.ficheirosClube);
        }
      })
      .catch((error) => {});
  };

  const onChangeGalleryStatus = (checked, id) => {
    let status = checked ? 1 : 0;

    axios
      .post(
        "https://spapapi.phormuladev.com/api/admin/set-clube-gallery-status",
        { status, id },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        getGaleriasClube(res.data.galeriasClube, res.data.imagensGaleriasClube);
      })
      .catch((error) => {});
  };

  const handleSubmitPDF = (e) => {
    if (!fileUploadList.length || !tituloPDF.length || !descricaoPDF.length) {
      notification.error({
        description:
          "Para guardar um novo documento, preencha todos os campos e faça o upload do ficheiro.",
      });
      return;
    }

    const formData = new FormData();

    formData.append("titulo", tituloPDF);
    formData.append("file", fileUploadList[0]);
    formData.append("descricao", descricaoPDF);
    formData.append("clubeId", slug);

    // You can use any AJAX library you like
    fetch("https://spapapi.phormuladev.com/api/admin/store-clube-file", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            setFileUploadList([]);
            setTituloPDF("");
            setDescricaoPDF("");

            fetch(`https://spapapi.phormuladev.com/api/all-ficheiros-clubes`, {
              method: "GET",
              headers: {
                Accept: "application/json",
                Authorization: "Bearer " + session_storage.token,
              },
            })
              .then((response) => {
                if (!response.ok) {
                  throw new Error("Erro ao buscar eventos");
                }
                return response.json();
              })
              .then((data) => {
                if (data.clubes?.length === 0) {
                  return;
                }

                const itensFiltrados = data.ficheiros.filter(
                  (item) => item.club_id === slug
                );
                getFilesPDF(itensFiltrados);
              })
              .catch((error) => console.error(error));
          })
          .catch((err) => {});
      })
      .then(() => {
        setFileUploadList([]);
        notification.success({
          description: "O ficheiro foi submetido com sucesso!",
        });
      })
      .catch(() => {
        notification.error({
          description: "Erro ao submeter o ficheiro! Tente novamente.",
        });
      });
  };

  const onChangeDatePost = (date, dateString) => {
    setAgendarPostEvent(dateString);
  };

  const onChangeRangeEvent = (dates, dateString) => {
    if (!dates) {
      // Lidar com a limpeza do RangePicker
      setDataEvent([]); // Ou o estado equivalente que você usa
    } else {
      setDataEvent([dateString[0], dateString[1]]);
    }
  };

  const onChangeRangeEventEdit = (dates, dateString) => {
    if (!dates) {
      // Lidar com a limpeza do RangePicker
      setDataEditEvent([]); // Ou o estado equivalente que você usa
    } else {
      setDataEditEvent([dateString[0], dateString[1]]);
    }
  };

  const handleEvent = (e) => {
    if (
      !dataEvent[0].length ||
      !dataEvent[1].length ||
      !descricaoEvent.length ||
      !fileEventList.length
    ) {
      notification.error({
        description: "Para criar um novo evento, preencha todos os campos.",
      });
      return;
    }

    const formData = new FormData();

    let noticia = noticiaEvent ? 1 : 0;
    let bolsaPremio = bolsaPremioEvent ? 1 : 0;

    formData.append("titulo", tituloEvent);
    formData.append("file", fileEventList[0]);
    formData.append("clubeId", slug);
    formData.append("dataInicio", dataEvent[0]);
    formData.append("dataFecho", dataEvent[1]);
    formData.append("descricao", descricaoEvent);
    formData.append("agendarPost", agendarPost);
    formData.append("area", areaEvent);
    formData.append("noticia", noticia);
    formData.append("bolsaPremio", bolsaPremio);

    setUploading(true);

    // You can use any AJAX library you like
    fetch("https://spapapi.phormuladev.com/api/admin/store-event", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    }).then((response) => {
      response.json().then((data) => {
        if (data.message == "already_exists") {
          notification.error({
            description: "Já existe um evento com o mesmo nome neste clube!",
          });
        } else {
          setDataEvent(["", ""]);
          setTituloEvent("");
          setDescricaoEvent("");
          setLocalizacaoEvent("");
          setAreaEvent("");
          setNoticiaEvent(false);
          setBolsaPremioEvent(false);
          getEventosClube(data.eventos);
          setfileEventList([]);
          notification.success({
            description: "O evento foi criado com sucesso!",
          });
        }
      });
    });
  };

  const propEventEdit = {
    onRemove: (file) => {
      setfileEventListEdit([]);
    },
    beforeUpload: (file) => {
      const isImage =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml";
      if (!isImage) {
        notification.error({
          description: "Apenas é possível fazer upload de imagens.",
        });
        setfileEventListEdit((current) => []);
        return Upload.LIST_IGNORE;
      }
      setfileEventListEdit([...fileEventListEdit, file]);
      return false;
    },
    fileEventListEdit,
  };

  const propGallery = {
    multiple: true,
    onRemove: (file) => {
      setGalleryImagesUploadList([]);
    },
    beforeUpload: (file) => {
      const isImage =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml";
      if (!isImage) {
        notification.error({
          description: "Apenas é possível fazer upload de imagens.",
        });
        // setGalleryImagesUploadList([]);

        return isImage || Upload.LIST_IGNORE;
      }

      setGalleryImagesUploadList((current) => [...current, file]);
      return false;
    },
    galleryImagesUploadList,
  };

  const handleStoreGallery = (e) => {
    if (!tituloGallery.length || !galleryImagesUploadList.length) {
      notification.error({
        description:
          "Para criar uma nova galeria, preencha o título e faça o upload de pelo menos uma imagem.",
      });
      return;
    }

    const formData = new FormData();

    galleryImagesUploadList.map((file, index) => {
      console.log(file);
      formData.append("imagem_" + index, file);
    });

    formData.append("titulo", tituloGallery);
    formData.append("clubeId", slug);

    setUploading(true);

    fetch("https://spapapi.phormuladev.com/api/admin/store-clube-gallery", {
      method: "POST",
      body: formData,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((res) => {
        if (res.status == 200) {
          setTituloGallery("");
          res.json().then((data) => {
            getGaleriasClube(data.galeriasClube, data.imagensGaleriasClube);
            setGalleryImagesUploadList(() => []);
            notification.success({
              description: "A galeria foi criada com sucesso!",
            });
          });
        }
        // notification.success({description: 'A galeria foi criada com sucesso!'});
      })
      .catch(() => {
        notification.error({
          description: "Já existe uma galeria com o mesmo nome neste clube!",
        });
      })
      .finally(() => {});
  };

  const handleEditEvent = () => {
    if (
      localizacaoEdit == null ||
      descricaoEventEdit?.length == 0 ||
      tituloEventEdit?.length == 0 ||
      dataEditEvent?.length == 0
    ) {
      notification.error({
        description: "Para editar o evento, preencha os campos respetivos.",
      });
      return;
    }

    const formData = new FormData();

    let data_inicio = dataEditEvent[0];
    let data_fecho = dataEditEvent[1];

    if (fileEventListEdit.length) {
      formData.append("file", fileEventListEdit[0]);
    }

    formData.append("titulo", tituloEventEdit);
    formData.append("eventId", eventIdEdit);
    formData.append("dataInicio", data_inicio);
    formData.append("dataFecho", data_fecho);
    formData.append("descricao", descricaoEventEdit);
    formData.append("localizacao", localizacaoEdit);
    formData.append("is_spap", 1);
    formData.append("area", areaEdit);

    setUploading(true);

    // You can use any AJAX library you like
    fetch("https://spapapi.phormuladev.com/api/admin/update-event", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            setShowEventDrawer(false);
            getEventosClube(data.eventos);
          })
          .catch((err) => {});
      })
      .then(() => {
        setfileEventListEdit([]);
        notification.success({
          description: "O evento foi atualizado com sucesso!",
        });
      })
      .catch(() => {
        notification.error({
          description: "Já existe um evento com o mesmo nome neste clube!",
        });
      })
      .finally(() => {});
  };

  const handleEditPDF = () => {
    if (!tituloPDFEdit.length || !descricaoPDFEdit.length) {
      notification.error({
        description: "Para editar o PDF, preencha os campos respetivos.",
      });
      return;
    }

    const formData = new FormData();

    if (fileUploadList.length) {
      formData.append("file", fileUploadList[0]);
    }

    formData.append("fileId", pdfIdEdit);
    formData.append("titulo", tituloPDFEdit);
    formData.append("clubeId", slug);
    formData.append("descricao", descricaoPDFEdit);

    // You can use any AJAX library you like
    fetch("https://spapapi.phormuladev.com/api/admin/update-clube-file", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            setShowPDFDrawer(false);
            getFilesPDF(data.ficheirosClube);
          })
          .catch((err) => {});
      })
      .then(() => {
        notification.success({
          description: "O PDF foi atualizado com sucesso!",
        });
      })
      .catch(() => {
        notification.error({
          description: "Já existe um ficheiro com o mesmo nome neste Clube!",
        });
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const handleRemoveImageModal = async (file) => {
    setImageIdToRemove(file.uid);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setRemoveImageModalOpen(true);
  };

  const handleRemoveImage = (e) => {
    axios
      .post(
        "https://spapapi.phormuladev.com/api/admin/remove-clube-gallery-image",
        { imageIdToRemove },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        setImageIdToRemove("");
        setRemoveImageModalOpen(false);
        getGaleriasClube(res.data.galeriasClube, res.data.imagensGaleriasClube);
      })
      .catch((error) => {
        // notification.error({description: 'Erro ao remover a imagem da galeria! Tente novamente.'});
      });
  };

  const beforeUploadNewGalleryImage = (file) => {
    const image =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/svg";
    if (!image) {
      notification.error({
        description: "Apenas pode fazer upload de imagens.",
      });
      return image || Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      notification.error({
        description: "Faça o upload de imagens menos pesadas.",
      });
      return isLt2M || Upload.LIST_IGNORE;
    }

    setGalleryImagesUploadList((current) => [...current, file]);
    return image && isLt2M;
  };

  const propsNewImage = {
    onChange({ file, fileList }) {},
  };

  const handleRemoveGalleryModal = (galleryId, name) => {
    setGalleryIdToRemove(galleryId);
    setGalleryNameToRemove(name);
    setRemoveGalleryModalOpen(true);
  };

  const handleRemoveGallery = (e) => {
    axios
      .post(
        "https://spapapi.phormuladev.com/api/admin/remove-clube-gallery",
        { galleryIdToRemove, slug },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        setGalleryIdToRemove("");
        setRemoveGalleryModalOpen(false);
        getGaleriasClube(res.data.galeriasClube, res.data.imagensGaleriasClube);
        notification.success({
          description: "A galeria foi removida com sucesso!",
        });
      })
      .catch((error) => {
        // notification.error({description: 'Erro ao remover a imagem da galeria! Tente novamente.'});
      });
  };

  const handleRemoveFile = (e) => {
    axios
      .post(
        "https://spapapi.phormuladev.com/api/admin/remove-clube-file",
        { idToRemove },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        setIdToRemove("");
        setRemovePDFModalOpen(false);
        if (res.status == 200) {
          getFilesPDF(res.data.ficheirosClube);
          notification.success({
            description: "O Ficheiro foi removida com sucesso!",
          });
        }
      })
      .catch((error) => {
        // notification.error({description: 'Erro ao remover a imagem da galeria! Tente novamente.'});
      });
  };

  const handleRemoveEvent = () => {
    axios
      .post(
        "https://spapapi.phormuladev.com/api/admin/remove-event",
        { idToRemove },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        setIdToRemove("");
        setRemoveEventModalOpen(false);
        if (res.status == 200) {
          getEventosClube(data.eventos);
          notification.success({
            description: "O evento foi removido com sucesso!",
          });
        }
      })
      .catch((error) => {
        // notification.error({description: 'Erro ao remover a imagem da galeria! Tente novamente.'});
      });
  };

  const openRemoveEventModal = (id, titulo) => {
    setIdToRemove(id);
    setTitleToRemove(titulo);
    setRemoveEventModalOpen(true);
  };

  const openRemovePDFModal = (id, titulo) => {
    setIdToRemove(id);
    setTitleToRemove(titulo);
    setRemovePDFModalOpen(true);
  };

  const handleremoveClube = () => {
    setIsModalremoveClubeOpen(true);
  };

  const removeClube = () => {
    let idToRemove = slug;

    axios
      .post(
        "https://spapapi.phormuladev.com/api/admin/remove-clube",
        {
          idToRemove,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        setIsModalremoveClubeOpen(false);
        notification.success({
          description: "O clube foi apagado com sucesso.",
        });
        setTimeout(() => {
          window.location.href =
            "http://spap.phormuladev.com/dashboard/todos-clubes";
        }, [2000]);
      })
      .catch((error) => {});
  };

  return (
    <Wrapper className={session_storage?.all_data.role == "admin" && "isAdmin"}>
      {session_storage?.all_data.role == "admin" && (
        <div>
          <Sidebar />
        </div>
      )}
      <Content>
        <Header />
        <ClubeHeader clube={slug} />
        <Card>
          <CardHeader>
            <Title>Informação do clube</Title>
          </CardHeader>
          <p className="p-clube">
            Informação que está disponível na primeira página do clube, pode
            adicionar texto, imagens e vídeos
          </p>
          <Divider style={{ margin: "12px 0" }} />
          <p className="h-clube">Descrição do clube</p>
          <ReactQuill
            modules={modules}
            formats={formats}
            theme="snow"
            value={descricaoClube}
            onChange={setDescricaoClube}
          />
          {hasError && (
            <div
              style={{
                marginTop: "2%",
                padding: "10px",
                background: "#ffc3c3",
                border: "1px solid #ffc3c3",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <ExclamationCircleOutlined twoToneColor="red" />{" "}
              <span style={{ paddingLeft: "10px", fontSize: "14px" }}>
                Por favor, introduza uma descrição válida.
              </span>
            </div>
          )}
          <Row gutter={4} justify="end">
            <Col className="gutter-row" span={24} align="end">
              <Button
                onClick={handleDescricao}
                type="primary"
                className="cta publish btn-descricao"
                align="end"
              >
                Publicar <IconOk className="icon icon-ok" />
              </Button>
            </Col>
          </Row>
        </Card>
        <WrapperCardEvento>
          <Card>
            <CardHeader>
              <Space direction="vertical" size={60}>
                <Title>Eventos a não perder</Title>
                {/* Verificar props.idClube para inserir nome ou nao */}
              </Space>

              <Space>
                <Switch
                  key={"bolsas-premios"}
                  onChange={(e) => setBolsaPremioEvent(e)}
                  checked={bolsaPremioEvent}
                />
                <span>Bolsas e prémios</span>
                <Switch
                  key={"noticia-evento"}
                  onChange={(e) => setNoticiaEvent(e)}
                  checked={noticiaEvent}
                />
                <span>Notícia</span>
                <DatePicker
                  placement="bottomRight"
                  style={{
                    width: "200px",
                    height: "45px",
                    border: "1px solid rgb(128, 159, 184)",
                  }}
                  format={dateFormat}
                  placeholder="Agendar Publicação"
                  onChange={onChangeDatePost}
                />
              </Space>

              {/* <Button>Agendar Publicação</Button> */}
            </CardHeader>
            <p className="text">
              <i>Eventos disponíveis para consultar na agenda</i>
            </p>
            <Divider />
            <Row gutter={[8, 8]} align="bottom">
              <Col span={14}>
                <label className="text">Titulo do Evento</label>
                <Input
                  key={"titulo-event"}
                  placeholder="Título"
                  name="titulo"
                  style={styles.input_class}
                  value={tituloEvent}
                  onChange={(e) => setTituloEvent(e.target.value)}
                />
              </Col>
              <Col span={10}>
                <label className="text">Data do Evento</label>
                <RangePicker
                  placeholder={["Dia de inicio", "Dia de fecho"]}
                  key={"range-data-event"}
                  format={rangeFormat}
                  onChange={onChangeRangeEvent}
                  style={styles.input_class}
                  showTime={false}
                  placement="bottomRight"
                />
              </Col>
            </Row>
            <Row gutter={[12, 12]} style={{ marginTop: "10px" }}>
              <Col span={8}>
                <p
                  className="text mb-0"
                  style={{ marginBottom: "0px !important" }}
                  align="center"
                >
                  Tamanho máximo de upload (1 MB)
                </p>
                <Dragger
                  {...propEvent}
                  style={{ marginBottom: "5px", height: "100%" }}
                  fileList={[...fileEventList]}
                >
                  <p className="ant-upload-drag-icon">
                    <IconUpload />
                  </p>
                  <p className="ant-upload-text text">
                    Upload da imagem do evento
                  </p>
                  <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                </Dragger>
              </Col>
              <Col span={16}>
                <Row gutter={[8, 8]} align="bottom">
                  <Col span={12}>
                    <label className="text">Localização</label>
                    <Input
                      key={"localizacao-event"}
                      placeholder="Localização"
                      name="localizacaoEvent"
                      style={styles.input_class}
                      value={localizacaoEvent}
                      onChange={(e) => setLocalizacaoEvent(e.target.value)}
                    />
                  </Col>
                  <Col span={12}>
                    <label className="text">Área de Diferenciação</label>
                    <Select
                      key={"area-evento"}
                      defaultValue={areaEvent ? areaEvent : ""}
                      placeholder="Área de Diferenciação"
                      style={styles.select_class}
                      onChange={(e) => setAreaEvent(e)}
                      options={[
                        { value: "Patologia geral", label: "Patologia geral" },
                        { value: "Citologia", label: "Citologia" },
                        {
                          value: "Patologia cutânea",
                          label: "Patologia cutânea",
                        },
                        {
                          value: "Patologia da cabeça e pescoço",
                          label: "Patologia da cabeça e pescoço",
                        },
                        {
                          value: "Patologia das partes moles",
                          label: "Patologia das partes moles",
                        },
                        {
                          value: "Patologia do sistema nervosa",
                          label: "Patologia do sistema nervoso",
                        },
                        {
                          value: "Patologia digital",
                          label: "Patologia digital",
                        },
                        {
                          value: "Patologia endócrina",
                          label: "Patologia endócrina",
                        },
                        {
                          value: "Patologia fetoplacentar",
                          label: "Patologia fetoplacentar",
                        },
                        {
                          value: "Patologia gastrointestinal",
                          label: "Patologia gastrointestinal",
                        },
                        {
                          value: "Patologia ginecológica",
                          label: "Patologia ginecológica",
                        },
                        {
                          value: "Patologia hematolinfoide",
                          label: "Patologia hematolinfoide",
                        },
                        {
                          value: "Patologia hepatobiliar",
                          label: "Patologia hepatobiliar",
                        },
                        {
                          value: "Patologia mamária",
                          label: "Patologia mamária",
                        },
                        {
                          value: "Patologia molecular",
                          label: "Patologia molecular",
                        },
                        {
                          value: "Patologia osteoarticular",
                          label: "Patologia osteoarticular",
                        },
                        {
                          value: "Patologia pancreática",
                          label: "Patologia pancreática",
                        },
                        {
                          value: "Patologia pulmonar",
                          label: "Patologia pulmonar",
                        },
                        {
                          value: "Patologia urogenital",
                          label: "Patologia urogenital",
                        },
                      ]}
                    />{" "}
                  </Col>
                </Row>
                <label
                  className="text"
                  style={{ paddingTop: "5px", marginBottom: "0px" }}
                >
                  Descrição do Evento
                </label>

                <ReactQuill
                  key={"descricao-evento"}
                  placeholder="Descrição"
                  modules={modules}
                  formats={formats}
                  theme="snow"
                  name="descricao"
                  onChange={(e) => setDescricaoEvent(e)}
                  value={descricaoEvent}
                />
              </Col>
            </Row>
            <Divider style={{ margin: "8px 0" }} />
            <Button
              onClick={handleEvent}
              type="primary"
              className="cta publish"
              align="end"
            >
              Publicar <IconOk className="icon icon-ok" />
            </Button>
          </Card>
          <WrapperCardList>
            <Row gutter={[8, 8]} className="flex-wrap">
              {currentItemsEvent.map((item, index) => {
                let textPreview = item.descricao
                  .replace(/<(h2|h3|img|b|strong)[^>]*>/g, "<p>")
                  .slice(0, 120);
                if (item.descricao.length > 120) textPreview += " (...)";
                return (
                  <Col span={12}>
                    <Card key={"eventos-" + item.id} style={{ height: "100%" }}>
                      <CardHeader>
                        <Title>{item.titulo}</Title>
                        <IconClose
                          className="icon icon-close"
                          onClick={() =>
                            openRemoveEventModal(item.id, item.titulo)
                          }
                        />
                      </CardHeader>
                      <Space direction="vertical">
                        <div
                          style={{ fontSize: "12px", lineHeight: "1.4" }}
                          dangerouslySetInnerHTML={{ __html: textPreview }}
                        ></div>
                        <Space size={30}>
                          <Space>
                            <IconCalendar className="icon icon-calendar" />
                            <span className="text">{item.date}</span>
                          </Space>
                          <Space
                            className="action-edit"
                            key={"evento-" + index}
                            onClick={() => clickToEventDrawer(item)}
                          >
                            <IconEdit className="icon icon-edit" />
                            <span>Editar</span>
                          </Space>
                          <Space className="action-edit">
                            {item.agendar_post != null && (
                              <>
                                <span>Este evento será publicado a</span>
                                {item.agendar_post}
                              </>
                            )}
                          </Space>
                        </Space>
                      </Space>
                    </Card>
                  </Col>
                );
              })}
            </Row>
            <Drawer
              key={"editar-evento-" + eventIdEdit}
              title="Editar Evento"
              width={700}
              onClose={() => setShowEventDrawer(false)}
              open={showEventDrawer}
              bodyStyle={{
                paddingBottom: 80,
              }}
              extra={
                <Space>
                  <Button onClick={() => setShowEventDrawer(false)}>
                    Cancelar
                  </Button>
                  <Button
                    onClick={handleEditEvent}
                    type="submit"
                    style={{
                      borderRadius: "6px",
                      background: "#494D7D",
                      color: "#fff",
                    }}
                  >
                    Guardar
                  </Button>
                </Space>
              }
            >
              <form layout="vertical" id="update-event-form">
                <Col>
                  <Row align={"middle"}>
                    <Col span={12}>
                      <h5 style={{ marginBottom: "10px", fontSize: "18px" }}>
                        Upload da imagem
                      </h5>
                      <p>
                        Poderá fazer o upload de uma nova imagem de destaque
                        clicando na imagem do lado direito.
                      </p>
                    </Col>
                    <Col span={12}>
                      <Dragger
                        {...propEventEdit}
                        style={{
                          marginBottom: "5px",
                          width: "100%",
                          backgroundImage:
                            "url(https://spapapi.phormuladev.com/eventos/" +
                            eventIdEdit +
                            "/" +
                            imagemDestaqueEdit +
                            ")",
                          backgroundSize: "cover",
                        }}
                      >
                        <div style={{ opacity: 0 }}>
                          <p className="ant-upload-drag-icon">
                            <IconUpload />
                          </p>
                          <p className="ant-upload-text text">
                            Upload da imagem{" "}
                          </p>
                          <p className="ant-upload-hint">
                            Faça Drag and drop aqui
                          </p>
                        </div>
                      </Dragger>
                    </Col>
                  </Row>
                  <Divider
                    style={{ background: "#fff", margin: "12px 0" }}
                  ></Divider>
                  <Row
                    className="row-drawer"
                    gutter={[8, 8]}
                    style={{ flexFlow: "column !important" }}
                  >
                    <Col span={24}>
                      <h6 style={styles.h6_label}>Título</h6>
                      <Input
                        key={"titulo-evento-" + eventIdEdit}
                        placeholder="Título"
                        name="titulo"
                        style={styles.input_class}
                        defaultValue={tituloEventEdit}
                        onChange={(e) => setTituloEventEdit(e.target.value)}
                        required
                      />
                    </Col>
                    <Col span={24}>
                      <h6 style={styles.h6_label}>Data</h6>
                      <RangePicker
                        placeholder={["Dia de inicio", "Dia de fecho"]}
                        format={"DD/MM/YYYY"}
                        showTime={false}
                        defaultValue={[
                          dayjs(dataEditEvent[0], "DD/MM/YYYY"),
                          dayjs(dataEditEvent[1], "DD/MM/YYYY"),
                        ]}
                        onChange={onChangeRangeEventEdit}
                        style={styles.input_class}
                        placement="bottomRight"
                      />
                    </Col>
                    <Col span={24}>
                      <h6 style={styles.h6_label}>Localização</h6>
                      <Input
                        key={"localizacao-evento-" + eventIdEdit}
                        placeholder="Localização"
                        name="localizacao"
                        style={styles.input_class}
                        defaultValue={localizacaoEdit}
                        onChange={(e) => setLocalizacaoEdit(e.target.value)}
                        required
                      />
                    </Col>
                    <Col span={24}>
                      <h6 style={styles.h6_label}>Área de Diferenciação</h6>
                      <Select
                        key={"area-evento-" + eventIdEdit}
                        value={areaEdit}
                        style={styles.select_class}
                        placeholder="Área de Diferenciação"
                        onChange={(e) => setAreaEdit(e)}
                        options={[
                          {
                            value: "Patologia geral",
                            label: "Patologia geral",
                          },
                          { value: "Citologia", label: "Citologia" },
                          {
                            value: "Patologia cutânea",
                            label: "Patologia cutânea",
                          },
                          {
                            value: "Patologia da cabeça e pescoço",
                            label: "Patologia da cabeça e pescoço",
                          },
                          {
                            value: "Patologia das partes moles",
                            label: "Patologia das partes moles",
                          },
                          {
                            value: "Patologia do sistema nervosa",
                            label: "Patologia do sistema nervoso",
                          },
                          {
                            value: "Patologia digital",
                            label: "Patologia digital",
                          },
                          {
                            value: "Patologia endócrina",
                            label: "Patologia endócrina",
                          },
                          {
                            value: "Patologia fetoplacentar",
                            label: "Patologia fetoplacentar",
                          },
                          {
                            value: "Patologia gastrointestinal",
                            label: "Patologia gastrointestinal",
                          },
                          {
                            value: "Patologia ginecológica",
                            label: "Patologia ginecológica",
                          },
                          {
                            value: "Patologia hematolinfoide",
                            label: "Patologia hematolinfoide",
                          },
                          {
                            value: "Patologia hepatobiliar",
                            label: "Patologia hepatobiliar",
                          },
                          {
                            value: "Patologia mamária",
                            label: "Patologia mamária",
                          },
                          {
                            value: "Patologia molecular",
                            label: "Patologia molecular",
                          },
                          {
                            value: "Patologia osteoarticular",
                            label: "Patologia osteoarticular",
                          },
                          {
                            value: "Patologia pancreática",
                            label: "Patologia pancreática",
                          },
                          {
                            value: "Patologia pulmonar",
                            label: "Patologia pulmonar",
                          },
                          {
                            value: "Patologia urogenital",
                            label: "Patologia urogenital",
                          },
                        ]}
                      />{" "}
                    </Col>
                    <Col span={24}>
                      <h6 style={styles.h6_label}>Descrição</h6>
                      <ReactQuill
                        key={"descricao-evento-" + eventIdEdit}
                        modules={modules}
                        formats={formats}
                        theme="snow"
                        name="descricao"
                        onChange={(e) => setDescricaoEventEdit(e)}
                        defaultValue={descricaoEventEdit}
                      />
                    </Col>
                  </Row>
                </Col>
              </form>
            </Drawer>
            <Drawer
              key={"editar-pdf-" + pdfIdEdit}
              title="Editar PDF"
              width={400}
              onClose={() => setShowPDFDrawer(false)}
              open={showPDFDrawer}
              bodyStyle={{
                paddingBottom: 80,
              }}
              extra={
                <Space>
                  <Button onClick={setShowPDFDrawer}>Cancelar</Button>
                  <Button
                    onClick={handleEditPDF}
                    type="submit"
                    style={{
                      borderRadius: "6px",
                      background: "#494D7D",
                      color: "#fff",
                    }}
                  >
                    Guardar
                  </Button>
                </Space>
              }
            >
              <form layout="vertical" id="update-event-form">
                <Col>
                  <Col span={24}>
                    <h5
                      style={{
                        marginBottom: "10px",
                        fontSize: "18px",
                        textAlign: "center",
                      }}
                    >
                      Visualizar o ficheiro PDF
                    </h5>
                    <p style={{ textAlign: "center", marginBottom: "1%" }}>
                      <a
                        href={
                          "https://spapapi.phormuladev.com/clubes/" +
                          slug +
                          "/ficheiros/" +
                          pdfFileEdit
                        }
                        target="_blank"
                      >
                        <IconUpload />
                      </a>
                    </p>
                    <p style={{ textAlign: "center" }}>{pdfFileEdit}</p>
                  </Col>
                  <Col span={24}>
                    <Dragger
                      key={"dragger-pdf" + pdfIdEdit}
                      {...propPDF}
                      style={{ marginBottom: "5px", width: "100%" }}
                    >
                      <div>
                        <p className="ant-upload-drag-icon">
                          <IconUpload />
                        </p>
                        <p className="ant-upload-text text">
                          Upload do novo Ficheiro{" "}
                        </p>
                        <p className="ant-upload-hint">
                          Se pretende atualizar o ficheiro, faça drag and drop
                          ou clique nesta caixa.
                        </p>
                      </div>
                    </Dragger>
                  </Col>
                  <Divider
                    style={{ background: "#fff", margin: "12px 0" }}
                  ></Divider>
                  <Row
                    className="row-drawer"
                    gutter={[8, 8]}
                    style={{ flexFlow: "column !important" }}
                  >
                    <Col span={24}>
                      <h6 style={styles.h6_label}>Título</h6>
                      <Input
                        key={"titulo-pdf" + pdfIdEdit}
                        placeholder="Título"
                        name="titulo"
                        style={styles.input_class}
                        defaultValue={tituloPDFEdit}
                        onChange={(e) => setTituloPDFEdit(e.target.value)}
                        required
                      />
                    </Col>
                    <Col span={24}>
                      <h6 style={styles.h6_label}>Descrição</h6>
                      <Input
                        key={"descricao-pdf" + pdfIdEdit}
                        placeholder="Descrição"
                        name="descricao"
                        style={styles.input_class}
                        defaultValue={descricaoPDFEdit}
                        onChange={(e) => setDescricaoPDFEdit(e.target.value)}
                        required
                      />
                    </Col>
                  </Row>
                </Col>
              </form>
            </Drawer>
            <ContentFooter>
              {/* <Space size={32}>
            <Link to='/dashboard/eventos'>
              <Button type='primary' className='cta'>
                Ver lista de eventos completa
              </Button>
            </Link>
          </Space> */}
              <Pagination
                defaultCurrent={1}
                total={allItems.length}
                pageSize={itemsPerPageEvent}
                onChange={handlePageEvent}
              />
            </ContentFooter>
          </WrapperCardList>
        </WrapperCardEvento>
        <Divider />
        <Files>
          <Row align={"center"} style={{ marginBottom: "10px" }}>
            <Col span={12}>
              <Title>
                RECURSOS PARA SI <br /> Recursos disponíveis para download na
                página do clube
              </Title>
            </Col>
            <Col span={12}>
              <AlertMesssage>
                <Space size={8}>
                  <IconInfo className="icon icon-info" />
                  <span>
                    Para poder alterar a ordem dos PDF´s na página do clube
                    basta fazer "drag and drop" na listagem acima
                  </span>
                </Space>
              </AlertMesssage>
            </Col>
          </Row>
          <Grid>
            <Card
              className="form"
              style={{ position: "sticky !important", top: "100px" }}
            >
              <Space direction="vertical" className="w-100">
                <h4>Faça upload do seu PDF</h4>
                <label className="text">Título do PDF</label>
                <Input
                  key={"titulo_pdf"}
                  style={styles.input_class}
                  name="titulo_pdf"
                  placeholder="Título do PDF"
                  value={tituloPDF}
                  onChange={(e) => setTituloPDF(e.target.value)}
                />
                <label className="text">Descrição do PDF</label>
                <TextArea
                  style={{ height: "32px" }}
                  key={"descricao_pdf"}
                  name="descricao_pdf"
                  placeholder="Desrição do PDF"
                  rows={4}
                  value={descricaoPDF}
                  onChange={(e) => setDescricaoPDF(e.target.value)}
                />
                <Dragger
                  {...propPDF}
                  fileList={[...fileUploadList]}
                  key={"dragger-pdf"}
                >
                  <p className="ant-upload-drag-icon">
                    <IconUpload />
                  </p>
                  <p className="ant-upload-text text">
                    Upload de novo ficheiro
                  </p>
                  <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                </Dragger>
                <p className="text center" align="center">
                  Tamanho máximo de upload (2 MB)
                </p>
                <Input
                  onClick={handleSubmitPDF}
                  type="button"
                  className="cta w-100"
                  value="Upload"
                />
              </Space>
            </Card>
            {filesPDF.length > 0 && (
              <Space direction="vertical">
                <GridCards>
                  {currentItemsPDF.map((item, index) => {
                    return (
                      <Card
                        className={
                          item.status == 0
                            ? "item disabled item-" + index
                            : "item item-" + index
                        }
                        key={"ficheiros-" + item.id}
                      >
                        <CardHeader>
                          <Title className="item__title">{item.titulo}</Title>
                          <IconClose
                            className="icon icon-close"
                            onClick={() =>
                              openRemovePDFModal(item.id, item.titulo)
                            }
                          />
                        </CardHeader>
                        <Space direction="vertical" style={{ width: "100%" }}>
                          <p className="item__text">{item.descricao}</p>
                          <Space
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              alignContent: "center",
                            }}
                          >
                            <Space size={20}>
                              <Space>
                                <IconCalendar className="icon icon-calendar" />
                                <span className="text">{item.dataToShow}</span>
                              </Space>
                              <Space>
                                {item.status == 1 ? (
                                  <>
                                    {" "}
                                    <Switch
                                      defaultChecked
                                      onChange={(e) =>
                                        onChangePDFStatus(e, item.id)
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Switch
                                      onChange={(e) =>
                                        onChangePDFStatus(e, item.id)
                                      }
                                    />
                                  </>
                                )}
                                <span className="text">
                                  {item.status == 1 ? "Ativo" : "Inativo"}
                                </span>
                              </Space>
                              <Space
                                className="action-edit"
                                onClick={() => clickToPDFDrawer(item)}
                              >
                                <IconEdit className="icon icon-edit" />
                                <span>Editar</span>
                              </Space>
                            </Space>
                            <div>
                              <Space className="action-edit">
                                <a
                                  target="_blank"
                                  href={
                                    "https://spapapi.phormuladev.com/clubes/" +
                                    slug +
                                    "/ficheiros/" +
                                    item.url
                                  }
                                >
                                  <EyeOutlined
                                    style={{ paddingRight: "5px" }}
                                  />
                                </a>
                              </Space>
                            </div>
                          </Space>
                        </Space>
                      </Card>
                    );
                  })}
                </GridCards>
                <ContentFooter>
                  <Pagination
                    defaultCurrent={1}
                    total={filesPDF.length}
                    pageSize={itemsPerPagePDF}
                    onChange={handlePagePDF}
                  />
                </ContentFooter>
              </Space>
            )}
          </Grid>
        </Files>
        <Divider />
        <Row align={"center"} style={{ marginBottom: "10px" }}>
          <Col span={12}>
            <Title>
              GALERIA DE IMAGENS <br /> Recursos disponíveis para download na
              página do clube
            </Title>
          </Col>
          <Col span={12}>
            <AlertMesssage>
              <Space size={8}>
                <IconInfo className="icon icon-info" />
                <span>
                  Para poder alterar a ordem das Galerias na página do clube
                  basta fazer "drag and drop" na listagem acima
                </span>
              </Space>
            </AlertMesssage>
          </Col>
        </Row>

        <Grid>
          <Card className="form">
            <Form>
              <Space direction="vertical" className="w-100">
                <h4>
                  Faça upload das suas imagens para a criação de uma galeria
                </h4>
                <label className="text">Título da Galeria</label>
                <Input
                  key={"titulo-gallery"}
                  style={styles.input_class}
                  placeholder="Título"
                  name="titulo_galeria"
                  value={tituloGallery}
                  onChange={(e) => setTituloGallery(e.target.value)}
                />
                <Dragger
                  key={"dragger-gallery"}
                  {...propGallery}
                  fileList={[...galleryImagesUploadList]}
                >
                  <p className="ant-upload-drag-icon">
                    <IconUpload />
                  </p>
                  <p className="ant-upload-text text">
                    Upload de novas imagens
                  </p>
                  <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                </Dragger>
                <p className="text center" align="center">
                  Tamanho máximo de upload (2 MB)
                </p>
                <Button
                  onClick={handleStoreGallery}
                  type="primary"
                  className="cta w-100"
                >
                  Upload
                </Button>
              </Space>
            </Form>
          </Card>
          {allItemsGallery.length > 0 && (
            <Space direction="vertical">
              <GridCards>
                {currentItemsGallery.map((item, index) => {
                  return (
                    <Card
                      className={
                        item.status == 0
                          ? "item disabled item-" + index
                          : "item item-" + index
                      }
                      key={"galleria-" + item.id}
                    >
                      <Space direction="vertical" className={"card-gallery"}>
                        <CardHeader className="header-gallery-div">
                          <div className="gallery-title">
                            <Title className="item__title">{item.titulo}</Title>
                            <IconClose
                              className="icon icon-close"
                              onClick={() =>
                                handleRemoveGalleryModal(item.id, item.titulo)
                              }
                            />
                          </div>
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                width: "auto",
                                flexWrap: "wrap",
                              }}
                            >
                              <Upload
                                style={{ width: "auto" }}
                                {...propsNewImage}
                                listType="picture-card"
                                fileList={item.imagens}
                                onPreview={handlePreview}
                                multiple={true}
                                onRemove={(e) => handleRemoveImageModal(e)}
                                customRequest={() =>
                                  handleNewGalleryImage(item.id)
                                }
                                beforeUpload={beforeUploadNewGalleryImage}
                              >
                                <div>
                                  <PlusOutlined />
                                  <div
                                    style={{
                                      marginTop: 8,
                                    }}
                                  >
                                    Upload
                                  </div>
                                </div>
                              </Upload>
                            </div>
                            {/* <Upload 
                        {...propsNewImage}
                        listType="picture-card"
                        defaultFileList={galleryImagesUploadList}
                        showUploadList={false}
                        customRequest={() => handleNewGalleryImage(item.id)}
                        multiple={true}
                        beforeUpload={beforeUploadNewGalleryImage}
                      > */}
                          </>
                        </CardHeader>

                        <Space size={30}>
                          <Space>
                            <IconCalendar className="icon icon-calendar" />
                            <span className="text">{item.dataToShow}</span>
                          </Space>
                          <Space>
                            {item.status == 1 ? (
                              <>
                                {" "}
                                <Switch
                                  defaultChecked
                                  onChange={(e) =>
                                    onChangeGalleryStatus(e, item.id)
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <Switch
                                  onChange={(e) =>
                                    onChangeGalleryStatus(e, item.id)
                                  }
                                />
                              </>
                            )}{" "}
                            <span className="text">
                              {item.status == 1 ? "Ativo" : "Inativo"}
                            </span>
                          </Space>
                          {/* TODO: ter funcao de editar ou nao}
                        <Space className='action-edit' onClick={(e) => alert('editar?')}>
                          <IconEdit className='icon icon-edit' />
                          <span>Editar</span>
                        </Space>
                        {*/}
                        </Space>
                      </Space>
                    </Card>
                  );
                })}
              </GridCards>
              <ContentFooter>
                <Pagination
                  defaultCurrent={1}
                  total={allItemsGallery.length}
                  pageSize={itemsPerPageGallery}
                  onChange={handlePageGallery}
                />
              </ContentFooter>
            </Space>
          )}
        </Grid>
        <Row
          style={{
            padding: "30px",
            borderRadius: "10px",
            background: "rgb(214, 215, 226)",
            margin: "20px 0",
          }}
          align="middle"
          gutter={[8, 8]}
        >
          <Col span={24}>
            <p className="mb-0 align-center">Deseja apagar este clube?</p>
            <Button
              style={{
                width: "30%",
                height: "45px",
                margin: "0 auto",
                display: "block",
                color: "#fff",
                background: "#f76565",
              }}
              onClick={handleremoveClube}
            >
              Apagar clube
            </Button>
          </Col>
        </Row>
        <Modal
          width={500}
          className="modal-decline-user"
          title="Remover Clube"
          open={isModalremoveClubeOpen}
          onOk={removeClube}
          onCancel={() => setIsModalremoveClubeOpen(false)}
          okText="Confirmar"
          cancelText="Cancelar"
        >
          <p>
            Tem a certeza que pretende <b>apagar todos os dados deste clube</b>?
            Esta ação é irreversivel.
          </p>
        </Modal>
        <Footer />
      </Content>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
      <Modal
        title="Tem a certeza que pretende eliminar esta imagem?"
        open={removeImageModalOpen}
        onOk={(e) => handleRemoveImage(e)}
        onCancel={() => setRemoveImageModalOpen(false)}
        okText="Confirmar"
        cancelText="Cancelar"
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
      <Modal
        title={
          'Tem a certeza que pretende eliminar a galeria "' +
          galleryNameToRemove +
          '"?'
        }
        open={removeGalleryModalOpen}
        onOk={(e) => handleRemoveGallery(e)}
        onCancel={() => setRemoveGalleryModalOpen(false)}
        okText="Confirmar"
        cancelText="Cancelar"
      ></Modal>

      <Modal
        key={"remove-event-" + idToRemove}
        title="Está prestes a eliminar um evento"
        open={removeEventModalOpen}
        onOk={handleRemoveEvent}
        onCancel={() => setRemoveEventModalOpen(false)}
        okText="Confirmar"
        cancelText="Cancelar"
      >
        <p>
          Tem a certeza que pretende remover o evento <b>'{titleToRemove}'</b>?
        </p>
      </Modal>
      <Modal
        key={"remove-file-" + idToRemove}
        title="Está prestes a eliminar um ficheiro"
        open={removePDFModalOpen}
        onOk={handleRemoveFile}
        onCancel={() => setRemovePDFModalOpen(false)}
        okText="Confirmar"
        cancelText="Cancelar"
      >
        <p>
          Tem a certeza que pretende remover o ficheiro <b>'{titleToRemove}'</b>
          ?
        </p>
      </Modal>
      <DrawerPerfil
        user={userDrawer}
        show={showDrawer}
        onChange={(e) => setShowDrawer(e)}
      />
    </Wrapper>
  );
}
