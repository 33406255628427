import styled from "styled-components";

import Sidebar from "../../../Components/Backoffice/Sidebar";
import Card from "../../../Components/Backoffice/Card";
import UserService from "../../../Services/user.service";
import Header from "../../../Components/Backoffice/Header";
import Title from "../../../Components/Backoffice/Title";
import NotificationSound from "../../../Assets/Sounds/tap-notification.mp3";
import {
  Col,
  Row,
  Badge,
  Avatar,
  Divider,
  Button,
  Space,
  Input,
  Typography,
  Checkbox,
  Form,
  notification,
  Popover,
} from "antd";
import ReactQuill from "react-quill";
import { useState, useEffect, useRef } from "react";
import {
  MessageOutlined,
  SendOutlined,
  FieldTimeOutlined,
  UserOutlined,
  EditOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import axios, { all } from "axios";
import Echo from "laravel-echo";
import { ReactComponent as IconClose } from "../../../Assets/Images/Backoffice/Close.svg";
import { ReactComponent as IconSearch } from "../../../Assets/Images/Backoffice/Search.svg";
const { Text } = Typography;

const CardContent = styled.div`
  position: relative;

  .ant-table-cell:nth-child(2) {
    text-align: left !important;
  }

  // .ant-table-cell, .ant-table-cell p {
  //   text-align: center !important;
  // }
`;

const CardFooter = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 235px);
  justify-content: space-between;

  button {
    border-radius: 12px;
    height: 50px;
    width: 100%;
  }
`;

const Grid = styled.div`
  align-items: flex-start;
  justify-content: flex-start;
  display: grid;
  grid-template-columns: 1fr 1fr auto;

  .item {
    // padding: 6.5px 18px;
  }

  .item.checked {
    border: 1px solid #494d7d;
    padding: 2px 10px;
    border-radius: 12px;
  }

  .item__name {
    color: #494d7d;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 22px;
    text-align: left;
  }

  .item__number {
    color: #8688a8;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 22px;
    text-align: left;
  }
`;

const PreGrid = styled.div`
  border-bottom: 1px solid #f1f4f9;
  border-top: 1px solid #f1f4f9;
  padding-top: 20px;
`;

const TableFooter = styled.div`
  bottom: 16px;
  left: 0;
  position: absolute;
`;

const Search = styled.div`
  position: relative;

  input {
    width: 100%;
    background-color: #f1f5f700;
    border: 2px solid #d9e1e7;
    border-radius: 16px;
    color: #8688a8;
    font-size: 16px;
    font-style: italic;
    letter-spacing: 0px;
    line-height: 18px;
    margin: 0;
    padding: 12px 58px 12px 26px;
    outline: 0;
  }

  .icon {
    position: absolute;
    right: 26px;
    top: 8px;
  }
`;

const Wrapper = styled.main`
  background-color: #e8e9f3;
  display: grid;
  grid-template-columns: 7.083vw 1fr;
  min-height: 100vh;

  .avatar-thread {
    aspect-ratio: 1 / 1 !important;
  }

  .ant-popover-title {
    margin-bottom: 1px !important;
  }

  .ql-editor * {
    font-size: 14px !important;
  }

  .new-msg-color {
    background: #d2d5eb !important;
  }

  .new-msg-color .msg-thread {
    color: #fff !important;
  }

  .msg-thread,
  .msg-thread * {
    font-size: 14px !important;
    line-height: 1.4;
    color: "#C2C4D4";
  }

  .msg-thread p {
    margin-bottom: 2px !important;
  }

  .chat-box .ant-avatar {
    width: 85%;
    height: auto;
  }

  .chat-box p {
    font-size: 13px !important;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 15px;
    background-color: lightgrey !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #fff !important;
  }

  .ant-input {
    height: 40px;
  }

  .card-msg {
    padding: 0 !important;
    margin-bottom: 0 !important;
  }

  .cancel-delete {
    border: 1px solid #c2c4d4 !important;
    color: #c2c4d4;
  }

  .span-last-msg * {
    color: #494d7d;
  }

  .card-message {
    margin-bottom: 20px;
  }

  .go-to-msg {
    color: #494d7d;
    font-size: 13px;
    cursor: pointer !important;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  ::-webkit-scrollbar * {
    background: transparent !important; // manage scrollbar background color here
  }

  .card-header {
    grid-template-columns: 1fr auto !important;
  }

  .chat-div {
    padding: 20px;
    background: #fff;
    border-radius: 16px 0 0 16px;
  }

  .cta,
  .cta.ant-btn {
    display: flex;
    background: #494d7d !important;
    color: #fff !important;
  }

  .profile-box {
    grid-template-columns: auto auto !important;
    -webkit-box-pack: center;
    justify-content: end !important;
  }

  .chat-box {
    height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
  }

  .row-message {
    margin-bottom: 20px;
  }

  .message-div .ql-editor {
    min-height: 300px !important;
  }

  .new-msg-div .ql-editor {
    min-height: 250px !important;
  }

  .message-div .ql-toolbar,
  .new-msg-div .ql-toolbar {
    background: #c2c4d4 !important;
    border-color: #c2c4d4 !important;
  }

  .cancel-cta {
    border: 1px solid #494d7d !important;
  }

  .message-div .ql-container,
  .new-msg-div .ql-container {
    background: #fff !important;
    border-color: #fff !important;
    height: max-content !important;
  }

  .message-div .quill,
  .new-msg-div .quill {
    margin-top: 10px;
  }

  .message-from-me {
    background: #e8e9f3;
    padding: 15px !important;
    border-radius: 6px;
    color: #0c1151;
    font-size: 12px;
  }

  .message-from-me * {
    color: #0c1151 !important;
  }

  .space-message-time {
    font-size: 11px;
  }

  .space-last-msg {
    font-size: 12px !important;
    margin-left: 10px;
    color: #809fb8 !important;
  }

  p {
    margin-bottom: 5px;
  }

  .message-from-other {
    background: #494d7d;
    padding: 15px !important;
    border-radius: 6px;
    font-size: 12px;
  }

  .message-from-other * {
    color: #fff !important;
  }

  .message-div,
  .new-msg-div {
    background: #d6d7e2;
    border-radius: 0 16px 16px 0;
    padding: 30px;
  }

  .subject {
    color: #06152b !important;
    font-size: 17px;
  }

  @media (max-width: 992px) {
  }
`;

const CardHeader = styled.div`
  align-items: center;
  display: grid;
  gap: 60px;
  grid-template-columns: 1fr auto auto;

  .icon-upload {
    cursor: pointer;
    height: 46px;
    width: 46px;
  }
`;

const Profile = styled.div`
  align-items: center;
  display: grid;
  gap: 10px;
  grid-template-columns: auto 1fr;
  justify-content: center;

  .profile__image {
    border: 1px solid ${(props) => props.theme.blue};
    border-radius: 360px;
  }

  .profile__text {
    color: #0c1151;x
    font-size: 14px;
    font-weight: 20px;
    letter-spacing: 0px;
    line-height: bold;
    text-align: left;
  }
`;

const Content = styled.div`
  padding: 1.771vw;
`;

export default function Mensagens(props) {
  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const [message, setMessage] = useState("");
  const [newBtn, setNewBtn] = useState(false);
  const [threadToShow, setThreadToShow] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [listMessages, setListMessages] = useState([]);
  const [listUsers, setListUsers] = useState([]);
  const [listUsersBkp, setListUsersBkp] = useState([]);
  const [threads, setThreads] = useState([]);
  const [checkboxes, setCheckboxes] = useState([]);
  const [currentSubject, setCurrentSubject] = useState("");
  const [currentSubjectArrayName, setCurrentSubjectArrayName] = useState("");
  const [isInit, setIsInit] = useState(false);
  const quillRef = useRef(null);
  document.title = "SPAP - Mensagens";
  const user = JSON.parse(localStorage.getItem("session_data"));
  const [form] = Form.useForm();
  const audioPlayer = useRef(null);

  notification.config({
    placement: "bottomRight",
    bottom: 10,
    duration: 3,
    rtl: false,
  });

  const handleMessages = (messages, allUsers) => {
    messages = messages.filter((msg) => {
      if (!msg.deleted_by) return msg;
      if (
        !JSON.parse(msg.deleted_by).includes(
          session_storage.all_data.user_session.id
        )
      )
        return msg;
    });
    messages.forEach((message) => {
      let otherUser =
        message.to_user != session_storage.all_data.user_session.id
          ? message.to_user
          : message.from_user;
      message.subjectName =
        session_storage.all_data.user_session.id +
        "-" +
        otherUser +
        "-" +
        message.subject;
    });
    let myMessages = Object.groupBy(messages, ({ subjectName }) => [
      subjectName,
    ]);
    let arrayNames = Object.keys(myMessages);
    let listMessages = [];

    arrayNames.forEach((array) => {
      let thread = myMessages[array];
      let sorted = thread.sort((b, a) => b.id - a.id)[
        myMessages[array].length - 1
      ];
      let firstCreated = thread.sort((a, b) =>
        a.created_at.localeCompare(b.created_at)
      )[0].created_at;
      let myUserId = session_storage.all_data.user_session.id;
      let userId =
        sorted.from_user == myUserId ? sorted.to_user : sorted.from_user;

      let other = allUsers?.filter((user) => {
        return user.id == userId;
      });

      if (other?.length > 0) {
        sorted.userName = other[0].nome_completo;
        let showNewMsg = 0;
        if (
          sorted.is_read == 0 &&
          sorted.to_user == session_storage.all_data.user_session.id
        ) {
          showNewMsg = 1;
        }
        listMessages.push({
          from_user: sorted.from_user,
          to_user: sorted.to_user,
          subject: sorted.subject,
          name: other[0].nome_completo,
          avatar: other[0].avatar,
          message: sorted.message,
          created: new Date(firstCreated).toLocaleString().split(",")[0],
          lastDate: new Date(sorted.updated_at).toLocaleString().split(",")[0],
          lastTime: new Date(sorted.updated_at).toLocaleString().split(",")[1],
          subjectName: array,
          showNew: showNewMsg,
          updated_at: sorted.updated_at,
          deleted_by: sorted.deleted_by,
        });
      }
    });

    let usersList = allUsers.filter((user) => {
      return user.id != session_storage.all_data.user_session.id;
    });

    setThreads(myMessages);
    setListUsers(usersList);
    setListUsersBkp(usersList);
    setListMessages(
      listMessages.sort((a, b) => b.updated_at.localeCompare(a.updated_at))
    );

    countNotReadMessages(listMessages);
    if (
      localStorage.getItem("currentUser") &&
      localStorage.getItem("currentSubject")
    ) {
      let thisThread = myMessages[
        localStorage.getItem("currentSubjectName")
      ].sort((a, b) => b.id - a.id);
      setThreadToShow(thisThread);
    }
  };

  const countNotReadMessages = (messages) => {
    let count = 0;
    messages.map((message) => {
      if (
        message.showNew == 1 &&
        message.to_user == session_storage.all_data.user_session.id
      ) {
        count += 1;
      }
    });

    localStorage.setItem("pendingMsg", count);
    window.dispatchEvent(new Event("storage"));
  };

  const checkIfMessageExists = () => {
    let checkProps = Object.keys(Object.assign({}, checkboxes));
    if (
      !checkProps.length ||
      !form.getFieldsValue(true)?.subject?.length ||
      !quillRef?.current?.value?.length
    ) {
      notification.error({
        description:
          "Para enviar mensagem, é necessário preencher o assunto e o corpo da mensagem.",
      });
      return;
    }

    let checkIfExists = listMessages.filter((message) => {
      let thisM = message.subjectName.split("-");
      if (thisM[1] == checkProps[0]) {
        if (form.getFieldsValue(true)?.subject == thisM[2]) {
          return message;
        }
      }
    });

    if (checkIfExists.length) {
      notification.error({
        description:
          "Já existe uma conversa com o mesmo assunto para o utilizador selecionado.",
      });
      return;
    }

    sendMessage(checkProps[0], form.getFieldsValue(true)?.subject);

    form.resetFields();
    setNewBtn(false);
    setCheckboxes([]);
  };

  useEffect(() => {
    if (!isInit) {
      return;
    }

    var pusher = new Pusher("bce938f1989b6fee1ff4", {
      cluster: "eu",
    });

    var channel = pusher.subscribe("my-channel");
    channel.bind("my-event", function (data) {
      if (
        data.from_user !=
          JSON.parse(localStorage.getItem("session_data")).all_data.user_session
            .id &&
        data.to_user ==
          JSON.parse(localStorage.getItem("session_data")).all_data.user_session
            .id
      ) {
        localStorage.setItem("newmsg", "new");
        window.dispatchEvent(new Event("storage"));
        setCurrentSubject(data.subject);
        let thisUser = listUsersBkp.filter((user) => {
          return user.id == data.from_user;
        });
        axios
          .get(
            "https://spapapi.phormuladev.com/api/messages/" +
              JSON.parse(localStorage.getItem("session_data")).all_data
                .user_session.id
          )
          .then((response) => {
            audioPlayer.current.play();
            handleMessages(response.data.messages, response.data.users);
          });
      }
    });

    return () => {
      pusher.unsubscribe("my-channel");
    };
  }, [isInit]);

  useEffect(() => {
    axios
      .get(
        "https://spapapi.phormuladev.com/api/messages/" +
          session_storage.all_data.user_session.id
      )
      .then((response) => {
        handleMessages(response.data.messages, response.data.users);
      })
      .finally(() => {
        setIsInit(true);
      });
  }, []);

  const sendMessage = (toUser, thisSubject) => {
    if (!quillRef?.current?.value.length || !thisSubject) return;
    var bodyFormData = new FormData();
    bodyFormData.append("from_user", session_storage.all_data.user_session.id);
    bodyFormData.append("to_user", toUser);
    bodyFormData.append("message", quillRef.current.value);
    bodyFormData.append("subject", thisSubject);

    axios
      .post("https://spapapi.phormuladev.com/api/messages", bodyFormData, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + session_storage.token,
        },
      })
      .then((response) => {
        notification.success({
          description: "Mensagem enviada com sucesso.",
        });
        handleMessages(response.data.messages, listUsersBkp);
        clearBodyMsg();
      });
  };

  const clearBodyMsg = () => {
    document.querySelectorAll(".ql-editor").forEach((elem) => {
      elem.innerHTML = "";
    });
  };

  const goToMessage = (message) => {
    setNewBtn(false);
    let thisThread = threads[message.subjectName].sort((a, b) => b.id - a.id);
    let userId =
      thisThread[0].from_user != session_storage.all_data.user_session.id
        ? thisThread[0].from_user
        : thisThread[0].to_user;
    let thisUser = listUsers.filter((user) => {
      return user.id == userId;
    });
    localStorage.setItem("currentUser", JSON.stringify(thisUser[0]));
    localStorage.setItem("currentSubjectName", message.subjectName);
    localStorage.setItem("currentSubject", message.subject);
    setThreadToShow(thisThread);
  };

  const handleCheckboxChange = (index) => {
    const newCheckboxes = [];
    newCheckboxes[index] = !newCheckboxes[index];
    setCheckboxes(newCheckboxes);
  };

  const showNewMessage = () => {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("currentSubject");
    setNewBtn(true);
  };

  const handleSearch = (value) => {
    if (value.length == 0) {
      setListUsers(listUsersBkp);
      return;
    }

    let input = value.toLowerCase();
    let novosUsersAListar = listUsersBkp.filter((user) => {
      if (
        (user.name && user.name.toLowerCase().includes(input)) ||
        (user.apelido && user.apelido.toLowerCase().includes(input)) ||
        (user.email && user.email.toLowerCase().includes(input)) ||
        (user.numero_socio &&
          user.numero_socio.toLowerCase().includes(input)) ||
        (user.telefone && user.telefone.toLowerCase().includes(input)) ||
        (user.orcid && user.orcid.toLowerCase().includes(input)) ||
        (user.socio_titular && user.socio_titular.toLowerCase().includes(input))
      ) {
        return user;
      }
    });

    setListUsers(novosUsersAListar);
  };

  const deleteMessage = (message) => {
    let deletedBy = [];
    if (!message.deleted_by) {
      deletedBy = [session_storage.all_data.user_session.id];
    } else {
      let currentDeleted = JSON.parse(message.deleted_by);
      currentDeleted.push(session_storage.all_data.user_session.id);
      deletedBy = currentDeleted;
    }

    let otherUser =
      message.to_user != session_storage.all_data.user_session.id
        ? message.to_user
        : message.from_user;

    var bodyFormData = new FormData();
    bodyFormData.append("user", otherUser);
    bodyFormData.append("subject", message.subject);
    bodyFormData.append("deleted_by", JSON.stringify(deletedBy));

    axios
      .post(
        "https://spapapi.phormuladev.com/api/delete-messages",
        bodyFormData,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((response) => {
        if (
          localStorage.getItem("currentUser") &&
          localStorage.getItem("currentSubject")
        ) {
          if (
            message.subject == localStorage.getItem("currentSubject") &&
            JSON.parse(localStorage.getItem("currentUser")).id == otherUser
          ) {
            localStorage.removeItem("currentUser");
            localStorage.removeItem("currentSubject");
          }
        }
        let newMessagesToList = listMessages.filter((msg) => {
          return msg.subjectName != message.subjectName;
        });
        setListMessages(newMessagesToList);
        notification.success({
          description: "Conversa apagada com sucesso.",
        });
      });
  };

  return (
    <Wrapper>
      <div>
        <Sidebar key={"sidebar-msg"} />
      </div>
      <Content>
        <Header />
        <Row justify={"space-between"} align={"middle"} className="mb-10">
          <Col>
            <Title>As minhas conversas</Title>
            <p>
              Aqui poderá conversar com os sócios organizando as conversas por
              assunto.
            </p>
          </Col>
          <Col>
            <Button
              onClick={() => {
                showNewMessage();
              }}
              className="cta"
              style={{
                height: "42px",
              }}
            >
              Criar nova mensagem <MessageOutlined />
            </Button>
          </Col>
        </Row>
        {localStorage.getItem("currentUser") &&
          localStorage.getItem("currentSubject") &&
          threadToShow.length > 0 && (
            <Row className="card-message">
              <Col span={12} className="chat-div">
                <Row justify={"space-between"} align={"middle"}>
                  <Col span={18} className="subject">
                    <b>Assunto:</b> "{localStorage.getItem("currentSubject")}"
                  </Col>
                  <Col span={6}>
                    <Profile className="profile-box">
                      <Badge className="profile__image">
                        {JSON.parse(localStorage.getItem("currentUser"))
                          .avatar ? (
                          <Avatar
                            size={50}
                            src={
                              "https://spapapi.phormuladev.com/avatars/" +
                              JSON.parse(localStorage.getItem("currentUser"))
                                .avatar
                            }
                          />
                        ) : (
                          <Avatar size={50} icon={<UserOutlined />} />
                        )}
                      </Badge>
                      <div className="profile__text">
                        Com: <br />
                        <b>
                          {JSON.parse(localStorage.getItem("currentUser")).name}
                        </b>
                      </div>
                    </Profile>
                  </Col>
                </Row>
                <Divider style={{ margin: "12px 0" }} />

                <div className="chat-box">
                  {threadToShow.map((msg) => {
                    return (
                      <>
                        <Row
                          gutter={[8, 8]}
                          className="row-message"
                          key={"msg-" + msg.id}
                        >
                          {msg.from_user !=
                          session_storage.all_data.user_session.id ? (
                            <>
                              <Col span={2}>
                                {JSON.parse(localStorage.getItem("currentUser"))
                                  .avatar ? (
                                  <Avatar
                                    size={"80%"}
                                    src={
                                      "https://spapapi.phormuladev.com/avatars/" +
                                      JSON.parse(
                                        localStorage.getItem("currentUser")
                                      ).avatar
                                    }
                                  />
                                ) : (
                                  <Avatar
                                    className="avatar-thread"
                                    size={"80%"}
                                    icon={<UserOutlined />}
                                  />
                                )}
                              </Col>
                              <Col span={20} className="message-from-other">
                                <div
                                  style={{
                                    fontSize: "12px",
                                    lineHeight: "1.4",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: msg.message,
                                  }}
                                ></div>
                                <Space className="space-message-time">
                                  <div>
                                    <CalendarOutlined />{" "}
                                    {
                                      new Date(msg.created_at)
                                        .toLocaleString()
                                        .split(",")[0]
                                    }
                                  </div>
                                  <div>
                                    <FieldTimeOutlined />{" "}
                                    {
                                      new Date(msg.created_at)
                                        .toLocaleString()
                                        .split(",")[1]
                                    }
                                  </div>
                                </Space>
                              </Col>
                            </>
                          ) : (
                            <>
                              <Col
                                offset={2}
                                span={20}
                                className="message-from-me"
                              >
                                <div
                                  style={{
                                    fontSize: "12px",
                                    lineHeight: "1.4",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: msg.message,
                                  }}
                                ></div>
                                <Space className="space-message-time">
                                  <div>
                                    <CalendarOutlined />{" "}
                                    {
                                      new Date(msg.created_at)
                                        .toLocaleString()
                                        .split(",")[0]
                                    }
                                  </div>
                                  <div>
                                    <FieldTimeOutlined />{" "}
                                    {
                                      new Date(msg.created_at)
                                        .toLocaleString()
                                        .split(",")[1]
                                    }
                                  </div>
                                </Space>
                              </Col>
                              <Col span={2}>
                                {session_storage.all_data.user_session
                                  .avatar ? (
                                  <Avatar
                                    size={"80%"}
                                    src={
                                      "https://spapapi.phormuladev.com/avatars/" +
                                      session_storage.all_data.user_session
                                        .avatar
                                    }
                                  />
                                ) : (
                                  <Avatar
                                    className="avatar-thread"
                                    size={"80%"}
                                    icon={<UserOutlined />}
                                  />
                                )}
                              </Col>
                            </>
                          )}
                        </Row>
                      </>
                    );
                  })}
                </div>
              </Col>
              <Col span={12} className="message-div">
                <span className="subject">
                  <b>Mensagem</b>
                </span>
                <ReactQuill ref={quillRef} key={"update-thread"}></ReactQuill>
                <Divider />
                <Row justify={"space-between"}>
                  <Col></Col>
                  <Col>
                    <Space>
                      <Button
                        type="secondary"
                        className="cancel-cta"
                        onClick={() => {
                          localStorage.removeItem("currentUser");
                          localStorage.removeItem("currentSubject");
                          localStorage.removeItem("currentSubjectName");
                          setThreadToShow([]);
                        }}
                      >
                        Fechar
                      </Button>
                      <Button
                        key={"send-msg"}
                        type="primary"
                        className="cta"
                        onClick={() => {
                          sendMessage(
                            JSON.parse(localStorage.getItem("currentUser")).id,
                            localStorage.getItem("currentSubject")
                          );
                        }}
                      >
                        Enviar <SendOutlined />
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        {newBtn && (
          <Row className="card-message ">
            <Col span={12} className="chat-div">
              <Card className="card-msg">
                <CardHeader style={{ gap: "0" }}>
                  <Search>
                    <input
                      type="text"
                      placeholder="Procurar..."
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                    <IconSearch className="icon icon-search" />
                  </Search>
                </CardHeader>
                <CardContent>
                  <PreGrid>
                    <Grid
                      style={{
                        gap: "10px",
                        gridTemplateColumns: "repeat(2,2fr)",
                        maxHeight: "380px",
                        overflowY: "scroll",
                      }}
                    >
                      {listUsers.map((item, index) => {
                        return (
                          <Space
                            key={"grid-new-msg-" + item.id}
                            className={
                              checkboxes[item.id]
                                ? "item item-" + item.id + " checked"
                                : "item item-" + item.id
                            }
                            align="center"
                            size={10}
                          >
                            <Checkbox
                              checked={checkboxes[item.id]}
                              onChange={() => handleCheckboxChange(item.id)}
                              className="item__checkbox"
                            />
                            {
                              // <Avatar src={<img src={'https://spapapi.phormuladev.com/avatars/' + item.avatar} />} />
                            }
                            <Profile>
                              <Badge className="profile__image">
                                {item.avatar ? (
                                  <Avatar
                                    size={30}
                                    src={
                                      "https://spapapi.phormuladev.com/avatars/" +
                                      item.avatar
                                    }
                                  />
                                ) : (
                                  <Avatar size={30} icon={<UserOutlined />} />
                                )}
                              </Badge>
                              <div className="profile__text">
                                <Text strong className="item__name">
                                  {item.name} {item.apelido ? item.apelido : ""}
                                </Text>
                                <br />
                                <Text className="item__number">
                                  {" "}
                                  Sócio:{" "}
                                  {item.numero_socio
                                    ? item.numero_socio
                                    : "N/A"}
                                </Text>
                              </div>
                            </Profile>
                          </Space>
                        );
                      })}
                    </Grid>
                  </PreGrid>
                </CardContent>
              </Card>
            </Col>
            <Col span={12} className="new-msg-div">
              <span className="subject">
                <b>Mensagem</b>
              </span>
              <Form form={form}>
                <Form.Item noStyle name={"subject"}>
                  <Input className="mt-10" placeholder="Assunto"></Input>
                </Form.Item>
              </Form>
              <ReactQuill ref={quillRef} key={"add-thread"}></ReactQuill>
              <Divider style={{ margin: "24px 0" }} />
              <Row justify={"space-between"}>
                <Col></Col>
                <Col>
                  <Space>
                    <Button
                      type="secondary"
                      className="cancel-cta"
                      onClick={() => {
                        localStorage.removeItem("currentUser");
                        localStorage.removeItem("currentSubject");
                        localStorage.removeItem("currentSubjectName");
                        setNewBtn(false);
                      }}
                    >
                      Fechar
                    </Button>
                    <Button
                      key={"send-new-msg"}
                      type="primary"
                      className="cta"
                      onClick={checkIfMessageExists}
                    >
                      Enviar <SendOutlined />
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {listMessages.map((message) => {
          let textPreview = message.message.replace(/<[^>]*>?/gm, " ");

          return (
            <>
              <Card className={message.showNew ? "new-msg-color" : ""}>
                <CardHeader className="card-header">
                  <Title className="item__title">
                    Mensagem: "{message.subject}"
                  </Title>
                  <Popover
                    className="pop-delete"
                    placement="topLeft"
                    title={"Tem a certeza que pretende apagar esta conversa?"}
                    content={
                      <>
                        <Row justify="end">
                          <Button
                            style={{
                              color: "#fff",
                              background: "#494d7d",
                              border: "1px solid #494d7d",
                              height: "25px",
                              lineHeight: "12px",
                            }}
                            onClick={() => {
                              deleteMessage(message);
                            }}
                          >
                            Confirmar
                          </Button>
                        </Row>
                      </>
                    }
                  >
                    <IconClose
                      className="icon icon-close"
                      // onClick={() => openRemovePDFModal(item.id, item.titulo)}
                    />
                  </Popover>
                </CardHeader>
                <Row justify={"space-between"} className="mt-10">
                  <Col>
                    <Profile>
                      <Badge className="profile__image">
                        {message.avatar ? (
                          <Avatar
                            size={40}
                            src={
                              "https://spapapi.phormuladev.com/avatars/" +
                              message.avatar
                            }
                          />
                        ) : (
                          <Avatar size={40} icon={<UserOutlined />} />
                        )}
                      </Badge>
                      <div className="profile__text">
                        <b>{message.name}</b>
                        <br />
                        <div
                          className="msg-thread"
                          style={{
                            fontSize: "12px",
                            lineHeight: "1.4",
                            color: "#C2C4D4",
                          }}
                          dangerouslySetInnerHTML={{ __html: textPreview }}
                        ></div>
                      </div>
                    </Profile>
                  </Col>
                  <Col>
                    <span className="span-last-msg">Ultima mensagem: </span>
                    <Space className="space-last-msg" direction="horizontal">
                      <div>
                        <CalendarOutlined /> {message.lastDate}
                      </div>
                      <div>
                        <FieldTimeOutlined /> {message.lastTime}
                      </div>
                    </Space>
                  </Col>
                </Row>
                <Divider style={{ margin: "12px 0" }} />
                <Row>
                  <Col span={24}>
                    <span className="span-last-msg">Iniciado em: </span>
                    <Space direction="horizontal">
                      <div className="space-last-msg">
                        <CalendarOutlined /> {message.created}
                      </div>
                      <div
                        className="go-to-msg"
                        key="edit"
                        onClick={(e) => {
                          e.preventDefault();
                          goToMessage(message);
                        }}
                      >
                        <EditOutlined /> Escrever
                      </div>
                    </Space>
                  </Col>
                </Row>
              </Card>
            </>
          );
        })}
      </Content>
      <audio ref={audioPlayer} src={NotificationSound} />
    </Wrapper>
  );
}
