import { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Button,
  Dropdown,
  message,
  Space,
  Tooltip,
  Input,
  Radio,
  Checkbox,
  Table,
  Select,
  Pagination,
} from "antd";
import {
  DownOutlined,
  UserOutlined,
  CheckOutlined,
  LoadingOutlined,
  PlusOutlined,
  InboxOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import UserService from "../../../Services/user.service";
import Sidebar from "../../../Components/Backoffice/Sidebar";
import Card from "../../../Components/Backoffice/Card";
import Title from "../../../Components/Backoffice/Title";
import Header from "../../../Components/Backoffice/Header";
import Footer from "../../../Components/Backoffice/Footer";
import { ReactComponent as IconSearch } from "../../../Assets/Images/Backoffice/Search.svg";
import { ReactComponent as IconOptions } from "../../../Assets/Images/Backoffice/Options.svg";
import { ReactComponent as IconOk } from "../../../Assets/Images/Backoffice/Ok.svg";
import { ReactComponent as IconClose } from "../../../Assets/Images/Backoffice/Close.svg";

import axios from "axios";
const { TextArea } = Input;
const Wrapper = styled.main`
  background-color: #E8E9F3;
  display: grid;
  grid-template-columns: 7.083vw 1fr;
  min-height: 100vh;
  @media (max-width: 992px) {
    
  }

  .item .icon {
    border: 2px solid #D9E1E7 !important;
    border-radius: 360px;
    cursor: pointer;
    transition; 3s ease;
  }

  .item .icon:hover {
    background-color: #D9E1E7;
  }

  .item .icon path {
    fill: #809FB8;
  }

  .item .icon:hover path {
    fill: #FEFEFE;
  }  
`;
const Content = styled.div`
  padding: 1.771vw;
`;
const CardHeader = styled.div`
  align-items: center;
  display: grid;
  gap: 60px;
  grid-template-columns: 1fr auto auto;
`;
const Search = styled.div`
  position: relative;
  input {
    background-color: #f1f5f700;
    border: 2px solid #d9e1e7;
    border-radius: 16px;
    color: #8688a8;
    font-size: 16px;
    font-style: italic;
    letter-spacing: 0px;
    line-height: 18px;
    margin: 0;
    padding: 12px 58px 12px 26px;
    outline: 0;
  }
  .icon {
    position: absolute;
    right: 26px;
    top: 8px;
  }
`;
const CardContent = styled.div`
  margin: 40px 0;
  position: relative;
  table th,
  table {
    color: #809fb8 !important;
    text-align: center !important;
  }
  .ant-checkbox-checked span.ant-checkbox-inner,
  .ant-checkbox-checked:hover span.ant-checkbox-inner {
    background-color: #1ad598 !important;
    border-color: #1ad598 !important;
  }
  .ant-checkbox:hover span.ant-checkbox-inner,
  .ant-checkbox-wrapper.item__checkbox:hover .ant-checkbox-inner {
    border-color: #1ad598 !important;
  }
  .user-name {
    color: #494d7d;
  }
  .user-number {
    border-color: #d9e1e7cc;
    color: #809fb8;
  }
  .user-active {
    background-color: #1ad5984d;
    border: none;
    color: #1ad598;
  }
  .user-desactive {
    background-color: #ea3a3d4d;
    border: none;
    color: #ea3a3d;
  }
  .user-disabled {
    background-color: #d9e1e7;
    border: none;
    color: #99b2c6;
  }
  li.ant-pagination-item {
    border: 1px solid #d9e1e7cc;
  }
  li.ant-pagination-item.ant-pagination-item-active {
    background-color: #8688a8;
    border-color: #8688a8;
    color: #ffffff;
  }
`;
const TableFooter = styled.div`
  bottom: 16px;
  left: 0;
  position: absolute;
`;
const RadioGroup = styled.div`
  button {
    background-color: #494d7d;
    border: none;
    color: #ffffff;
    transition: 0.3s ease;
  }
  button:hover {
    background-color: #1ad598;
    color: #ffffff !important;
  }
`;

export default function PageWarnings(props) {
  document.title = "SPAP - Warnings";
  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const profile = UserService.getUserProfile();
  const [warningToUpdate, setWarningToUpdate] = useState("");
  const [warnings, setWarnings] = useState([]);
  const [users, setUser] = useState([]);

  useEffect(() => {
    fetch(`https://spapapi.phormuladev.com/api/all-warnings`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.warnings.length === 0) {
          return;
        }

        data.warnings.forEach((warning) => {
          let created_at = warning.created_at;
          warning.dateToShow = new Date(created_at).toLocaleDateString();
        });

        setWarnings(data.warnings);
        setUsers(data.users);
      })
      .catch((error) => console.error(error));
  }, []);

  const [value, setValue] = useState(1);
  const radioChange = (e) => {
    setValue(e.target.value);
  };
  const handleMenuClick = (e) => {
    message.info("Click on menu item.");
    console.log("click", e);
  };

  const items = [
    {
      label: (
        <RadioGroup>
          <Radio.Group onChange={radioChange} value={value}>
            <Space direction="vertical" className="space-align-block">
              <Radio value={1}>Aviso de cobrança</Radio>
              <Radio value={2}>Aviso de certificado</Radio>
              <Radio value={3}>Recibo de compras</Radio>
              <Radio value={4}>Ativar utilizador</Radio>
              <Radio value={5}>Desativar utilizador</Radio>
              <Button onClick={handleMenuClick}>
                <Space>
                  Enviar
                  <CheckOutlined />
                </Space>
              </Button>
            </Space>
          </Radio.Group>
        </RadioGroup>
      ),
    },
  ];
  const menuProps = {
    items,
    onClick: false,
  };

  const [tablePagination, setTablePagination] = useState(5);
  const selectHandleChange = (value) => {
    console.log(`selected ${value}`);
    setTablePagination(value);
  };
  /* Table */
  const columns = [
    {
      title: "Nº",
      dataIndex: "number",
      sorter: {
        compare: (a, b) => a.status - b.status,
      },
    },
    {
      title: "Tipo",
      dataIndex: "type",
      sorter: {
        compare: (a, b) => a.type - b.type,
      },
    },
    {
      title: "Mensagem",
      dataIndex: "message",
      sorter: {
        compare: (a, b) => a.active - b.active,
      },
    },
    {
      title: "Data",
      dataIndex: "date",
      sorter: {
        compare: (a, b) => a.date - b.date,
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: {
        compare: (a, b) => a.status - b.status,
      },
    },
    {
      title: "Ação",
      dataIndex: "approve",
      sorter: {
        compare: (a, b) => a.status - b.status,
      },
    },
    {
      title: (
        <Dropdown menu={menuProps}>
          <Button>
            <Space>
              Ações
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
      dataIndex: "action",
    },
  ];

  const t = 1;
  const data = [];

  warnings.map((item) =>
    data.push({
      key: item.id,
      number: <b className="user-name">{item.id}</b>,
      type: <b>{item.tipo}</b>,
      message: <b>{item.descricao}</b>,
      date: <b>{item.dateToShow}</b>,
      status:
        item.status === 1 ? (
          <Button className="user-active">
            <b>ATIVO</b>
          </Button>
        ) : (
          <Button className="user-desactive">
            <b>INATIVO</b>
          </Button>
        ),
      approve: (
        <>
          {item.tipo == "Nova adesão a sócio" && item.status === 1 ? (
            <>
              <Space.Compact direction="vertical">
                <Space>
                  <Space>
                    <IconOk
                      className="icon icon-ok"
                      onClick={() =>
                        showModalConfirm(item.email, item.nome, true, item.id)
                      }
                    />{" "}
                    Aprovar
                  </Space>
                  <Space>
                    <IconClose
                      className="icon icon-close"
                      onClick={() =>
                        showModalConfirm(item.email, item.nome, false, item.id)
                      }
                    />{" "}
                    Declinar
                  </Space>
                </Space>
              </Space.Compact>
            </>
          ) : (
            ""
          )}
        </>
      ),
      action: (
        <Dropdown menu={menuProps} key={item.id}>
          <Button>
            <Space>
              Ações
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    })
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  const tableOnChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };
  return (
    <Wrapper>
      <div>
        <Sidebar />
      </div>
      <Content>
        <Header />
        <Card>
          <CardHeader>
            <Title>Lista de warnings completa</Title>
            <Search>
              <input
                type="text"
                placeholder="Procurar..."
                onChange={(e) => console.log(e.target.value)}
              />
              <IconSearch className="icon icon-search" />
            </Search>
            <IconOptions className="icon icon-options" />
          </CardHeader>
          <CardContent>
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={data}
              onChange={tableOnChange}
              pagination={{ pageSize: tablePagination }}
            />
            <TableFooter>
              <Select
                defaultValue={tablePagination}
                style={{
                  width: 120,
                }}
                onChange={selectHandleChange}
                options={[
                  {
                    value: "5",
                    label: "5",
                  },
                  {
                    value: "10",
                    label: "10",
                  },
                  {
                    value: "20",
                    label: "20",
                  },
                  {
                    value: "50",
                    label: "50",
                  },
                ]}
              />
            </TableFooter>
          </CardContent>
        </Card>
        <Footer />
      </Content>
    </Wrapper>
  );
}
