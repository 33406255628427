import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Avatar, Badge } from "antd";
import { UserOutlined } from "@ant-design/icons";
import authService from "../../../../Services/auth.service";

import Echo from "laravel-echo";

import { ReactComponent as IconInbox } from "../../../../Assets/Images/Backoffice/Top-Menu/inbox-off.svg";
import { ReactComponent as IconInboxHover } from "../../../../Assets/Images/Backoffice/Top-Menu/inbox-on.svg";
import { ReactComponent as IconUser } from "../../../../Assets/Images/Backoffice/Top-Menu/user-off.svg";
import { ReactComponent as IconUserHover } from "../../../../Assets/Images/Backoffice/Top-Menu/user-on.svg";
import { ReactComponent as IconSite } from "../../../../Assets/Images/Backoffice/Top-Menu/site-spap-off.svg";
import { ReactComponent as IconSiteHover } from "../../../../Assets/Images/Backoffice/Top-Menu/site-spap-on.svg";
import { ReactComponent as IconLogout } from "../../../../Assets/Images/Backoffice/Top-Menu/logout-off.svg";
import { ReactComponent as IconLogoutHover } from "../../../../Assets/Images/Backoffice/Top-Menu/logout-on.svg";

const Wrapper = styled.div`
  place-self: center flex-end;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 26px;

  .menu-bcko > a {
    position: relative !important;
  }

  .msg {
    position: relative !important;
  }

  .count-items {
    background: rgb(248, 132, 54);
    color: #fff;
    border-radius: 100px;
    text-align: center;
    padding-top: 4px;
    position: absolute;
    font-size: 14px;
    height: 18px;
    width: 18px !important;
    line-height: 10px;
    bottom: 0;
    right: 5px;
  }
`;

const Profile = styled.div`
  align-items: center;
  display: grid;
  gap: 19px;
  grid-template-columns: auto 1fr;
  justify-content: center;

  .profile__image {
    border: 1px solid ${(props) => props.theme.blue};
    border-radius: 360px;
  }

  .profile__text {
    color: #0c1151;
    font-size: 14px;
    font-weight: 20px;
    letter-spacing: 0px;
    line-height: bold;
    text-align: left;
  }
`;

const Nav = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  svg {
    cursor: pointer;
    transition: 0.3s ease;
  }
`;

export default function Menu(props) {
  const session_storage = JSON.parse(localStorage.getItem("session_data"));

  if (session_storage.all_data.user_session.avatar == null) {
    session_storage.all_data.user_session.avatar = "default-avatar.png";
  }

  // window.Pusher = require('pusher-js');

  // window.Echo = new Echo({
  //   broadcaster: 'pusher',
  //   key: 'b62308e8b0ab7d706270',
  //   cluster: 'eu',
  //   encrypted: true
  // });

  // window.Echo.channel('events')
  //   .listen('NewUser', (e) => {
  //     session_storage.all_data.warnings = e.message.warnings;
  //     session_storage.all_data.users.push(e.message.user);
  //     localStorage.setItem('session_data', JSON.stringify(session_storage));
  // });

  /* #TODO alterar variavel userAlertCount, adicionar chamada para quantidade de notificações do usuário */
  const userAlertCount = 5;
  const [hovered, setHovered] = useState([false, false, false, false]);

  const handleMouseEvent = (index, event) => {
    const newArray = [...hovered];
    newArray[index] = event;
    setHovered(newArray);
  };

  return (
    <Wrapper className="menu-bcko">
      <Profile>
        <Badge
          count={session_storage.all_data.warnings?.length}
          className="profile__image"
        >
          {session_storage.all_data.user_session.avatar ? (
            <Avatar
              size={60}
              src={
                "https://spapapi.phormuladev.com/avatars/" +
                session_storage.all_data.user_session.avatar
              }
            />
          ) : (
            <Avatar size={60} icon={<UserOutlined />} />
          )}
        </Badge>
        <div className="profile__text">
          Bem vindo(a), <br />
          <i>{session_storage.all_data.user_session.name}</i>
        </div>
      </Profile>
      <Nav>
        <Link className="msg hide-event" to="/dashboard/mensagens">
          {hovered[0] ? (
            <IconInboxHover onMouseLeave={() => handleMouseEvent(0, false)} />
          ) : (
            <IconInbox onMouseEnter={() => handleMouseEvent(0, true)} />
          )}
          {localStorage.getItem("pendingMsg") != null &&
            JSON.parse(localStorage.getItem("pendingMsg")) > 0 && (
              <span className="count-items">
                {JSON.parse(localStorage.getItem("pendingMsg"))}
              </span>
            )}
        </Link>
        {session_storage.all_data.role == "moderator" ? (
          <>
            <Link to="/socios/dados-de-perfil/">
              {hovered[1] ? (
                <IconUserHover
                  onMouseLeave={() => handleMouseEvent(1, false)}
                />
              ) : (
                <IconUser onMouseEnter={() => handleMouseEvent(1, true)} />
              )}
            </Link>
          </>
        ) : (
          <>
            <Link to="/dashboard/perfil">
              {hovered[1] ? (
                <IconUserHover
                  onMouseLeave={() => handleMouseEvent(1, false)}
                />
              ) : (
                <IconUser onMouseEnter={() => handleMouseEvent(1, true)} />
              )}
            </Link>
          </>
        )}

        <Link to="/" target="_blank">
          {hovered[2] ? (
            <IconSiteHover onMouseLeave={() => handleMouseEvent(2, false)} />
          ) : (
            <IconSite onMouseEnter={() => handleMouseEvent(2, true)} />
          )}
        </Link>
        <Link to="/logout/">
          {hovered[3] ? (
            <IconLogoutHover onMouseLeave={() => handleMouseEvent(3, false)} />
          ) : (
            <IconLogout onMouseEnter={() => handleMouseEvent(3, true)} />
          )}
        </Link>
      </Nav>
    </Wrapper>
  );
}
