import styled from "styled-components";
import { Link, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faCalendarDay,
  faFileArchive,
  faFileCirclePlus,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";

import Container from "../../../../Components/Container";
import FlexInColumn from "../../../../Components/FlexInColumn";
import FlexInRow from "../../../../Components/FlexInRow";
import PageTitle from "../../../../Components/PageTitle";

import IconFile from "../../../../Assets/Images/icon-awesome-file-alt.svg";
import IconSubscribe from "../../../../Assets/Images/icon-partilhar.svg";
import IconSeeMore from "../../../../Assets/Images/icon-saber-mais.svg";
import IconShare from "../../../../Assets/Images/icon-partilhar.svg";
import IconReturn from "../../../../Assets/Images/icon-voltar.svg";
import { notification } from "antd";
import Item from "antd/es/list/Item";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #000000;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: left;
  width: 100%;
  margin-top: 7.813vw;

  img {
    width: 100%;
  }

  ul {
    list-style: none;
    width: 8.333vw;
    padding: 0;
  }

  li {
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    gap: 0.729vw;
    text-align: left;
    font-size: 1.146vw;
    font-weight: bold;
    line-height: 1.51vw;
    letter-spacing: 0px;
    opacity: 1;
    margin: 1.042vw 0;

    img {
      width: 1.667vw;
    }
  }

  li a {
    color: #f88436;
    text-decoration: none;
  }

  .event__content a {
    display: initial;
  }

  .event__content img {
    max-width: 60%;
    display: block;
    margin-right: auto;
  }

  .event__content iframe {
    height: 30vw;
    width: 60%;
  }

  .event__content ul {
    width: 100%;
  }

  .icon {
    margin-right: 8px;
  }

  .icon-file {
    width: 3.438vw;
  }

  .share {
    cursor: pointer;
    color: ${(props) => props.theme.orange};
  }

  .event-content {
    width: 100%;
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    .event__content iframe {
      height: 400px;
      width: 100%;
    }
  }

  @media (min-width: 1920px) {
    li {
      font-size: 22px;
      line-height: 29px;
      margin: 20px 0;
    }
  }
`;

const Tab = styled.div`
  margin-bottom: 30px;
  width: 100%;

  span {
    text-align: left;
    font-size: 1.146vw;
    font-weight: bold;
    line-height: 1.51vw;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
  }

  @media (max-width: 992px) {
    span {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }
  }

  @media (min-width: 1920px) {
    span {
      font-size: 24px;
      line-height: 28px;
    }
  }
`;

const Title = styled.h3`
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;

  @media (max-width: 992px) {
    font-size: 3.333vw;
    line-height: 4.444vw;
  }

  @media (min-width: 1920px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

const SectionShareContent = styled.section`
  margin-top: 30px;
  padding: 3.646vw 0;
  background: #d6d7e2 0% 0% no-repeat padding-box;
  opacity: 1;

  .title {
    text-align: center;
    font-size: 2.083vw;
    font-weight: bold;
    line-height: 2.5vw;
    letter-spacing: 0px;
    color: #0c1151;
    text-transform: uppercase;
    opacity: 1;
  }

  .text {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    line-height: inherit;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    margin-bottom: 4.167vw;
  }

  @media (min-width: 1920px) {
    .title {
      font-size: 40px;
      line-height: 48px;
    }

    .text {
      font-size: 24px;
      line-height: 28px;
    }
  }
`;

const SectionMembers = styled.section``;

const SectionMembersContent = styled.div`
  background-color: #ffffff;
  border: 3px solid #0c1151;
  border-radius: 1.042vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "image title"
    "image text"
    "image cta";
  opacity: 1;
  overflow: hidden;

  @media (min-width: 1920px) {
    border-radius: 10px;
  }
`;

const SectionMembersImage = styled.div`
  border-radius: 1.042vw 0px 0px 1.042vw;
  grid-area: image;
  overflow: hidden;

  @media (min-width: 1920px) {
    border-radius: 10px 0px 0px 10px;
  }
`;

const SectionMembersTitle = styled.h3`
  grid-area: title;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;
  margin: 1.042vw;
  place-self: flex-end flex-start;

  @media (max-width: 992px) {
    font-size: 3.333vw;
    line-height: 4.444vw;
  }

  @media (min-width: 1920px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

const SectionMembersText = styled.p`
  grid-area: text;
  text-align: left;
  font-size: 0.938vw;
  line-height: 1.25vw;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin: 0 1.042vw;
  place-self: center flex-start;

  @media (max-width: 992px) {
    font-size: 2.222vw;
    line-height: 3.333vw;
  }

  @media (min-width: 1920px) {
    font-size: 22px;
    line-height: 29px;
  }
`;

const SectionMembersCta = styled.a`
  grid-area: cta;
  text-align: left;
  font-size: 0.938vw;
  font-weight: bold;
  line-height: 1.25vw;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  background: #090d3d 0% 0% no-repeat padding-box;
  border-radius: 0.417vw;
  opacity: 1;
  transition: 0.3s ease;
  margin: 1.042vw;
  place-self: flex-end flex-start;
  padding: 0.521vw 1.563vw;
  text-decoration: none;
  align-items: center;
  justify-content: center;

  :hover {
    background-color: #8688a8;
    color: #e5e5e5;
  }

  @media (max-width: 992px) {
  }

  @media (min-width: 1920px) {
    border-radius: 10px;
    font-size: 22px;
    line-height: 29px;
    margin: 10px;
  }
`;

export default function Apresentacao(props) {
  const session_storage = JSON.parse(localStorage.getItem("session_data"));

  const { slug } = useParams();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [apresentacao, setApresentacao] = useState([]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const getApresentacao = (myApresentacao) => {
    let apresentacaoAMostrar = myApresentacao[0];
    apresentacaoAMostrar.url = apresentacaoAMostrar.imagem_destaque
      ? "https://spapapi.phormuladev.com/apresentacoes/" +
        apresentacaoAMostrar.id +
        "/" +
        apresentacaoAMostrar.imagem_destaque
      : "https://spapapi.phormuladev.com/clubes/clube-estudo-default.svg";
    setApresentacao(apresentacaoAMostrar);
  };

  useEffect(() => {
    fetch(
      `https://spapapi.phormuladev.com/api/get-apresentacao/` +
        slug.split("-").pop(),
      {
        method: "GET",
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar apresentacaos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          console.log("nenhum apresentacao");
        }
        getApresentacao(data.apresentacao);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleShare = (link) => {
    navigator.clipboard.writeText(link);
    notification.info({ description: "Link copiado: " + link });
  };

  return (
    <Wrapper>
      <Container>
        <PageTitle
          title={apresentacao.titulo}
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "Ferramentas úteis",
              link: "/ferramentas-uteis",
            },
            {
              text: "Formação",
              link: "/ferramentas-uteis/formacao",
            },
            {
              text: "Cursos e Congressos",
              link: "/ferramentas-uteis/formacao/cursos-congressos",
            },
            {
              text: apresentacao.titulo,
            },
          ]}
          imageDesktop={apresentacao.url}
          imageMobile={apresentacao.url}
          imageAlt={apresentacao.titulo}
        />

        {/* <Tab>
          <FlexInColumn gap="1.823vw">
            <FlexInColumn gap="0.833vw">
              <span>
                <FontAwesomeIcon icon={faLocationDot} className="icon icon-location" />
                {apresentacao.localizacao}
              </span>
            </FlexInColumn>
            <FlexInColumn gap="0.833vw">
              <span>
                <FontAwesomeIcon icon={faCalendarDay} className="icon icon-location" />
                {apresentacao.date}
              </span>
            </FlexInColumn>
          </FlexInColumn>
        </Tab> */}

        <FlexInRow gap="3.646vw">
          <FlexInColumn gap="230px">
            <FlexInRow className="event-content">
              <div>
                <img src={apresentacao.url} />
              </div>

              {session_storage != null &&
              session_storage?.all_data?.user_session.tipo_utilizador !=
                "socio_nao_aprovado" &&
              session_storage?.all_data?.user_session.tipo_utilizador !=
                "socio_nao_aprovado_especialista" &&
              session_storage?.all_data?.user_session.tipo_utilizador !=
                "nao_socio" ? (
                <div
                  className="event__content"
                  dangerouslySetInnerHTML={{ __html: apresentacao.descricao }}
                ></div>
              ) : (
                <>
                  <p style={{ color: "#EE8585" }}>
                    Documentos e links abaixo visíveis apenas para sócios
                  </p>
                  <div
                    className="event__content"
                    dangerouslySetInnerHTML={{
                      __html: apresentacao.descricao?.replace(
                        /<a\b[^>]*>|<\/a>/gi,
                        ""
                      ),
                    }}
                  ></div>
                </>
              )}
            </FlexInRow>
            <div>
              <ul>
                <li className="link-disabled">
                  <Link to="/">
                    <img
                      src={IconSubscribe}
                      alt="Icon Subscribe"
                      className="icon icon-subscribe"
                    />
                    Inscrever
                  </Link>
                </li>
                <li className="link-disabled">
                  <Link to="/">
                    <img
                      src={IconSeeMore}
                      alt="Icon See More"
                      className="icon icon-see-more"
                    />
                    Saber mais
                  </Link>
                </li>
                <li>
                  <span
                    className="share"
                    onClick={() => handleShare(window.location.href)}
                  >
                    <img
                      src={IconShare}
                      alt="Icon Share"
                      className="icon icon-share"
                    />
                    Partilhar
                  </span>
                </li>
                <li>
                  <a href="javascript:history.back()">
                    <img
                      src={IconReturn}
                      alt="Icon Return"
                      className="icon icon-return"
                    />
                    Voltar
                  </a>
                </li>
              </ul>
            </div>
          </FlexInColumn>

          {/* <FlexInRow>
            <Title>
              <FontAwesomeIcon icon={faFileArchive} className="icon icon-file-archive" />
              Trabalhos Realizados por Sócios
            </Title>
            <FlexInRow gap="3.646vw">
              <FlexInColumn>
                <Text>
                  <img src={IconFile} alt="Icon File" className="icon icon-file" />
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam uiof nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
                    <Link to="/">Clique para visualizar</Link>
                  </p>
                </Text>
                <Text>
                  <img src={IconFile} alt="Icon File" className="icon icon-file" />
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam uiof nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
                    <Link to="/">Clique para visualizar</Link>
                  </p>
                </Text>
              </FlexInColumn>
            </FlexInRow>
          </FlexInRow> */}
        </FlexInRow>
      </Container>
    </Wrapper>
  );
}
