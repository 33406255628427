import { React, useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import FlexInColumn from "../FlexInColumn";
import FlexInRow from "../FlexInRow";
import { Button, Modal, Result } from "antd";
import axios from "axios";

import BgImage from "../../Assets/Images/img-subscrever-nl-area-socio@2x.png";
import Recaptcha from "../Recaptcha";

const Wrapper = styled.section`
  background-color: #fde0cd;
  padding: 4.167vw 0;
  position: relative;

  #bgImage__space {
    width: 28.646vw;
  }

  #bgImage {
    position: absolute;
    max-width: 28.646vw;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  @media (max-width: 992px) {
    padding: 11.111vw 20px 46.26vw;

    #bgImage {
      max-width: 46.667vw;
    }
  }

  @media (min-width: 1920px) {
    padding: 80px 0;

    #bgImage__space {
      width: 550px;
    }

    #bgImage {
      max-width: 550px;
    }
  }
`;

const Title = styled.h2`
  text-align: center;
  font-size: 2.083vw;
  font-weight: bold;
  line-height: 2.5vw;
  letter-spacing: 0px;
  color: #0c1151;
  text-transform: uppercase;
  opacity: 1;

  @media (min-width: 992px) {
    margin-right: 7.292vw;
  }

  @media (max-width: 992px) {
    font-size: 5.556vw;
    line-height: 6.667vw;
    margin: 0 40px;
  }

  @media (min-width: 1920px) {
    font-size: 40px;
    line-height: 48px;
  }
`;

const Form = styled.form`
  background: #f8e287 0% 0% no-repeat padding-box;
  background-color: #f8e287;
  border-radius: 1.042vw;
  opacity: 1;
  padding: 1.823vw;
  margin-bottom: 2.969vw;
  margin-right: 7.292vw;
  width: 56.667vw;
  z-index: 3;

  input[type="text"],
  input[type="email"] {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 0.521vw;
    opacity: 1;
    padding: 1.146vw 1.302vw;
    width: 100%;
    outline: 0;
    transition: 0.3s ease;
  }

  input[type="text"]:focus,
  input[type="email"]:focus {
    box-shadow: 0 16px 40px #f8e28733;
  }

  input[type="checkbox"] {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: none;
    cursor: pointer;
    opacity: 1;
    height: 1.302vw;
    width: 1.302vw;
  }

  label {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    text-transform: initial;
  }

  label a {
    display: initial;
    text-decoration: underline;
    transition: 0.3s ease-out;
  }

  label a:hover {
    text-decoration: none;
  }

  input[type="submit"] {
    background-color: #f88436;
    border: none;
    border-radius: 0.521vw;
    opacity: 1;
    text-align: center;
    font-size: 1.146vw;
    font-weight: bold;
    line-height: 1.51vw;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    padding: 0.729vw 1.563vw;
    place-self: center;
    margin-bottom: -2.968vw;
    transition: 0.3s ease;
  }

  input[type="submit"]:hover {
    background-color: #e87426;
    color: #ececec;
  }

  @media (max-width: 992px) {
    border-radius: 2.778vw;
    padding: 8.611vw 2.222vw 2.222vw 2.222vw;
    margin: 8.889vw 0 0;
    width: 100%;

    input[type="text"],
    input[type="email"],
    textarea {
      border-radius: 2.778vw;
      font-size: 3.889vw;
      line-height: 5.278vw;
      padding: 3.333vw 4.167vw;
    }

    input[type="checkbox"] {
      height: 6.944vw;
      width: 6.944vw;
    }

    label,
    label a {
      font-size: 2.778vw;
      line-height: 3.611vw;
      display: initial;
    }

    label a {
      text-decoration: underline;
    }

    input[type="submit"] {
      border-radius: 2.778vw;
      font-size: 3.611vw;
      line-height: 5vw;
      padding: 2.778vw 4.167vw;
      margin-top: 5.556vw;
      margin-bottom: -6.8165vw;
    }
  }

  @media (min-width: 1920px) {
    label,
    label * {
      font-size: 18px;
      line-height: 24px;
    }

    input[type="text"],
    input[type="email"],
    textarea {
      font-size: 18px;
      line-height: 24px;
      padding: 25px;
    }

    input[type="checkbox"] {
      height: 25px;
      width: 25px;
    }

    input[type="submit"] {
      border-radius: 10px;
      font-size: 22px;
      line-height: 29px;
      padding: 15px 30px;
    }
  }
`;

export default function Newsletter(props) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const [newsletterEmail, setNewsletterEmail] = useState("");
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [nomeNewsletter, setName] = useState("");

  const handleNewsletter = (e) => {
    e.preventDefault();

    let email = e.target.email.value;
    let nome = e.target.name.value;
    axios
      .post("https://spapapi.phormuladev.com/api/set-newsletter", {
        email,
        nome,
      })
      .then((res) => {
        if (res.status == 200) {
          document.getElementById("newsletter-form").reset();
          setName(nome);
          setSuccessModalOpen(true);
          setTimeout(() => {
            setSuccessModalOpen(false);
          }, 3000);
          e.target.email.value = "";
          e.target.name.value = "";
          e.target.confirm.checked = false;
        }
      })
      .catch((error) => {
        setName(nome);
        setErrorModalOpen(true);
        setTimeout(() => {
          setErrorModalOpen(false);
        }, 3000);
      });
  };

  const handleToken = (token) => {};

  return (
    <Wrapper>
      <FlexInColumn gap="7.396vw">
        {windowWidth > 992 && <div id="bgImage__space"></div>}
        <FlexInRow gap="60px" placeself="center">
          <Title>SUBSCREVA A NEWSLETTER DA SPAP</Title>
          <Form method="post" action="#" onSubmit={(e) => handleNewsletter(e)}>
            <FlexInRow gap="1.458vw">
              <FlexInColumn gap="2.083vw" mobile="column">
                <input type="text" name="name" placeholder="Nome*" required />
                <input
                  type="email"
                  name="email"
                  placeholder="E-mail*"
                  required
                />
              </FlexInColumn>
              <FlexInColumn gap="1.042vw">
                <input type="checkbox" name="confirm" required />
                <label>
                  Ao preencher este formulário de contacto declaro conhecer os{" "}
                  <Link to="/termos-e-condicoes" target="_blank">
                    termos e condições
                  </Link>{" "}
                  associados à subscrição. <br />* Campos de preenchimento
                  obrigatório.
                </label>
              </FlexInColumn>
              <Recaptcha
                sitekey="6Lcfpy8mAAAAAMsUWvFiFerTMe9IdnDZP5KljRUu"
                onToken={handleToken}
              />
              <input
                type="submit"
                name="submit"
                value="Subscrever newsletter"
              />
            </FlexInRow>
          </Form>
        </FlexInRow>
      </FlexInColumn>
      <img id="bgImage" src={BgImage} alt="/" />
      <Modal
        centered
        open={successModalOpen}
        footer={null}
        onCancel={() => setSuccessModalOpen(false)}
      >
        <Result
          style={{ background: "#fff" }}
          status="success"
          title="Subscreveu a nossa newsltter!"
          subTitle={
            "Obrigado " +
            nomeNewsletter +
            " por subscrever a newsletter, fique atento!"
          }
        />
      </Modal>
      <Modal
        centered
        open={errorModalOpen}
        footer={null}
        onCancel={() => setErrorModalOpen(false)}
      >
        <Result
          style={{ background: "#fff" }}
          title="Subscreveu a nossa newsletter anteriormente."
          subTitle={"Olá " + nomeNewsletter + ", esteja atento às novidades!"}
        />
      </Modal>
    </Wrapper>
  );
}
