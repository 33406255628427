import { Link } from "react-router-dom";
import styled from "styled-components";
import { Space } from "antd";
import React, { useState, useEffect, Suspense } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";

import Container from "../../Components/Container";
import FlexInRow from "../../Components/FlexInRow";
import HomeCarousel from "../../Components/HomeCarousel";
import IconWarning from "../../Assets/Images/Pagamentos/Icon-warning.svg";
// const HomeCalendar = React.lazy(() => import('../../Components/HomeCalendar'));
const HighlightsCards = React.lazy(() =>
  import("../../Components/HighlightsCards")
);
const SupportedBySpap = React.lazy(() =>
  import("../../Components/SupportedBySpap")
);

import { ReactComponent as IconLock } from "../../Assets/Images/Icon-lock.svg";
import { ReactComponent as IconLogin } from "../../Assets/Images/icon-home-profile.svg";
import { ReactComponent as IconUser } from "../../Assets/Images/Icon-user.svg";

const PaymentAlert = styled.div`
  background: #fcc29b 0% 0% no-repeat padding-box;
  padding: 8px 18px;
  font-size: 14px;
  z-index: 9;
  width: 100%;

  * {
    text-align: left;
    font: italic normal bold 15px Fira Sans;
    letter-spacing: 0px;
    color: #0c1151;
    margin: auto;
  }
`;

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 90px;
  overflow-x: hidden;

  img {
    width: 100%;
  }

  @media (max-width: 992px) {
    margin-top: 100px;
  }

  @media (max-width: 698px) {
    margin-top: 20vw;
  }
`;

const SectionCards = styled.section`
  padding: 20px 0;
  position: relative;
  z-index: 3;

  .content {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 2.083vw;
  }

  .card {
    align-items: center;
    justify-content: center;
    display: flex;
    border: none;
    border-radius: 1.042vw;
    overflow: hidden;
    position: relative;
    max-height: 275px !important;
  }

  .card .item__hover {
    opacity: 0;
    transition: 0.5s ease;
    width: 100%;
    padding: 0;
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
  }

  .card:hover .item__hover {
    opacity: 1;
  }

  .card .item__content {
    position: absolute;
    bottom: 26px;
    text-decoration: none;
    color: #f88436;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.666vw;
    text-transform: uppercase;
    transition: 0.3s;

    .icon-lock {
      width: 3.5416vw;
    }

    .icon-user {
      margin-left: 10px;
      width: 5.052vw;
    }
  }

  .card:hover .item__content {
    color: #0c1151;

    path {
      fill: #0c1151;
    }
  }

  @media (max-width: 992px) {
    padding: 6.111vw 0;

    .content {
      grid-template-columns: 1fr;
      gap: 5.556vw;
    }

    .card {
      border-radius: 2.778vw;
    }

    .card .item__content {
      bottom: 15px;
      font-size: 10px;
      line-height: 14px;
      max-width: 90%;

      .icon-lock {
        width: 30px;
      }

      .icon-user {
        margin-left: 10px;
        width: 40px;
      }
    }
  }

  @media (min-width: 1920px) {
    padding: 2.083vw 0;

    .content {
      grid-template-columns: 3fr 1fr;
      gap: 60px;
    }

    .card .item__content {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

export default function Home(props) {
  const [pendentQuotas, setPendentQuotas] = useState(false);

  document.title = "SPAP - Home";
  const session_storage =
    localStorage.getItem("session_data") != null
      ? JSON.parse(localStorage.getItem("session_data"))
      : null;
  console.log(session_storage);
  useEffect(() => {
    if (session_storage?.all_data.user_session.id) {
      axios
        .get("https://spapapi.phormuladev.com/api/get-status-user", {
          params: {
            id: session_storage?.all_data.user_session.id,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            if (res.data.quotas.length > 0) {
              if (
                res.data.quotas.length == 1 &&
                res.data.quotas[0].tipo == "ESP"
              ) {
                setPendentQuotas(false);
              } else {
                setPendentQuotas(true);
              }
            }
            if (session_storage.all_data.role != "admin") {
              session_storage.all_data.user_session = res.data.user_session[0];
              session_storage.all_data.role =
                res.data.user_session[0].tipo_utilizador;
            }
          }
          localStorage.setItem("session_data", JSON.stringify(session_storage));
          console.log(session_storage);
        })
        .catch((error) => {});
    }
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <title>Sociedade Portuguesa de Anatomia Patológica - SPAP</title>
        <meta
          name="description"
          content="A SPAP é uma Associação de direito privado, de índole científica e cultural, sem fins lucrativos, que visa o aperfeiçoamento técnico-científico da especialidade de Anatomia Patológica bem como dos seus associados."
        />
        <meta
          name="keywords"
          content="spap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      {pendentQuotas && (
        <>
          <PaymentAlert style={{}}>
            <Space size={18}>
              <img
                src={IconWarning}
                alt="Pagamento pendente"
                style={{ width: "22px" }}
              />
              <p>
                As suas quotas não se encontram atualizadas. Por favor, entre em
                contacto connosco ou aceda a{" "}
                <Link to={"/socios/pagamentos"}>Pagamentos</Link>.
              </p>
            </Space>
          </PaymentAlert>
        </>
      )}
      <SectionCards>
        <Container className="content">
          <HomeCarousel></HomeCarousel>
          <FlexInRow gap="2.083vw" mobile="row">
            {session_storage == null ? (
              <Link to="/login" className="card card-1">
                <picture>
                  <source
                    srcSet={require("../../Assets/Images/Destaque-size-02-01@2x.png")}
                    media="(min-width: 990px)"
                  />
                  <img
                    src={require("../../Assets/Images/Destaque-size-02-01.png")}
                    alt="Faça login na sua conta"
                  />
                </picture>
                <div className="item__hover">
                  <picture>
                    <source
                      srcSet={require("../../Assets/Images/Destaque-size-1@2x.png")}
                      media="(min-width: 990px)"
                    />
                    <img
                      src={require("../../Assets/Images/Destaque-size-1.png")}
                      alt="Faça login na sua conta"
                    />
                  </picture>
                </div>
                <Space size={10} direction="vertical" className="item__content">
                  <IconLock className="icon icon-lock" />
                  <span>
                    Aceda a todo site <br />
                    faça login na sua conta
                  </span>
                </Space>
              </Link>
            ) : (
              <Link
                to={
                  session_storage && session_storage?.all_data.role == "admin"
                    ? "/dashboard"
                    : "/socios/area-de-socio"
                }
                className="card card-1"
              >
                <picture>
                  <source
                    srcSet={require("../../Assets/Images/background-home-profile@2x.png")}
                    media="(min-width: 990px)"
                  />
                  <img
                    src={require("../../Assets/Images/background-home-profile.png")}
                    alt="Visitar dashboard"
                  />
                </picture>
                <div className="item__hover">
                  <picture>
                    <source
                      srcSet={require("../../Assets/Images/background-home-profile-hover@2x.png")}
                      media="(min-width: 990px)"
                    />
                    <img
                      src={require("../../Assets/Images/Destaque-size-1.png")}
                      alt="Faça login na sua conta"
                    />
                  </picture>
                </div>
                <Space size={10} direction="vertical" className="item__content">
                  <IconLogin className="icon icon-login" />
                  <span>
                    {session_storage &&
                    session_storage?.all_data.role == "admin"
                      ? "Aceda ao painel administrativo"
                      : "Visualize a área de sócio"}
                  </span>
                </Space>
              </Link>
            )}
            <Link to="/socios/conheca-as-vantagens" className="card card-2">
              <picture>
                <source
                  srcSet={require("../../Assets/Images/Destaque-size-02-02@2x.png")}
                  media="(min-width: 990px)"
                />
                <img
                  src={require("../../Assets/Images/Destaque-size-02-02.png")}
                  alt="Torne-se sócio"
                />
              </picture>
              <div className="item__hover">
                <picture>
                  <source
                    srcSet={require("../../Assets/Images/Destaque-size-02-over@2x.png")}
                    media="(min-width: 990px)"
                  />
                  <img
                    src={require("../../Assets/Images/Destaque-size-02-over.png")}
                    alt="Torne-se sócio"
                  />
                </picture>
              </div>
              <Space direction="vertical" className="item__content">
                <IconUser className="icon icon-user" />
                <span>
                  Torne-se sócio <br />
                  conheça as vantagens
                </span>
              </Space>
            </Link>
          </FlexInRow>
        </Container>
      </SectionCards>
      <Suspense fallback={""}>
        <HighlightsCards title="Preparamos vários destaques a pensar em si" />
        {/* <HomeCalendar /> */}

        <SupportedBySpap />
      </Suspense>
    </Wrapper>
  );
}
