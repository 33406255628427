import { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Divider, Space, Button, Pagination, Typography } from "antd";
import Card from "../../../Components/Backoffice/Card";
import Title from "../../../Components/Backoffice/Title";

import { ReactComponent as IconOptions } from "../../../Assets/Images/Backoffice/Options.svg";
import { ReactComponent as IconSearch } from "../../../Assets/Images/Backoffice/Search.svg";
import { ReactComponent as IconUsersSolid } from "../../../Assets/Images/Backoffice/Users-Solid.svg";
import { ReactComponent as IconDigestiva } from "../../../Assets/Images/icon-digestiva.svg";

const { Text } = Typography;

const CardHeader = styled.div`
  align-items: center;
  display: grid;
  gap: 22px;
  grid-template-columns: 1fr auto auto;
`;

const Search = styled.div`
  position: relative;

  input {
    background-color: #f1f5f700;
    border: 2px solid #d9e1e7;
    border-radius: 16px;
    color: #8688a8;
    font-size: 16px;
    font-style: italic;
    letter-spacing: 0px;
    line-height: 18px;
    margin: 0;
    padding: 12px 58px 12px 26px;
    outline: 0;
  }

  .icon {
    position: absolute;
    right: 26px;
    top: 8px;
  }
`;

const CardContent = styled.div`
  padding: 25px 0;
`;
const Grid = styled.div`
  display: grid;
  gap: 18px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  .columnRow {
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    height: 100%;
    gap: 20px;
  }

  .item {
    background-color: #d6d7e2;
    border: none;
    border-radius: 16px;
    color: #8688a8;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 12px 0.625vw;
  }

  .space-gestor {
    min-height: 100px !important;
  }

  .item.disable {
    opacity: 0.5;
  }

  .item__image {
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: #f88436;
    border: 3px solid #0c1151;
    border-radius: 16px;
    display: flex;
    padding: 0px 1.4583vw;

    * {
      color: #0c1151;
      font-family: "Fira Sans";
      font-size: 20px;
      font-weight: bold;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .item__status {
    background-color: #ea3a3d;
    color: #ffffff;
    padding: 6px 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    place-self: flex-start;
    width: min-content;
  }

  .item__status.active {
    background-color: #1ad598;
  }

  .item__cta {
    margin-right: auto;
  }

  .link {
    text-decoration: none;
  }

  @media (max-width: 1280px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 990px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const ContentFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    font-size: 15px;
    font-weight: bold;
    height: 45px;
    line-height: 18px;
  }

  .link {
    text-decoration: none;
  }

  .cta {
    margin-left: 0;
  }
`;

export default function PreviewClubes() {
  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const [value, setValue] = useState("");
  const [clubes, setClubes] = useState([]);
  const [modalNovoClubeOpen, setModalNovoClubeOpen] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [currentItems, setCurrentItems] = useState(
    allItems?.length > 0 && allItems.slice(0, 4)
  );
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(4);

  useEffect(() => {
    fetch(`https://spapapi.phormuladev.com/api/all-membros-clubes`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          console.log("nenhum clube");
        }
        d;
        let allClubes = data.clubes;

        if (allClubes?.length > 0) {
          allClubes.forEach((clube) => {
            clube.icon = "";
            clube.title = clube.name;
            clube.status = clube.status;

            let membrosClube = data.membrosClubes?.filter((membrosClube) => {
              if (membrosClube.club_id == clube.id) {
                return membrosClube;
              }
            });

            clube.members = membrosClube.length;

            clube.gestores = [];

            membrosClube?.map((membro) => {
              data.users?.map((user) => {
                if (user.id == membro.user_id && membro.member_type == 2) {
                  let nome = user.name;
                  let apelido = user.apelido ? user.apelido : "";
                  clube.gestores.push(nome + " " + apelido);
                }
              });
            });

            clube.image = clube.url
              ? "https://spapapi.phormuladev.com/clubes/" + clube.url
              : "https://spapapi.phormuladev.com/clubes/clube-estudo-default.svg";
          });
        }

        setClubes(allClubes);
        setAllItems(allClubes);
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(allClubes?.slice(itemOffset, endOffset));
      })
      .catch((error) => console.error(error));
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
      if (windowWidth <= 1280) {
        if (windowWidth <= 990) {
          setItemsPerPage(4);
        } else {
          setItemsPerPage(4);
        }
        setAllItems(allItems);
      }
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const handlePage = (page) => {
    const newOffset = ((page - 1) * itemsPerPage) % allItems.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(allItems?.slice(itemOffset, endOffset));
  }, [itemOffset, allItems]);

  const handleSearch = (event) => {
    if (event.length == 0) {
      setAllItems(clubes);
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(allItems?.slice(itemOffset, endOffset));
      return;
    }

    let input = event.toLowerCase();

    let novosClubesAListar = clubes.filter((clube) => {
      if (clube.name.toLowerCase().includes(input)) {
        return clube;
      }
    });

    setAllItems(novosClubesAListar);
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(allItems?.slice(itemOffset, endOffset));
  };

  return (
    <Card>
      <CardHeader>
        <Title>Clubes de estudo</Title>
        <Search>
          <input
            type="text"
            placeholder="Procurar..."
            onChange={(e) => handleSearch(e.target.value)}
          />
          <IconSearch className="icon icon-search" />
        </Search>
        <IconOptions />
      </CardHeader>
      {currentItems?.length > 0 ? (
        <>
          <CardContent>
            <Grid>
              {currentItems.map((item, index) => {
                return (
                  <div
                    className={
                      item.status
                        ? "item item-" + index
                        : "item disable item-" + index
                    }
                    key={item.id}
                  >
                    <div className="columnRow">
                      <div>
                        {item.image && (
                          <div
                            className="item__image"
                            style={{
                              backgroundPosition: "center center",
                              backgroundImage: "url(" + item.image + ")",
                              backgroundSize: "cover",
                              height: "180px",
                            }}
                          >
                            <Space direction="vertical" align="center">
                              {/* <img src={item.image}  style={{ width: '100%', height:'100%', borderRadius: '15px'}}/> */}

                              <div
                                style={{
                                  position: "absolute",
                                  width: "100%",
                                  bottom: "0",
                                  left: "0%",
                                }}
                              >
                                {item.title}
                              </div>
                            </Space>
                          </div>
                        )}
                        <div className="item__name">
                          <Space direction="vertical" className="space-gestor">
                            <h5 style={{ paddingTop: "10px" }}>
                              GESTOR(ES) DO CLUBE
                            </h5>
                            {item.gestores.length >= 1 ? (
                              <>
                                <Space.Compact direction="vertical">
                                  {item.gestores.map((name, index) => {
                                    return <span key={index}>{name}</span>;
                                  })}
                                </Space.Compact>
                              </>
                            ) : (
                              <>
                                <Space.Compact direction="vertical">
                                  <span key={index}>Por atribuir</span>
                                </Space.Compact>
                              </>
                            )}
                          </Space>
                        </div>
                      </div>
                      <div>
                        <div
                          className={
                            item.status ? "item__status active" : "item__status"
                          }
                        >
                          {item.status ? "ATIVO" : "INATIVO"}
                        </div>
                        <div className="item__members">
                          <Space direction="vertical">
                            <h5>MEMBROS</h5>
                            <Space>
                              <IconUsersSolid /> {item.members}
                            </Space>
                          </Space>
                        </div>
                        <div className="item__cta">
                          <Link
                            to={"/dashboard/clubes/" + item.id}
                            className="link"
                          >
                            <Button type="primary" className="cta">
                              Gerir clube
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Grid>
          </CardContent>
        </>
      ) : (
        <>
          <p>Não foi encontrado nenhum clube.</p>
        </>
      )}
      <ContentFooter>
        <Link to={"/dashboard/clubes"} className="link">
          <Button type="primary" className="cta">
            Criar novo clube
          </Button>
        </Link>
        <Pagination
          defaultCurrent={1}
          total={allItems?.length}
          pageSize={itemsPerPage}
          onChange={handlePage}
        />
      </ContentFooter>
    </Card>
  );
}
