import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Divider, Space, Typography } from "antd";
import Card from "../../../Components/Backoffice/Card";
import Title from "../../../Components/Backoffice/Title";

import { ReactComponent as IconOptions } from "../../../Assets/Images/Backoffice/Options.svg";
import { ReactComponent as IconAnticorpos } from "../../../Assets/Images/Backoffice/Dashboard/Shortcuts/anticorpos.svg";
import { ReactComponent as IconCalendar } from "../../../Assets/Images/Backoffice/Dashboard/Shortcuts/calendar.svg";
import { ReactComponent as IconCC } from "../../../Assets/Images/Backoffice/Dashboard/Shortcuts/cc.svg";
import { ReactComponent as IconGraduate } from "../../../Assets/Images/Backoffice/Dashboard/Shortcuts/graduate.svg";
import { ReactComponent as IconUsers } from "../../../Assets/Images/Backoffice/Dashboard/Shortcuts/users.svg";
import { ReactComponent as IconWallet } from "../../../Assets/Images/Backoffice/Dashboard/Shortcuts/wallet.svg";
import Warnings from "../Warnings";

const { Text, Link } = Typography;

const CardHeader = styled.div`
  align-items: center;
  display: grid;
  gap: 60px;
  grid-template-columns: 1fr auto;
`;

const CardContent = styled.div`
  display: grid;
  gap: 25px;
  grid-template-columns: 20vw repeat(5, 1fr);

  .disabled {
    opacity: 0.6;
  }
  .item {
    border: 3px solid #d6d7e2;
    justify-content: center;
    border-radius: 16px;
    padding: 30px 20px;
    overflow: hidden;

    strong {
      color: #494d7d;
      font-size: 40px;
      letter-spacing: 0px;
    }
  }

  .item:first-child {
    padding: 0;

    > div:first-child {
      height: 100%;

      .bgGray {
        padding: 30px 20px;
        height: 100%;
      }
    }

    > div:nth-child(2) {
      padding: 30px 10px;
    }
  }

  .bgGray {
    background-color: #d6d7e2;
  }
`;

export default function DashboardCard() {
  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const [sociosTitulares, setSociosTitulares] = useState([]);
  const [associados, setAssociados] = useState([]);
  const [sociosHonorarios, setSociosHonorarios] = useState([]);
  const [sociosNaoDefinidos, setSociosNaoDefinidos] = useState([]);
  const [quotasPorPagar, setQuotasPorPagar] = useState([]);
  const [quotasAtualizadas, setQuotasAtualizadas] = useState([]);
  const [anticorpos, setAnticorpos] = useState([]);

  const [eventos, setEventos] = useState([]);
  useEffect(() => {
    fetch(`https://spapapi.phormuladev.com/api/all-dashboard-card-data`, {
      method: "GET",
    })
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          console.log("nenhum evento");
        }
        const socios = data.users?.filter((user) => {
          if (user.tipo_utilizador == "socio_aprovado") {
            return user;
          }
        });

        let socios_titulares = socios?.filter((user) => {
          if (
            user.tipo_socio == "Titular" ||
            user.tipo_socio == "SPAP" ||
            user.tipo_socio == "SPAP+ESP" ||
            user.tipo_socio == "Não definido"
          ) {
            return user;
          }
        });
        setSociosTitulares(socios_titulares);

        setAssociados(() => {
          let thisSocios = socios?.filter((user) => {
            // if((user.tipo_socio == 'Titular') || (user.tipo_socio == 'Honorário') || (user.tipo_socio == 'Não definido')) {
            if (
              user.tipo_socio == "Titular" ||
              user.tipo_socio == "Honorário" ||
              user.tipo_socio == "SPAP" ||
              user.tipo_socio == "SPAP+ESP" ||
              user.tipo_socio == "NISPAP" ||
              user.tipo_socio == "NISPAP+ESP" ||
              user.tipo_socio == "Não definido"
            ) {
              return user;
            }
          });
          return thisSocios;
        });

        let socios_honorarios = socios?.filter((user) => {
          if (user.tipo_socio == "Honorário") {
            return user;
          }
        });
        setSociosHonorarios(socios_honorarios);

        let socios_naodefinidos = data.users?.filter((user) => {
          if (user.tipo_socio == "Não definido") {
            return user;
          }
        });
        setSociosNaoDefinidos(socios_naodefinidos);

        let quotas_por_pagar = data.quotas?.filter((quota) => {
          if (quota.ano == new Date().getFullYear() && quota.status != "pago") {
            return quota;
          }
        });
        setQuotasPorPagar(quotas_por_pagar);

        let quotas_atualizadas = data.quotas?.filter((quota) => {
          if (quota.ano == new Date().getFullYear() && quota.status == "pago") {
            return quota;
          }
        });
        setQuotasAtualizadas(quotas_atualizadas);

        setEventos(data.eventos);
        setAnticorpos(data.anticorpos);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <Card>
      <CardHeader>
        <Title>Dashboard</Title>
        <IconOptions />
      </CardHeader>
      <Divider />
      <CardContent>
        <Space className="item" style={{ justifyContent: "flex-start" }}>
          <Space direction="vertical" className="bgGray" align="center">
            <IconUsers />
            <Text>Associados</Text>
            <Text strong>{associados.length}</Text>
          </Space>
          <Space direction="vertical" style={{ justifyContent: "center" }}>
            <Space.Compact direction="vertical" align="center">
              <Text>Sócios Titulares</Text>
              <Text strong align="center">
                {sociosTitulares.length}
              </Text>
            </Space.Compact>
            <Divider />
            <Space.Compact direction="vertical" align="center">
              <Text>Sócios Honorários</Text>
              <Text strong align="center">
                {sociosHonorarios.length}
              </Text>
            </Space.Compact>
          </Space>
        </Space>
        <Space direction="vertical" className="item " align="center">
          <IconWallet />
          <Text align="center">Quotas pagas</Text>
          <Text>{new Date().getFullYear()}</Text>
          <Text strong>{quotasAtualizadas.length}</Text>
        </Space>
        <Space direction="vertical" className="item " align="center">
          <IconCC />
          <Text>Quotas por pagar</Text>
          <Text>{new Date().getFullYear()}</Text>
          <Text strong>{quotasPorPagar.length}</Text>
        </Space>
        <Space direction="vertical" className="item disabled" align="center">
          <IconGraduate />
          <Text>Certificados</Text>
          <Text>{new Date().getFullYear()}</Text>
          <Text strong>0</Text>
        </Space>
        <Space direction="vertical" className="item " align="center">
          <IconCalendar />
          <Text>Eventos</Text>
          <Text>{new Date().getFullYear()}</Text>
          <Text strong>{eventos.length}</Text>
        </Space>
        <Space direction="vertical" className="item" align="center">
          <IconAnticorpos />
          <Text>Anticorpos</Text>
          <Text>{new Date().getFullYear()}</Text>
          <Text strong>{anticorpos.length}</Text>
        </Space>
      </CardContent>

      <Warnings />
    </Card>
  );
}
