import React, { useState, useEffect, react } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  Divider,
  Checkbox,
  Modal,
  Result,
  Input,
  Space,
  notification,
  Switch,
  Select,
  Row,
  Col,
  Button,
  Table,
  Upload,
} from "antd";
const { TextArea } = Input;
const { Dragger } = Upload;
import frontcss from "../../../../src/Assets/css/front.css";
import { ReactComponent as IconUpload } from "../../../Assets/Images/Backoffice/Upload-off.svg";
import { ReactComponent as IconDownloadOff } from "../../../Assets/Images/Backoffice/Download-off.svg";
import { ReactComponent as IconDownloadOn } from "../../../Assets/Images/Backoffice/Download-on.svg";

import Container from "../../../Components/Container";
import FlexInRow from "../../../Components/FlexInRow";
import FlexInColumn from "../../../Components/FlexInColumn";
import PageTitle from "../../../Components/PageTitle";
import SidebarMenu from "../../../Components/SidebarMenu";
import RecommendedLinks from "../../../Components/RecommendedLinks";

import BgAreaSocio from "../../../Assets/Images/bg-area-de-socio@2x.png";
import PageText from "../../../Components/PageText";

import axios from "axios";
import TableDocuments from "../../../Components/TableDocuments";
import UserUploadPayment from "../../../Components/UserUploadPayment/UserUploadPayment";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #000000;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  width: 100%;
  margin-top: 7.813vw;

  img {
    width: 100%;
  }

  .ant-space-item {
    color: #000;
  }

  @media (max-width: 992px) {
    margin-top: 120px;
  }

  h5 {
    text-align: left;
  }
`;

const LoginMessage = styled.span`
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;
`;

const SectionHighlightsCards = styled.section`
  padding: 20px 0;

  @media (max-width: 992px) {
    .sidebar-menu {
      margin: auto;
      place-self: center;
    }
  }
`;

const Title = styled.h2`
  color: #0c1151;
  font-size: 2.083vw;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 2.5vw;
  opacity: 1;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 992px) {
    font-size: 5.556vw;
    line-height: 6.667vw;
  }
`;

const HighlightsCards = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 5.938vw;
  grid-row-gap: 4.167vw;

  .card {
    border: none;
    border-radius: 1.042vw;
    overflow: hidden;
    position: relative;
    /* max-height: 17.188vw; */
  }

  .card .item__hover {
    opacity: 0;
    transition: 0.5s ease;
    width: 100%;
    padding: 0;
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
  }

  .card:hover .item__hover {
    opacity: 1;
  }
`;

const StudyActions = styled.section`
  background-image: url(${BgAreaSocio});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 11.198vw 0;
  margin-top: -11.198vw;

  .title {
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    line-height: inherit;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    margin-bottom: 1.042vw;
    padding-top: 5.599vw;
  }

  .text {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 1.875vw;
    width: 53.125vw;
  }

  a {
    background: #eeeeee 0% 0% no-repeat padding-box;
    border: 3px solid #0c1151;
    border-radius: 20px;
    padding: 20px;
    opacity: 1;
    text-align: center;
    font-size: 1.042vw;
    font-weight: bold;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #0c1151;
    text-decoration: none;
    text-transform: uppercase;
    flex-direction: column;
    opacity: 1;
    width: 13.542vw;
    min-height: 10.417vw;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.938vw;
    transition: 0.3s ease;

    img {
      width: 4.583vw;
    }
  }

  a.orange {
    background: #f88436 0% 0% no-repeat padding-box;
    border: 3px solid #0c1151;
    border-radius: 20px;
    opacity: 1;
  }

  a:hover {
    color: #0c1151;
    box-shadow: 0 8px 24px #00000055;
  }

  @media (max-width: 992px) {
    .text {
    }
  }

  @media (min-width: 1920px) {
    .text {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  ${(props) =>
    props.gap
      ? `
    gap: ${props.gap};
  `
      : `
    gap: 20px;
  `}

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    gap: 50px;
  }
`;

var styles = {
  h6_label: {
    color: "#809FB8",
    fontFamily: "Roboto",
    fontSize: "15px",
    marginBottom: "0",
  },
  h5_label: {
    fontFamily: "Fira Sans, Condensed",
    fontSize: "18px",
    color: "#0C1151",
    marginBottom: "0",
  },
  input_class: {
    width: "100%",
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #707070",
    color: "#0C1151",
  },
  switch_label: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    borderRadius: "8px",
    padding: "0px 16px",
    color: "#809FB8",
    border: "1px solid #809FB8",
    textAlign: "center",
  },
  upload: {
    width: "100%",
  },
  colPadding: {
    padding: "0px 10px",
  },
  input_class2: {
    width: "100%",
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #ccc",
    color: "#0C1151",
  },
};

export default function SociosQuotas(props) {
  const [api, contextHolder] = notification.useNotification();

  notification.config({
    placement: "bottomRight",
    bottom: 10,
    duration: 5,
    rtl: false,
  });

  const session_storage = JSON.parse(localStorage.getItem("session_data"));

  const [fileComprovativo, setfileComprovativo] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [dataToShow, setDataToShow] = useState([]);
  const [tipoSocio, setTipoSocio] = useState(
    session_storage.all_data.user_session.tipo_socio
  );
  const [numeroSocio, setNumeroSocio] = useState(
    session_storage.all_data.user_session.numero_socio
  );
  const [nome, setNome] = useState(session_storage.all_data.user_session.name);
  const [apelido, setApelido] = useState(
    session_storage.all_data.user_session.instituicao
  );
  const [instituicao, setInstituicao] = useState(
    session_storage.all_data.user_session.instituicao
  );
  const [instituicao2, setInstituicao2] = useState(
    session_storage.all_data.user_session.instituicao_2
  );
  const [grau, setGrau] = useState(session_storage.all_data.user_session.grau);
  const [grau2, setGrau2] = useState(
    session_storage.all_data.user_session.grau_2
  );
  const [area, setArea] = useState(
    session_storage.all_data.user_session.area_subespecializacao
  );
  const [area2, setArea2] = useState(
    session_storage.all_data.user_session.area_subespecializacao_2
  );
  const [telefone, setTelefone] = useState(
    session_storage.all_data.user_session.telefone
  );
  const [resumoProfissional, setResumoProfissional] = useState(
    session_storage.all_data.user_session.resumo_profissional
  );
  const [socioTitular, setSocioTitular] = useState(
    session_storage.all_data.user_session.socio_titular
  );
  const [orcid, setOrcid] = useState(
    session_storage.all_data.user_session.orcid
  );
  const [paginaWeb1, setPaginaWeb1] = useState(
    session_storage.all_data.user_session.pagina_web_1
  );
  const [paginaWeb2, setPaginaWeb2] = useState(
    session_storage.all_data.user_session.pagina_web_2
  );
  const [cartaoCidadao, setCartaoCidadao] = useState(
    session_storage.all_data.user_session.cartao_cidadao
  );
  const [numeroContribuinte, setNumeroContribuinte] = useState(
    session_storage.all_data.user_session.numero_contribuinte
  );
  const [morada, setMorada] = useState(
    session_storage.all_data.user_session.morada
  );
  const [codigoPostal, setCodigoPostal] = useState(
    session_storage.all_data.user_session.codigo_postal
  );
  const [pais, setPais] = useState(session_storage.all_data.user_session.pais);

  const [mostraApresentacaoPublico, setMostraApresentacaoPublico] = useState(
    session_storage.all_data.user_session.mostra_apresentacao_publico
  );
  const [mostraApresentacaoSocios, setMostraApresentacaoSocios] = useState(
    session_storage.all_data.user_session.mostra_apresentacao_socios
  );
  const [mostraDadosProfissionaisPublico, setMostraDadosProfissionaisPublico] =
    useState(
      session_storage.all_data.user_session.mostra_dados_profissionais_publico
    );
  const [mostraDadosProfissionaisSocios, setMostraDadosProfissionaisSocios] =
    useState(
      session_storage.all_data.user_session.mostra_dados_profissionais_socios
    );
  const [mostraDadosPublico, setMostraDadosPublico] = useState(
    session_storage.all_data.user_session.mostra_dados_publico
  );
  const [mostraDadosSocios, setMostraDadosSocios] = useState(
    session_storage.all_data.user_session.mostra_dados_socios
  );
  const [mostraResumoPublico, setMostraResumoPublico] = useState(
    session_storage.all_data.user_session.mostra_resumo_publico
  );
  const [mostraResumoSocios, setMostraResumoSocios] = useState(
    session_storage.all_data.user_session.mostra_resumo_socios
  );
  const [mostraLinksPublico, setMostraLinksPublico] = useState(
    session_storage.all_data.user_session.mostra_resumo_publico
  );
  const [mostraLinksSocios, setMostraLinksSocios] = useState(
    session_storage.all_data.user_session.mostra_resumo_socios
  );
  const [mostraSegundaOpiniao, setMostraSegundaOpiniao] = useState(
    session_storage.all_data.user_session.segunda_opiniao
  );

  const propComprovativoPDF = {
    onRemove: (file) => {
      const index = fileComprovativo.indexOf(file);
      const newFileList = fileComprovativo.slice();
      newFileList.splice(index, 1);
      setfileComprovativo(newFileList);
    },
    beforeUpload: (file) => {
      const isPDF = file.type === "application/pdf";
      if (!isPDF) {
        message.error(`${file.name} não é um ficheiro PDF`);
        setfileComprovativo([]);
        return isPDF || Upload.LIST_IGNORE;
      }

      setfileComprovativo([file]);
      return false;
    },
    fileComprovativo,
  };

  useEffect(() => {
    fetch(
      `https://spapapi.phormuladev.com/api/get-quotas-user/` +
        session_storage.all_data.user_session.id,
      {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          Authorization: "Bearer " + session_storage.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar quotas");
        }
        return response.json();
      })
      .then((data) => {
        getQuotas(data.quotas);
      })
      .catch((error) => console.error(error));
  }, []);

  const getDataToShow = (quotasAListar = []) => {
    if (quotasAListar?.length == 0) {
      return [];
    }
    let data = [];
    for (let i = 0; i < quotasAListar.length; i++) {
      data.push({
        key: i,
        tipo: <b>{quotasAListar[i].tipo}</b>,
        ano: <b>{quotasAListar[i].ano}</b>,
        data: (
          <b>
            {quotasAListar[i].payment_valid_date
              ? quotasAListar[i].payment_valid_date
              : "-"}
          </b>
        ),
        valor: <b>{quotasAListar[i].valor}</b>,
        status: quotasAListar[i].status,
        documentos: (
          <>
            <Space>
              {quotasAListar[i]?.payment_file_receipt && (
                <Button
                  target="_blank"
                  href={`https://spapapi.phormuladev.com/recibos/${quotasAListar[i]?.payment_file_receipt}`}
                >
                  Recibo
                </Button>
              )}
              {quotasAListar[i]?.payment_file_credit && (
                <Button
                  target="_blank"
                  href={`https://spapapi.phormuladev.com/notaCredito/${quotasAListar[i]?.payment_file_credit}`}
                >
                  Nota de crédito
                </Button>
              )}
              {quotasAListar[i]?.payment_file_proof && (
                <Button
                  target="_blank"
                  href={`https://spapapi.phormuladev.com/comprovantes/${quotasAListar[i]?.payment_file_proof}`}
                >
                  Comprovativo
                </Button>
              )}
            </Space>
          </>
        ),
        // recibo: quotasAListar[i]?.payment_file_receipt && <a href={`https://spapapi.phormuladev.com/recibos/${quotasAListar[i]?.payment_file_receipt}`} target="_blank" rel="noopener noreferrer"><IconUpload style={{ rotate: '180deg', cursor: 'pointer' }} /></a>
      });
    }

    console.log(data);

    return data;
  };

  const getQuotas = (allQuotas) => {
    allQuotas.forEach((quota) => {
      if (quota.status == "pago") {
        quota.status = "Pago";
      } else {
        quota.status = "Não pago";
      }
      if (Number(quota.valor)) {
        quota.valor = quota.valor + " €";
      } else {
        quota.valor = "N/A";
      }
    });
    const quotasOrdenadas = allQuotas?.sort((b, a) => a.ano - b.ano);
    setDataToShow(() => getDataToShow(quotasOrdenadas));
  };

  const [fileUpload, setFileUpload] = useState([]);

  const propUpload = {
    onRemove: (file) => {
      setFileUpload([]);
    },
    beforeUpload: (file) => {
      const isPDF = file.type === "application/pdf";
      if (!isPDF) {
        notification.error({
          description: "Apenas é possível fazer upload de arquivos PDF.",
        });
        setFileUpload([]);
        return Upload.LIST_IGNORE;
      }
      setFileUpload([file]);
    },
    fileUpload,
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editPaymentId, setEditPaymentId] = useState(null);
  const [uploading, setUploading] = useState(false);

  const showModal = (item) => {
    setEditPaymentId(item.id);
    setfileComprovativo([
      {
        id: "not_new",
        name: item.payment_file_proof,
      },
    ]);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (fileComprovativo[0].id === "not_new") return false;
    if (uploading) return false;

    const formData = new FormData();

    if (fileComprovativo?.length) {
      formData.append("file", fileComprovativo[0]);
    } else {
      return false;
    }

    formData.append("id", editPaymentId);

    setUploading(true);
    // You can use any AJAX library you
    fetch("https://spapapi.phormuladev.com/api/update-user-comprovante", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            setfileComprovativo([]);
            setIsModalVisible(false);
            setUploading(false);
            // TODO: talvez carregar valores pagamentos novamente aqui
          })
          .catch((err) => {});
      })
      .then(() => {
        setfileComprovativo([]);
        setIsModalVisible(false);
        setUploading(false);
        notification.success({
          description: "O comprovante foi atualizado com sucesso!",
        });
      })
      .catch(() => {
        notification.error({
          description: "Já existe um comprovante com este nome!",
        });
        setUploading(false);
      })
      .finally(() => {});
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "Tipo",
      dataIndex: "tipo",
    },
    {
      title: "Ano",
      dataIndex: "ano",
    },
    {
      title: "Valor",
      dataIndex: "valor",
    },
    {
      title: "Estado",
      dataIndex: "status",
    },
    {
      title: "Documentos",
      dataIndex: "documentos",
    },
  ];

  const SidebarMenuLinks = [
    {
      title: "Área pessoal",
      link: "/socios/area-de-socio",
    },
    {
      title: "Dados de perfil",
      link: "/socios/dados-de-perfil/",
    },
    {
      title: "Quotas",
      link: "/socios/quotas",
    },
    {
      title: "Pagamentos",
      link: "/socios/pagamentos",
    },
    {
      title: "Meus Eventos",
      link: "/socios/meus-eventos",
    },
    {
      title: "Mensagens",
      link: "/socios/mensagens",
    },
  ];

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <Wrapper>
      <Container>
        <Row gutter={[24, 24]}>
          <Col xl={19} xs={24}>
            <PageTitle
              title="Quotas"
              path={[
                {
                  text: "Homepage",
                  link: "/",
                },
                {
                  text: "Sócios",
                  link: "/socios",
                },
                {
                  text: "Área de sócio",
                  link: "/socios/area-de-socio",
                },
                {
                  text: "Quotas",
                },
              ]}
              imageDesktop={require("../../../Assets/Images/img-quotas@2x.png")}
              imageMobile={require("../../../Assets/Images/img-quotas@2x.png")}
              imageAlt="Sócios - Quotas"
            />
            <SectionHighlightsCards>
              <Grid>
                <FlexInRow gap="0">
                  {/* <PageText title="Aceda aqui às quotas" /> */}
                  <FlexInRow>
                    <TableDocuments
                      option="quotas"
                      dataSource={dataToShow}
                      columns={columns}
                    />
                    <Modal
                      title="Upload de Arquivo"
                      open={isModalVisible}
                      onOk={handleOk}
                      onCancel={handleCancel}
                    >
                      <Dragger
                        key={"comprovativo" + editPaymentId}
                        {...propComprovativoPDF}
                        fileList={fileComprovativo}
                        style={{ borderRadius: "12px" }}
                        accept="application/pdf"
                      >
                        <p className="ant-upload-text">
                          Faça o upload do comprovativo
                        </p>
                        <p className="ant-upload-hint">
                          Apenas ficheiros com formato PDF são permitidos
                        </p>
                      </Dragger>
                    </Modal>
                  </FlexInRow>
                </FlexInRow>
              </Grid>
            </SectionHighlightsCards>
          </Col>
          <Col xl={5} xs={24}>
            <SidebarMenu navItens={SidebarMenuLinks} alert={1} />
          </Col>
        </Row>
      </Container>

      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={[
          {
            title: "Lista de sócios",
            link: "/socios/lista-de-socios",
          },
          {
            title: "Pedir uma 2ª opinião",
            link: "/ferramentas-uteis/pedir-uma-segunda-opiniao",
          },
          {
            title: "Área pessoal",
            link: "/socios/area-de-socio",
          },
          {
            title: "Contactos",
            link: "/spap/contactos",
          },
        ]}
      />
    </Wrapper>
  );
}
