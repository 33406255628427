import styled from "styled-components";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import Container from "../../../../Components/Container";
import FlexInRow from "../../../../Components/FlexInRow";
import FlexInColumn from "../../../../Components/FlexInColumn";
import PageTitle from "../../../../Components/PageTitle";
import SupportedBySpap from "../../../../Components/SupportedBySpap";

import { Button, Divider, Space, Image, Row, Col } from "antd";
import JuntarseClube from "../../../../Components/JuntarseClube";
import SidebarMenu from "../../../../Components/SidebarMenu";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #000000;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: left;
  width: 100%;
  margin-top: 7.813vw;

  > .content {
    padding-bottom: 5.208vw;
  }

  img {
    width: 100%;
  }

  .link {
    color: ${(props) => props.theme.orange};
    transition: 0.3s ease;
  }

  .link:hover {
    color: ${(props) => props.theme.orangeHover};
  }

  .cta {
    background-color: ${(props) => props.theme.orange};
    border: none;
    color: ${(props) => props.theme.white};
    transition: 0.3s ease;
  }

  .cta:hover {
    background-color: ${(props) => props.theme.orangeHover};
    color: ${(props) => props.theme.whiteHover};
  }

  .sidebar__cta {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0.521vw;
    color: #0c1151;
    opacity: 1;
    text-decoration: none;
    text-align: center;
    letter-spacing: 0px;
    transition: 0.3s ease;
    font-size: 1.146vw;
    font-weight: bold;
    line-height: 1.51vw;
    border: 0.156vw solid #0c1151;
    padding: 0.781vw 1.563vw;
  }

  .sidebar__cta:hover {
    background-color: #0c1151;
    border-color: transparent;
    color: #e5e5e5;
  }

  .sidebar__cta.blue {
    background: #0c1151 0% 0% no-repeat padding-box;
    border: 0.156vw solid transparent;
    color: #ffffff;
  }

  .sidebar__cta.blue:hover {
    background-color: #8688a8;
    color: #e5e5e5;
  }

  .bar {
    height: 1px;
    width: 66.854vw;
    background-color: #d6cdf7;
    opacity: 1;
    margin: auto;
  }

  .manager__image {
    display: block;
    width: 89px;
  }

  .row-wrap {
    flex-wrap: wrap !important;
  }

  @media (max-width: 992px) {
    margin-top: 120px;

    span {
      line-height: 14px;
    }
  }
`;

const Title = styled.h2`
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;

  @media (max-width: 992px) {
    font-size: 3.333vw;
    line-height: 4.444vw;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 80px;

  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export default function ClubeNovoMembros(props) {
  const { slug } = useParams();
  const [clube, setClube] = useState("");
  const [membrosClube, setMembrosClube] = useState("");
  const [gestoresClube, setGestoresClube] = useState("");

  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const [noResults, setNoResults] = useState("");

  useEffect(() => {
    console.log(slug);
    fetch(
      `https://spapapi.phormuladev.com/api/get-clube/` + slug.split("-").pop(),
      {
        method: "GET",
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          console.log("nenhum clube");
          setNoResults("Nenhum membro associado");
        }
        getClube(data.clube);
        getMembrosClube(data.membrosClube, data.users);
        getGestoresClube(data.membrosClube, data.users);
      })
      .catch((error) => console.error(error));
  }, []);

  const getMembrosClube = (membros, users) => {
    let membrosDesteClube = [];
    membros.length > 0 &&
      membros.map((membro) => {
        users &&
          users.length > 0 &&
          users.map((user) => {
            if (user.id == membro.user_id && membro.member_type == 1) {
              membrosDesteClube.push(user);
            }
          });
      });

    setMembrosClube(membrosDesteClube);
  };

  const getClube = (thisClub) => {
    let myClube = thisClub[0];
    myClube.url = myClube.url
      ? "https://spapapi.phormuladev.com/clubes/" + myClube.url
      : "https://spapapi.phormuladev.com/clubes/clube-estudo-default.svg";

    setClube(myClube);
  };
  const getGestoresClube = (gestores, users) => {
    let gestoresDesteClube = [];
    gestores.sort((a, b) => a.ordem - b.ordem);
    gestores &&
      gestores.length > 0 &&
      gestores.map((gestor) => {
        users &&
          users.length > 0 &&
          users.map((user) => {
            if (user.id == gestor.user_id && gestor.member_type == 2) {
              gestoresDesteClube.push(user);
            }
          });
      });

    setGestoresClube(gestoresDesteClube);
  };

  const SidebarMenuLinks = [
    {
      title: clube.name,
      link: "/socios/clubes-de-estudo/" + slug,
    },
    {
      title: "Membros",
      link: "/socios/clubes-de-estudo/" + slug + "/membros",
    },
    {
      title: "Eventos",
      link: "/socios/clubes-de-estudo/" + slug + "/eventos",
    },
    {
      title: "Galeria",
      link: "/socios/clubes-de-estudo/" + slug + "/galeria",
    },
    {
      title: "Documentos",
      link: "/socios/clubes-de-estudo/" + slug + "/documentos",
    },
  ];

  const setCapitalize = (str) => {
    var splitStr = str.toLowerCase().split(" ");

    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you

      // Assign it back to the array

      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }

    // Directly return the joined string

    return splitStr.join(" ");
  };

  return (
    <Wrapper>
      <Container className="content">
        <PageTitle
          title={clube.name}
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "Sócios",
              link: "/socios",
            },
            {
              text: "Clubes de estudo",
              link: "/socios/clubes-de-estudo",
            },
            {
              text: clube.name,
              link: "/socios/clubes-de-estudo/" + slug + "",
            },
            {
              text: "Membros",
            },
          ]}
          imageDesktop={clube.url}
          imageMobile={clube.url}
          imageAlt={clube.name}
          border={false}
          sidebar={
            <SidebarMenu
              navItens={SidebarMenuLinks}
              className="sidebar"
              margin="-120px"
            />
          }
          clubes={true}
        />

        <FlexInRow>
          <Row gutter={[30, 8]}>
            <Col span={19}>
              {gestoresClube.length > 0 || membrosClube.length > 0 ? (
                <Space direction="vertical" style={{ width: "100%" }}>
                  {gestoresClube.length > 0 && (
                    <>
                      <Divider orientation="left" orientationMargin="0">
                        <Title>Equipa coordenadora</Title>
                      </Divider>

                      <Row
                        align="initial"
                        className="row-wrap"
                        gutter={[20, 20]}
                      >
                        {gestoresClube.map((item, index) => {
                          return (
                            <Col xs={24} lg={8} md={8} key={index}>
                              <Space align="start">
                                {item.avatar ? (
                                  <>
                                    <div
                                      style={{
                                        borderRadius: "7px",
                                        border: "3px solid #0C1151",
                                        backgroundPosition: "center center",
                                        backgroundSize: "cover",
                                        backgroundImage:
                                          "url(https://spapapi.phormuladev.com/avatars/" +
                                          item.avatar +
                                          ")",
                                        width: "85px",
                                        height: "85px",
                                      }}
                                    ></div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      style={{
                                        borderRadius: "7px",
                                        border: "3px solid #0C1151",
                                        backgroundPosition: "center center",
                                        backgroundSize: "contain",
                                        backgroundImage: `url(https://spapapi.phormuladev.comsocio-default.svg)`,
                                        width: "85px",
                                        height: "85px",
                                      }}
                                    ></div>
                                  </>
                                )}
                                <Space direction="vertical">
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {item.name}{" "}
                                    {item.apelido ? item.apelido : ""}
                                  </span>
                                  <span style={{ fontSize: "12px" }}>
                                    {setCapitalize(item.instituicao)}
                                  </span>
                                </Space>
                              </Space>
                            </Col>
                          );
                        })}
                      </Row>
                    </>
                  )}

                  <Divider orientation="left" orientationMargin="0">
                    <Title Title>Membros</Title>
                  </Divider>
                  <Row align="initial" className="row-wrap" gutter={[20, 20]}>
                    {membrosClube.length > 0 ? (
                      membrosClube.map((item, index) => {
                        return (
                          <Col xs={24} lg={8} md={8}>
                            <Space align="start">
                              {item.avatar ? (
                                <>
                                  <div
                                    style={{
                                      borderRadius: "7px",
                                      border: "3px solid #0C1151",
                                      backgroundPosition: "center center",
                                      backgroundSize: "cover",
                                      backgroundImage:
                                        "url(https://spapapi.phormuladev.com/avatars/" +
                                        item.avatar +
                                        ")",
                                      width: "85px",
                                      height: "85px",
                                    }}
                                  ></div>
                                </>
                              ) : (
                                <>
                                  <div
                                    style={{
                                      borderRadius: "7px",
                                      border: "3px solid #0C1151",
                                      backgroundPosition: "center center",
                                      backgroundSize: "contain",
                                      backgroundImage: `url(https://spapapi.phormuladev.comsocio-default.svg)`,
                                      width: "85px",
                                      height: "85px",
                                    }}
                                  ></div>
                                </>
                              )}
                              {/* <picture className='manager__image'>
                      <source
                        srcSet={ item.avatar ? 'https://spapapi.phormuladev.com/avatars/' + item.avatar : require('../../../../Assets/Images/foto-default@2x.png')}
                        media='(min-width: 990px)'
                      />
                      <img
                        src={ item.avatar ? 'https://spapapi.phormuladev.com/avatars/' + item.avatar : require('../../../../Assets/Images/foto-default@2x.png')}
                        alt={item.name}
                      />
                    </picture> */}
                              <Space direction="vertical">
                                <span
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item.name} {item.apelido ? item.apelido : ""}
                                </span>
                                <span style={{ fontSize: "12px" }}>
                                  {item.instituicao}
                                </span>
                              </Space>
                            </Space>
                          </Col>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </Row>
                </Space>
              ) : (
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Divider orientation="left" orientationMargin="0">
                    <Title>{noResults}</Title>
                  </Divider>
                </Space>
              )}
            </Col>
          </Row>
        </FlexInRow>
      </Container>

      <SupportedBySpap title="Veja Também" />
    </Wrapper>
  );
}
