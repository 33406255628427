import { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Space, Row, Col } from "antd";
import Container from "../../Components/Container";
import FlexInRow from "../../Components/FlexInRow";
import FlexInColumn from "../../Components/FlexInColumn";
import PageTitle from "../../Components/PageTitle";
import Newsletter from "../../Components/Newsletter";
import SidebarMenu from "../../Components/SidebarMenu";
import urlSlug from "url-slug";
import BgAreaSocio from "../../Assets/Images/bg-area-de-socio@2x.png";
import IconPatologiaDigestiva from "../../Assets/Images/icon-digestiva.svg";
import IconPatologiaFetoplacentar from "../../Assets/Images/icon-patologia-fetoplacentar.svg";
import IconVerTodosClubes from "../../Assets/Images/icon-ver-todos-os-clubes.svg";
import { ReactComponent as IconArrow } from "../../Assets/Images/icon-arrow.svg";
import { Helmet } from "react-helmet";
const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  img {
    width: 100%;
  }

  @media (max-width: 992px) {
    margin-top: 120px;
  }
`;

const LoginMessage = styled.span`
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;

  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (min-width: 1920px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

const SectionHighlightsCards = styled.section`
  padding: 0 0 3.646vw;

  @media (max-width: 992px) {
    .sidebar-menu {
      margin: auto;
      place-self: center;
    }
  }
`;

const Title = styled.h2`
  color: #0c1151;
  font-size: 2.083vw;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 2.5vw;
  opacity: 1;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 992px) {
    font-size: 5.556vw;
    line-height: 6.667vw;
  }

  @media (min-width: 1920px) {
    font-size: 40px;
    line-height: 48px;
  }
`;

const HighlightsCards = styled.div`
  display: grid;
  grid-template-columns: 15.625vw 15.625vw 15.625vw;
  grid-column-gap: 5.938vw;
  grid-row-gap: 4.167vw;

  .card {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    border: none;
    border-radius: 1.042vw;
    overflow: hidden;
    position: relative;
    /* max-height: 17.188vw; */
  }

  .card .item__hover {
    opacity: 0;
    transition: 0.5s ease;
    width: 100%;
    padding: 0;
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
  }

  .card:hover .item__hover {
    opacity: 1;
  }

  .card .item__content {
    position: absolute;
    bottom: 0;
    text-decoration: none;
    color: #f88436;
    font-size: 1.08vw;
    font-weight: bold;
    line-height: 1.666vw;
    text-transform: uppercase;
    transition: 0.3s;
    padding: 1.1416vw 0.9416vw;
    width: 100%;
    transition: 0.3s ease;
    text-align: left;

    .icon {
      width: 1.822vw;
      transition: 0.3s ease;
    }
  }

  .card:hover .item__content {
    color: #0c1151;

    path {
      fill: #0c1151;
    }
  }

  @media (max-width: 992px) {
    grid-template-columns: auto auto;
    grid-column-gap: 2.778vw;
    grid-row-gap: 2.778vw;
    position: relative;
    z-index: 3;

    .card {
      border-radius: 2.778vw;
    }

    .card .item__content {
      bottom: 15px;
      font-size: 12px;
      line-height: 16px;
      padding: 10px 20px;

      .icon {
        width: 30px;
      }
    }
  }

  @media (min-width: 1920px) {
    grid-column-gap: 110px;
    grid-row-gap: 110px;
    grid-template-columns: 300px 300px 300px;

    .card .item__content {
      font-size: 23px;
      line-height: 32px;
      padding: 26px 20px;
      text-align: left;
    }

    .card .item__content .icon {
      width: 35px;
    }
  }
`;

const StudyActions = styled.section`
  background-image: url(${BgAreaSocio});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 11.198vw 0;
  margin-top: -11.198vw;

  .title {
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    line-height: inherit;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    margin-bottom: 1.042vw;
    padding-top: 5.599vw;
  }

  .text {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 1.875vw;
    width: 53.125vw;
  }

  a {
    background: #eeeeee 0% 0% no-repeat padding-box;
    border: 3px solid #0c1151;
    border-radius: 20px;
    padding: 20px;
    opacity: 1;
    text-align: center;
    font-size: 1.042vw;
    font-weight: bold;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #0c1151;
    text-decoration: none;
    text-transform: uppercase;
    flex-direction: column;
    opacity: 1;
    width: 13.542vw;
    min-height: 10.417vw;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.938vw;
    transition: 0.3s ease;

    img {
      width: 4.583vw;
    }
  }

  a.orange {
    background: #f88436 0% 0% no-repeat padding-box;
    border: 3px solid #0c1151;
    border-radius: 20px;
    opacity: 1;
  }

  a:hover {
    color: #0c1151;
    box-shadow: 0 8px 24px #00000055;
  }

  @media (max-width: 992px) {
    gap: 20px;

    .title {
      font-size: 18px;
      line-height: 22px;
    }

    .text {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }

    a {
      font-size: 12px;
      line-height: 16px;
      width: 130px;

      img {
        width: 40px;
      }
    }
  }

  @media (min-width: 1920px) {
    .title {
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 20px;
      padding-top: 50px;
      text-transform: uppercase;
    }

    .text {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  ${(props) =>
    props.gap
      ? `
    gap: ${props.gap};
  `
      : `
    gap: 5.208vw;
  `}

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    gap: 50px;
  }
`;

export default function Socios(props) {
  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const [clubes, setClubes] = useState([]);

  const checkUserEnrolled = (membrosClubes) => {
    let myUser = session_storage.all_data.user_session;
    let encontrouUser = false;
    if (membrosClubes.length > 0) {
      membrosClubes.map((membro) => {
        if (membro.user_id == myUser.id) {
          encontrouUser = true;
        }
      });
      if (encontrouUser) return true;
    }
    return false;
  };

  useEffect(() => {
    const fetchClubes = async () => {
      try {
        const response = await fetch(
          `https://spapapi.phormuladev.com/api/all-clubes`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + session_storage?.token,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        const data = await response.json();
        if (data.clubes.length === 0) {
          return;
        }
        const dataClubes = data.clubes;
        const userClubes = [];

        const checkUserEnrolled = (membrosClubes) => {
          let myUser = session_storage.all_data.user_session;
          let encontrouUser = false;
          if (membrosClubes.length > 0) {
            membrosClubes.map((membro) => {
              if (membro.user_id == myUser.id) {
                encontrouUser = true;
              }
            });
            if (encontrouUser) return true;
          }
          return false;
        };

        const promises = dataClubes.map(async (clube) => {
          const clubeResponse = await fetch(
            `https://spapapi.phormuladev.com/api/get-clube/` + clube.id,
            {
              method: "GET",
            }
          );
          if (!clubeResponse.ok) {
            throw new Error("Erro ao buscar eventos");
          }
          const clubeData = await clubeResponse.json();
          if (clubeData.length === 0) {
            console.log("nenhum clube");
          }
          const check = checkUserEnrolled(clubeData.membrosClube);
          if (check) {
            console.log(clube);
            clube.slug = urlSlug(clube.name);
            clube.slug += "-" + clube.id;
            clube.url = clube.url
              ? "https://spapapi.phormuladev.com/clubes/" + clube.url
              : "https://spapapi.phormuladev.com/clubes/clube-estudo-default.svg";
            userClubes.push(clube);
          }
        });

        await Promise.all(promises);

        setClubes(userClubes);
      } catch (error) {
        console.error(error);
      }
    };

    fetchClubes();
  }, []);

  const HighlightsCardsLinks = [
    {
      link: "/socios/lista-de-socios",
      title: "Lista de Sócios",
      desktop: require("../../Assets/Images/Destaque-socio-03@2x.png"),
      mobile: require("../../Assets/Images/Destaque-socio-03-over.png"),
      desktopHover: require("../../Assets/Images/Destaque-socio-03-over@2x.png"),
      mobileHover: require("../../Assets/Images/Destaque-socio-03-over.png"),
      alt: "Card 1",
      width: "",
    },
    {
      link: "/ferramentas-uteis/pedir-uma-segunda-opiniao",
      title: "Pedir uma 2ª opinião",
      desktop: require("../../Assets/Images/Destaque-socio-05@2x.png"),
      mobile: require("../../Assets/Images/Destaque-socio-05.png"),
      desktopHover: require("../../Assets/Images/Destaque-socio-05-over@2x.png"),
      mobileHover: require("../../Assets/Images/Destaque-socio-05-over.png"),
      alt: "Card 5",
      width: "",
    },
    {
      link: "/ferramentas-uteis/pesquisar-um-anticorpo",
      title: "Pesquisar um anticorpo",
      desktop: require("../../Assets/Images/Destaque-size-03-06@2x.png"),
      mobile: require("../../Assets/Images/Destaque-size-03-06.png"),
      desktopHover: require("../../Assets/Images/Destaque-size-03-06-over@2x.png"),
      mobileHover: require("../../Assets/Images/Destaque-size-03-06-over.png"),
      alt: "Card 6",
      width: "",
    },
    {
      link: "/spap/estatutos-e-documentos",
      title: "Documentos da SPAP",
      desktop: require("../../Assets/Images/Destaque-socio-04@2x.png"),
      mobile: require("../../Assets/Images/Destaque-socio-04.png"),
      desktopHover: require("../../Assets/Images/Destaque-socio-04-over@2x.png"),
      mobileHover: require("../../Assets/Images/Destaque-socio-04-over.png"),
      alt: "Card 4",
      width: "",
    },
    {
      link: "/ferramentas-uteis/guidelines",
      title: "Guidelines",
      desktop: require("../../Assets/Images/Destaque-socio-01@2x.png"),
      mobile: require("../../Assets/Images/Destaque-socio-01.png"),
      desktopHover: require("../../Assets/Images/Destaque-socio-01-over@2x.png"),
      mobileHover: require("../../Assets/Images/Destaque-socio-01-over.png"),
      alt: "Card 3",
      width: "",
    },
    {
      link: "/ferramentas-uteis/formacao",
      title: "Formação",
      desktop: require("../../Assets/Images/Destaque-formacao-02@2x.png"),
      mobile: require("../../Assets/Images/Destaque-formacao-02.png"),
      desktopHover: require("../../Assets/Images/Destaque-formacao-02-over@2x.png"),
      mobileHover: require("../../Assets/Images/Destaque-formacao-02-over.png"),
      alt: "Card 2",
      width: "",
    },
  ];

  const SidebarMenuLinks = [
    {
      title: "Área pessoal",
      link: "/socios/area-de-socio",
    },
    {
      title: "Dados de perfil",
      link: "/socios/dados-de-perfil/",
    },
    {
      title: "Quotas",
      link: "/socios/quotas",
    },
    {
      title: "Pagamentos",
      link: "/socios/pagamentos",
    },
    {
      title: "Meus Eventos",
      link: "/socios/meus-eventos",
    },
    {
      title: "Mensagens",
      link: "/socios/mensagens",
    },
  ];

  return (
    <Wrapper>
      <Helmet>
        <title>
          Área de Sócio | Sociedade Portuguesa de Anatomia Patológica - SPAP
        </title>
        <meta
          name="description"
          content="Seja bem-vindo à sua área pessoal de sócio SPAP / NISPAP"
        />
        <meta
          name="keywords"
          content="spap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <Container>
        <Row gutter={[24, 24]}>
          <Col xl={19} xs={24}>
            <PageTitle
              title="ÁREA DE SÓCIO"
              path={[
                {
                  text: "Homepage",
                  link: "/",
                },
                {
                  text: "Sócios",
                  link: "/socios",
                },
                {
                  text: "Área de sócio",
                },
              ]}
              imageDesktop={require("../../Assets/Images/img-area-de-socio@2x.png")}
              imageMobile={require("../../Assets/Images/img-area-de-socio.png")}
              imageAlt="Sócios"
            />
            <SectionHighlightsCards>
              <Grid>
                <FlexInRow gap="0px">
                  <FlexInRow gap="0">
                    {session_storage && (
                      <>
                        <LoginMessage style={{ marginTop: "10px" }}>
                          Olá, {session_storage.all_data.user_session.name}!
                        </LoginMessage>
                      </>
                    )}
                    <LoginMessage>
                      Seja bem-vindo à sua área pessoal de sócio.
                    </LoginMessage>
                  </FlexInRow>
                  <Title style={{ marginTop: "40px" }}>
                    ÁREAS QUE PODERÃO SER DO SEU INTERESSE
                  </Title>
                  <HighlightsCards>
                    {HighlightsCardsLinks.map((item, index) => {
                      return (
                        <Link
                          to={item.link}
                          className={"card card-" + (index + 1)}
                        >
                          <picture>
                            <source
                              srcSet={item.desktop}
                              media="(min-width: 990px)"
                            />
                            <img
                              src={item.mobile}
                              alt={item.alt}
                              width={item.width}
                            />
                          </picture>
                          <div className="item__hover">
                            <picture>
                              <source
                                srcSet={item.desktopHover}
                                media="(min-width: 990px)"
                              />
                              <img
                                src={item.mobileHover}
                                alt={item.alt}
                                width={item.width}
                              />
                            </picture>
                          </div>
                          <Space className="item__content">
                            <IconArrow className="icon" />
                            <span>{item.title}</span>
                          </Space>
                        </Link>
                      );
                    })}
                  </HighlightsCards>
                </FlexInRow>
              </Grid>
            </SectionHighlightsCards>
          </Col>
          <Col xl={5} xs={24}>
            <SidebarMenu navItens={SidebarMenuLinks} alert={1} />
          </Col>
        </Row>
      </Container>

      <StudyActions>
        <Container>
          <h2 className="title">Os seus clubes de estudo</h2>
          <FlexInColumn gap="20px" className="boas">
            {clubes.length > 0 &&
              clubes.map((item, index) => {
                return (
                  <Link
                    to={"/socios/clubes-de-estudo/" + item.slug}
                    className="orange"
                    style={{
                      padding: "10px",
                      backgroundImage: "url(" + item.url + ")",
                      backgroundSize: "cover",
                    }}
                  >
                    {/* <img src={IconPatologiaDigestiva} alt="Patalogia Digestiva" /> */}
                    <span style={{ padding: "5px" }}>{item.name}</span>
                  </Link>
                );
              })}
            <Link to="/socios/clubes-de-estudo">
              <img
                src={IconVerTodosClubes}
                alt="Ver todos os Clubes"
                width="5.938vw"
              />
              <span>VER TODOS OS CLUBES DE ESTUDO</span>
            </Link>
          </FlexInColumn>
        </Container>
      </StudyActions>

      <Newsletter />
    </Wrapper>
  );
}
