import React, { useState, useEffect, Suspense } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";
import Container from "../../Components/Container";
import FlexInRow from "../../Components/FlexInRow";
import FlexInColumn from "../../Components/FlexInColumn";
const SupportedByNispap = React.lazy(() =>
  import("../../Components/SupportedByNispap")
);
import { Space } from "antd";
import { ReactComponent as IconEmail } from "../../Assets/Images/Backoffice/email.svg";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  .sc-blnsrp .container {
    margin-left: 0 !important;
  }

  .carousel,
  .carousel-inner,
  .carousel-item {
    background-color: #225e79;
    border: none;
    overflow: hidden;
    height: 100%;
  }

  .carousel {
    height: 80vh;
  }

  .carousel {
    align-items: center;
    display: flex;
  }

  .imagem-fundo {
    background-position: center center;
    background-size: cover !important;
  }

  .carousel-control-next,
  .carousel-control-prev {
    bottom: inherit;
    top: inherit;
    height: 2rem;
    width: 2rem;
    color: #f88436;
    font-size: 3.125vw;
    opacity: 1;
  }

  .imagem-fundo-unico .carousel-control-next,
  .imagem-fundo-unico .carousel-control-prev {
    display: none;
  }

  .carousel-control-prev {
    left: 1.563vw;
  }

  .carousel-control-next {
    right: 1.563vw;
  }

  .carousel-item .item__hover {
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
  }

  .carousel .item__title,
  .carousel .item__subtitle {
    text-align: center;
    font-size: 4.167vw;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 5vw;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
  }

  .carousel .item__title {
    margin: 0;
  }

  .carousel .item__subtitle {
    margin-bottom: 2.083vw;
  }

  .carousel .item__text {
    margin: 0;
    text-align: center;
    font-size: 1.563vw;
    letter-spacing: 0px;
    line-height: 1.875vw;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
  }

  .carousel .item__cta {
    background: #f88436 0% 0% no-repeat padding-box;
    border-radius: 26.042vw;
    color: #ffffff;
    font-size: 1.146vw;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 1.51vw;
    opacity: 1;
    padding: 0.781vw 1.563vw;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.3s ease;
    max-width: 21.094vw;
    margin: 4vw auto 0;
  }

  .carousel .item__cta:hover {
    background-color: #e87426;
    color: #ececec;
  }

  #social-wall {
    align-items: center;
    bottom: 100px;
    display: flex;
    left: 200px;
    position: absolute;
    gap: 30px;

    path {
      color: #fff;
      fill: #fff;
    }
  }

  @media (max-width: 992px) {
    margin-top: 47px;

    .carousel {
      height: 70vh;
    }

    .carousel .item__title,
    .carousel .item__subtitle {
      font-size: 28px;
      line-height: 22px;
    }

    .carousel .item__text {
      font-size: 18px;
      line-height: 26px;
      width: 80%;
      margin: auto;
    }

    .carousel .item__cta {
      font-size: 14px;
      line-height: 20px;
      padding: 10px 15px;
      margin-bottom: -9.813vw;
      max-width: 260px;
    }

    #social-wall {
      bottom: 50px;
      left: calc(50% - 45px);
    }
  }

  @media (min-width: 1920px) {
    #social-wall {
      left: 300px;
      gap: 60px;

      > a {
        scale: 1.5;
      }
    }
  }
`;

const CarouselImage = styled.img`
  width: 100%;
  transition: 0.5s ease;
`;

const Title = styled.h2`
  color: ${(props) => props.theme.blue};
  font-size: 2.083vw;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 2.5vw;
  opacity: 1;
  text-align: center;
  text-transform: uppercase;
  width: calc(100vw - 14.584vw);

  @media (max-width: 992px) {
    font-size: 5.556vw;
    line-height: 6.667vw;
    width: 100%;
  }

  @media (min-width: 1920px) {
    width: auto;
  }
`;

const Subtitle = styled.h3`
  color: ${(props) => props.theme.blue};
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: inherit;
  opacity: 1;
  text-align: center;
  width: calc(100vw - 14.584vw);

  @media (max-width: 992px) {
    font-size: 3.333vw;
    line-height: 4.444vw;
    width: 100%;
  }

  @media (min-width: 1920px) {
    width: auto;
  }
`;

const SectionOnDemand = styled.section`
  background-color: #cbe0ea;
  padding: 3.646vw 0;

  .item {
    margin: auto;
    transition: 0.3s ease;
    width: 23.438vw;
    border-radius: 12px;
    overflow: hidden;

    img {
      width: 100%;
    }

    :hover {
      box-shadow: 0px 12px 50px #00000025;
    }
  }

  @media (max-width: 992px) {
    .item {
      margin-bottom: 30px;
      width: 220px;
    }
  }
`;

const SectionNews = styled.section`
  background-color: ${(props) => props.theme.blue};
  background-image: url(${require("../../Assets/Images/NISPAP/bg-novidades@2x.png")});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5.208vw 0;

  .title,
  .subtitle {
    color: #ffffff;
    text-align: left;
  }

  .item {
    text-decoration: none;
  }

  .item__image {
    margin: auto;
    width: 6.25vw;

    img {
      width: 100%;
    }
  }

  .item__text {
    color: #ffffff;
    font-size: 1.146vw;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 1.51vw;
    text-align: center;
  }

  @media (max-width: 992px) {
    .item {
      margin-top: 40px;
    }

    .item__image {
      width: 80px;
    }

    .item__text {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }
  }
`;

const Logo = styled.img`
  width: 5vw;
  width: ${(props) => props.width};
`;

export default function NSPAP(props) {
  document.title = "NISPAP - Home";
  const SocialLinks = [
    {
      link: "https://www.instagram.com/nucleointernosdaspap/",
      mobile: require("../../Assets/Images/Icon-social-instagram-botton.png"),
      desktop: require("../../Assets/Images/Icon-social-instagram-botton@2x.png"),
      alt: "Instagram Icon",
      width: "25px",
    },
    {
      link: "mailto:nucleointernosspap@gmail.com",
      svg: <IconEmail />,
      alt: "Email Icon",
      width: "30px",
    },
  ];

  const [banners, setBanners] = useState([]);
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 992px)").matches
  );

  const getBannersOrder = (banners) => {
    let nispapBanners = banners?.filter((banner) => {
      if (banner.type == 1) return banner;
    });
    nispapBanners.sort((a, b) => a.ordem - b.ordem);
    return nispapBanners;
  };

  useEffect(() => {
    fetch(`https://spapapi.phormuladev.com/api/all-banners`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar banners");
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          console.log("nenhum evento");
        }
        setBanners(() => getBannersOrder(data.banners));
      })
      .catch((error) => console.error(error));
  }, []);

  const CarouselItens = [
    {
      link: "/nispap/sobre-nos/quem-somos",
      linkText: "Saiba mais sobre NISPAP",
      title: "NISPAP",
      subtitle: "NÚCLEO DE INTERNOS",
      text: "Sociedade Portuguesa de Anatomia Patológica",
      desktop: require("../../Assets/Images/banner-home-01@2x.png"),
      mobile: require("../../Assets/Images/banner-home-01.png"),
      alt: "Carousel 1",
      width: "",
    },
    {
      link: "/nispap/sobre-nos/quem-somos",
      linkText: "Saiba mais sobre NISPAP",
      title: "NISPAP",
      subtitle: "NÚCLEO DE INTERNOS",
      text: "Sociedade Portuguesa de Anatomia Patológica",
      desktop: require("../../Assets/Images/banner-home-02@2x.png"),
      mobile: require("../../Assets/Images/banner-home-02@2x.png"),
      alt: "Carousel 2",
      width: "",
    },
  ];

  const ListCards = [
    {
      link: "https://www.pathpresenter.com",
      imageDesktop: require("../../Assets/Images/NISPAP/curso-01@2x.png"),
      imageMobile: require("../../Assets/Images/NISPAP/curso-01.png"),
      imageAlt: "Card Curso 01",
    },
    {
      link: "https://www.cap.org/protocols-and-guidelines/cancer-reporting-tools/cancer-protocol-templates",
      imageDesktop: require("../../Assets/Images/NISPAP/curso-02@2x.png"),
      imageMobile: require("../../Assets/Images/NISPAP/curso-02.png"),
      imageAlt: "Card Curso 02",
    },
    {
      link: "https://learn.mghpathology.org/index.php/WSI:study",
      imageDesktop: require("../../Assets/Images/NISPAP/curso-03@2x.png"),
      imageMobile: require("../../Assets/Images/NISPAP/curso-03.png"),
      imageAlt: "Curso Card 03",
    },
  ];

  const ListNews = [
    {
      link: "/nispap/novidades/eventos-nispap",
      imageDesktop: require("../../Assets/Images/NISPAP/icon-novidades@2x.png"),
      imageMobile: require("../../Assets/Images/NISPAP/icon-novidades.png"),
      imageAlt: "Eventos NISPAP",
      text: "Eventos NISPAP",
    },
    {
      link: "/ferramentas-uteis/publicacoes",
      imageDesktop: require("../../Assets/Images/NISPAP/icon-novidades-02@2x.png"),
      imageMobile: require("../../Assets/Images/NISPAP/icon-novidades-02.png"),
      imageAlt: "Publicações",
      text: "Publicações",
    },
    // {
    //   'link' : '/ferramentas-uteis/caso-do-mes',
    //   'imageDesktop' : require('../../Assets/Images/NISPAP/icon-novidades-03@2x.png'),
    //   'imageMobile' : require('../../Assets/Images/NISPAP/icon-novidades-03.png'),
    //   'imageAlt' : 'Caso do mês',
    //   'text' : 'Caso do mês'
    // },
    {
      link: "/nispap/utilitarios/reunioes-interservicos",
      imageDesktop: require("../../Assets/Images/NISPAP/icon-novidades-04@2x.png"),
      imageMobile: require("../../Assets/Images/NISPAP/icon-novidades-04.png"),
      imageAlt: "Reuniões interserviços",
      text: "Reuniões interserviços",
    },
  ];

  console.log(banners);
  return (
    <Wrapper>
      <Helmet>
        <title>
          Núcleo de Internos Sociedade Portuguesa de Anatomia Patológica -
          NISPAP
        </title>
        <meta
          name="description"
          content="O Núcleo de Internos da Sociedade Portuguesa de Anatomia Patológica é uma organização de médicos internos, de diferentes unidades hospitalares, que pretende representar todos os internos de Anatomia Patológica em Portugal."
        />
        <meta
          name="keywords"
          content="nispap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      {banners?.length > 0 && (
        <Carousel
          indicators={false}
          prevIcon={
            banners.length == 1 ? "" : <FontAwesomeIcon icon={faChevronLeft} />
          }
          nextIcon={
            banners.length == 1 ? "" : <FontAwesomeIcon icon={faChevronRight} />
          }
        >
          {banners.map((item, index) => {
            return (
              <Carousel.Item
                className={"imagem-fundo item item-" + index}
                style={
                  !matches && item.imagem_hover != null
                    ? {
                        background:
                          "url(https://spapapi.phormuladev.com/banners/" +
                          item.imagem_hover +
                          ")",
                      }
                    : {
                        background:
                          "url(https://spapapi.phormuladev.com/banners/" +
                          item.imagem +
                          ")",
                      }
                }
              >
                <div className="imagem-div">
                  {/* <Carousel.Caption className="item__hover">
                  <Link to={item.link}>
                    <picture>
                      <source srcSet={item.desktopHover} media="(min-width: 990px)" />
                      <CarouselImage src={item.mobileHover} alt={item.alt} />
                    </picture>
                  </Link>
                </Carousel.Caption> */}
                  <Carousel.Caption className="item__hover">
                    <FlexInRow gap="0">
                      <h2 className="item__title">{item.titulo}</h2>
                      <div
                        className="item__text"
                        dangerouslySetInnerHTML={{ __html: item.descricao }}
                      ></div>
                      <a href={item.link} className="item__cta">
                        SAIBA MAIS
                      </a>
                    </FlexInRow>
                  </Carousel.Caption>
                </div>
                <div id="social-wall">
                  {SocialLinks.map((item, index) => {
                    return (
                      <a
                        href={item.link}
                        target="_blank"
                        className="social-icon"
                      >
                        {item?.svg ? (
                          item.svg
                        ) : (
                          <picture className={"partner-" + (index + 1)}>
                            <source
                              srcSet={item.desktop}
                              media="(min-width: 990px)"
                            />
                            <Logo
                              src={item.mobile}
                              alt={item.alt}
                              width={item.width}
                            />
                          </picture>
                        )}
                      </a>
                    );
                  })}
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
      )}

      <Suspense fallback={""}>
        <SupportedByNispap />
      </Suspense>

      <SectionOnDemand>
        <Container>
          <FlexInRow gap="3.125vw">
            <FlexInRow gap="0">
              <Title>Formação 'On Demand'</Title>
              <Subtitle>
                Aceda as materais educativos online, de forma gratuita e com
                elevada qualidade para completar a tua formação. <br />
                Com a aprovação da NISPAP
              </Subtitle>
            </FlexInRow>
            {ListCards.length > 0 && (
              <FlexInColumn gap="5.208vw" mobile="column">
                {ListCards.map((item, index) => {
                  {
                    /* #TODO inserir link do cliente, alterar <div> para <a>*/
                  }
                  return (
                    <a
                      href={item.link}
                      target="_blank"
                      className={"item item-" + index}
                    >
                      <picture className="item__image">
                        <source
                          srcSet={item.imageDesktop}
                          media="(min-width: 990px)"
                        />
                        <img src={item.imageMobile} alt={item.imageAlt} />
                      </picture>
                    </a>
                  );
                })}
              </FlexInColumn>
            )}
          </FlexInRow>
        </Container>
      </SectionOnDemand>

      <SectionNews>
        <Container>
          <FlexInRow gap="5.208vw">
            <FlexInRow gap="0">
              <Title className="title">Novidades</Title>
              <Subtitle className="subtitle">
                Não queremos que percas nada! <br />
                Sabe tudo sobre os próximos congressos, cursos e as mais
                recentes publicações!
              </Subtitle>
            </FlexInRow>
            {ListNews.length > 0 && (
              <FlexInColumn gap="5.208vw" mobile="column">
                {ListNews.map((item, index) => {
                  return (
                    <Link to={item.link} className={"item item-" + index}>
                      <FlexInRow gap="0.625vw">
                        <picture className="item__image">
                          <source
                            srcSet={item.imageDesktop}
                            media="(min-width: 990px)"
                          />
                          <img src={item.imageMobile} alt={item.imageAlt} />
                        </picture>
                        <p className="item__text">{item.text} »</p>
                      </FlexInRow>
                    </Link>
                  );
                })}
              </FlexInColumn>
            )}
          </FlexInRow>
        </Container>
      </SectionNews>
    </Wrapper>
  );
}
