import { React, useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import urlSlug from "url-slug";

import Container from "../../../Components/Container";
import FlexInRow from "../../../Components/FlexInRow";
import FlexInColumn from "../../../Components/FlexInColumn";
import PageTitle from "../../../Components/PageTitle";
import RecommendedLinks from "../../../Components/RecommendedLinks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import { Space, Row, Col } from "antd";
import PageText from "../../../Components/PageText";
import Apresentacoes from "../../Backoffice/Apresentacoes";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  > .content {
    padding-bottom: 4vw;
  }

  img {
    width: 100%;
  }

  @media (max-width: 992px) {
    margin-top: 120px;
  }

  .item__content {
    margin-top: 10px;
  }
`;

const Grid3 = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 2.9166vw;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    gap: 50px;
  }

  @media (min-width: 1920px) {
    gap: 56px;
    grid-template-columns: 792px 1fr;
  }
`;

const Grid = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-wrap: wrap;
  gap: 2.9166vw;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    gap: 50px;
  }

  @media (min-width: 1920px) {
    gap: 56px;
    grid-template-columns: 792px 1fr;
  }
`;

const FeaturedItem = styled.div`
  border: 3px solid #494d7d;
  border-radius: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  .item {
    border: 3px solid #494d7d !important;
  }

  .item__image {
    height: 21.354vw;
    overflow: hidden;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .item__title {
    text-align: left;
    font-size: 16px;
    line-height: inherit;
    font-weight: bold;
    letter-spacing: 0px;
    color: rgb(12, 17, 81) !important;
    // color: ${(props) => props.theme.blue};
    opacity: 1;
    margin: 0 20px 0.417vw;
  }

  .item__title.noDate {
    margin-bottom: 2.697vw;
  }

  .item__content p {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0 20px 20px;
  }

  .item__cta {
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #0c1151;
    border-radius: 0.521vw;
    opacity: 1;
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    text-decoration: none;
    color: #ffffff;
    opacity: 1;
    width: 8.021vw;
    height: 2.396vw;
    transition: 0.3s ease;
    margin: 0 20px 20px;
  }

  .item__cta:hover {
    background-color: ${(props) => props.theme.blueHover};
    color: #e5e5e5;
  }

  .item__date {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    color: ${(props) => props.theme.blue};
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.417vw;
    margin: 0 20px 1.042vw;
  }

  .item__date svg {
    font-size: 0.938vw;
  }

  @media (max-width: 992px) {
    .item__image {
      height: auto;
    }

    .item__title {
      font-size: 18px;
      line-height: 26px;
      color: rgb(12, 17, 81) !important;
    }

    .item__text,
    .item__cta,
    .item__date,
    .item__date svg {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }

    .item__cta {
      border-radius: 8px;
      height: 30px;
      width: 150px;
    }
  }

  @media (min-width: 1920px) {
    .item__content p,
    .item__cta,
    .item__date,
    .item__date svg {
      font-size: 18px;
      line-height: 24px;
    }

    .item__title {
      font-size: 24px;
      line-height: 28px;
      color: rgb(12, 17, 81) !important;
    }

    .item__image {
      height: 410px;
    }

    .item__cta {
      border-radius: 10px;
      font-size: 18px;
      line-height: 24px;
      padding: 15px 30px;
      max-width: 214px;
    }
  }
`;

const SectionCards = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.354vw;

  .item {
    opacity: 1;
  }

  .item__content {
    padding: 0.781vw 1.042vw;
  }

  .item__image {
    border: none;
    overflow: hidden;
    width: 13.541vw;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .item__title {
    text-align: left;
    font-size: 16px;
    line-height: inherit;
    font-weight: bold;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    margin-top: 10px;
  }

  .item__text {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .item__cta {
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #0c1151;
    border-radius: 0.521vw;
    opacity: 1;
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    text-decoration: none;
    color: #ffffff;
    opacity: 1;
    width: 8.021vw;
    height: 2.396vw;
    transition: 0.3s ease;
  }

  .item__cta:hover {
    background-color: #8688a8;
    color: #e5e5e5;
  }

  .item__date {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.417vw;
  }

  .item__date svg {
    font-size: 0.938vw;
  }

  @media (min-width: 992px) {
    .item__content {
      width: 25.625vw;
    }
  }

  @media (max-width: 992px) {
    gap: 20px;

    .item__image {
      margin-bottom: 20px;
      width: 100%;
    }

    .item__title {
      font-size: 18px;
      line-height: 26px;
    }

    .item__text,
    .item__cta,
    .item__date,
    .item__date svg {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }

    .item__cta {
      border-radius: 8px;
      height: 30px;
      width: 150px;
    }
  }

  @media (min-width: 1920px) {
    .item__text,
    .item__cta,
    .item__date,
    .item__date svg {
      font-size: 18px;
      line-height: 24px;
    }

    .item__content {
      padding: 19px;
      width: 532px;
    }

    .item__title {
      font-size: 24px;
      line-height: 28px;
    }

    .item__cta {
      border-radius: 10px;
      font-size: 18px;
      line-height: 24px;
      width: 184px;
      padding: 15px 30px;
      max-height: 48px;
    }

    .item__image {
      min-height: 222px;
      width: 260px;
    }
  }
`;

const SeeMore = styled.div`
  padding-top: 50px;
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;

  a {
    text-decoration: none;
    color: ${(props) => props.theme.orange};
    transition: 0.3s ease;
    font-size: 16px;
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }

  @media (max-width: 992px) {
    padding-bottom: 50px;

    a {
      font-size: 18px;
      line-height: 26px;
    }
  }
`;

const Grid2 = styled.div`
  border-top: 1px solid #707070;
  display: grid;
  margin-top: 50px;
  padding-top: 50px;
  grid-template-columns: 1fr 1fr;
  gap: 3.125vw;

  .item {
    display: grid;
    grid-template-columns: 8.229vw 1fr;
    gap: 15px;
  }

  .item__image {
    background-color: #f4f4f7;
    border: 1px solid #707070;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .item__title {
    text-align: left;
    font-family: "Fira Sans", sans-serif;
    font-size: 0.8333vw;
    font-weight: bold;
    line-height: 0.9895vw;
    letter-spacing: 0px;
    color: #0c1151;
    display: block;
  }

  .item__text {
    text-align: left;
    font-size: 0.7291vw;
    line-height: 0.9895vw;
    letter-spacing: 0px;
    color: #000000;
    display: block;
  }

  .item__cta {
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #0c1151;
    border-radius: 0.521vw;
    opacity: 1;
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    text-decoration: none;
    color: #ffffff;
    opacity: 1;
    width: 8.021vw;
    height: 2.396vw;
    transition: 0.3s ease;
  }

  .item__cta:hover {
    background-color: ${(props) => props.theme.blueHover};
    color: #e5e5e5;
  }

  @media (max-width: 992px) {
    margin-top: 30px;
    padding-top: 20px;
    gap: 20px;
    grid-template-columns: 1fr;

    .item {
      display: grid;
      grid-template-columns: 1fr;
    }

    .item__image {
      height: 50vw;
    }

    .item__title {
      font-size: 16px;
      line-height: 20px;
    }

    .item__text {
      font-size: 12px;
      line-height: 16px;
    }
  }

  @media (min-width: 1920px) {
    gap: 60px;
    grid-template-columns: 1fr 1fr 1fr;

    .item__cta {
      border-radius: 10px;
      font-size: 18px;
      line-height: 24px;
      width: 184px;
      padding: 15px 30px;
      max-height: 48px;
    }
  }
`;

export default function Destaques(props) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [apresentacoes, setApresentacoes] = useState([]);

  const [noResults, setNoResults] = useState("");

  const getApresentacoes = (allApresentacoes) => {
    let apresentacoesToShow = [];

    let today = new Date().toLocaleDateString();

    if (allApresentacoes?.length > 0) {
      allApresentacoes.map((apresentacao) => {
        apresentacao.slug = urlSlug(apresentacao.titulo);

        apresentacao.slug += "-" + apresentacao.id;
        apresentacao.url = apresentacao.imagem_destaque
          ? "https://spapapi.phormuladev.com/apresentacoes/" +
            apresentacao.id +
            "/" +
            apresentacao.imagem_destaque
          : "https://spapapi.phormuladev.com/clubes/clube-estudo-default.svg";
      });
    }

    const apresentacoesOrdenadas = allApresentacoes.sort(
      (a, b) => a.ordem - b.ordem
    );

    setApresentacoes(apresentacoesOrdenadas);
  };

  useEffect(() => {
    fetch(`https://spapapi.phormuladev.com/api/all-apresentacoes`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar apresentacoes");
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          setNoResults("Não há conteúdo Destaques no momento");
          console.log("nenhum evento");
        }
        console.log(data.apresentacoes);
        getApresentacoes(data.apresentacoes);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <Wrapper>
      <Container className="content">
        <PageTitle
          title="Cursos e congressos"
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "Ferramentas úteis",
              link: "/ferramentas-uteis",
            },
            {
              text: "Formação",
              link: "/ferramentas-uteis/formacao",
            },
            {
              text: "Cursos e Congressos",
            },
          ]}
          imageDesktop={require("../../../Assets/Images/img-destaques@2x.png")}
          imageMobile={require("../../../Assets/Images/img-destaques.png")}
          imageAlt="Cursos e congressos"
        />

        <FlexInRow gap="40px">
          <PageText title="Aqui pode encontrar os cursos e congressos passados com material didático disponível para formação, assim como links para cursos internacionais." />
          {apresentacoes?.length > 0 ? (
            <>
              <Row gutter={[24, 24]}>
                {apresentacoes.map((item, index) => {
                  let titlePreview = item.titulo?.slice(0, 50);
                  if (item.titulo.length > 50) titlePreview += " (...)";
                  let textPreview = item.descricao
                    .replace(/<(h2|h3|img|b|strong)[^>]*>/g, "<p>")
                    .slice(0, 120);
                  if (item.descricao.length > 120) textPreview += " (...)";
                  return (
                    <Col span={8}>
                      <div
                        style={{
                          border: "2px solid #0C1151",
                          overflow: "hidden",
                          width: "100%",
                          borderRadius: "10px",
                          display: "block",
                        }}
                      >
                        <Link
                          to={
                            "/ferramentas-uteis/formacao/cursos-congressos/" +
                            item.slug
                          }
                          className="item__cta"
                        >
                          <div
                            className="item__image"
                            style={{
                              backgroundImage: "url(" + item.url + ")",
                              backgroundSize: "cover",
                              backgroundPosition: "center center",
                            }}
                          >
                            <picture style={{ opacity: "0" }}>
                              <source
                                srcSet={item.url}
                                media="(min-width: 990px)"
                              />
                              <img src={item.url} alt={item.alt} />
                            </picture>{" "}
                          </div>
                        </Link>
                      </div>
                      <div className="item__content">
                        <div>
                          <h3
                            style={{
                              color: "rgb(12, 17, 81)",
                              fontSize: "20px",
                            }}
                          >
                            {titlePreview}
                          </h3>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </>
          ) : (
            <PageText title={noResults} />
          )}
        </FlexInRow>
      </Container>

      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={[
          {
            title: "História",
            link: "/spap/historia",
          },
          {
            title: "Órgãos sociais",
            link: "/spap/orgaos-sociais",
          },
          {
            title: "Bolsas e prémios",
            link: "/spap/bolsas-e-premios",
          },
          {
            title: "Vantagens de ser sócio",
            link: "/socios/conheca-as-vantagens",
          },
        ]}
      />
    </Wrapper>
  );
}
