import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  notification,
  Checkbox,
  Table,
  Typography,
  Avatar,
  Dropdown,
  Col,
  Row,
  Button,
  Divider,
  Input,
  Popover,
  Space,
  Select,
  Upload,
  Modal,
  Switch,
} from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  LoadingOutlined,
  InboxOutlined,
  EditOutline,
} from "@ant-design/icons";
import Card from "../../../Components/Backoffice/Card";
import Title from "../../../Components/Backoffice/Title";
import axios from "axios";
import authService from "../../../Services/auth.service";
import { ReactSortable } from "react-sortablejs";

import { ReactComponent as IconUser } from "../../../Assets/Images/Backoffice/user-club.svg";
import { ReactComponent as IconPhoto } from "../../../Assets/Images/Backoffice/Photo.svg";
import { ReactComponent as IconEdit } from "../../../Assets/Images/Backoffice/Edit.svg";
import { ReactComponent as IconUsersSolid } from "../../../Assets/Images/Backoffice/Users-Solid.svg";
import { ReactComponent as IconSearch } from "../../../Assets/Images/Backoffice/Search.svg";
import { ReactComponent as IconUpload } from "../../../Assets/Images/Backoffice/Upload-off.svg";
import {
  CheckCircleTwoTone,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { ReactComponent as IconClose } from "../../../Assets/Images/Backoffice/Close.svg";
import { ReactComponent as IconOptions } from "../../../Assets/Images/Backoffice/Options.svg";
import { ReactComponent as IconMessage } from "../../../Assets/Images/Backoffice/Message.svg";
import { ReactComponent as IconSettings } from "../../../Assets/Images/Backoffice/Settings.svg";
import DrawerPerfil from "../../../Pages/Backoffice/Socios/drawerPerfil";
const { Dragger } = Upload;
const { Text } = Typography;

const CardHeader = styled.div`
  align-items: center;
  display: grid;
  gap: 60px;
  grid-template-columns: 1fr auto auto;

  .icon-upload {
    cursor: pointer;
    height: 46px;
    width: 46px;
  }

  .c-pointer {
    cursor: pointer !important;
  }
`;

const CardFooter = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 235px);
  justify-content: space-between;

  button {
    border-radius: 12px;
    height: 50px;
    width: 100%;
  }
`;

const Search = styled.div`
  position: relative;

  input {
    width: 100%;
    background-color: #f1f5f700;
    border: 2px solid #d9e1e7;
    border-radius: 16px;
    color: #8688a8;
    font-size: 16px;
    font-style: italic;
    letter-spacing: 0px;
    line-height: 18px;
    margin: 0;
    padding: 12px 58px 12px 26px;
    outline: 0;
  }

  .icon {
    position: absolute;
    right: 26px;
    top: 8px;
  }
`;

const Wrapper = styled.section`
  display: grid;
  grid-template-columns: 19.2708vw 1fr;
  gap: 50px;

  margin-bottom: 50px;

  .item__icons {
    align-items: start;
  }

  .c-pointer {
    cursor: pointer;
  }

  .title {
    font-size: 16px;
    font-weight: bold;
    line-eheight: 23px;
    letter-spacing: 0px;
    color: #050505;
    text-transform: uppercase;
    text-align: left;
  }

  .ant-modal-footer {
    display: none !important;
  }

  .clickable {
    cursor: pointer;
  }

  .text {
    text-align: left;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #494d7d;
  }

  .cta {
    background-color: #494d7d;
    border: none;
    border-radius: 6px;
    color: #ffffff;
    height: 45px;
  }

  .cta:hover {
    color: #ffffff;
  }

  .clube-active {
    background-color: #1ad5984d;
    border: none;
    color: #1ad598;
    pointer-events: none;
  }

  .clube-desactive {
    background-color: #ea3a3d4d;
    border: none;
    color: #ea3a3d;
    pointer-events: none;
  }

  .ant-popover {
    z-index: 9999 !important;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const CardContent = styled.div`
  margin: 15px 0 30px;
  position: relative;

  .ant-table-cell:nth-child(2) {
    text-align: left !important;
  }

  // .ant-table-cell, .ant-table-cell p {
  //   text-align: center !important;
  // }
`;

const PreGrid = styled.div`
  border-bottom: 1px solid #f1f4f9;
  border-top: 1px solid #f1f4f9;
  padding-top: 20px;
`;

const TableFooter = styled.div`
  bottom: 16px;
  left: 0;
  position: absolute;
`;

const UploadImage = styled.div`
  align-items: center;
  background-color: #8688a8;
  border: 3px solid #0c1151;
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  height: 230px;
  justify-content: center;
`;

const Grid = styled.div`
  align-items: flex-start;
  justify-content: flex-start;
  display: grid;
  grid-template-columns: 1fr 1fr auto;

  .item {
    // padding: 6.5px 18px;
  }

  .item.checked {
    border: 1px solid #494d7d;
    padding: 2px 10px;
    border-radius: 12px;
  }

  .item__name {
    color: #494d7d;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 22px;
    text-align: left;
  }

  .item__number {
    color: #8688a8;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 22px;
    text-align: left;
  }
`;

var styles = {
  h6_label: {
    color: "#809FB8",
    fontFamily: "Roboto",
    fontSize: "15px",
    marginTop: "1%",
  },
  input_class_disabled: {
    marginBottom: "2%",
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
    opacity: 0.6,
    pointerEvents: "none",
  },
  input_class: {
    paddingLeft: "10px",
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
    width: "100%",
  },
  switch_label: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    borderRadius: "8px",
    padding: "0px 16px",
    color: "#809FB8",
    border: "1px solid #809FB8",
    textAlign: "center",
  },
  upload: {
    width: "100%",
  },
};

export default function ClubeHeader(props) {
  const clubeId = props.clube;

  const [checkboxes, setCheckboxes] = useState([]);

  const users = 23;

  Array.prototype.diff = function (a) {
    return this.filter(function (i) {
      return a.indexOf(i) < 0;
    });
  };

  notification.config({
    placement: "bottomRight",
    bottom: 10,
    duration: 5,
    rtl: false,
  });

  const session_storage = JSON.parse(localStorage.getItem("session_data"));

  const navigate = useNavigate();
  const [clube, setClube] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [usersSelected, setUsersSelected] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [novoNome, setNovoNome] = useState("");
  const [userIdToRemoveSelected, setUserIdToRemoveSelected] = useState("");
  const [usernameToRemoveSelected, setUsernameToRemoveSelected] = useState("");
  const [isModalUploadOpen, setIsModalUploadOpen] = useState(false);
  const [isModalRemoveManagerOpen, setIsModalRemoveUserOpen] = useState(false);
  const [isModalMembrosOpen, setIsModalMembrosOpen] = useState(false);
  const [isModalGestoresOpen, setIsModalGestoresOpen] = useState(false);
  const [ismodalNomeClubeOpen, setIsModalNomeClubeOpen] = useState(false);
  const [tablePagination, setTablePagination] = useState(5);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState("");
  const [tipoUserToRemove, setTipoUserToRemove] = useState("");
  const [titleToRemove, setTitleToRemove] = useState("");
  const [membrosClube, setMembrosClube] = useState([]);
  const [gestoresClube, setGestoresClube] = useState([]);
  const [naoMembrosClubeBkp, setNaoMembrosClubeBkp] = useState([]);
  const [naoGestoresClubeBkp, setNaoGestoresClubeBkp] = useState([]);
  const [membrosToShow, setMembrosToShow] = useState([]);
  const [avatar, setAvatar] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [naoGestoresClube, setNaoGestoresClube] = useState([]);
  const [naoMembrosClube, setNaoMembrosClube] = useState([]);
  const [userDrawer, setUserDrawer] = useState([]);

  const handleClubeInfo = (allInfo) => {
    setUsersList(allInfo.users);
    setClube(allInfo.clube[0]);
    let urlAvatar = null;

    if (allInfo.clube[0].url != null) {
      urlAvatar =
        "https://spapapi.phormuladev.com/clubes/" + allInfo.clube[0].url;
    }
    setAvatar(urlAvatar);

    // if(session_storage.all_data.role == 'moderator') {
    //   let isGestor = allInfo.membrosClube.filter((membro) => { if((membro.user_id == session_storage.all_data.user_session.id) && (membro.member_type == 2)) { return membro; }});
    //   if(isGestor.length == 0) {
    //     window.location.href = 'https://spap.phormuladev.com';
    //   }
    // }

    const membrosDesteClube = [];
    if (allInfo.membrosClube?.length > 0) {
      allInfo.membrosClube.map((membrosClube) => {
        allInfo.users?.map((user) => {
          if (user.id == membrosClube.user_id) {
            user.member_type = membrosClube.member_type;
            membrosDesteClube.push(user);
          }
        });
      });
    }
    setMembrosClube(membrosDesteClube);

    const gestoresDesteClube = [];
    if (allInfo.membrosClube?.length > 0) {
      allInfo.membrosClube.sort((a, b) => a.ordem - b.ordem);
      allInfo.membrosClube?.map((membrosClube) => {
        if (membrosClube.member_type == 2) {
          allInfo.users?.map((user) => {
            if (user.id == membrosClube.user_id) {
              user.member_type = membrosClube.member_type;
              gestoresDesteClube.push(user);
            }
          });
        }
      });
    }
    setGestoresClube(gestoresDesteClube);

    if (session_storage.all_data.role == "socio_aprovado") {
      const ids = gestoresDesteClube.map((item) => item.id);
      const encontradoIncludes = ids.includes(
        session_storage.all_data.user_session.id
      );
      if (!encontradoIncludes) {
        navigate("/");
      }
    }
    getDataToShow(membrosDesteClube);

    const naoGestoresDesteClube = allInfo.users.diff(gestoresDesteClube);
    setNaoGestoresClube(naoGestoresDesteClube);
    setNaoGestoresClubeBkp(naoGestoresDesteClube);

    const naoMembrosDesteClube = allInfo.users.diff(membrosDesteClube);
    setNaoMembrosClube(naoMembrosDesteClube);
    setNaoMembrosClubeBkp(naoGestoresDesteClube);
  };

  useEffect(() => {
    fetch(`https://spapapi.phormuladev.com/api/get-clube/` + clubeId, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar clube");
        }
        return response.json();
      })
      .then((data) => {
        handleClubeInfo(data);
      })
      .catch((error) => console.error(error));
  }, []);

  const selectHandleChange = (value) => {
    setTablePagination(value);
  };

  const clickToUserDrawer = (membros, userId) => {
    console.log(userId);
    let userData = membros.filter(function (item) {
      if (item.id == userId) {
        return item;
      }
    });
    setUserDrawer(userData[0]);
    setShowDrawer(true);
  };

  const getDataToShow = (membros = []) => {
    const dataToShow = [];
    membros.map((item) =>
      dataToShow.push({
        key: "membros-" + item.id,
        id_membro: item.id,
        nameToRemove: item.name,
        name: (
          <Space className="item__icons" style={{ alignItems: "left" }}>
            <b className="user-name">
              {item.name} {item.apelido ? item.apelido : ""}
            </b>
            {/* <IconMessage className='icon icon-message' /> */}
            <IconSettings
              className="icon icon-settings"
              onClick={() => clickToUserDrawer(membros, item.id)}
            />
          </Space>
        ),
        number: (
          <Button className="user-number">
            <b>{item.numero_socio ? item.numero_socio : "N/A"}</b>
          </Button>
        ),
        type: <b style={{ textAlign: "center " }}>SPAP</b>,
        member_type: item.member_type,
        active: <p>{item.member_type == 2 ? "Gestor" : "Membro"}</p>,
        status:
          item.status === 1 ? (
            <Button className="user-active">
              <b>ATIVO</b>
            </Button>
          ) : (
            <Button className="user-desactive">
              <b>INATIVO</b>
            </Button>
          ),
      })
    );

    setMembrosToShow(dataToShow);
  };

  // useEffect(() => {

  //   const dataToShow = [];
  //   membrosClube.map((item) =>
  //   dataToShow.push({
  //       key: 'membros-' + item.id,
  //       id_membro: item.id,
  //       nameToRemove: item.name,
  //       name:
  //       <Space className='item__icons'>
  //       <b className='user-name'>{item.name} {item.apelido ? item.apelido : ''}</b>
  //       <IconMessage className='icon icon-message' />
  //       <IconSettings className='icon icon-settings' onClick={() => clickToUserDrawer(item.id)}/>
  //       </Space>
  //       ,
  //       number : <Button className='user-number'><b>{item.numero_socio ? item.numero_socio : 'N/A'}</b></Button>,
  //       type :  <b style={{ textAlign: 'center '}}>SPAP</b>,
  //       member_type : item.member_type,
  //       active : <p>{item.member_type == 2 ? 'Gestor' : 'Membro'}</p>,
  //       status :  (item.status === 1 ? <Button className='user-active'><b>ATIVO</b></Button>
  //       : <Button className='user-desactive'><b>INATIVO</b></Button>
  //       )
  //     })
  //   );
  //   setMembrosToShow([]);
  //   setMembrosToShow(dataToShow);
  // }, [membrosClube]);

  const getGestoresClube = (membrosClubes) => {
    let gestoresDesteClube = [];
    if (membrosClubes?.length > 0) {
      membrosClubes?.sort((a, b) => a.ordem - b.ordem);
      membrosClubes.map((membrosClube) => {
        if (membrosClube.club_id == clubeId && membrosClube.member_type == 2) {
          usersList.map((user) => {
            if (user.id == membrosClube.user_id) {
              user.member_type = membrosClube.member_type;
              gestoresDesteClube.push(user);
            }
          });
        }
      });
    }
    setGestoresClube(gestoresDesteClube);
  };

  const handleUserDataToRemove = (id, name, tipo) => {
    setUserIdToRemoveSelected(id);
    setUsernameToRemoveSelected(name);
    setIsModalRemoveUserOpen(true);
    setTipoUserToRemove(tipo);
    if (tipo == 2) {
      setTitleToRemove("Remover gestor:");
    } else {
      setTitleToRemove("Remover membro:");
    }
  };

  const handleRemoveUser = () => {
    let userSelected = userIdToRemoveSelected;

    axios
      .post(
        "https://spapapi.phormuladev.com/api/remove-clube-member",
        { clubeId, userSelected, tipoUserToRemove },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        setIsModalRemoveUserOpen(false);
        setCheckboxes([]);
        handleClubeInfo(res.data);
        notification.success({
          description:
            'O utilizador "' +
            usernameToRemoveSelected +
            '" foi removido do clube.',
        });
      })
      .catch((error) => {
        notification.error({
          description:
            'Erro ao remover o utilizador "' +
            usernameToRemoveSelected +
            '" do clube. Por favor, tente de novo.',
        });
      });
  };

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
    },
    {
      title: "Nr. Sócio",
      dataIndex: "number",
      sorter: {
        compare: (a, b) => a.number - b.number,
      },
    },
    {
      title: "Tipo de Sócio",
      dataIndex: "type",
      sorter: {
        compare: (a, b) => a.type - b.type,
      },
    },
    {
      title: "Tipo de Adesão",
      dataIndex: "active",
      sorter: {
        compare: (a, b) => a.active - b.active,
      },
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  const handleSearchMembros = (event) => {
    if (event.length == 0) {
      setNaoMembrosClube(naoMembrosClubeBkp);
      return;
    }

    let input = event.toLowerCase();

    let novosUsersAListar = naoMembrosClubeBkp.filter((user) => {
      if (
        (user.name && user.name.toLowerCase().includes(input)) ||
        (user.apelido && user.apelido.toLowerCase().includes(input)) ||
        (user.email && user.email.toLowerCase().includes(input)) ||
        (user.numero_socio &&
          user.numero_socio.toLowerCase().includes(input)) ||
        (user.telefone && user.telefone.toLowerCase().includes(input)) ||
        (user.orcid && user.orcid.toLowerCase().includes(input)) ||
        (user.socio_titular && user.socio_titular.toLowerCase().includes(input))
      ) {
        return user;
      }
    });

    setNaoMembrosClube(novosUsersAListar);
  };

  const handleSearchMembrosAtuais = (event) => {
    const allUsers = membrosClube;
    let noUser = false;

    let membrosAMostrar = [];

    if (event.length == 0) {
      noUser = true;
      return;
    }

    if (!noUser) {
      let input = event.toLowerCase();

      membrosAMostrar = allUsers.filter((user) => {
        if (
          (user.name && user.name.toLowerCase().includes(input)) ||
          (user.email && user.email.toLowerCase().includes(input)) ||
          (user.numero_socio &&
            user.numero_socio.toLowerCase().includes(input)) ||
          (user.telefone && user.telefone.toLowerCase().includes(input)) ||
          (user.orcid && user.orcid.toLowerCase().includes(input)) ||
          (user.socio_titular &&
            user.socio_titular.toLowerCase().includes(input))
        ) {
          return user;
        }
      });
    }

    let dataToShow = [];

    membrosAMostrar.map((item) =>
      dataToShow.push({
        key: "membros-" + item.id,
        id_membro: item.id,
        name: (
          <Space className="item__icons">
            <b className="user-name">
              {item.name} {item.apelido ? item.apelido : ""}
            </b>
            <IconMessage className="icon icon-message" />
            <IconSettings
              className="icon icon-settings"
              onClick={() => clickToUserDrawer(item.id)}
            />
          </Space>
        ),
        number: (
          <Button className="user-number">
            <b>{item.numero_socio ? item.numero_socio : "N/A"}</b>
          </Button>
        ),
        type: <b style={{ textAlign: "center " }}>SPAP</b>,
        member_type: item.member_type,
        active: <p>{item.member_type == 2 ? "Gestor" : "Membro"}</p>,
        status:
          item.status === 1 ? (
            <Button className="user-active">
              <b>ATIVO</b>
            </Button>
          ) : (
            <Button className="user-desactive">
              <b>INATIVO</b>
            </Button>
          ),
      })
    );

    setMembrosToShow(dataToShow);
  };

  useEffect(() => {}, [membrosClube]);

  const handleSearchGestores = (event) => {
    if (event.length == 0) {
      setNaoGestoresClube(naoGestoresClubeBkp);
      return;
    }

    let input = event.toLowerCase();

    let novosUsersAListar = naoGestoresClubeBkp.filter((user) => {
      if (
        (user.name && user.name.toLowerCase().includes(input)) ||
        (user.apelido && user.apelido.toLowerCase().includes(input)) ||
        (user.email && user.email.toLowerCase().includes(input)) ||
        (user.numero_socio &&
          user.numero_socio.toLowerCase().includes(input)) ||
        (user.telefone && user.telefone.toLowerCase().includes(input)) ||
        (user.orcid && user.orcid.toLowerCase().includes(input)) ||
        (user.socio_titular && user.socio_titular.toLowerCase().includes(input))
      ) {
        return user;
      }
    });

    setNaoGestoresClube(novosUsersAListar);
  };

  const handleCheckboxChange = (index) => {
    const newCheckboxes = [...checkboxes];
    newCheckboxes[index] = !newCheckboxes[index];
    setCheckboxes(newCheckboxes);
  };

  const hideModalUpload = () => {
    setIsModalUploadOpen(false);
  };

  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleUpload = () => {
    const formData = new FormData();

    formData.append("file", fileList[0]);
    formData.append("clubeId", clubeId);

    setUploading(true);
    // You can use any AJAX library you like
    fetch("https://spapapi.phormuladev.com/api/admin/upload-clube-image", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            setClube(data.clube[0]);
            setIsModalUploadOpen(false);
            setAvatar(
              "https://spapapi.phormuladev.com/clubes/" + data.clube[0].url
            );
          })
          .catch((err) => {});
      })
      .then(() => {
        setFileList([]);
        notification.success({
          description: "A imagem do clube foi atualizada com sucesso!",
        });
      })
      .catch(() => {})
      .finally(() => {
        setUploading(false);
      });
  };

  const handleCancelModalMembros = () => {
    setCheckboxes([]);
    setIsModalMembrosOpen(false);
  };

  const handleCancelModalGestores = () => {
    setCheckboxes([]);
    setIsModalGestoresOpen(false);
  };

  const handleFormNomeClube = (e) => {
    e.preventDefault();

    let nome = novoNome;

    if (nome.length == 0) {
      setHasError(true);
      setTimeout(() => {
        setHasError(false);
      }, 3000);
      return;
    }

    axios
      .post(
        "https://spapapi.phormuladev.com/api/admin/update-clube-nome",
        {
          nome,
          clubeId,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        if (res.data.clube.length) {
          console.log(res.data.clube);
          setClube(res.data.clube[0]);
          setIsModalNomeClubeOpen(false);
          notification.success({
            description: "O nome do clube foi criado com sucesso",
          });
        }
      })
      .catch((error) => {
        setIsModalNomeClubeOpen(false);
        notification.error({
          description:
            "Erro ao alterar o nome do clube. Por favor, tente de novo.",
        });
      });
  };

  const handleUsersToClube = (type) => {
    let usersSelected = [];
    checkboxes.map((checkbox, index) => {
      if (checkbox == true) {
        usersSelected.push(index);
      }
    });
    axios
      .post(
        "https://spapapi.phormuladev.com/api/admin/set-members",
        { clubeId, usersSelected, type },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        type == 1
          ? setIsModalMembrosOpen(false)
          : setIsModalGestoresOpen(false);
        setCheckboxes([]);
        handleClubeInfo(res.data);
        notification.success({
          description: "Os utilizadores foram associados ao clube com sucesso.",
        });
      })
      .catch((error) => {
        notification.error({
          description:
            "Erro ao associar utilizadores ao clube. Por favor, tente de novo.",
        });
      });
  };

  const prop = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const nomeClubeOpen = () => {
    setIsModalNomeClubeOpen(true);
  };

  const onChangeClubStatus = (status) => {
    let statusClube = status == true ? 1 : 0;

    axios
      .post(
        "https://spapapi.phormuladev.com/api/admin/set-clube-status",
        { statusClube, clubeId },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        if (res.data.clubes.length) {
          session_storage.all_data.clubes = res.data.clubes;
          localStorage.setItem("session_data", JSON.stringify(session_storage));
          let newClubeValue = clube;
          newClubeValue.status = statusClube;
          setClube(newClubeValue);
        }
      })
      .catch((error) => {});
  };

  const tableOnChange = (pagination, filters, sorter, extra) => {};

  const deleteColumn = {
    title: "",
    key: "delete",
    render: (_, record) => (
      <Button
        type="link"
        icon={<DeleteOutlined />}
        onClick={(e) => {
          setDeleteRecord(record.key);
          handleUserDataToRemove(
            record.id_membro,
            record.nameToRemove,
            record.member_type
          );
        }}
      />
    ),
  };

  const handleOrdem = (array) => {
    setGestoresClube(array);

    let ordemGestores = [];
    array.map(function (item, index) {
      ordemGestores.push({ ordem: index, id: item.id });
    });
    var bodyFormData = new FormData();

    bodyFormData.append("array", JSON.stringify(ordemGestores));
    bodyFormData.append("clubeId", clubeId);

    fetch("https://spapapi.phormuladev.com/api/admin/set-ordem-gestores", {
      method: "POST",
      body: bodyFormData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    }).then((response) => {
      response.json().then((data) => {
        // session_storage.all_data.membrosClubes = data.membrosClubes;
        // localStorage.setItem('session_data', JSON.stringify(session_storage));
        // syncData();
      });
    });
  };

  return (
    <>
      <Wrapper>
        {avatar == null && (
          <UploadImage onClick={() => setIsModalUploadOpen(true)}>
            <Space align="center">
              {/* <img src={avatar}  style={{ width: '98px', height: '98px', borderRadius: '50px'}}/> */}

              <IconPhoto className="icon icon-photo" />
              <u>Upload de imagem</u>
            </Space>
          </UploadImage>
        )}

        {avatar != null && (
          <UploadImage
            style={{ position: "relative" }}
            onClick={() => setIsModalUploadOpen(true)}
          >
            {/* <Space align="center"> */}
            <img
              src={avatar}
              style={{ width: "100%", height: "100%", borderRadius: "15px" }}
            />

            <div style={{ position: "absolute", bottom: "10px" }}>
              <IconPhoto className="icon icon-photo" />
              <u>Upload de imagem</u>
            </div>

            {/* </Space> */}
          </UploadImage>
        )}
        <Space direction="vertical">
          <Grid align={"top"}>
            <Space direction="vertical">
              <Space direction="horizontal">
                <span className="title">GESTOR(ES) DO CLUBE</span>
                <IconEdit
                  className="icon icon-edit clickable"
                  onClick={() => setIsModalGestoresOpen(true)}
                />
                <span
                  className="text c-pointer"
                  onClick={() => setIsModalGestoresOpen(true)}
                >
                  Adicionar
                </span>
              </Space>
              {gestoresClube.length ? (
                <>
                  <Space direction="vertical">
                    <ReactSortable
                      list={gestoresClube}
                      setList={(e) => handleOrdem(e)}
                      className="div-sortable"
                    >
                      {gestoresClube.map((item, index) => {
                        return (
                          <Space>
                            <IconUser className="icon icon-edit clickable" />
                            <span className="text">
                              {item.name} {item.apelido ? item.apelido : ""}
                            </span>{" "}
                            <IconClose
                              className="icon icon-close"
                              key={"user-" + item.id}
                              onClick={() =>
                                handleUserDataToRemove(item.id, item.name, 2)
                              }
                            />
                          </Space>
                        );
                      })}
                    </ReactSortable>
                  </Space>
                </>
              ) : (
                <>
                  <p>Gestores por atribuir</p>
                </>
              )}
            </Space>
            <Row align={"top"} gutter={[8, 8]}>
              <Col span={12}>
                <IconEdit
                  className="icon icon-edit clickable"
                  onClick={nomeClubeOpen}
                />
                <span className="text">{clube.name}</span>
              </Col>
              <Col span={12} align={"end"}>
                <a
                  href={
                    "https://spap.phormuladev.com/socios/clubes-de-estudo/" +
                    clube?.id
                  }
                  target="_blank"
                >
                  <Button className="cta">Aceder ao clube</Button>
                </a>
              </Col>
            </Row>
          </Grid>
          <Divider />
          <Space align="start" size={50}>
            <Space.Compact direction="vertical">
              <span className="title">Estado</span>
              <Space>
                {clube?.status == 1 ? (
                  <>
                    {" "}
                    <Switch
                      defaultChecked
                      onChange={(e) => onChangeClubStatus(e)}
                    />
                  </>
                ) : (
                  <>
                    <Switch onChange={(e) => onChangeClubStatus(e)} />
                  </>
                )}
                {clube?.status == 1 ? (
                  <Button className="clube-active">
                    <b>ATIVO</b>
                  </Button>
                ) : (
                  <Button className="clube-desactive">
                    <b>INATIVO</b>
                  </Button>
                )}
              </Space>
            </Space.Compact>
            <Space.Compact direction="vertical">
              <span className="title">Membros</span>
              <Space>
                <IconUsersSolid className="icon icon-avatar" />
                <span className="text">{membrosClube.length}</span>
              </Space>
            </Space.Compact>
            <Button className="cta" onClick={() => setIsModalMembrosOpen(true)}>
              Adicionar Membros
            </Button>
          </Space>
        </Space>
      </Wrapper>
      <Card>
        <CardHeader>
          <Title>Membros</Title>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Search>
              <input
                type="text"
                placeholder="Procurar..."
                onChange={(e) => handleSearchMembrosAtuais(e.target.value)}
              />
              <IconSearch className="icon icon-search" />
            </Search>
            <IconOptions className="icon icon-options" />
          </div>
        </CardHeader>
        <CardContent>
          <Table
            columns={[...columns, deleteColumn]}
            dataSource={membrosToShow}
            onChange={() => tableOnChange}
            pagination={{ pageSize: tablePagination }}
          />
          <TableFooter>
            <Select
              defaultValue={tablePagination}
              style={{
                width: 120,
              }}
              onChange={selectHandleChange}
              options={[
                {
                  value: "5",
                  label: "5",
                },
                {
                  value: "10",
                  label: "10",
                },
                {
                  value: "20",
                  label: "20",
                },
                {
                  value: "50",
                  label: "50",
                },
              ]}
            />
          </TableFooter>
        </CardContent>
      </Card>
      <Modal
        open={isModalUploadOpen}
        width={300}
        onCancel={() => setIsModalUploadOpen(false)}
        footer={[
          <Button
            type="primary"
            onClick={handleUpload}
            disabled={fileList.length === 0}
            loading={uploading}
            style={{
              borderRadius: "12px",
              background: "#494D7D",
              width: "100%",
              height: "45px",
            }}
          >
            {uploading ? "Uploading" : "Start Upload"}
          </Button>,
        ]}
      >
        <h5
          style={{
            marginBottom: 0,
            fontSize: "18px",
            textAlign: "center",
            paddingTop: "20px",
            paddingBottom: "10px",
          }}
        >
          Faça upload da imagem do clube
        </h5>
        <p
          style={{
            marginBottom: 0,
            fontSize: "14px",
            textAlign: "center",
            color: "#809FB8",
            paddingBottom: "20px",
          }}
        >
          Tamanho máximo de upload (2 MB)
        </p>
        <Dragger {...prop} style={{ borderRadius: "12px" }}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibit from
            uploading company data or other band files
          </p>
        </Dragger>
      </Modal>

      <Modal
        className="modal-set-member"
        open={isModalMembrosOpen}
        width={800}
        height={350}
        onCancel={() => setIsModalMembrosOpen(false)}
      >
        <h5
          style={{
            marginBottom: 0,
            fontSize: "18px",
            textAlign: "center",
            paddingTop: "20px",
            paddingBottom: "10px",
          }}
        >
          Adicionar membros
        </h5>
        <p
          style={{
            marginBottom: 0,
            fontSize: "14px",
            textAlign: "center",
            color: "#809FB8",
            paddingBottom: "20px",
          }}
        >
          Selecione os membros que pretende associar ao clube.
        </p>
        <>
          {" "}
          <Card>
            <CardHeader style={{ gap: "0" }}>
              <Title></Title>
              <Search>
                <input
                  type="text"
                  placeholder="Procurar..."
                  onChange={(e) => handleSearchMembros(e.target.value)}
                />
                <IconSearch className="icon icon-search" />
              </Search>
            </CardHeader>
            <CardContent>
              <PreGrid>
                <Grid
                  style={{
                    gap: "10px",
                    gridTemplateColumns: "repeat(4,2fr)",
                    maxHeight: "300px",
                    overflowY: "scroll",
                    padding: "10px",
                  }}
                >
                  {naoMembrosClube.map((item, index) => {
                    return (
                      <Space
                        className={
                          checkboxes[item.id]
                            ? "item item-" + item.id + " checked"
                            : "item item-" + item.id
                        }
                        align="center"
                        size={10}
                      >
                        <Checkbox
                          checked={checkboxes[item.id]}
                          onChange={() => handleCheckboxChange(item.id)}
                          className="item__checkbox"
                        />
                        {
                          // <Avatar src={<img src={'https://spapapi.phormuladev.com/avatars/' + item.avatar} />} />
                        }
                        <Space.Compact direction="vertical">
                          <Text strong className="item__name">
                            {item.name} {item.apelido ? item.apelido : ""}
                          </Text>
                          <Text className="item__number">
                            {" "}
                            Sócio:{" "}
                            {item.numero_socio ? item.numero_socio : "N/A"}
                          </Text>
                        </Space.Compact>
                      </Space>
                    );
                  })}
                </Grid>
              </PreGrid>
            </CardContent>
            <CardFooter>
              <Button
                type="primary"
                onClick={() => handleUsersToClube(1)}
                style={{ background: "#494D7D" }}
              >
                Convidar
              </Button>
              <Button onClick={handleCancelModalMembros}>Cancelar</Button>
            </CardFooter>
          </Card>
        </>
      </Modal>

      <Modal
        className="modal-set-member"
        open={isModalGestoresOpen}
        width={800}
        height={350}
        onCancel={() => setIsModalGestoresOpen(false)}
      >
        <h5
          style={{
            marginBottom: 0,
            fontSize: "18px",
            textAlign: "center",
            paddingTop: "20px",
            paddingBottom: "10px",
          }}
        >
          Adicionar gestores
        </h5>
        <p
          style={{
            marginBottom: 0,
            fontSize: "14px",
            textAlign: "center",
            color: "#809FB8",
            paddingBottom: "20px",
          }}
        >
          Selecione os gestores que pretende associar ao clube.
        </p>
        <>
          {" "}
          <Card>
            <CardHeader style={{ gap: "0" }}>
              <Search>
                <input
                  type="text"
                  placeholder="Procurar..."
                  onChange={(e) => handleSearchGestores(e.target.value)}
                />
                <IconSearch className="icon icon-search" />
              </Search>
            </CardHeader>
            <CardContent>
              <PreGrid>
                <Grid
                  style={{
                    gap: "10px",
                    gridTemplateColumns: "repeat(4,2fr)",
                    maxHeight: "300px",
                    overflowY: "scroll",
                    padding: "10px",
                  }}
                >
                  {naoGestoresClube.map((item, index) => {
                    return (
                      <Space
                        className={
                          checkboxes[item.id]
                            ? "item item-" + item.id + " checked"
                            : "item item-" + item.id
                        }
                        align="center"
                        size={10}
                      >
                        <Checkbox
                          checked={checkboxes[item.id]}
                          onChange={() => handleCheckboxChange(item.id)}
                          className="item__checkbox"
                        />
                        {
                          // <Avatar src={<img src={'https://spapapi.phormuladev.com/avatars/' + item.avatar} />} />
                        }
                        <Space.Compact direction="vertical">
                          <Text strong className="item__name">
                            {item.name} {item.apelido ? item.apelido : ""}
                          </Text>
                          <Text className="item__number">
                            {" "}
                            Sócio:{" "}
                            {item.numero_socio ? item.numero_socio : "N/A"}
                          </Text>
                        </Space.Compact>
                      </Space>
                    );
                  })}
                </Grid>
              </PreGrid>
            </CardContent>
            <CardFooter>
              <Button
                type="primary"
                onClick={() => handleUsersToClube(2)}
                style={{ background: "#494D7D" }}
              >
                Convidar
              </Button>
              <Button onClick={handleCancelModalGestores}>Cancelar</Button>
            </CardFooter>
          </Card>
        </>
      </Modal>

      <Modal
        className="modal-novo-clube"
        open={ismodalNomeClubeOpen}
        title="Editar nome do Clube"
        onCancel={() => setIsModalNomeClubeOpen(false)}
      >
        <label>Nome atual</label>
        <div>
          <Input
            type="text"
            name="nome"
            style={styles.input_class_disabled}
            value={clube?.name}
          />
        </div>
        <label style={{ marginTop: "3%", textAlign: "right" }}>Novo Nome</label>
        <div>
          <input
            type="text"
            name="novo_nome_clube"
            defaultValue=""
            style={styles.input_class}
            placeholder="Novo Nome"
            onChange={(e) => setNovoNome(e.target.value)}
          />
        </div>
        {hasError && (
          <div
            style={{
              marginTop: "2%",
              padding: "10px",
              background: "#ffc3c3",
              border: "1px solid #ffc3c3",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ExclamationCircleOutlined twoToneColor="red" />{" "}
            <span style={{ paddingLeft: "10px", fontSize: "14px" }}>
              Por favor, introduza um nome válido.
            </span>
          </div>
        )}
        <Input
          type="button"
          onClick={handleFormNomeClube}
          value="Guardar"
          style={{
            marginInlineStart: "0",
            background: "#494D7D",
            color: "#fff",
            borderRadius: "6px",
            width: "100%",
            height: "45px",
            marginTop: "2%",
          }}
        />
        <Button
          onClick={() => setIsModalNomeClubeOpen(false)}
          style={{
            marginInlineStart: "0",
            background: "#fff",
            color: "#494D7D",
            border: "1px solid #494D7D",
            borderRadius: "6px",
            width: "100%",
            height: "45px",
            marginTop: "2%",
          }}
        >
          Voltar
        </Button>
      </Modal>
      <Modal
        width={500}
        className="modal-remove-user"
        title={titleToRemove}
        open={isModalRemoveManagerOpen}
        onOk={() => handleRemoveUser()}
        onCancel={() => setIsModalRemoveUserOpen(false)}
        okText="Confirmar"
        cancelText="Cancelar"
      >
        {tipoUserToRemove == 2 ? (
          <>
            <p>
              Tem a certeza que pretende remover o utilizador "
              {usernameToRemoveSelected}" como gestor deste clube?{" "}
            </p>
          </>
        ) : (
          <>
            <p>
              Tem a certeza que pretende remover o utilizador "
              {usernameToRemoveSelected}" como membro deste clube?{" "}
            </p>
          </>
        )}
      </Modal>
      <DrawerPerfil
        // onUpdate={(e) => handleUsers(e)}
        user={userDrawer}
        show={showDrawer}
        onChange={(e) => setShowDrawer(e)}
      />
    </>
  );
}
