import styled from "styled-components";
import perfilcss from "../../../../src/Assets/css/backoffice.css";
import UserService from "../../../Services/user.service";
import Sidebar from "../../../Components/Backoffice/Sidebar";
import Card from "../../../Components/Backoffice/Card";
import Title from "../../../Components/Backoffice/Title";
import Header from "../../../Components/Backoffice/Header";
import { ReactComponent as LogoPhoto } from "../../../Assets/Images/Backoffice/Photo.svg";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

import {
  Button,
  message,
  Upload,
  Input,
  DatePicker,
  Switch,
  Modal,
  Select,
  Divider,
  Col,
  Row,
  notification,
} from "antd";
import {
  DownloadOutlined,
  LoadingOutlined,
  InboxOutlined,
  EditOutlined,
  UserOutlined,
} from "@ant-design/icons";

const { TextArea } = Input;
const { Dragger } = Upload;

import authService from "../../../Services/auth.service";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

const Wrapper = styled.main`
  background-color: #e8e9f3;
  display: grid;
  grid-template-columns: 7.083vw 1fr;
  min-height: 100vh;

  h6 {
    margin-bottom: 1% !important;
  }

  .ant-select-selection-search::placeholder {
    padding-top: 20px;
  }

  @media (max-width: 992px) {
  }
`;

const Content = styled.div`
  padding: 1.771vw;
`;

const upload = `
.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
  border-radius: 50%;
  display: block !important;
  margin-left: auto !important;
}
`;

var styles = {
  h6_label: {
    color: "#809FB8",
    fontFamily: "Roboto",
    fontSize: "14px",
    marginBottom: "1%;",
  },
  input_class: {
    width: "100%",
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
  },
  input_class2: {
    width: "100%",
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #ccc",
    color: "#0C1151",
  },
  switch_label: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    borderRadius: "8px",
    padding: "0px 16px",
    color: "#809FB8",
    border: "1px solid #809FB8",
    textAlign: "center",
  },
  upload: {
    width: "100%",
  },
};

export default function Perfil(props) {
  document.title = "SPAP - Perfil";

  const session_storage = JSON.parse(localStorage.getItem("session_data"));

  if (session_storage.all_data.user_session.avatar == null) {
    session_storage.all_data.user_session.avatar = "default-avatar.png";
  }

  const [api, contextHolder] = notification.useNotification();

  notification.config({
    placement: "bottomRight",
    bottom: 10,
    duration: 5,
    rtl: false,
  });

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [user, setUser] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalUploadOpen, setIsModalUploadOpen] = useState(false);
  const [nomeCompleto, setNomeCompleto] = useState("");
  const [tipoSocio, setTipoSocio] = useState("");
  const [numeroSocio, setNumeroSocio] = useState("");
  const [nomeFaturacao, setNomeFaturacao] = useState("");
  const [nome, setNome] = useState("");
  const [apelido, setApelido] = useState("");
  const [instituicao, setInstituicao] = useState("");
  const [instituicao2, setInstituicao2] = useState("");
  const [grau, setGrau] = useState("");
  const [grau2, setGrau2] = useState("");
  const [area, setArea] = useState("");
  const [area2, setArea2] = useState("");
  const [area3, setArea3] = useState("");
  const [telefone, setTelefone] = useState("");
  const [resumoProfissional, setResumoProfissional] = useState("");
  const [socioTitular, setSocioTitular] = useState("");
  const [orcid, setOrcid] = useState("");
  const [paginaWeb1, setPaginaWeb1] = useState("");
  const [paginaWeb2, setPaginaWeb2] = useState("");
  const [cartaoCidadao, setCartaoCidadao] = useState("");
  const [numeroContribuinte, setNumeroContribuinte] = useState("");
  const [morada, setMorada] = useState("");
  const [codigoPostal, setCodigoPostal] = useState("");
  const [pais, setPais] = useState("");
  const [cidade, setCidade] = useState("");
  const [numeroSocioESP, setNumeroSocioESP] = useState("");
  const [numeroCelula, setNumeroCelula] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [avatar, setAvatar] = useState("");
  const [mostraApresentacaoPublico, setMostraApresentacaoPublico] =
    useState("");
  const [mostraApresentacaoSocios, setMostraApresentacaoSocios] = useState("");
  const [mostraDadosProfissionaisPublico, setMostraDadosProfissionaisPublico] =
    useState("");
  const [mostraDadosProfissionaisSocios, setMostraDadosProfissionaisSocios] =
    useState("");
  const [mostraDadosPublico, setMostraDadosPublico] = useState("");
  const [mostraDadosSocios, setMostraDadosSocios] = useState("");
  const [mostraResumoPublico, setMostraResumoPublico] = useState("");
  const [mostraResumoSocios, setMostraResumoSocios] = useState("");
  const [mostraLinksPublico, setMostraLinksPublico] = useState("");
  const [mostraLinksSocios, setMostraLinksSocios] = useState("");
  const [mostraSegundaOpiniao, setMostraSegundaOpiniao] = useState("");
  const [nifInstituicao, setNifInstituicao] = useState("");
  const setCapitalize = (str) => {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  };
  const [instituicaoOptions, setInstituicaoOptions] = useState(() => {
    let inst = [
      { value: "ARS ALGARVE", label: "ARS ALGARVE" },
      {
        value: "BRITISH HOSPITAL LISBON XXI, S.A",
        label: "BRITISH HOSPITAL LISBON XXI, S.A",
      },
      { value: "CEDAP", label: "CEDAP" },
      {
        value: "CENTRO DE MEDICINA E REABILITAÇÃO DE ALCOITÃO",
        label: "CENTRO DE MEDICINA E REABILITAÇÃO DE ALCOITÃO",
      },
      {
        value: "CENTRO DE SAÚDE DE OLEIROS",
        label: "CENTRO DE SAÚDE DE OLEIROS",
      },
      {
        value: "CENTRO DE SAÚDE PEDRÓGÃO GRANDE",
        label: "CENTRO DE SAÚDE PEDRÓGÃO GRANDE",
      },
      {
        value: "CENTRO HOSPITALAR ALTO AVE E.P.E.",
        label: "CENTRO HOSPITALAR ALTO AVE E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR ALTO MINHO E.P.E.",
        label: "CENTRO HOSPITALAR ALTO MINHO E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR BAIXO ALENTEJO E.P.E.",
        label: "CENTRO HOSPITALAR BAIXO ALENTEJO E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR BAIXO VOUGA",
        label: "CENTRO HOSPITALAR BAIXO VOUGA",
      },
      {
        value: "CENTRO HOSPITALAR BARLAVENTO ALGARVIO, E.P.E.",
        label: "CENTRO HOSPITALAR BARLAVENTO ALGARVIO, E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR BARREIRO MONTIJO E.P.E.",
        label: "CENTRO HOSPITALAR BARREIRO MONTIJO E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR CALDAS DA RAINHA E.P.E.",
        label: "CENTRO HOSPITALAR CALDAS DA RAINHA E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR CASCAIS E.P.E.",
        label: "CENTRO HOSPITALAR CASCAIS E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR COVA DA BEIRA, E.P.E.",
        label: "CENTRO HOSPITALAR COVA DA BEIRA, E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR DE LEIRIA, E.P.E.",
        label: "CENTRO HOSPITALAR DE LEIRIA, E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR DO ALGARVE",
        label: "CENTRO HOSPITALAR DO ALGARVE",
      },
      {
        value: "CENTRO HOSPITALAR DO FUNCHAL",
        label: "CENTRO HOSPITALAR DO FUNCHAL",
      },
      {
        value: "CENTRO HOSPITALAR DO OESTE",
        label: "CENTRO HOSPITALAR DO OESTE",
      },
      {
        value: "CENTRO HOSPITALAR E UNIVERSITÁRIO DE COIMBRA",
        label: "CENTRO HOSPITALAR E UNIVERSITÁRIO DE COIMBRA",
      },
      {
        value: "CENTRO HOSPITALAR E UNIVERSITÁRIO DE SÃO JOÃO E.P.E.",
        label: "CENTRO HOSPITALAR E UNIVERSITÁRIO DE SÃO JOÃO E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR E UNIVERSITÁRIO DO ALGARVE",
        label: "CENTRO HOSPITALAR E UNIVERSITÁRIO DO ALGARVE",
      },
      {
        value: "CENTRO HOSPITALAR E UNIVERSITÁRIO DO PORTO E.P.E.",
        label: "CENTRO HOSPITALAR E UNIVERSITÁRIO DO PORTO E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR ENTRE DOURO E VOUGA E.P.E",
        label: "CENTRO HOSPITALAR ENTRE DOURO E VOUGA E.P.E",
      },
      {
        value: "CENTRO HOSPITALAR LISBOA OCIDENTAL E.P.E.",
        label: "CENTRO HOSPITALAR LISBOA OCIDENTAL E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR MÉDIO - TEJO  E.P.E.",
        label: "CENTRO HOSPITALAR MÉDIO - TEJO  E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR MÉDIO AVE, E.P.E.",
        label: "CENTRO HOSPITALAR MÉDIO AVE, E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR MUNDIAL CONFIANÇA - ÉVORA E.P.E.",
        label: "CENTRO HOSPITALAR MUNDIAL CONFIANÇA - ÉVORA E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR NORDESTE, E.P.E.",
        label: "CENTRO HOSPITALAR NORDESTE, E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR PÓVOA DO VARZIM/VILA CONDE E.P.E.",
        label: "CENTRO HOSPITALAR PÓVOA DO VARZIM/VILA CONDE E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR PSIQUIATRICO DE COIMBRA",
        label: "CENTRO HOSPITALAR PSIQUIATRICO DE COIMBRA",
      },
      {
        value: "CENTRO HOSPITALAR PSIQUIÁTRICO DE LISBOA E.P.E.",
        label: "CENTRO HOSPITALAR PSIQUIÁTRICO DE LISBOA E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR S. FRANCISCO,  E.P.E.",
        label: "CENTRO HOSPITALAR S. FRANCISCO,  E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR SETÚBAL, E.P.E.",
        label: "CENTRO HOSPITALAR SETÚBAL, E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR TÂMEGA E SOUSA E.P.E.",
        label: "CENTRO HOSPITALAR TÂMEGA E SOUSA E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR TONDELA VISEU E.P.E",
        label: "CENTRO HOSPITALAR TONDELA VISEU E.P.E",
      },
      {
        value: "CENTRO HOSPITALAR TRÁS OS MONTES E ALTO DOURO E.P.E",
        label: "CENTRO HOSPITALAR TRÁS OS MONTES E ALTO DOURO E.P.E",
      },
      {
        value: "CENTRO HOSPITALAR UNIVERSITÁRIO DE COIMBRA",
        label: "CENTRO HOSPITALAR UNIVERSITÁRIO DE COIMBRA",
      },
      {
        value: "CENTRO HOSPITALAR UNIVERSITÁRIO DE SANTO ANTÓNIO",
        label: "CENTRO HOSPITALAR UNIVERSITÁRIO DE SANTO ANTÓNIO",
      },
      {
        value: "CENTRO HOSPITALAR UNIVERSITÁRIO LISBOA CENTRAL E.P.E.",
        label: "CENTRO HOSPITALAR UNIVERSITÁRIO LISBOA CENTRAL E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR UNIVERSITÁRIO LISBOA NORTE, E.P.E.",
        label: "CENTRO HOSPITALAR UNIVERSITÁRIO LISBOA NORTE, E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR VILA NOVA DE GAIA / ESPINHO E.P.E.",
        label: "CENTRO HOSPITALAR VILA NOVA DE GAIA / ESPINHO E.P.E.",
      },
      {
        value: "CENTRO HOSPITALAR VILA REAL/PESO DA RÉGUA S.ª E.P.E.",
        label: "CENTRO HOSPITALAR VILA REAL/PESO DA RÉGUA S.ª E.P.E.",
      },
      {
        value: "CLÍNICA DE STO ANTÓNIO",
        label: "CLÍNICA DE STO ANTÓNIO",
      },
      {
        value: "CLÍNICA JOAQUIM CHAVES",
        label: "CLÍNICA JOAQUIM CHAVES",
      },
      {
        value: "CLÍNICA LUSÍADAS ALMADA",
        label: "CLÍNICA LUSÍADAS ALMADA",
      },
      {
        value: "CLÍNICA LUSÍADAS FARO",
        label: "CLÍNICA LUSÍADAS FARO",
      },
      {
        value: "CLÍNICA LUSÍADAS FÓRUM ALGARVE",
        label: "CLÍNICA LUSÍADAS FÓRUM ALGARVE",
      },
      {
        value: "CLÍNICA LUSÍADAS GAIA",
        label: "CLÍNICA LUSÍADAS GAIA",
      },
      {
        value: "CLÍNICA LUSÍADAS ORIENTE",
        label: "CLÍNICA LUSÍADAS ORIENTE",
      },
      {
        value: "CLÍNICA LUSÍADAS SACAVÉM",
        label: "CLÍNICA LUSÍADAS SACAVÉM",
      },
      {
        value: "CLÍNICA MÉDICA DO PADRÃO",
        label: "CLÍNICA MÉDICA DO PADRÃO",
      },
      {
        value: "CLÍNICA MÉDICA POVOA DO VARZIM - CLIPÓVOA",
        label: "CLÍNICA MÉDICA POVOA DO VARZIM - CLIPÓVOA",
      },
      {
        value: "CUF ALMADA CLÍNICA",
        label: "CUF ALMADA CLÍNICA",
      },
      {
        value: "CUF ALVALADE CLÍNICA",
        label: "CUF ALVALADE CLÍNICA",
      },
      {
        value: "CUF BELÉM CLÍNICA",
        label: "CUF BELÉM CLÍNICA",
      },
      {
        value: "CUF CASCAIS HOSPITAL",
        label: "CUF CASCAIS HOSPITAL",
      },
      {
        value: "CUF COIMBRA HOSPITAL",
        label: "CUF COIMBRA HOSPITAL",
      },
      {
        value: "CUF DESCOBERTAS HOSPITAL",
        label: "CUF DESCOBERTAS HOSPITAL",
      },
      {
        value: "CUF INFANTE SANTO HOSPITAL",
        label: "CUF INFANTE SANTO HOSPITAL",
      },
      {
        value: "CUF MAFRA CLÍNICA",
        label: "CUF MAFRA CLÍNICA",
      },
      {
        value: "CUF MIRAFLORES CLÍNICA",
        label: "CUF MIRAFLORES CLÍNICA",
      },
      {
        value: "CUF PORTO HOSPITAL",
        label: "CUF PORTO HOSPITAL",
      },
      {
        value: "CUF PORTO INSTITUTO",
        label: "CUF PORTO INSTITUTO",
      },
      {
        value: "CUF S. DOMINGOS RANA CLÍNICA",
        label: "CUF S. DOMINGOS RANA CLÍNICA",
      },
      {
        value: "CUF S. JOÃO DA MADEIRA CLÍNICA",
        label: "CUF S. JOÃO DA MADEIRA CLÍNICA",
      },
      {
        value: "CUF SANTARÉM HOSPITAL",
        label: "CUF SANTARÉM HOSPITAL",
      },
      {
        value: "CUF SINTRA CLÍNICA",
        label: "CUF SINTRA CLÍNICA",
      },
      { value: "CUF TEJO", label: "CUF TEJO" },
      {
        value: "CUF TORRES VEDRAS HOSPITAL",
        label: "CUF TORRES VEDRAS HOSPITAL",
      },
      {
        value: "CUF VISEU HOSPITAL",
        label: "CUF VISEU HOSPITAL",
      },
      {
        value: "FACULDADE DE MEDICINA DO PORTO",
        label: "FACULDADE DE MEDICINA DO PORTO",
      },
      {
        value: "FUNDAÇÃO CHAMPALIMAUD",
        label: "FUNDAÇÃO CHAMPALIMAUD",
      },
      {
        value: "GERMANO DE SOUSA - LABORATÓRIO DE ANÁLISES CLÍNICAS",
        label: "GERMANO DE SOUSA - LABORATÓRIO DE ANÁLISES CLÍNICAS",
      },
      {
        value: "HOSPITAIS PRIVADOS DE PORTUGAL - PORTO",
        label: "HOSPITAIS PRIVADOS DE PORTUGAL - PORTO",
      },
      {
        value: "HOSPITAL AGOSTINHO RIBEIRO",
        label: "HOSPITAL AGOSTINHO RIBEIRO",
      },
      {
        value: "Hospital Albert Einstein",
        label: "Hospital Albert Einstein",
      },
      {
        value: "HOSPITAL AMATO LUSITANO DE CASTELO BRANCO",
        label: "HOSPITAL AMATO LUSITANO DE CASTELO BRANCO",
      },
      {
        value: "HOSPITAL ARCEBISPO JOÃO CRISÓSTOMO-CANTANHEDE",
        label: "HOSPITAL ARCEBISPO JOÃO CRISÓSTOMO-CANTANHEDE",
      },
      {
        value: "HOSPITAL BARLAVENTO ALGARVIO, E.P.E.",
        label: "HOSPITAL BARLAVENTO ALGARVIO, E.P.E.",
      },
      {
        value: "HOSPITAL BEATRIZ ÂNGELO",
        label: "HOSPITAL BEATRIZ ÂNGELO",
      },
      {
        value: "HOSPITAL BERNARDINO LOPES DE OLIVEIRA - ALCOBAÇA",
        label: "HOSPITAL BERNARDINO LOPES DE OLIVEIRA - ALCOBAÇA",
      },
      {
        value: "HOSPITAL Cª SEG. MUNDIAL CONFIANÇA (PORTO)",
        label: "HOSPITAL Cª SEG. MUNDIAL CONFIANÇA (PORTO)",
      },
      {
        value: "HOSPITAL CÂNDIDO DE FIGUEIREDO -TONDELA",
        label: "HOSPITAL CÂNDIDO DE FIGUEIREDO -TONDELA",
      },
      {
        value: "HOSPITAL CENTRAL DO FUNCHAL",
        label: "HOSPITAL CENTRAL DO FUNCHAL",
      },
      {
        value: "HOSPITAL CRUZ VERMELHA PORTUGUESA",
        label: "HOSPITAL CRUZ VERMELHA PORTUGUESA",
      },
      {
        value: "HOSPITAL CURRY CABRAL",
        label: "HOSPITAL CURRY CABRAL",
      },
      {
        value: "HOSPITAL D. ESTEFÂNIA",
        label: "HOSPITAL D. ESTEFÂNIA",
      },
      {
        value: "HOSPITAL DA LIGA DE AMIGOS DOS HOSPITAIS - LISBOA",
        label: "HOSPITAL DA LIGA DE AMIGOS DOS HOSPITAIS - LISBOA",
      },
      {
        value: "HOSPITAL DA LUZ ARRÁBIDA",
        label: "HOSPITAL DA LUZ ARRÁBIDA",
      },
      {
        value: "HOSPITAL DA LUZ AVEIRO",
        label: "HOSPITAL DA LUZ AVEIRO",
      },
      {
        value: "HOSPITAL DA LUZ CLÍNICA DA AMADORA",
        label: "HOSPITAL DA LUZ CLÍNICA DA AMADORA",
      },
      {
        value: "HOSPITAL DA LUZ CLÍNICA DE ÁGUEDA",
        label: "HOSPITAL DA LUZ CLÍNICA DE ÁGUEDA",
      },
      {
        value: "HOSPITAL DA LUZ CLÍNICA DE AMARANTE",
        label: "HOSPITAL DA LUZ CLÍNICA DE AMARANTE",
      },
      {
        value: "HOSPITAL DA LUZ CLÍNICA DE CERVEIRA",
        label: "HOSPITAL DA LUZ CLÍNICA DE CERVEIRA",
      },
      {
        value: "HOSPITAL DA LUZ CLÍNICA DE ODIVELAS",
        label: "HOSPITAL DA LUZ CLÍNICA DE ODIVELAS",
      },
      {
        value: "HOSPITAL DA LUZ CLÍNICA DE OEIRAS",
        label: "HOSPITAL DA LUZ CLÍNICA DE OEIRAS",
      },
      {
        value: "HOSPITAL DA LUZ CLÍNICA DE OIÃ",
        label: "HOSPITAL DA LUZ CLÍNICA DE OIÃ",
      },
      {
        value: "HOSPITAL DA LUZ CLÍNICA DE VILA REAL",
        label: "HOSPITAL DA LUZ CLÍNICA DE VILA REAL",
      },
      {
        value: "HOSPITAL DA LUZ CLÍNICA DO PORTO",
        label: "HOSPITAL DA LUZ CLÍNICA DO PORTO",
      },
      {
        value: "HOSPITAL DA LUZ GUIMARÃES",
        label: "HOSPITAL DA LUZ GUIMARÃES",
      },
      {
        value: "HOSPITAL DA LUZ LISBOA",
        label: "HOSPITAL DA LUZ LISBOA",
      },
      {
        value: "HOSPITAL DA LUZ PÓVOA DE VARZIM",
        label: "HOSPITAL DA LUZ PÓVOA DE VARZIM",
      },
      {
        value: "HOSPITAL DA LUZ SETÚBAL",
        label: "HOSPITAL DA LUZ SETÚBAL",
      },
      {
        value: "HOSPITAL DA MARINHA",
        label: "HOSPITAL DA MARINHA",
      },
      {
        value: "HOSPITAL DA MISERICÓRDIA DE ÉVORA",
        label: "HOSPITAL DA MISERICÓRDIA DE ÉVORA",
      },
      {
        value: "HOSPITAL DA ORDEM TERCEIRA SÃO FRANCISCO DO PORTO",
        label: "HOSPITAL DA ORDEM TERCEIRA SÃO FRANCISCO DO PORTO",
      },
      {
        value: "HOSPITAL DA PRELADA",
        label: "HOSPITAL DA PRELADA",
      },
      {
        value: "HOSPITAL DA SANTA CASA DA MISERICORDIA DE PONTE DA BARCA",
        label: "HOSPITAL DA SANTA CASA DA MISERICORDIA DE PONTE DA BARCA",
      },
      {
        value: "HOSPITAL DA V. ORDEM TERCEIRA",
        label: "HOSPITAL DA V. ORDEM TERCEIRA",
      },
      {
        value: "HOSPITAL DAS FORÇAS ARMADAS/ FORÇA AÉREA",
        label: "HOSPITAL DAS FORÇAS ARMADAS/ FORÇA AÉREA",
      },
      {
        value: "HOSPITAL DE ALCANENA",
        label: "HOSPITAL DE ALCANENA",
      },
      {
        value: "HOSPITAL DE BRAGA",
        label: "HOSPITAL DE BRAGA",
      },
      {
        value: "HOSPITAL DE CASCAIS (PPP)",
        label: "HOSPITAL DE CASCAIS (PPP)",
      },
      {
        value: "HOSPITAL DE CASCAIS DR. JOSÉ DE ALMEIDA",
        label: "HOSPITAL DE CASCAIS DR. JOSÉ DE ALMEIDA",
      },
      {
        value: "HOSPITAL DE JESUS",
        label: "HOSPITAL DE JESUS",
      },
      {
        value: "HOSPITAL DE LOULÉ",
        label: "HOSPITAL DE LOULÉ",
      },
      {
        value: "HOSPITAL DE MAGALHÃES  LEMOS",
        label: "HOSPITAL DE MAGALHÃES  LEMOS",
      },
      {
        value: "HOSPITAL DE SANTA CRUZ",
        label: "HOSPITAL DE SANTA CRUZ",
      },
      {
        value: "Hospital de Santarém",
        label: "Hospital de Santarém",
      },
      {
        value: "HOSPITAL DE SÃO LUÍS - LISBOA",
        label: "HOSPITAL DE SÃO LUÍS - LISBOA",
      },
      {
        value: "HOSPITAL DISTRITAL DA FIGUEIRA DA FOZ, E.P.E.",
        label: "HOSPITAL DISTRITAL DA FIGUEIRA DA FOZ, E.P.E.",
      },
      {
        value: "HOSPITAL DISTRITAL DE ÁGUEDA",
        label: "HOSPITAL DISTRITAL DE ÁGUEDA",
      },
      {
        value: "HOSPITAL DISTRITAL DE CHAVES",
        label: "HOSPITAL DISTRITAL DE CHAVES",
      },
      {
        value: "HOSPITAL DISTRITAL DE LAMEGO",
        label: "HOSPITAL DISTRITAL DE LAMEGO",
      },
      {
        value: "HOSPITAL DISTRITAL DE S. JOÃO DA MADEIRA",
        label: "HOSPITAL DISTRITAL DE S. JOÃO DA MADEIRA",
      },
      {
        value: "HOSPITAL DISTRITAL DE SANTARÉM, E.P.E.",
        label: "HOSPITAL DISTRITAL DE SANTARÉM, E.P.E.",
      },
      {
        value: "HOSPITAL DISTRITAL DO FUNDÃO",
        label: "HOSPITAL DISTRITAL DO FUNDÃO",
      },
      {
        value: "HOSPITAL DISTRITAL DO POMBAL",
        label: "HOSPITAL DISTRITAL DO POMBAL",
      },
      {
        value: "HOSPITAL DIVINO ESPIRITO SANTO | PONTA DELGADA",
        label: "HOSPITAL DIVINO ESPIRITO SANTO | PONTA DELGADA",
      },
      {
        value: "HOSPITAL DO ENTRONCAMENTO",
        label: "HOSPITAL DO ENTRONCAMENTO",
      },
      {
        value: "HOSPITAL DO LITORAL ALENTEJANO",
        label: "HOSPITAL DO LITORAL ALENTEJANO",
      },
      {
        value: "HOSPITAL DOS SAMS - LISBOA",
        label: "HOSPITAL DOS SAMS - LISBOA",
      },
      {
        value: "HOSPITAL DOUTOR JOSÉ MARIA GRANDE - PORTALEGRE",
        label: "HOSPITAL DOUTOR JOSÉ MARIA GRANDE - PORTALEGRE",
      },
      {
        value: "HOSPITAL PROF. DOUTOR FERNANDO FONSECA, E.P.E",
        label: "Hospital Prof. Doutor Fernando Fonseca, E.P.E",
      },
      {
        value: "HOSPITAL DR. FRANCISCO ZAGALO-OVAR",
        label: "HOSPITAL DR. FRANCISCO ZAGALO-OVAR",
      },
      {
        value: "HOSPITAL DR. NÉLIO MENDONÇA - FUNCHAL",
        label: "HOSPITAL DR. NÉLIO MENDONÇA - FUNCHAL",
      },
      {
        value: "HOSPITAL ESPÍRITO SANTO-ÉVORA",
        label: "HOSPITAL ESPÍRITO SANTO-ÉVORA",
      },
      {
        value: "HOSPITAL GARCIA DE ORTA, E.P.E.",
        label: "HOSPITAL GARCIA DE ORTA, E.P.E.",
      },
      {
        value: "HOSPITAL GERAL COLÓNIA PORTUGUESA DO BRASIL - C.H.CCOIMBRA",
        label: "HOSPITAL GERAL COLÓNIA PORTUGUESA DO BRASIL - C.H.CCOIMBRA",
      },
      {
        value: "HOSPITAL INFANTE D. PEDRO, E.P.E.",
        label: "HOSPITAL INFANTE D. PEDRO, E.P.E.",
      },
      {
        value: "HOSPITAL INFANTIL SÃO JOÃO DE DEUS - MONTEMOR -O-NOVO",
        label: "HOSPITAL INFANTIL SÃO JOÃO DE DEUS - MONTEMOR -O-NOVO",
      },
      {
        value: "HOSPITAL INTERNACIONAL DOS AÇORES",
        label: "HOSPITAL INTERNACIONAL DOS AÇORES",
      },
      {
        value: "HOSPITAL JOAQUIM URBANO",
        label: "HOSPITAL JOAQUIM URBANO",
      },
      {
        value: "HOSPITAL JOSÉ LUCIANO DE CASTRO - ANADIA",
        label: "HOSPITAL JOSÉ LUCIANO DE CASTRO - ANADIA",
      },
      {
        value: "HOSPITAL LUSÍADAS ALBUFEIRA",
        label: "HOSPITAL LUSÍADAS ALBUFEIRA",
      },
      {
        value: "HOSPITAL LUSÍADAS AMADORA",
        label: "HOSPITAL LUSÍADAS AMADORA",
      },
      {
        value: "HOSPITAL LUSÍADAS LISBOA",
        label: "HOSPITAL LUSÍADAS LISBOA",
      },
      {
        value: "HOSPITAL LUSÍADAS PORTO",
        label: "HOSPITAL LUSÍADAS PORTO",
      },
      {
        value: "HOSPITAL MILITAR PRINCIPAL",
        label: "HOSPITAL MILITAR PRINCIPAL",
      },
      {
        value: "HOSPITAL MILITAR REGIONAL 4",
        label: "HOSPITAL MILITAR REGIONAL 4",
      },
      {
        value: "HOSPITAL MILITAR REGIONAL Nº1",
        label: "HOSPITAL MILITAR REGIONAL Nº1",
      },
      {
        value: "HOSPITAL MILITAR REGIONAL Nº2",
        label: "HOSPITAL MILITAR REGIONAL Nº2",
      },
      {
        value: "HOSPITAL NARCISO FERREIRA",
        label: "HOSPITAL NARCISO FERREIRA",
      },
      {
        value: "HOSPITAL NOSSA SENHORA DA AJUDA-ESPINHO",
        label: "HOSPITAL NOSSA SENHORA DA AJUDA-ESPINHO",
      },
      {
        value: "HOSPITAL NOSSA SENHORA DA ARRÁBIDA",
        label: "HOSPITAL NOSSA SENHORA DA ARRÁBIDA",
      },
      {
        value: "HOSPITAL NOSSA SENHORA DA ASSUNÇÃO - SEIA",
        label: "HOSPITAL NOSSA SENHORA DA ASSUNÇÃO - SEIA",
      },
      {
        value: "HOSPITAL NOSSA SENHORA DA CONCEIÇÃO DE VALONGO",
        label: "HOSPITAL NOSSA SENHORA DA CONCEIÇÃO DE VALONGO",
      },
      {
        value: "HOSPITAL NOSSA SENHORA DA GUIA - AVELAR",
        label: "HOSPITAL NOSSA SENHORA DA GUIA - AVELAR",
      },
      {
        value: "HOSPITAL NOSSA SENHORA DO ROSÁRIO, E.P.E.",
        label: "HOSPITAL NOSSA SENHORA DO ROSÁRIO, E.P.E.",
      },
      {
        value: "HOSPITAL ORDEM DA LAPA",
        label: "HOSPITAL ORDEM DA LAPA",
      },
      {
        value: "HOSPITAL ORDEM DO CARMO - PORTO",
        label: "HOSPITAL ORDEM DO CARMO - PORTO",
      },
      {
        value: "HOSPITAL ORDEM TERCEIRA DA SS TRINDADE PORTO",
        label: "HOSPITAL ORDEM TERCEIRA DA SS TRINDADE PORTO",
      },
      {
        value: "HOSPITAL ORTOPEDICO SANTANA",
        label: "HOSPITAL ORTOPEDICO SANTANA",
      },
      {
        value: "HOSPITAL PARTICULAR DA MADEIRA",
        label: "HOSPITAL PARTICULAR DA MADEIRA",
      },
      {
        value: "HOSPITAL PARTICULAR DE ALMADA",
        label: "HOSPITAL PARTICULAR DE ALMADA",
      },
      {
        value: "HOSPITAL PARTICULAR DE LISBOA",
        label: "HOSPITAL PARTICULAR DE LISBOA",
      },
      {
        value: "HOSPITAL PARTICULAR DO ALGARVE",
        label: "HOSPITAL PARTICULAR DO ALGARVE",
      },
      {
        value: "HOSPITAL PRISIONAL S. JOÃO DE DEUS",
        label: "HOSPITAL PRISIONAL S. JOÃO DE DEUS",
      },
      {
        value: "HOSPITAL PRIVADO DA BOAVISTA - HPP PORTO",
        label: "HOSPITAL PRIVADO DA BOAVISTA - HPP PORTO",
      },
      {
        value: "HOSPITAL PRIVADO DOS CLÉRIGOS - HPP - PORTO",
        label: "HOSPITAL PRIVADO DOS CLÉRIGOS - HPP - PORTO",
      },
      {
        value: "HOSPITAL PULIDO VALENTE, E.P.E.",
        label: "HOSPITAL PULIDO VALENTE, E.P.E.",
      },
      {
        value: "HOSPITAL REYNALDO DOS SANTOS-VILA FRANCA DE XIRA",
        label: "HOSPITAL REYNALDO DOS SANTOS-VILA FRANCA DE XIRA",
      },
      {
        value: "HOSPITAL S. PEDRO PESCADOR",
        label: "HOSPITAL S. PEDRO PESCADOR",
      },
      {
        value: "HOSPITAL SANTA CASA DA MISERICÓRIA DE BENAVENTE",
        label: "HOSPITAL SANTA CASA DA MISERICÓRIA DE BENAVENTE",
      },
      {
        value: "HOSPITAL SANTA CECÍLIA",
        label: "HOSPITAL SANTA CECÍLIA",
      },
      {
        value: "HOSPITAL SANTA LUZIA DE ELVAS",
        label: "HOSPITAL SANTA LUZIA DE ELVAS",
      },
      {
        value: "HOSPITAL SANTA MARIA",
        label: "HOSPITAL SANTA MARIA",
      },
      {
        value: "HOSPITAL SANTA MARIA (PORTO)",
        label: "HOSPITAL SANTA MARIA (PORTO)",
      },
      {
        value: "HOSPITAL SANTA MARIA MAIOR, E.P.E.",
        label: "HOSPITAL SANTA MARIA MAIOR, E.P.E.",
      },
      {
        value: "HOSPITAL SANTA MARTA, E.P.E.",
        label: "HOSPITAL SANTA MARTA, E.P.E.",
      },
      {
        value: "HOSPITAL SANTO ANTÓNIO E.P.E. - PORTO",
        label: "HOSPITAL SANTO ANTÓNIO E.P.E. - PORTO",
      },
      {
        value: "HOSPITAL SANTO ESPIRITO | ILHA TERCEIRA",
        label: "HOSPITAL SANTO ESPIRITO | ILHA TERCEIRA",
      },
      {
        value: "HOSPITAL SÃO MIGUEL - OLIVEIRA DE AZEMEIS",
        label: "HOSPITAL SÃO MIGUEL - OLIVEIRA DE AZEMEIS",
      },
      {
        value: "HOSPITAL SÃO PEDRO GONÇALVES TELMO-PENICHE",
        label: "HOSPITAL SÃO PEDRO GONÇALVES TELMO-PENICHE",
      },
      {
        value: "HOSPITAL SÃO SEBASTIÃO, E.P.E.",
        label: "HOSPITAL SÃO SEBASTIÃO, E.P.E.",
      },
      {
        value: "HOSPITAL SÃO TEOTÓNIO, E.P.E.",
        label: "HOSPITAL SÃO TEOTÓNIO, E.P.E.",
      },
      {
        value: "HOSPITAL SENHORA DA OLIVEIRA - GUIMARÃES",
        label: "HOSPITAL SENHORA DA OLIVEIRA - GUIMARÃES",
      },
      {
        value: "HOSPITAL SOUSA MARTINS - GUARDA",
        label: "HOSPITAL SOUSA MARTINS - GUARDA",
      },
      {
        value: "HOSPITAL VALENTIM RIBEIRO",
        label: "HOSPITAL VALENTIM RIBEIRO",
      },
      {
        value: "HOSPITAL VENERÁVEL IRMANDADE N.S. DA LAPA - PORTO",
        label: "HOSPITAL VENERÁVEL IRMANDADE N.S. DA LAPA - PORTO",
      },
      {
        value: "HOSPITAL VENERÁVEL IRMANDADE N.S. DO TERÇO DE CARIDADE - PORTO",
        label: "HOSPITAL VENERÁVEL IRMANDADE N.S. DO TERÇO DE CARIDADE - PORTO",
      },
      {
        value: "HOSPITAL VISCONDE DE SALREU-ESTARREJA",
        label: "HOSPITAL VISCONDE DE SALREU-ESTARREJA",
      },
      {
        value: "HOSPITAL VILA FRANCA DE XIRA",
        label: "HOSPITAL VILA FRANCA DE XIRA",
      },
      {
        value: "INSTITUTO CUF DE ONCOLOGIA - CLUSTER TEJO",
        label: "INSTITUTO CUF DE ONCOLOGIA - CLUSTER TEJO",
      },
      {
        value: "INSTITUTO PORTUGUÊS DE ONCOLOGIA COIMBRA",
        label: "INSTITUTO PORTUGUÊS DE ONCOLOGIA COIMBRA",
      },
      {
        value: "INSTITUTO PORTUGUÊS DE ONCOLOGIA LISBOA",
        label: "INSTITUTO PORTUGUÊS DE ONCOLOGIA LISBOA",
      },
      {
        value: "INSTITUTO PORTUGUÊS DE ONCOLOGIA PORTO",
        label: "INSTITUTO PORTUGUÊS DE ONCOLOGIA PORTO",
      },
      {
        value: "INSTITUTO PORTUGUÊS DE RITMO CARDÍACO",
        label: "INSTITUTO PORTUGUÊS DE RITMO CARDÍACO",
      },
      { value: "IPATIMUP", label: "IPATIMUP" },
      { value: "IPL / ESTeSL", label: "IPL / ESTeSL" },
      { value: "IMP DIAGNOSTICS", label: "IMP DIAGNOSTICS" },
      {
        value: "JOAQUIM CHAVES SAÚDE - CASA DE SAÚDE DE CARNAXIDE",
        label: "JOAQUIM CHAVES SAÚDE - CASA DE SAÚDE DE CARNAXIDE",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CENTRO ONCOLÓGICO DRA. NATÁLIA CHAVES",
        label: "JOAQUIM CHAVES SAÚDE - CENTRO ONCOLÓGICO DRA. NATÁLIA CHAVES",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLINICA DE CARCAVELOS",
        label: "JOAQUIM CHAVES SAÚDE - CLINICA DE CARCAVELOS",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE CASCAIS",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE CASCAIS",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE ENTRECAMPOS",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE ENTRECAMPOS",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE FARO",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE FARO",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE GASTROENTEROLOGIA DE FARO",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE GASTROENTEROLOGIA DE FARO",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE MIRAFLORES",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE MIRAFLORES",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE OEIRAS",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE OEIRAS",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE OLHÃO",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE OLHÃO",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIOLOGIA DE CASCAIS",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIOLOGIA DE CASCAIS",
      },
      {
        value:
          "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIOLOGIA DE VILA FRANCA DE XIRA",
        label:
          "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIOLOGIA DE VILA FRANCA DE XIRA",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DA MADEIRA",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DA MADEIRA",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DE SANTARÉM",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DE SANTARÉM",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DO ALGARVE",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DO ALGARVE",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DO PORTO",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE RADIONCOLOGIA DO PORTO",
      },
      {
        value: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE SINTRA",
        label: "JOAQUIM CHAVES SAÚDE - CLÍNICA DE SINTRA",
      },
      {
        value:
          "JOAQUIM CHAVES SAÚDE - DR. JOAQUIM CHAVES, LABORATÓRIO DE ANÁLISE",
        label:
          "JOAQUIM CHAVES SAÚDE - DR. JOAQUIM CHAVES, LABORATÓRIO DE ANÁLISES CLÍNICAS",
      },
      {
        value:
          "JOAQUIM CHAVES SAÚDE - DR. JOAQUIM CHAVES, LABORATÓRIO DE ANÁLISE",
        label:
          "JOAQUIM CHAVES SAÚDE - DR. JOAQUIM CHAVES, LABORATÓRIO DE ANÁLISES CLÍNICAS ALGARVE",
      },
      {
        value:
          "JOAQUIM CHAVES SAÚDE - DR. JOAQUIM CHAVES, LABORATÓRIO DE ANATOMIA PATOLÓGICA",
        label:
          "JOAQUIM CHAVES SAÚDE - DR. JOAQUIM CHAVES, LABORATÓRIO DE ANATOMIA PATOLÓGICA",
      },
      {
        value: "JOSE MELLO SAÚDE",
        label: "JOSE MELLO SAÚDE",
      },
      {
        value: "JÚLIO TEIXEIRA, SA",
        label: "JÚLIO TEIXEIRA, SA",
      },
      { value: "LENICARE", label: "LENICARE" },
      {
        value: "LENITUDES MEDICAL CENTER",
        label: "LENITUDES MEDICAL CENTER",
      },
      {
        value: "LISBON UNITED KINGDOM HOSPITAL, SERVIÇOS DE SAÚDE, S.ª",
        label: "LISBON UNITED KINGDOM HOSPITAL, SERVIÇOS DE SAÚDE, S.ª",
      },
      {
        value: "MATERNIDADE DR. ALFREDO DA COSTA",
        label: "MATERNIDADE DR. ALFREDO DA COSTA",
      },
      {
        value: "MATERNIDADE DR. DANIEL DE MATOS",
        label: "MATERNIDADE DR. DANIEL DE MATOS",
      },
      { value: "NEPHROCARE", label: "NEPHROCARE" },
      {
        value: "OFFICIAL AGENCY",
        label: "OFFICIAL AGENCY",
      },
      { value: "Reino Unido", label: "Reino Unido" },
      {
        value: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE ALMEIRIM",
        label: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE ALMEIRIM",
      },
      {
        value: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE AVINTES",
        label: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE AVINTES",
      },
      {
        value: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE ESTREMOZ",
        label: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE ESTREMOZ",
      },
      {
        value: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE MORA",
        label: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS DE MORA",
      },
      {
        value: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS FAMALICÃO",
        label: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS FAMALICÃO",
      },
      {
        value: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS JUIZ DE FORA",
        label: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS JUIZ DE FORA",
      },
      {
        value: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS PORTIMÃO",
        label: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS PORTIMÃO",
      },
      {
        value: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS RAINHA DONA LEONOR",
        label: "UNIDADE DE CUIDADOS DE SAÚDE PERSONALIZADOS RAINHA DONA LEONOR",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR AGUDA",
        label: "UNIDADE DE SAÚDE FAMILIAR AGUDA",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR ALVES MARTINS",
        label: "UNIDADE DE SAÚDE FAMILIAR ALVES MARTINS",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR BRIOSA",
        label: "UNIDADE DE SAÚDE FAMILIAR BRIOSA",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR CELAS SAÚDE",
        label: "UNIDADE DE SAÚDE FAMILIAR CELAS SAÚDE",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR COVA DA PIEDADE",
        label: "UNIDADE DE SAÚDE FAMILIAR COVA DA PIEDADE",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR CSI SEIXAL",
        label: "UNIDADE DE SAÚDE FAMILIAR CSI SEIXAL",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR DE SANTIAGO-LEIRIA",
        label: "UNIDADE DE SAÚDE FAMILIAR DE SANTIAGO-LEIRIA",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR DE TORNADA",
        label: "UNIDADE DE SAÚDE FAMILIAR DE TORNADA",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR DE VALBOM",
        label: "UNIDADE DE SAÚDE FAMILIAR DE VALBOM",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR DE VALONGO",
        label: "UNIDADE DE SAÚDE FAMILIAR DE VALONGO",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR DO MAR",
        label: "UNIDADE DE SAÚDE FAMILIAR DO MAR",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR LAVRADIO",
        label: "UNIDADE DE SAÚDE FAMILIAR LAVRADIO",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR LUSITÂNIA",
        label: "UNIDADE DE SAÚDE FAMILIAR LUSITÂNIA",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR MACTAMÃ",
        label: "UNIDADE DE SAÚDE FAMILIAR MACTAMÃ",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR RAMADA",
        label: "UNIDADE DE SAÚDE FAMILIAR RAMADA",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR SANTO ANTÓNIO DA CHARNECA",
        label: "UNIDADE DE SAÚDE FAMILIAR SANTO ANTÓNIO DA CHARNECA",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR SÃO GONÇALO",
        label: "UNIDADE DE SAÚDE FAMILIAR SÃO GONÇALO",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR SENHORA DA LAPA",
        label: "UNIDADE DE SAÚDE FAMILIAR SENHORA DA LAPA",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR TERRAS AZURARA",
        label: "UNIDADE DE SAÚDE FAMILIAR TERRAS AZURARA",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR VALE DO VEZ",
        label: "UNIDADE DE SAÚDE FAMILIAR VALE DO VEZ",
      },
      {
        value: "UNIDADE DE SAÚDE FAMILIAR VILLA LONGA",
        label: "UNIDADE DE SAÚDE FAMILIAR VILLA LONGA",
      },
      {
        value: "UNIDADE DE SAÚDE PÚBLICA DE ÉVORA",
        label: "UNIDADE DE SAÚDE PÚBLICA DE ÉVORA",
      },
      {
        value: "UNIDADE LOCAL DE SAÚDE DA GUARDA, EPE",
        label: "UNIDADE LOCAL DE SAÚDE DA GUARDA, EPE",
      },
      {
        value: "UNIDADE LOCAL DE SAÚDE DE MATOSINHOS, S.A",
        label: "UNIDADE LOCAL DE SAÚDE DE MATOSINHOS, S.A",
      },
      {
        value: "UNIDADE LOCAL DE SAÚDE DO ALTO MINHO",
        label: "UNIDADE LOCAL DE SAÚDE DO ALTO MINHO",
      },
      {
        value: "UNIDADE LOCAL DE SAÚDE DO BAIXO ALENTEJO",
        label: "UNIDADE LOCAL DE SAÚDE DO BAIXO ALENTEJO",
      },
      {
        value: "UNIDADE LOCAL DE SAÚDE DO LITORAL ALENTEJANO",
        label: "UNIDADE LOCAL DE SAÚDE DO LITORAL ALENTEJANO",
      },
      {
        value: "UNIDADE LOCAL DE SAÚDE DO NORDESTE, E.P.E.",
        label: "UNIDADE LOCAL DE SAÚDE DO NORDESTE, E.P.E.",
      },
      {
        value: "UNIDADE LOCAL DE SAÚDE DO NORTE ALENTEJANO",
        label: "UNIDADE LOCAL DE SAÚDE DO NORTE ALENTEJANO",
      },
      {
        value: "Unilabs - LAP PORTO",
        label: "Unilabs - LAP Porto",
      },
      { value: "Synlab Porto", label: "Synlab Porto" },
      { value: "Outro", label: "Outro" },
    ];

    inst.map((i) => {
      i.label = setCapitalize(i.label);
    });

    return inst;
  });

  notification.config({
    placement: "bottomRight",
    bottom: 10,
    duration: 5,
    rtl: false,
  });

  useEffect(() => {
    fetch(
      `https://spapapi.phormuladev.com/api/get-user/` +
        session_storage.all_data.user_session.id,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + session_storage.token,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar users");
        }
        return response.json();
      })
      .then((data) => {
        if (data.user.length === 0) {
          return;
        }

        let userData = data.user;
        setTipoSocio(userData.tipo_socio);
        setNomeCompleto(userData.nome_completo);
        setNumeroSocio(userData.numero_socio);
        setNomeFaturacao(userData.nome_faturacao);
        setNome(userData.name);
        setApelido(userData.apelido);
        setInstituicao(userData.instituicao);
        setInstituicao2(userData.instituicao_2);
        setGrau(userData.grau);
        setGrau2(userData.grau_2);
        setArea(userData.area_subespecializacao);
        setArea2(userData.area_subespecializacao_2);
        setArea3(userData.area_diferenciacao_3);
        setTelefone(userData.telefone);
        setResumoProfissional(userData.resumo_profissional);
        setSocioTitular(userData.socio_titular);
        setOrcid(userData.orcid);
        setPaginaWeb1(userData.pagina_web_1);
        setPaginaWeb2(userData.pagina_web_2);
        setCartaoCidadao(userData.cartao_cidadao);
        setNumeroContribuinte(userData.numero_contribuinte);
        setMorada(userData.morada);
        setCodigoPostal(userData.codigo_postal);
        setPais(userData.pais);
        setCidade(userData.cidade);
        setNumeroSocioESP(userData.numero_esp);
        setNumeroCelula(userData.numero_celula);
        setDataNascimento(() => {
          let dataToShow = userData?.data_nascimento;
          if (userData?.data_nascimento?.indexOf("/") > -1) {
            const [day, month, year] = userData?.data_nascimento?.split("/");
            dataToShow = year + "-" + month + "-" + day;
          }

          return dataToShow;
        });

        setAvatar(() => {
          if (userData.avatar != null) {
            return "https://spapapi.phormuladev.com/avatars/" + userData.avatar;
          } else {
            return "https://spapapi.phormuladev.com/avatars/default-avatar.png";
          }
        });
        setMostraApresentacaoPublico(userData.mostra_apresentacao_publico);
        setMostraApresentacaoSocios(userData.mostra_apresentacao_socios);
        setMostraDadosProfissionaisPublico(
          userData.mostra_dados_profissionais_publico
        );
        setMostraDadosProfissionaisSocios(
          userData.mostra_dados_profissionais_socios
        );
        setMostraDadosPublico(userData.mostra_dados_publico);
        setMostraDadosSocios(userData.mostra_dados_socios);
        setMostraResumoPublico(userData.mostra_resumo_publico);
        setMostraResumoSocios(userData.mostra_resumo_socios);
        setMostraLinksPublico(userData.mostra_resumo_publico);
        setMostraLinksSocios(userData.mostra_resumo_socios);
        setMostraSegundaOpiniao(userData.segunda_opiniao);
      })
      .catch((error) => console.error(error));
  }, []);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
  };

  const changeInputNumeroSocio = () => {
    let input_socio = document.querySelector(".input-socio");
    if (input_socio.classList.contains("pointer-none")) {
      input_socio.classList.remove("pointer-none");
      input_socio.focus();
    } else {
      input_socio.classList.add("pointer-none");
    }
  };

  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const handleUpload = () => {
    const formData = new FormData();

    formData.append("file", fileList[0]);

    setUploading(true);
    // You can use any AJAX library you like
    fetch("https://spapapi.phormuladev.com/api/admin/upload-avatar", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            let storage = JSON.parse(localStorage.getItem("session_data"));
            storage.all_data.user_session.avatar = data.url;
            session_storage.all_data.users.filter((user) => {
              if (user.id == storage.all_data.user_session.id) {
                user.avatar = data.url;
              }
            });
            localStorage.setItem("session_data", JSON.stringify(storage));
            setAvatar("https://spapapi.phormuladev.com/avatars/" + data.url);
          })
          .catch((err) => {});
      })
      .then(() => {
        setFileList([]);
        message.success("upload successfully.");
      })
      .catch(() => {
        message.error("upload failed.");
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const prop = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const onChangeData = (date, dateString) => {
    setDataNascimento(dateString);
  };

  console.log(mostraSegundaOpiniao);

  const handleSubmit = (event) => {
    event.preventDefault();

    let data_to_send = {
      name: nome,
      email: session_storage.all_data.user_session.email,
      apelido: apelido,
      numero_socio: numeroSocio,
      tipo_socio: tipoSocio,
      instituicao: instituicao,
      instituicao_2: instituicao2,
      grau: grau,
      grau_2: grau2,
      area_subespecializacao: area,
      area_subespecializacao_2: area2,
      telefone: telefone,
      resumo_profissional: resumoProfissional,
      socio_titular: socioTitular,
      orcid: orcid,
      pagina_web_1: paginaWeb1,
      pagina_web_2: paginaWeb2,
      cartao_cidadao: cartaoCidadao,
      numero_contribuinte: numeroContribuinte,
      morada: morada,
      codigo_postal: codigoPostal,
      pais: pais,
      mostra_apresentacao_publico: mostraApresentacaoPublico,
      mostra_apresentacao_socios: mostraApresentacaoSocios,
      mostra_dados_profissionais_publico: mostraDadosProfissionaisPublico,
      mostra_dados_profissionais_socios: mostraDadosProfissionaisSocios,
      mostra_dados_publico: mostraDadosPublico,
      mostra_dados_socios: mostraDadosSocios,
      mostra_resumo_publico: mostraResumoPublico,
      mostra_resumo_socios: mostraResumoSocios,
      mostra_links_publico: mostraLinksPublico,
      mostra_links_socios: mostraLinksSocios,
      segunda_opiniao: mostraSegundaOpiniao,
      area_diferenciacao_3: area3,
      nome_faturacao: nomeFaturacao,
      numero_celula: numeroCelula,
      numero_esp: numeroSocioESP,
      data_nascimento: dataNascimento,
      nome_completo: nomeCompleto,
      cidade: cidade,
    };
    let user_id = session_storage.all_data.user_session.id;
    let post_data = JSON.stringify(data_to_send);

    //let response = UserService.updateProfile(session_storage.token.accessToken, session_storage.all_data.user_session.id, JSON.stringify(data_to_send));
    axios
      .post(
        "https://spapapi.phormuladev.com/api/update-profile",
        {
          user_id,
          post_data,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          notification.success({
            description: "Perfil atualizado com sucesso.",
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          notification.error({ description: "Erro ao atualizar o perfil" });
        }
      });
  };

  return (
    <Wrapper>
      <div>
        <Sidebar />
      </div>

      <Content
        style={{
          margin: "12px 0px 0",
        }}
      >
        <Header />
        <Card>
          <Title>Editar Perfil</Title>
          <Divider style={{ margin: "12px 0" }} />
          <form onSubmit={handleSubmit}>
            <Row style={{ padding: "12px 0" }}>
              <Col span={16}>
                <h6 style={{ color: "#0C1151" }}>
                  Personalize o seu perfil para que outros sócios SPAP possam
                  entrar em contacto consigo
                </h6>
                <p>
                  Poderá personalizar os campos do seu perfil, bem com as
                  definições de visualização de cada área. Por definição o seu
                  perfil irá surgir na listagem de sócios.
                </p>
              </Col>
              <Col span={8}>
                <Row style={{ justifyContent: "center", alignItems: "center" }}>
                  <Col span={8} style={{ textAlign: "right" }}>
                    <div
                      style={{
                        borderRadius: "80px",
                        border: "1px solid #494D7D",
                        width: "100px",
                        height: "100px",
                        float: "right",
                      }}
                      onClick={showModal}
                    >
                      <img
                        src={avatar ? avatar : <UserOutlined />}
                        style={{
                          width: "98px",
                          height: "98px",
                          borderRadius: "50px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </Col>
                  <Col span={12} style={{ paddingLeft: "10px" }}>
                    <h6 style={{ color: "#809FB8", paddingLeft: "7px" }}>
                      Fotografia de perfil
                    </h6>
                    <Row
                      style={{
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <Col>
                        <LogoPhoto className="logo" />
                      </Col>
                      <Col>
                        <Button
                          style={{
                            color: "#494D7D",
                            textDecoration: "underline",
                            border: "none",
                            boxShadow: "none",
                            padding: "0",
                            fontSize: "12px",
                          }}
                          onClick={showModal}
                        >
                          Upload de nova imagem
                        </Button>
                      </Col>
                      {/* <Upload {...props} style={{ display: 'block' }}> */}

                      {/* </Upload> */}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ padding: "12px 0" }} gutter={[24, 8]}>
              <Col span={7}>
                <h6 style={styles.h6_label}>Nome</h6>
                <Input
                  name="nome"
                  placeholder="Nome"
                  style={styles.input_class}
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                />
              </Col>
              <Col span={7}>
                <h6 style={styles.h6_label}>Apelido</h6>
                <Input
                  name="apelido"
                  placeholder="Apelido"
                  style={styles.input_class}
                  value={apelido}
                  onChange={(e) => setApelido(e.target.value)}
                />
              </Col>

              <Col
                span={8}
                offset={2}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Row>
                  <Col style={{ marginRight: "15px" }}>
                    <h6 style={styles.h6_label}>Tipo de sócio</h6>
                    <Select
                      style={{ height: "45px" }}
                      defaultValue={tipoSocio ? tipoSocio : ""}
                      onChange={(e) => setTipoSocio(e)}
                      options={[
                        { value: "", label: "Não definido" },
                        { value: "Titular", label: "Titular" },
                        { value: "Agregado", label: "Agregado" },
                        { value: "Honorário", label: "Honorário" },
                        { value: "SPAP", label: "SPAP" },
                        { value: "SPAP+ESP", label: "SPAP+ESP" },
                        { value: "NISPAP", label: "NISPAP" },
                        { value: "NISPAP+ESP", label: "NISPAP+ESP" },
                      ]}
                    />
                  </Col>
                  <Col
                    style={{ marginLeft: "15px" }}
                    className="numero-socio-input"
                  >
                    <h6 style={styles.h6_label}>Nr. de Sócio</h6>
                    <Row>
                      <Input
                        onChange={(e) => setNumeroSocio(e.target.value)}
                        className="input-socio pointer-none"
                        style={{
                          color: "#809FB8",
                          padding: "0px 5px",
                          height: "45px",
                          fontWeight: "bold",
                          textAlign: "center",
                          fontSize: "16px",
                          width: "100px",
                          border: "1px solid rgb(128, 159, 184) !important",
                        }}
                        value={numeroSocio}
                      />
                      <Button
                        style={{
                          height: "45px",
                          width: "40px",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#809FB8",
                          border: "none",
                          boxShadow: "none",
                        }}
                        icon={<EditOutlined />}
                        onClick={changeInputNumeroSocio}
                      ></Button>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ paddingTop: "20px" }}>
              <span style={{ paddingRight: "15px" }}>
                Caso pretenda surgir também como patologista para{" "}
                <u style={{ color: "#0C1151", fontWeight: "bold" }}>
                  pedido de 2ª opinião
                </u>
                , selecione a seguinte opção:
              </span>
              <Switch
                name="segundaOpiniao"
                checked={mostraSegundaOpiniao == 1 ? true : false}
                onChange={(e) => setMostraSegundaOpiniao(e)}
              />
            </Row>
            <Divider style={{ margin: "30px 0" }} />
            <Row
              style={{ display: "flex", justifyContent: "space-between" }}
              gutter={[24, 8]}
            >
              <Col span={12}>
                <h5 style={{ marginBottom: "10px", fontSize: "18px" }}>
                  Apresentação
                </h5>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <span
                  style={{
                    paddingRight: "15px",
                    color: "#809FB8",
                    fontWeight: "bold",
                  }}
                >
                  Esta secção estará visível para:
                </span>{" "}
                <Switch
                  key={"mostra_apresentacao_socio"}
                  checked={mostraApresentacaoSocios == 1 ? true : false}
                  onChange={(e) => setMostraApresentacaoSocios(e)}
                />{" "}
                <span style={{ color: "#494D7D" }}></span>Sócios SPAP{" "}
                <Switch
                  key={"mostra_apresentacao_publico"}
                  checked={mostraApresentacaoPublico == 1 ? true : false}
                  onChange={(e) => setMostraApresentacaoPublico(e)}
                  style={{ marginLeft: "10px" }}
                />{" "}
                <span style={{ color: "#494D7D" }}></span>Público geral
              </Col>
            </Row>
            <Row style={{ padding: "12px 0" }} gutter={[24, 8]}>
              <Col span={7}>
                <h6 style={styles.h6_label}>Instituição</h6>
                <Select
                  key={"instituicao"}
                  style={styles.select_class}
                  className="instituicao-select"
                  placeholder="Instituição"
                  defaultValue={instituicao ? instituicao : []}
                  onChange={(e) => setInstituicao(e)}
                  showSearch
                  options={instituicaoOptions}
                />{" "}
              </Col>
              <Col span={7}>
                <h6 style={styles.h6_label}>
                  3ª área de diferenciação (opcional)
                </h6>
                <Input
                  key={"area3"}
                  placeholder="3ª área de diferenciação"
                  style={styles.input_class}
                  value={area3}
                  onChange={(e) => setArea3(e.target.value)}
                />
              </Col>
            </Row>
            <Divider style={{ margin: "30px 0" }} />
            <Row style={{ display: "flex", justifyContent: "space-between" }}>
              <Col span={12}>
                <h5 style={{ marginBottom: "10px", fontSize: "18px" }}>
                  Dados de contacto
                </h5>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <span
                  style={{
                    paddingRight: "15px",
                    color: "#809FB8",
                    fontWeight: "bold",
                  }}
                >
                  Esta secção estará visível para:
                </span>{" "}
                <Switch
                  checked={!!mostraDadosSocios}
                  onChange={(e) => setMostraDadosSocios(e)}
                />{" "}
                <span style={{ color: "#494D7D" }}></span>E-mail{" "}
                <Switch
                  style={{ marginLeft: "10px" }}
                  checked={!!mostraDadosPublico}
                  onChange={(e) => setMostraDadosPublico(e)}
                />{" "}
                <span style={{ color: "#494D7D" }}></span>Telefone
              </Col>
            </Row>
            <Row style={{ padding: "12px 0" }} gutter={[24, 8]}>
              <Col span={7}>
                <h6 style={styles.h6_label}>Email</h6>
                <Input
                  name="email"
                  placeholder="Email"
                  style={styles.input_class}
                  value={session_storage.all_data.user_session.email}
                />
              </Col>
              <Col span={7}>
                <h6 style={styles.h6_label}>Telefone</h6>
                <Input
                  name="telefone"
                  placeholder="Telefone"
                  style={styles.input_class}
                  value={telefone}
                  onChange={(e) => setTelefone(e.target.value)}
                />
              </Col>
            </Row>
            <Divider style={{ margin: "30px 0" }} />
            <Row style={{ display: "flex", justifyContent: "space-between" }}>
              <Col span={12}>
                <h5 style={{ marginBottom: "10px", fontSize: "18px" }}>
                  Resumo Profissional
                </h5>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <span
                  style={{
                    paddingRight: "15px",
                    color: "#809FB8",
                    fontWeight: "bold",
                  }}
                >
                  Esta secção estará visível para:
                </span>{" "}
                <Switch
                  checked={!!mostraResumoSocios}
                  onChange={(e) => setMostraResumoSocios(e)}
                />{" "}
                <span style={{ color: "#494D7D" }}></span>Sócios SPAP{" "}
                <Switch
                  style={{ marginLeft: "10px" }}
                  checked={!!mostraDadosProfissionaisPublico}
                  onChange={(e) => setMostraDadosProfissionaisPublico(e)}
                />{" "}
                <span style={{ color: "#494D7D" }}></span>Público geral
              </Col>
            </Row>

            <Row style={{ padding: "12px 0" }}>
              <TextArea
                onChange={(e) => setResumoProfissional(e.target.value)}
                value={resumoProfissional ? resumoProfissional : ""}
                style={{
                  color: "#809FB8",
                  fontStyle: "italic",
                  border: "2px solid #D9E1E7",
                  borderRadius: "16px",
                }}
                placeholder="Escreva aqui a sua mensagem..."
                autoSize={{ minRows: 5 }}
              />
            </Row>
            <Row style={{ padding: "12px 0" }}>
              <Col span={24}>
                <h6 style={styles.h6_label}>ORCID</h6>
                <Input
                  name="orcid"
                  placeholder="ORCID"
                  style={styles.input_class}
                  value={orcid}
                  onChange={(e) => setOrcid(e.target.value)}
                />
              </Col>
            </Row>

            <Divider style={{ margin: "30px 0" }} />
            <Row style={{ display: "flex", justifyContent: "space-between" }}>
              <Col span={12}>
                <h5 style={{ marginBottom: "10px", fontSize: "18px" }}>
                  Sócio(s) titular(es) proponente(s)
                </h5>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <span
                  style={{
                    paddingRight: "15px",
                    color: "#809FB8",
                    fontWeight: "bold",
                  }}
                >
                  Esta secção estará visível para:
                </span>{" "}
                <Switch
                  key={"mostraDadosProfissionaisSocios"}
                  checked={!!mostraDadosProfissionaisSocios}
                  onChange={(e) => setMostraDadosProfissionaisSocios(e)}
                />{" "}
                <span style={{ color: "#494D7D" }}></span>Sócios SPAP{" "}
                <Switch
                  key={"mostraDadosProfissionaisSocios"}
                  style={{ marginLeft: "10px" }}
                  checked={!!mostraDadosProfissionaisPublico}
                  onChange={(e) => setMostraDadosProfissionaisPublico(e)}
                />{" "}
                <span style={{ color: "#494D7D" }}></span>Público geral
              </Col>
            </Row>

            <Row style={{ padding: "12px 0" }} gutter={[24, 8]}>
              <Col span={24}>
                <h6 style={styles.h6_label}>
                  Sócio(s) titular(es) proponente(s)
                </h6>
                <Input
                  name="socio_titular"
                  placeholder="Sócio titular proponente"
                  style={styles.input_class}
                  value={socioTitular}
                  onChange={(e) => setSocioTitular(e.target.value)}
                />
              </Col>
            </Row>
            <Divider style={{ margin: "30px 0" }} />
            <Row style={{ display: "flex", justifyContent: "space-between" }}>
              <Col span={12}>
                <h5 style={{ marginBottom: "10px", fontSize: "18px" }}>
                  Links de páginas web próprias
                </h5>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <span
                  style={{
                    paddingRight: "15px",
                    color: "#809FB8",
                    fontWeight: "bold",
                  }}
                >
                  Esta secção estará visível para:
                </span>{" "}
                <Switch
                  key={"mostraLinksSocios"}
                  checked={!!mostraLinksSocios}
                  onChange={(e) => setMostraLinksSocios(e)}
                />{" "}
                <span style={{ color: "#494D7D" }}></span>Sócios SPAP{" "}
                <Switch
                  key={"mostraLinksPublico"}
                  style={{ marginLeft: "10px" }}
                  checked={!!mostraLinksPublico}
                  onChange={(e) => setMostraLinksPublico(e)}
                />{" "}
                <span style={{ color: "#494D7D" }}></span>Público geral
              </Col>
            </Row>
            <Row style={{ padding: "12px 0" }} gutter={[24, 8]}>
              <Col span={7}>
                <h6 style={styles.h6_label}>Página web #1</h6>
                <Input
                  name="pagina_web_1"
                  placeholder="Página web #1"
                  style={styles.input_class}
                  value={paginaWeb1}
                  onChange={(e) => setPaginaWeb1(e.target.value)}
                />
              </Col>
              <Col span={7}>
                <h6 style={styles.h6_label}>Página web #2</h6>
                <Input
                  name="pagina_web_2"
                  placeholder="Página web #2"
                  style={styles.input_class}
                  value={paginaWeb2}
                  onChange={(e) => setPaginaWeb2(e.target.value)}
                />
              </Col>
              {/* <Col span={6} offset={1}>
                  <h6 style={styles.h6_label}>Página web #3</h6>
                  <Input  placeholder="Página web #3" style={styles.input_class } value={orcid} onChange={(e) => setOrcid(e.target.value)}/>
                </Col> */}
            </Row>
            <Divider style={{ margin: "30px 0" }} />
            <Col
              style={{
                padding: "30px",
                borderRadius: "16px",
                background: "#D6D7E2",
              }}
            >
              <h5 style={{ marginBottom: "10px", fontSize: "18px" }}>
                Dados administrativos
              </h5>
              <p>
                Esta secção é privada, e apenas a administração SPAP tem acesso
                a ver os seus dados
              </p>
              <Divider style={{ background: "#fff" }}></Divider>
              <Row style={{ padding: "6px 0" }} gutter={[24, 8]}>
                <Col span={9}>
                  <h6 style={styles.h6_label}>Nome/Entidade de faturação</h6>

                  <Input
                    name="nome_faturacao"
                    placeholder="Nome/Entidade de faturação"
                    style={styles.input_class}
                    value={nomeFaturacao}
                    onChange={(e) => setNomeFaturacao(e.target.value)}
                  />
                </Col>

                <Col span={5}>
                  <h6 style={styles.h6_label}>NIF de faturação</h6>

                  <Input
                    name="nif_faturacao"
                    placeholder="NIF de faturação"
                    style={styles.input_class}
                    value={nifInstituicao}
                    onChange={(e) => setNifInstituicao(e.target.value)}
                  />
                </Col>
              </Row>
              <Row style={{ padding: "6px 0" }} gutter={[24, 8]}>
                <Col span={7}>
                  <h6 style={styles.h6_label}>Cartão de cidadão</h6>
                  <Input
                    name="cartao_cidadao"
                    placeholder="Cartão de cidadão"
                    style={styles.input_class}
                    value={cartaoCidadao}
                    onChange={(e) => setCartaoCidadao(e.target.value)}
                  />
                </Col>
                <Col span={7}>
                  <h6 style={styles.h6_label}>Data de nascimento</h6>
                  <Input
                    name="data_nascimento"
                    type="date"
                    placeholder="Data de nascimento"
                    style={styles.input_class}
                    value={dataNascimento}
                    onChange={(e) => setDataNascimento(e.target.value)}
                  />
                </Col>
              </Row>
              <Row style={{ padding: "6px 0" }} gutter={[24, 8]}>
                <Col span={7}>
                  <h6 style={styles.h6_label}>Nº. Célula Profissional</h6>
                  <Input
                    name="numero_celula"
                    placeholder="Nº. Célula Profissional"
                    style={styles.input_class}
                    value={numeroCelula}
                    onChange={(e) => setNumeroCelula(e.target.value)}
                  />
                </Col>
                <Col span={7}>
                  <h6 style={styles.h6_label}>Nº. de Sócio ESP</h6>
                  <Input
                    name="numero_socio_esp"
                    placeholder="Nº. de Sócio ESP"
                    style={styles.input_class}
                    value={numeroSocioESP}
                    onChange={(e) => setNumeroSocioESP(e.target.value)}
                  />
                </Col>
              </Row>
              <Row style={{ padding: "6px 0" }} gutter={[24, 8]}>
                <Col span={7}>
                  <h6 style={styles.h6_label}>Morada</h6>
                  <Input
                    name="morada"
                    placeholder="Morada"
                    style={styles.input_class}
                    value={morada}
                    onChange={(e) => setMorada(e.target.value)}
                  />
                </Col>
                <Col span={7}>
                  <h6 style={styles.h6_label}>Cidade</h6>
                  <Select
                    style={{ lineheight: "45px" }}
                    onChange={(e) => setCidade(e)}
                    name="cidade"
                    showSearch
                    placeholder="Selecione uma Cidade"
                    optionFilterProp="children"
                    value={cidade ? cidade : []}
                    required
                  >
                    {Array.isArray(session_storage.cidades) &&
                      session_storage.cidades.map((cidade) => (
                        <Select.Option value={cidade.nome}>
                          {" "}
                          {cidade.nome}{" "}
                        </Select.Option>
                      ))}
                  </Select>
                </Col>
              </Row>
              <Row style={{ padding: "6px 0" }} gutter={[24, 8]}>
                <Col span={7}>
                  <h6 style={styles.h6_label}>Código Postal</h6>
                  <Input
                    name="codigo_postal"
                    placeholder="Código Postal"
                    style={styles.input_class}
                    value={codigoPostal}
                    onChange={(e) => setCodigoPostal(e.target.value)}
                  />
                </Col>
                <Col span={7}>
                  <h6 style={styles.h6_label}>País</h6>
                  <Input
                    name="pais"
                    placeholder="País"
                    style={styles.input_class}
                    value={pais}
                    onChange={(e) => setPais(e.target.value)}
                  />
                </Col>
              </Row>
              <Row style={{ padding: "6px 0" }} gutter={[24, 8]}>
                <Col span={9}>
                  <h6 style={styles.h6_label}>Nome Completo</h6>
                  <Input
                    name="nome_completo"
                    placeholder="Nome Completo"
                    style={styles.input_class}
                    value={nomeCompleto}
                    onChange={(e) => setNomeCompleto(e.target.value)}
                  />
                </Col>
                <Col span={5}>
                  <h6 style={styles.h6_label}>NIF Pessoal</h6>

                  <Input
                    name="numero_contribuinte"
                    placeholder="NIF Pessoal"
                    style={styles.input_class}
                    value={numeroContribuinte}
                    onChange={(e) => setNumeroContribuinte(e.target.value)}
                  />
                </Col>
              </Row>
            </Col>
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "20px",
              }}
            >
              <Col span={14}>
                <Row>
                  <button
                    type="submit"
                    style={{
                      borderRadius: "12px",
                      background: "#494D7D",
                      color: "#fff",
                      height: "42px",
                      border: "1px solid #494D7D",
                      padding: "0px 20px",
                    }}
                  >
                    Salvar Alterações
                  </button>
                  <button
                    style={{
                      borderRadius: "12px",
                      background: "#fff",
                      color: "#494D7D",
                      height: "42px",
                      border: "1px solid #494D7D",
                      marginLeft: "15px",
                    }}
                  >
                    Visualizar a minha página de sócio
                  </button>
                </Row>
              </Col>
              <Col span={10} style={{ textAlign: "left" }}>
                <p style={{ fontSize: "12px" }}>
                  Conheça a{" "}
                  <a
                    style={{ color: "#0C1151", fontWeight: "bold" }}
                    href=""
                    target={"_blank"}
                  >
                    política de privacidade
                  </a>{" "}
                  deste website bem como os{" "}
                  <a
                    style={{ color: "#0C1151", fontWeight: "bold" }}
                    href=""
                    target={"_blank"}
                  >
                    termos e condições
                  </a>{" "}
                  do mesmo. <br />* Campos de preenchimento obrigatório.
                </p>
              </Col>
            </Row>
          </form>
        </Card>
        <Modal
          open={isModalOpen}
          width={300}
          onCancel={hideModal}
          footer={[
            <Button
              type="primary"
              onClick={handleUpload}
              disabled={fileList.length === 0}
              loading={uploading}
              style={{
                borderRadius: "12px",
                background: "#494D7D",
                width: "100%",
                height: "45px",
              }}
            >
              {uploading ? "Uploading" : "Start Upload"}
            </Button>,
          ]}
        >
          <h5
            style={{
              marginBottom: 0,
              fontSize: "18px",
              textAlign: "center",
              paddingTop: "20px",
              paddingBottom: "10px",
            }}
          >
            Faça upload da sua imagem
          </h5>
          <p
            style={{
              marginBottom: 0,
              fontSize: "14px",
              textAlign: "center",
              color: "#809FB8",
              paddingBottom: "20px",
            }}
          >
            Tamanho máximo de upload (2 MB)
          </p>
          <Dragger {...prop} style={{ borderRadius: "12px" }}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p
              className="ant-upload-text"
              style={{ padding: "0px 10px", fontSize: "14px" }}
            >
              Clique ou arraste para esta área a imagem de perfil
            </p>
          </Dragger>
        </Modal>
      </Content>
    </Wrapper>
  );
}
