import { useState, useEffect } from "react";
import {
  Button,
  Tabs,
  notification,
  Space,
  Drawer,
  Input,
  Row,
  Col,
  Modal,
  Upload,
  Typography,
  Divider,
} from "antd";
const { Dragger } = Upload;
const { Text } = Typography;
const { TextArea } = Input;
import axios from "axios";
import SecondTab from "../Socios/secondTab";
import FirstTab from "../Socios/firstTab";
import ThirdTab from "../Socios/thirdTab";
import PagamentosTab from "../Socios/PagamentosTab";
import authService from "../../../Services/auth.service";

export default function DrawerPerfil(props) {
  let userDrawer = props.user;

  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const [isModalRemoveSocioOpen, setIsModalRemoveSocioOpen] = useState(false);

  const [api, contextHolder] = notification.useNotification();

  notification.config({
    placement: "bottomRight",
    bottom: 10,
    duration: 5,
    rtl: false,
  });

  const handleClose = () => {
    props.onChange(false);
  };

  const tabItems = [
    {
      key: "1",
      label: `Editar Perfil`,
      children: <FirstTab user={userDrawer} />,
    },
    {
      key: "2",
      label: `Dados Administrativos`,
      children: <SecondTab user={userDrawer} />,
    },
  ];

  const handleSubmit = (user_id) => {
    var form = new FormData(document.getElementById("update-profile-form"));

    var email = form.get("email");
    var orcid = form.get("orcid");
    var name = form.get("name");
    var apelido = form.get("apelido");
    var numeroSocio = form.get("numero_socio");
    var nifInstituicao = form.get("nif_instituicao");
    var tipoSocio = document.querySelector(
      ".tipo-socio-select .ant-select-selection-item"
    )?.title;
    var instituicao = document.querySelector(
      ".instituicao-select .ant-select-selection-item"
    )?.title;
    var instituicao2 = form.get("instituicao_2");
    var paginaWeb1 = form.get("pagina_web_1");
    var paginaWeb2 = form.get("pagina_web_2");
    var area = document.querySelector(
      ".area-select .ant-select-selection-item"
    )?.title;
    var area2 = document.querySelector(
      ".area2-select .ant-select-selection-item"
    )?.title;
    var area3 = form.get("area_3");
    var grau = document.querySelector(
      ".grau-select .ant-select-selection-item"
    )?.textContent;
    var grau2 = form.get("grau_2");
    var telefone = form.get("telefone");
    var resumoProfissional = form.get("resumo_profissional");
    var socioTitular = form.get("socio_titular");
    var morada = form.get("morada");
    var pais = form.get("pais");
    var cidade = document.querySelector(
      ".cidade-select .ant-select-selection-item"
    )?.textContent;
    var codigoPostal = form.get("codigo_postal");
    var cartaoCidadao = form.get("cartao_cidadao");
    var numeroContribuinte = form.get("numero_contribuinte");
    var mostraSegundaOpiniao =
      document.getElementById("mostra_segunda_opiniao").ariaChecked == "true"
        ? 1
        : 0;
    var mostraApresentacaoPublico =
      document.getElementById("mostra_apresentacao_publico").ariaChecked ==
      "true"
        ? 1
        : 0;
    var mostraApresentacaoSocios =
      document.getElementById("mostra_apresentacao_socios").ariaChecked ==
      "true"
        ? 1
        : 0;
    var mostraDadosProfissionaisPublico =
      document.getElementById("mostra_dados_profissionais_publico")
        .ariaChecked == "true"
        ? 1
        : 0;
    var mostraDadosProfissionaisSocios =
      document.getElementById("mostra_dados_profissionais_socios")
        .ariaChecked == "true"
        ? 1
        : 0;
    var mostraDadosPublico =
      document.getElementById("mostra_dados_publico").ariaChecked == "true"
        ? 1
        : 0;
    var mostraDadosSocios =
      document.getElementById("mostra_dados_socios").ariaChecked == "true"
        ? 1
        : 0;
    var mostraResumoPublico =
      document.getElementById("mostra_resumo_publico").ariaChecked == "true"
        ? 1
        : 0;
    var mostraResumoSocios =
      document.getElementById("mostra_resumo_socios").ariaChecked == "true"
        ? 1
        : 0;
    var mostraLinksPublico =
      document.getElementById("mostra_links_publico").ariaChecked == "true"
        ? 1
        : 0;
    var mostraLinksSocios =
      document.getElementById("mostra_links_socios").ariaChecked == "true"
        ? 1
        : 0;
    var numeroCelula = form.get("numero_celula");
    var numeroEsp = form.get("numero_esp");
    var nomeFaturacao = form.get("nome_faturacao");
    var dataNascimento = form.get("data_nascimento");
    var nomeCompleto = form.get("nome_completo");

    let data_to_send = {
      name: name,
      apelido: apelido,
      numero_socio: numeroSocio,
      tipo_socio: tipoSocio,
      instituicao: instituicao,
      instituicao_2: instituicao2,
      grau: grau,
      grau_2: grau2,
      area_subespecializacao: area,
      area_subespecializacao_2: area2,
      area_diferenciacao_3: area3,
      telefone: telefone,
      resumo_profissional: resumoProfissional,
      socio_titular: socioTitular,
      orcid: orcid,
      pagina_web_1: paginaWeb1,
      pagina_web_2: paginaWeb2,
      cartao_cidadao: cartaoCidadao,
      numero_contribuinte: numeroContribuinte,
      cidade: cidade,
      morada: morada,
      codigo_postal: codigoPostal,
      pais: pais,
      mostra_apresentacao_publico: mostraApresentacaoPublico,
      mostra_apresentacao_socios: mostraApresentacaoSocios,
      mostra_dados_profissionais_publico: mostraDadosProfissionaisPublico,
      mostra_dados_profissionais_socios: mostraDadosProfissionaisSocios,
      mostra_dados_publico: mostraDadosPublico,
      mostra_dados_socios: mostraDadosSocios,
      mostra_resumo_publico: mostraResumoPublico,
      mostra_resumo_socios: mostraResumoSocios,
      mostra_links_publico: mostraLinksPublico,
      mostra_links_socios: mostraLinksSocios,
      segunda_opiniao: mostraSegundaOpiniao,
      nome_faturacao: nomeFaturacao,
      nome_completo: nomeCompleto,
      numero_esp: numeroEsp,
      numero_celula: numeroCelula,
      data_nascimento: dataNascimento,
      nif_instituicao: nifInstituicao,
    };

    let post_data = JSON.stringify(data_to_send);

    //let response = UserService.updateProfile(session_storage.token.accessToken, session_storage.all_data.user_session.id, JSON.stringify(data_to_send));
    axios
      .post(
        "https://spapapi.phormuladev.com/api/update-profile",
        {
          user_id,
          post_data,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          notification.success({
            description: "Perfil atualizado com sucesso",
          });
          props.onUpdate(res.data.users);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          notification.error({ description: "Erro ao atualizar o perfil" });
        }
      });
  };

  const handleRemoveSocio = () => {
    setIsModalRemoveSocioOpen(true);
  };

  const removeSocio = () => {
    let socioToRemove = userDrawer.id;
    axios
      .post(
        "https://spapapi.phormuladev.com/api/admin/remove-socio",
        {
          socioToRemove,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        setIsModalRemoveSocioOpen(false);
        notification.success({
          description:
            'O sócio "' + userDrawer.name + '" foi removido com sucesso.',
        });
        setTimeout(() => {
          window.location.reload();
        }, [2000]);
      })
      .catch((error) => {});
  };

  return (
    <>
      <Drawer
        title="Editar perfil"
        width={720}
        onClose={handleClose}
        open={props.show}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button
              onClick={(e) => handleSubmit(userDrawer.id)}
              type="submit"
              style={{
                borderRadius: "6px",
                background: "#494D7D",
                color: "#fff",
              }}
            >
              Guardar
            </Button>
          </Space>
        }
      >
        <form id="update-profile-form">
          <Tabs defaultActiveKey="1" items={tabItems} />
        </form>
        <Divider></Divider>
        <Row
          style={{
            padding: "30px",
            borderRadius: "10px",
            background: "rgb(214, 215, 226)",
            margin: "20px 0",
          }}
          align="middle"
          gutter={[8, 8]}
        >
          <Col span={24}>
            <p className="mb-0 align-center">Deseja remover este sócio?</p>
            <Button
              style={{
                width: "50%",
                height: "45px",
                margin: "0 auto",
                display: "block",
                color: "#fff",
                background: "#f76565",
              }}
              onClick={handleRemoveSocio}
            >
              Remover sócio
            </Button>
          </Col>
        </Row>
      </Drawer>
      <Modal
        width={500}
        className="modal-decline-user"
        title="Remover Sócio"
        open={isModalRemoveSocioOpen}
        onOk={removeSocio}
        onCancel={() => setIsModalRemoveSocioOpen(false)}
        okText="Confirmar"
        cancelText="Cancelar"
      >
        <p>
          Tem a certeza que pretende remover o sócio <b>{userDrawer.name}</b>?
        </p>
      </Modal>
    </>
  );
}
