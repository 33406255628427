import React, { useState, useEffect, react } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Tabs,
  Dropdown,
  message,
  Switch,
  Divider,
  Space,
  Row,
  Col,
  notification,
  DatePicker,
  Form,
  Drawer,
  Tooltip,
  Input,
  Radio,
  Checkbox,
  Modal,
  Message,
  Table,
  Upload,
  Select,
  Pagination,
  Avatar,
  Typography,
} from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { ReactComponent as IconUpload } from "../../../Assets/Images/Backoffice/Upload-off.svg";
const { Dragger } = Upload;
const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;
import authService from "../../../Services/auth.service";
import axios from "axios";
import dayjs from "dayjs";

import { saveAs } from "file-saver";

var styles = {
  h6_label: {
    color: "#809FB8",
    fontFamily: "Roboto",
    fontSize: "15px",
  },
  input_class: {
    width: "100%",
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
  },
  switch_label: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    borderRadius: "8px",
    padding: "0px 16px",
    color: "#809FB8",
    border: "1px solid #809FB8",
    textAlign: "center",
  },
  upload: {
    width: "100%",
  },
  input_class2: {
    width: "100%",
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #ccc",
    color: "#0C1151",
  },
};

export default function PagamentosTab(props) {
  let userDrawer = props.user;

  const [purchases, setPurchases] = useState([]);

  useEffect(() => {
    setPurchases([]);
    fetchPurchases();
  }, [userDrawer]);

  const fetchPurchases = async () => {
    if (userDrawer.id) {
      try {
        const response = await axios.get(
          "https://spapapi.phormuladev.com/api/getPurchases",
          {
            params: { id_user: userDrawer.id },
          }
        );
        let dataSource = response.data.map((purchase, index) => {
          let price = purchase.payment_value;
          if (price == null || price == "") {
            switch (userDrawer.tipo_socio) {
              case "Titular":
                price = purchase.payment_value_spap_esp;
                break;
              case "NISPAP+ESP":
                price = purchase.payment_value_nispap_esp;
                break;
              case "NISPAP":
                price = purchase.payment_value_nispap;
                break;
              case "SPAP+ESP":
                price = purchase.payment_value_spap_esp;
                break;
              case "SPAP":
                price = purchase.payment_value_spap;
                break;
              default:
                price = purchase.payment_value_spap_esp;
                return false;
            }
          } else {
            if (price.endsWith(".00")) {
              price = price.slice(0, -3);
            }
          }

          return {
            key: index.toString(),
            title: purchase.title,
            date: 2024,
            payment_value: price + " €",
            status: (
              <Switch
                defaultChecked={purchase.status === "pago" ? true : false}
                onChange={(e) => onChangeStatus(e, purchase.id)}
              />
            ),
            comprovativo: (
              <a
                style={
                  purchase.payment_file_proof ? "" : { pointerEvents: "none" }
                }
                href={`https://spapapi.phormuladev.com/comprovantes/${purchase?.payment_file_proof}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconUpload style={{ rotate: "180deg", cursor: "pointer" }} />
              </a>
            ),
            recibo: (
              <IconUpload
                style={{ cursor: "pointer" }}
                onClick={() => showModal(purchase)}
              />
            ),
          };
        });
        setPurchases(dataSource);
      } catch (error) {
        console.log("error buscar purchases");
      }
    } else {
      setPurchases([]);
    }
  };

  const session_storage = JSON.parse(localStorage.getItem("session_data"));

  const [usersList, setUsersList] = useState(session_storage.all_data.users);

  const tableOnChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const [quotas, setQuotas] = useState(function (params) {
    let quotas = session_storage.all_data.quotas;
    let files = session_storage.all_data.files;
    let quotasByUser = [];
    quotas?.map((quota) => {
      if (userDrawer.id == quota.user_id) {
        files?.map((file) => {
          if (quota.recibo == file.id) {
            quota.fileName = file.file;
          }
        });
        quotasByUser.push(quota);
      }
    });
    return quotasByUser;
  });

  const menuProps = {
    onClick: false,
  };

  const [tablePagination, setTablePagination] = useState(5);
  const selectHandleChange = (value) => {
    console.log(`selected ${value}`);
    setTablePagination(value);
  };

  const columns = [
    {
      title: "Tipo",
      dataIndex: "title",
    },
    {
      title: "Ano",
      dataIndex: "date",
    },
    {
      title: "Valor",
      dataIndex: "payment_value",
    },
    {
      title: "Pago",
      dataIndex: "status",
    },
    {
      title: "Comprovativo",
      dataIndex: "comprovativo",
    },
    {
      title: "Recibo",
      dataIndex: "recibo",
    },
  ];

  const t = 1;
  const data = [];

  quotas?.map((item) =>
    data.push({
      key: item.id,
      quota: (
        <Space className="item__icons">
          <b className="user-name">{item.descricao}</b>{" "}
        </Space>
      ),
      ano: (
        <Button className="user-number">
          <b>{item.data}</b>
        </Button>
      ),
      valor: <b style={{ textAlign: "center " }}>70€</b>,
      pdf: (
        <IconUpload
          className="icon icon-upload"
          onClick={() =>
            saveFile("https://spapapi.phormuladev.com/recibos/" + item.fileName)
          }
        />
      ),
    })
  );

  const saveFile = (url) => {
    console.log(url);
    saveAs(url, "recibo.pdf");
  };

  const [fileUpload, setFileUpload] = useState([]);

  const propUpload = {
    onRemove: (file) => {
      setFileUpload([]);
    },
    beforeUpload: (file) => {
      const isPDF = file.type === "application/pdf";
      if (!isPDF) {
        notification.error({
          description: "Apenas é possível fazer upload de arquivos PDF.",
        });
        setFileUpload([]);
        return Upload.LIST_IGNORE;
      }
      setFileUpload([file]);
    },
    fileUpload,
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddPaymentOpen, setIsAddPaymentOpen] = useState(false);
  const [editPaymentId, setEditPaymentId] = useState(null);
  const [uploading, setUploading] = useState(false);

  const [plans, setPlans] = useState([]);

  const showModal = (item) => {
    setEditPaymentId(item.id);
    setFileUpload([
      {
        id: "not_new",
        name: item.payment_file_receipt,
      },
    ]);
    setIsModalVisible(true);
  };

  const showModalAddPaymentUser = () => {
    fetch(`https://spapapi.phormuladev.com/api/all-plans`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar planos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          console.log("nenhum plano");
        }

        setOptionAddPayment("");
        setValueAddPayment("");
        setIsAddPaymentOpen(true);
        setPlans(data.quotes);
      })
      .catch((error) => console.error(error));
  };

  const handleOk = () => {
    if (fileUpload[0].id === "not_new") return false;
    if (uploading) return false;

    const formData = new FormData();

    if (fileUpload.length) {
      formData.append("file", fileUpload[0]);
    } else {
      return false;
    }

    formData.append("id", editPaymentId);

    setUploading(true);
    // You can use any AJAX library you
    fetch("https://spapapi.phormuladev.com/api/update-user-recibo", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            setFileUpload([]);
            setIsModalVisible(false);
            setUploading(false);
            // TODO: talvez carregar valores pagamentos novamente aqui
          })
          .catch((err) => {});
      })
      .then(() => {
        setFileUpload([]);
        setIsModalVisible(false);
        setUploading(false);
        notification.success({
          description: "O recibo foi atualizado com sucesso!",
        });
      })
      .catch(() => {
        notification.error({
          description: "Já existe um recibo com este nome!",
        });
        setUploading(false);
      })
      .finally(() => {});
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setFileUpload([]);
  };

  const handleOkAddPaymentUser = () => {
    let order_id = `${dayjs().format("YYYYMMDDHHmmss")}${userDrawer.id}`;

    let data = {
      planId: optionAddPayment,
      userId: userDrawer.id,
      orderId: order_id,
      amount: valueAddPayment.replace(",", "."),
      userEmail: userDrawer.email,
    };

    axios
      .post("https://spapapi.phormuladev.com/api/purchaseDebit", {
        data: data,
      })
      .then((result) => {
        console.log(result);

        setIsAddPaymentOpen(false);
        setOptionAddPayment("");
        setValueAddPayment("");

        setPurchases([]);
        fetchPurchases();

        notification.success({
          message: `Sucesso`,
          description: "O pagamento foi adicionado ao usuário",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCancelAddPaymentUser = () => {
    setIsAddPaymentOpen(false);
  };

  const [valueAddPayment, setValueAddPayment] = useState("");

  const handleInputChangeValueAddPayment = (e) => {
    const newValue = e.target.value;
    // Permitir apenas dígitos numéricos
    if (/^[0-9]*$/.test(newValue)) {
      setValueAddPayment(newValue);
    }
  };

  const [optionAddPayment, setOptionAddPayment] = useState("");

  const handleOptionChangeValueAddPayment = (value) => {
    setOptionAddPayment(value);
  };

  const onChangeStatus = (checked, id) => {
    let status = checked ? "pago" : "nao pago";

    axios
      .post(
        "https://spapapi.phormuladev.com/api/updateUserPaymentStatus/" + id,
        { status },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        console.log("status switch");
        console.log(res);
      })
      .catch((error) => {});
  };

  return (
    <>
      <Col
        style={{ padding: "30px", borderRadius: "16px", background: "#D6D7E2" }}
      >
        <h5>Pagamentos em cobrança</h5>
        <p>
          Quotas que estão em cobrança, altere o estado de acordo com o
          recebimento dos valores
        </p>
        <Divider></Divider>
        <Table dataSource={purchases} columns={columns} />
        <Modal
          title="Upload de Arquivo"
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Dragger {...propUpload} fileList={[...fileUpload]}>
            <p className="ant-upload-drag-icon">
              <IconUpload />
            </p>
            <p className="ant-upload-text text">Upload do recibo</p>
            <p className="ant-upload-hint">Faça Drag and drop aqui</p>
          </Dragger>
          {fileUpload.length && fileUpload[0].id === "not_new" && (
            <a
              style={{
                paddingTop: "10px",
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
              }}
              href={`https://spapapi.phormuladev.com/recibos/${fileUpload[0].name}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <EyeOutlined style={{ paddingRight: "10px" }} /> Ver recibo atual
            </a>
          )}
        </Modal>
      </Col>
      <Divider></Divider>
      <Row
        style={{
          padding: "30px",
          borderRadius: "10px",
          background: "rgb(214, 215, 226)",
          margin: "20px 0",
        }}
        align="middle"
        gutter={[8, 8]}
      >
        <Col span={24}>
          <p className="mb-0 align-center">
            Adicionar novo de registo transferência bancária
          </p>
          <Button
            style={{
              width: "50%",
              height: "45px",
              margin: "0 auto",
              display: "block",
              color: "#fff",
              background: "#7676CC",
            }}
            onClick={() => showModalAddPaymentUser()}
          >
            Adicionar
          </Button>
        </Col>
      </Row>

      <Modal
        title="Adicionar pagamento a usuario"
        open={isAddPaymentOpen}
        onOk={handleOkAddPaymentUser}
        onCancel={handleCancelAddPaymentUser}
      >
        {plans?.length > 0 ? (
          <Select
            style={{ width: 200 }}
            placeholder="Selecione um plano"
            onChange={handleOptionChangeValueAddPayment}
          >
            {plans.map((plan) => (
              <Option key={plan.id} value={plan.id}>
                {plan.title}
              </Option>
            ))}
          </Select>
        ) : (
          <p>Cadastre planos</p>
        )}
        <Input
          placeholder="Valor"
          value={valueAddPayment}
          onChange={handleInputChangeValueAddPayment}
          style={{
            marginTop: "20px",
            paddingTop: "10px",
            paddingBottom: "10px",
            width: "120px",
          }}
        ></Input>
      </Modal>
    </>
  );
}
