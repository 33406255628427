import styled from "styled-components";

import Container from "../../../Components/Container";
import Card from "../../../Components/Backoffice/Card";
import UserService from "../../../Services/user.service";
import Header from "../../../Components/Backoffice/Header";
import Title from "../../../Components/Backoffice/Title";
import PageTitle from "../../../Components/PageTitle";
import SidebarMenu from "../../../Components/SidebarMenu";
import NotificationSound from "../../../Assets/Sounds/tap-notification.mp3";
import RecommendedLinks from "../../../Components/RecommendedLinks";
import { ReactComponent as IconClose } from "../../../Assets/Images/Backoffice/Close.svg";
import { ReactComponent as IconSearch } from "../../../Assets/Images/Backoffice/Search.svg";
const { Text } = Typography;

import {
  Col,
  Row,
  Badge,
  Avatar,
  Divider,
  Button,
  Space,
  Input,
  Typography,
  Checkbox,
  Form,
  notification,
  Popover,
  Empty,
} from "antd";
import ReactQuill from "react-quill";
import { useState, useEffect, useRef } from "react";
import {
  MessageOutlined,
  SendOutlined,
  FieldTimeOutlined,
  UserOutlined,
  EditOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import PageText from "../../../Components/PageText";
import axios, { all } from "axios";
import DrawerMensagens from "./DrawerMensagens";

const CardHeader = styled.div`
  align-items: center;
  display: grid;
  gap: 60px;
  grid-template-columns: 1fr auto auto;

  .icon-upload {
    cursor: pointer;
    height: 46px;
    width: 46px;
  }
`;

const Profile = styled.div`
  align-items: center;
  display: grid;
  gap: 10px;
  grid-template-columns: auto 1fr;
  justify-content: center;

  .profile__image {
    border: 1px solid ${(props) => props.theme.blue};
    border-radius: 360px;
  }

  .profile__text {
    color: #0c1151;x
    font-size: 14px;
    font-weight: 20px;
    letter-spacing: 0px;
    line-height: bold;
    text-align: left;
  }
`;

const PreGrid = styled.div`
  border-bottom: 1px solid #f1f4f9;
  border-top: 1px solid #f1f4f9;
  padding-top: 20px;
`;

const Search = styled.div`
  position: relative;

  input {
    width: 100%;
    background-color: #f1f5f700;
    border: 2px solid #d9e1e7;
    border-radius: 16px;
    color: #8688a8;
    font-size: 16px;
    font-style: italic;
    letter-spacing: 0px;
    line-height: 18px;
    margin: 0;
    padding: 12px 58px 12px 26px;
    outline: 0;
  }

  .icon {
    position: absolute;
    right: 26px;
    top: 8px;
  }
`;

const CardContent = styled.div`
  position: relative;

  .ant-table-cell:nth-child(2) {
    text-align: left !important;
  }

  // .ant-table-cell, .ant-table-cell p {
  //   text-align: center !important;
  // }
`;

const Wrapper = styled.main`
  margin-top: 7.813vw;

  .mb-100 {
    margin-bottom: 100px !important;
  }

  .user-item {
    height: min-content;
  }

  .h-250 {
    height: 250px !important;
  }

  .ant-empty {
    border-radius: 16px;
    border: 1px solid #c2c4d4;
    padding: 20px;
  }

  .grid-users {
    gap: 10px;
    grid-template-columns: repeat(3, 3fr);
    overflow-y: scroll;
    max-height: 250px !important;
  }

  .ql-editor * {
    font-size: 14px !important;
  }

  .click-msg {
    margin-top: 10px;
    font-size: 16px !important;
  }

  .not-new-msg-color {
    background: transparent;
    border: 1px solid #e8e9f3;
  }

  .new-msg-color {
    background: #d2d5eb !important;
  }

  .new-msg-color .msg-thread {
    color: #fff !important;
  }

  .msg-thread,
  .msg-thread * {
    font-size: 14px !important;
    line-height: 1.4;
    color: #c2c4d4;
  }

  .msg-thread p {
    margin-bottom: 2px !important;
  }

  .chat-box .ant-avatar {
    width: 75%;
    text-align: center;
    height: auto;
  }

  .chat-box p {
    font-size: 13px !important;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 15px;
    background-color: lightgrey !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #fff !important;
  }

  .ant-input {
    height: 40px;
  }

  .card-msg {
    padding: 0 !important;
    margin-bottom: 0 !important;
  }

  .span-last-msg * {
    color: #494d7d;
  }

  .card-message {
    margin-bottom: 20px;
  }

  .go-to-msg {
    color: #494d7d;
    cursor: pointer !important;
    font-size: 13px;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-30 {
    margin-bottom: 30px;
  }

  ::-webkit-scrollbar * {
    background: transparent !important; // manage scrollbar background color here
  }

  .card-header {
    grid-template-columns: 1fr auto !important;
  }

  .chat-div .ant-row {
    margin-bottom: 20px;
  }

  .chat-div {
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #e8e9f3;
    padding: 20px;
    background: #fff;
    border-radius: 16px;
  }

  .cta {
    display: flex;
    background: rgb(12, 17, 81) !important;
    padding: 0 30px;
    height: 40px;
    color: #fff !important;
  }

  .profile-box {
    grid-template-columns: auto auto !important;
    -webkit-box-pack: center;
    justify-content: end !important;
  }

  .chat-box {
    height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
  }

  .row-message {
    margin-bottom: 20px;
  }

  .message-div .ql-editor {
    min-height: 150px !important;
  }

  .new-msg-div .ql-editor {
    min-height: 200px !important;
  }

  .message-div .ql-toolbar,
  .new-msg-div .ql-toolbar {
    background: #c2c4d4 !important;
    border-color: #c2c4d4 !important;
  }

  .cancel-cta {
    height: 40px;
    padding: 0 30px;
    border: 1px solid #494d7d !important;
  }

  .message-div .ql-container,
  .new-msg-div .ql-container {
    background: #fff !important;
    border-color: #fff !important;
    height: max-content !important;
  }

  .message-div .quill,
  .new-msg-div .quill {
    margin-top: 10px;
  }

  .message-from-me {
    background: #e8e9f3;
    padding: 15px !important;
    border-radius: 6px;
    color: #0c1151;
    font-size: 12px;
  }

  .message-from-me * {
    color: #0c1151 !important;
  }

  .space-message-time {
    font-size: 11px;
  }

  .space-last-msg {
    font-size: 12px !important;
    margin-left: 10px;
    color: #809fb8 !important;
  }

  p {
    margin-bottom: 5px;
  }

  .message-from-other {
    background: #494d7d;
    padding: 15px !important;
    border-radius: 6px;
    font-size: 12px;
  }

  .message-from-other * {
    color: #fff !important;
  }

  .message-div,
  .new-msg-div {
    background: #d6d7e2;
    border-radius: 16px;
    padding: 20px;
  }

  .subject {
    color: #06152b !important;
    font-size: 17px;
  }

  @media (max-width: 992px) {
  }
`;

const LoginMessage = styled.span`
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: #0c1151;
  opacity: 1;
`;

const SectionHighlightsCards = styled.section`
  padding: 3.646vw 0;

  @media (max-width: 992px) {
    .sidebar-menu {
      margin: auto;
      place-self: center;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  ${(props) =>
    props.gap
      ? `
    gap: ${props.gap};
  `
      : `
    gap: 5.208vw;
  `}

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    gap: 50px;
  }
`;

export default function SociosMensagens(props) {
  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const [message, setMessage] = useState("");
  const [newBtn, setNewBtn] = useState(false);
  const [threadToShow, setThreadToShow] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [listMessages, setListMessages] = useState([]);
  const [listUsers, setListUsers] = useState([]);
  const [listUsersBkp, setListUsersBkp] = useState([]);
  const [threads, setThreads] = useState([]);
  const [checkboxes, setCheckboxes] = useState([]);
  const [currentSubject, setCurrentSubject] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const [currentSubjectArrayName, setCurrentSubjectArrayName] = useState("");
  const [isInit, setIsInit] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const quillRef = useRef(null);
  document.title = "SPAP - Mensagens";
  const user = JSON.parse(localStorage.getItem("session_data"));
  const [form] = Form.useForm();
  const [audioElement, setAudioElement] = useState();
  const [canPlay, setCanPlay] = useState(false);
  useEffect(() => {
    audioElement?.addEventListener("canplaythrough", (event) => {
      setCanPlay(true);
    });
  }, [audioElement]);

  const audioPlayer = useRef(null);

  useEffect(() => {
    setAudioElement(new Audio(NotificationSound));
  }, []);

  const AudioStartClick = () => {
    if (!canPlay) return;
    audioElement.play();
  };
  // stop
  const AudioStopClick = () => {
    if (!canPlay) return;
    audioElement.pause();
  };

  notification.config({
    placement: "bottomRight",
    bottom: 10,
    duration: 3,
    rtl: false,
  });

  const handleMessages = (messages, users) => {
    messages = messages.filter((msg) => {
      if (!msg.deleted_by) return msg;
      if (
        !JSON.parse(msg.deleted_by).includes(
          session_storage.all_data.user_session.id
        )
      )
        return msg;
    });
    messages.forEach((message) => {
      let otherUser =
        message.to_user != session_storage.all_data.user_session.id
          ? message.to_user
          : message.from_user;
      message.subjectName =
        session_storage.all_data.user_session.id +
        "-" +
        otherUser +
        "-" +
        message.subject;
    });
    let myMessages = Object.groupBy(messages, ({ subjectName }) => [
      subjectName,
    ]);
    let arrayNames = Object.keys(myMessages);
    let listMessages = [];

    arrayNames.forEach((array) => {
      let thread = myMessages[array];
      let sorted = thread.sort((b, a) => b.id - a.id)[
        myMessages[array].length - 1
      ];
      let firstCreated = thread.sort((a, b) =>
        a.created_at.localeCompare(b.created_at)
      )[0].created_at;
      let myUserId = session_storage.all_data.user_session.id;
      let userId =
        sorted.from_user == myUserId ? sorted.to_user : sorted.from_user;
      let otherUser = users?.filter((user) => {
        return user.id == userId;
      });

      if (otherUser.length > 0) {
        sorted.userName = otherUser[0].nome_completo;
        let showNewMsg = 0;
        if (
          sorted.is_read == 0 &&
          sorted.to_user == session_storage.all_data.user_session.id
        ) {
          showNewMsg = 1;
        }
        listMessages.push({
          from_user: sorted.from_user,
          to_user: sorted.to_user,
          subject: sorted.subject,
          name: otherUser[0].nome_completo,
          avatar: otherUser[0].avatar,
          message: sorted.message,
          created: new Date(firstCreated).toLocaleString().split(",")[0],
          lastDate: new Date(sorted.updated_at).toLocaleString().split(",")[0],
          lastTime: new Date(sorted.updated_at).toLocaleString().split(",")[1],
          subjectName: array,
          showNew: showNewMsg,
          updated_at: sorted.updated_at,
        });
      }
    });

    // listMessages = listMessages.sort((a, b) =>
    //   b.updated_at.localeCompare(a.updated_at)
    // );

    let usersList = users.filter((user) => {
      return user.id != session_storage.all_data.user_session.id;
    });

    setThreads(myMessages);
    setListUsers(usersList);
    setListUsersBkp(usersList);
    setListMessages(
      listMessages.sort((a, b) => b.updated_at.localeCompare(a.updated_at))
    );

    countNotReadMessages(listMessages);

    if (
      localStorage.getItem("currentUser") &&
      localStorage.getItem("currentSubject")
    ) {
      let thisThread = myMessages[
        localStorage.getItem("currentSubjectName")
      ].sort((a, b) => b.id - a.id);
      let userId =
        thisThread[0].from_user != session_storage.all_data.user_session.id
          ? thisThread[0].from_user
          : thisThread[0].to_user;

      let thisUser = users.filter((user) => {
        return user.id == userId;
      });

      setCurrentSubject(thisThread[0].subject);
      setThreadToShow(thisThread);
    }
  };

  const countNotReadMessages = (messages) => {
    let count = 0;
    messages.map((message) => {
      if (
        message.showNew == 1 &&
        message.to_user == session_storage.all_data.user_session.id
      ) {
        count += 1;
      }
    });

    if (count == 0) {
      window.localStorage.setItem("pendingMsg", 0);
      window.dispatchEvent(new Event("storage"));
      return;
    }

    window.localStorage.setItem("pendingMsg", count);
  };

  useEffect(() => {
    if (threadToShow.length > 0) {
      setOpenDrawer(true);
    }
  }, [threadToShow]);

  const checkIfMessageExists = () => {
    let checkProps = Object.keys(Object.assign({}, checkboxes));
    console.log(checkProps);
    if (
      !checkProps.length ||
      !form.getFieldsValue(true)?.subject?.length ||
      !quillRef?.current?.value?.length
    ) {
      notification.error({
        description:
          "Para enviar mensagem, é necessário preencher o assunto e o corpo da mensagem.",
      });
      return;
    }

    let checkIfExists = listMessages.filter((message) => {
      let thisM = message.subjectName.split("-");
      console.log(thisM);
      if (thisM[1] == checkProps[0]) {
        if (form.getFieldsValue(true)?.subject == thisM[2]) {
          return message;
        }
      }
    });

    if (checkIfExists.length) {
      notification.error({
        description:
          "Já existe uma conversa com o mesmo assunto para o utilizador selecionado.",
      });
      return;
    }

    let toUser = listUsersBkp.filter((user) => {
      return user.id == checkProps[0];
    });
    localStorage.setItem("currentUser", JSON.stringify(toUser[0]));

    let newSubjectName =
      session_storage.all_data.user_session.id +
      "-" +
      checkProps[0] +
      "-" +
      form.getFieldsValue(true)?.subject;
    localStorage.setItem("currentSubject", newSubjectName);
    localStorage.setItem("currentSubjectName", newSubjectName);
    localStorage.setItem("currentSubject", form.getFieldsValue(true)?.subject);

    sendMessage(checkProps[0], form.getFieldsValue(true)?.subject);

    form.resetFields();
    setNewBtn(false);
    setCheckboxes([]);
  };

  useEffect(() => {
    if (!isInit) {
      return;
    }

    var pusher = new Pusher("bce938f1989b6fee1ff4", {
      cluster: "eu",
    });

    var channel = pusher.subscribe("my-channel");
    channel.bind("my-event", function (data) {
      if (
        data.from_user !=
          JSON.parse(localStorage.getItem("session_data")).all_data.user_session
            .id &&
        data.to_user ==
          JSON.parse(localStorage.getItem("session_data")).all_data.user_session
            .id
      ) {
        localStorage.setItem("newmsg", "new");
        window.dispatchEvent(new Event("storage"));
        setCurrentSubject(data.subject);
        let thisUser = listUsers.filter((user) => {
          return user.id == data.from_user;
        });
        setCurrentUser(thisUser[0]);
        axios
          .get(
            "https://spapapi.phormuladev.com/api/messages/" +
              JSON.parse(localStorage.getItem("session_data")).all_data
                .user_session.id
          )
          .then((response) => {
            // AudioStartClick();
            handleMessages(response.data.messages, response.data.users);
          });
      }
    });
  }, [isInit]);

  useEffect(() => {
    axios
      .get(
        "https://spapapi.phormuladev.com/api/messages/" +
          session_storage.all_data.user_session.id
      )
      .then((response) => {
        handleMessages(response.data.messages, response.data.users);
      })
      .finally(() => {
        setIsInit(true);
      });
  }, []);

  const deleteMessage = (message) => {
    let deletedBy = [];
    if (!message.deleted_by) {
      deletedBy = [session_storage.all_data.user_session.id];
    } else {
      let currentDeleted = JSON.parse(message.deleted_by);
      currentDeleted.push(session_storage.all_data.user_session.id);
      deletedBy = currentDeleted;
    }

    let otherUser =
      message.to_user != session_storage.all_data.user_session.id
        ? message.to_user
        : message.from_user;

    var bodyFormData = new FormData();
    bodyFormData.append("user", otherUser);
    bodyFormData.append("subject", message.subject);
    bodyFormData.append("deleted_by", JSON.stringify(deletedBy));

    axios
      .post(
        "https://spapapi.phormuladev.com/api/delete-messages",
        bodyFormData,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((response) => {
        if (
          localStorage.getItem("currentUser") &&
          localStorage.getItem("currentSubject")
        ) {
          if (
            message.subject == localStorage.getItem("currentSubject") &&
            JSON.parse(localStorage.getItem("currentUser")).id == otherUser
          ) {
            localStorage.removeItem("currentUser");
            localStorage.removeItem("currentSubject");
          }
        }
        let newMessagesToList = listMessages.filter((msg) => {
          return msg.subjectName != message.subjectName;
        });
        setListMessages(newMessagesToList);
        notification.success({
          description: "Conversa apagada com sucesso.",
        });
      });
  };

  const sendMessage = (toUser, thisSubject) => {
    if (!quillRef?.current?.value.length || !thisSubject) return;
    var bodyFormData = new FormData();
    bodyFormData.append("from_user", session_storage.all_data.user_session.id);
    bodyFormData.append("to_user", toUser);
    bodyFormData.append("message", quillRef.current.value);
    bodyFormData.append("subject", thisSubject);

    axios
      .post("https://spapapi.phormuladev.com/api/messages", bodyFormData, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + session_storage.token,
        },
      })
      .then((response) => {
        notification.success({
          description: "Mensagem enviada com sucesso.",
        });
        handleMessages(response.data.messages, listUsersBkp);
        clearBodyMsg();
      });
  };

  const clearBodyMsg = () => {
    document.querySelectorAll(".ql-editor").forEach((elem) => {
      elem.innerHTML = "";
    });
  };

  const goToMessage = (message) => {
    setNewBtn(false);
    let thisThread = threads[message.subjectName].sort((a, b) => b.id - a.id);
    let userId =
      thisThread[0].from_user != session_storage.all_data.user_session.id
        ? thisThread[0].from_user
        : thisThread[0].to_user;
    let thisUser = listUsers.filter((user) => {
      return user.id == userId;
    });
    localStorage.setItem("currentUser", JSON.stringify(thisUser[0]));
    localStorage.setItem("currentSubjectName", message.subjectName);
    localStorage.setItem("currentSubject", message.subject);
    setThreadToShow(thisThread);
  };

  const handleCheckboxChange = (index) => {
    const newCheckboxes = [];
    newCheckboxes[index] = !newCheckboxes[index];
    setCheckboxes(newCheckboxes);
  };

  const showNewMessage = () => {
    setListUsers(listUsersBkp);
    form.resetFields();
    clearBodyMsg();
    setCheckboxes([]);
    setCurrentStep(0);
    setShowMessage(false);
    setNewBtn(true);
  };

  const handleSearch = (value) => {
    if (value.length == 0) {
      setListUsers(listUsersBkp);
      return;
    }

    let input = value.toLowerCase();
    let novosUsersAListar = listUsersBkp.filter((user) => {
      if (
        (user.name && user.name.toLowerCase().includes(input)) ||
        (user.apelido && user.apelido.toLowerCase().includes(input)) ||
        (user.email && user.email.toLowerCase().includes(input)) ||
        (user.numero_socio &&
          user.numero_socio.toLowerCase().includes(input)) ||
        (user.telefone && user.telefone.toLowerCase().includes(input)) ||
        (user.orcid && user.orcid.toLowerCase().includes(input)) ||
        (user.socio_titular && user.socio_titular.toLowerCase().includes(input))
      ) {
        return user;
      }
    });

    setListUsers(novosUsersAListar);
  };

  const SidebarMenuLinks = [
    {
      title: "Área pessoal",
      link: "/socios/area-de-socio",
    },
    {
      title: "Dados de perfil",
      link: "/socios/dados-de-perfil/",
    },
    {
      title: "Quotas",
      link: "/socios/quotas",
    },
    {
      title: "Pagamentos",
      link: "/socios/pagamentos",
    },
    {
      title: "Meus Eventos",
      link: "/socios/meus-eventos",
    },
    {
      title: "Mensagens",
      link: "/socios/mensagens",
    },
  ];

  return (
    <Wrapper>
      <Container>
        <Row gutter={[24]} className="mb-100">
          <Col xl={19} xs={24}>
            <PageTitle
              title="Mensagens"
              path={[
                {
                  text: "Homepage",
                  link: "/",
                },
                {
                  text: "Sócios",
                  link: "/socios",
                },
                {
                  text: "Área de sócio",
                  link: "/socios/area-de-socio",
                },
                {
                  text: "Mensagens",
                },
              ]}
              imageDesktop={require("../../../Assets/Images/img-password.png")}
              imageMobile={require("../../../Assets/Images/img-password.png")}
              imageAlt="Sócios - Mensagens"
            />
            {!newBtn && listMessages.length > 0 && (
              <Row justify={"start"} align={"middle"} className="mb-10">
                <Col>
                  <Button
                    onClick={() => {
                      showNewMessage();
                    }}
                    className="cta"
                    style={{
                      height: "42px",
                    }}
                  >
                    Criar nova mensagem <MessageOutlined />
                  </Button>
                </Col>
              </Row>
            )}

            {newBtn && (
              <>
                <Row className="card-message">
                  {currentStep == 0 && (
                    <>
                      <Col span={24} className="chat-div">
                        <Card className="card-msg">
                          <CardHeader style={{ gap: "0" }}>
                            <Row justify="space-between" align="middle">
                              <Col>
                                <Title>Escolha com quem conversar</Title>
                              </Col>
                              <Col span={10}>
                                <Search>
                                  <input
                                    type="text"
                                    placeholder="Procurar..."
                                    onChange={(e) =>
                                      handleSearch(e.target.value)
                                    }
                                  />
                                  <IconSearch className="icon icon-search" />
                                </Search>
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardContent>
                            <PreGrid>
                              <div className="h-250">
                                <Grid className="grid-users">
                                  {listUsers.map((item, index) => {
                                    return (
                                      <Space
                                        key={"grid-new-msg-" + item.id}
                                        className={
                                          checkboxes[item.id]
                                            ? "user-item item item-" +
                                              item.id +
                                              " checked"
                                            : "user-item item item-" + item.id
                                        }
                                        align="center"
                                        size={10}
                                      >
                                        <Checkbox
                                          checked={checkboxes[item.id]}
                                          onChange={() =>
                                            handleCheckboxChange(item.id)
                                          }
                                          className="item__checkbox"
                                        />
                                        {
                                          // <Avatar src={<img src={'https://spapapi.phormuladev.com/avatars/' + item.avatar} />} />
                                        }
                                        <Profile>
                                          <Badge className="profile__image">
                                            {item.avatar ? (
                                              <Avatar
                                                size={30}
                                                src={
                                                  "https://spapapi.phormuladev.com/avatars/" +
                                                  item.avatar
                                                }
                                              />
                                            ) : (
                                              <Avatar
                                                size={30}
                                                icon={<UserOutlined />}
                                              />
                                            )}
                                          </Badge>
                                          <div className="profile__text">
                                            <Text strong className="item__name">
                                              {item.name}{" "}
                                              {item.apelido ? item.apelido : ""}
                                            </Text>
                                            <br />
                                            <Text className="item__number">
                                              {" "}
                                              Sócio:{" "}
                                              {item.numero_socio
                                                ? item.numero_socio
                                                : "N/A"}
                                            </Text>
                                          </div>
                                        </Profile>
                                      </Space>
                                    );
                                  })}
                                </Grid>
                              </div>
                            </PreGrid>
                          </CardContent>
                        </Card>
                      </Col>
                    </>
                  )}
                  {currentStep == 1 && (
                    <>
                      <Col span={24} className="new-msg-div">
                        <span className="subject">
                          <b>Mensagem</b>
                        </span>
                        <Form form={form}>
                          <Form.Item noStyle name={"subject"}>
                            <Input
                              className="mt-10"
                              placeholder="Assunto"
                            ></Input>
                          </Form.Item>
                        </Form>
                        <ReactQuill
                          ref={quillRef}
                          key={"add-thread"}
                        ></ReactQuill>
                      </Col>
                    </>
                  )}
                </Row>
                <Row justify={"space-between"} className="mb-30">
                  <Col></Col>
                  <Col>
                    <Space>
                      {currentStep == 0 && (
                        <>
                          <Button
                            type="secondary"
                            className="cancel-cta"
                            onClick={() => setNewBtn(false)}
                          >
                            Fechar
                          </Button>
                          <Button
                            key={"send-msg"}
                            type="primary"
                            className="cta"
                            onClick={() => {
                              if (!checkboxes.length) {
                                return;
                              }
                              setCurrentStep(1);
                            }}
                          >
                            Seguinte
                          </Button>
                        </>
                      )}
                      {currentStep == 1 && (
                        <>
                          <Button
                            type="secondary"
                            className="cancel-cta"
                            onClick={() => setCurrentStep(0)}
                          >
                            Anterior
                          </Button>
                          <Button
                            key={"send-msg"}
                            type="primary"
                            className="cta"
                            onClick={checkIfMessageExists}
                          >
                            Enviar <SendOutlined />
                          </Button>
                        </>
                      )}
                    </Space>
                  </Col>
                </Row>
              </>
            )}
            {!listMessages.length && isInit && (
              <Empty
                description={
                  <>
                    Sem conversas. Para criar uma nova conversa, clique no botão
                    abaixo.
                    <Row
                      justify={"center"}
                      align={"middle"}
                      className="mb-10 mt-10"
                    >
                      <Col>
                        <Button
                          onClick={() => {
                            showNewMessage();
                          }}
                          className="cta"
                          style={{
                            height: "42px",
                          }}
                        >
                          Criar nova mensagem <MessageOutlined />
                        </Button>
                      </Col>
                    </Row>
                  </>
                }
              />
            )}
            {listMessages.map((message) => {
              let textPreview = message.message.replace(/<[^>]*>?/gm, " ");

              return (
                <>
                  <Card
                    className={
                      message.showNew ? "new-msg-color" : "not-new-msg-color"
                    }
                  >
                    <CardHeader className="card-header">
                      <Title className="item__title">
                        Mensagem: "{message.subject}"
                      </Title>
                      <Popover
                        className="pop-delete"
                        placement="topLeft"
                        title={
                          "Tem a certeza que pretende apagar esta conversa?"
                        }
                        content={
                          <>
                            <Row justify="end">
                              <Button
                                style={{
                                  color: "#fff",
                                  background: "#494d7d",
                                  border: "1px solid #494d7d",
                                  height: "25px",
                                  lineHeight: "12px",
                                }}
                                onClick={() => {
                                  deleteMessage(message);
                                }}
                              >
                                Confirmar
                              </Button>
                            </Row>
                          </>
                        }
                      >
                        <IconClose
                          className="icon icon-close"
                          // onClick={() => openRemovePDFModal(item.id, item.titulo)}
                        />
                      </Popover>
                    </CardHeader>
                    <Row justify={"space-between"} className="mt-10">
                      <Col>
                        <Profile>
                          <Badge className="profile__image">
                            {message.avatar ? (
                              <Avatar
                                size={40}
                                src={
                                  "https://spapapi.phormuladev.com/avatars/" +
                                  message.avatar
                                }
                              />
                            ) : (
                              <Avatar size={40} icon={<UserOutlined />} />
                            )}
                          </Badge>
                          <div className="profile__text">
                            <b>{message.name}</b>
                            <br />
                            <div
                              className="msg-thread"
                              style={{
                                fontSize: "12px",
                                lineHeight: "1.4",
                                color: "#C2C4D4",
                              }}
                              dangerouslySetInnerHTML={{ __html: textPreview }}
                            ></div>
                          </div>
                        </Profile>
                      </Col>
                      <Col>
                        <span className="span-last-msg">Ultima mensagem: </span>
                        <Space
                          className="space-last-msg"
                          direction="horizontal"
                        >
                          <div>
                            <CalendarOutlined /> {message.lastDate}
                          </div>
                          <div>
                            <FieldTimeOutlined /> {message.lastTime}
                          </div>
                        </Space>
                      </Col>
                    </Row>
                    <Divider style={{ margin: "12px 0" }} />
                    <Row>
                      <Col span={24}>
                        <span className="span-last-msg">Iniciado em: </span>
                        <Space direction="horizontal">
                          <div className="space-last-msg">
                            <CalendarOutlined /> {message.created}
                          </div>
                          <div
                            className="go-to-msg"
                            key="edit"
                            onClick={() => goToMessage(message)}
                          >
                            <EditOutlined /> Escrever
                          </div>
                        </Space>
                      </Col>
                    </Row>
                  </Card>
                </>
              );
            })}
          </Col>
          <Col xl={5} xs={24}>
            <SidebarMenu navItens={SidebarMenuLinks} alert={1} />
          </Col>
        </Row>
      </Container>

      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={[
          {
            title: "Lista de sócios",
            link: "/socios/lista-de-socios",
          },
          {
            title: "Pedir uma 2ª opinião",
            link: "/ferramentas-uteis/pedir-uma-segunda-opiniao",
          },
          {
            title: "Área pessoal",
            link: "/socios/area-de-socio",
          },
          {
            title: "Contactos",
            link: "/spap/contactos",
          },
        ]}
      />
      <DrawerMensagens
        show={openDrawer}
        onChange={(e) => {
          setOpenDrawer(e);
          if (!e) {
            setThreadToShow([]);
            localStorage.removeItem("currentSubject");
            localStorage.removeItem("currentUser");
          }
        }}
        thread={threadToShow}
        subject={localStorage.getItem("currentSubject")}
        user={JSON.parse(localStorage.getItem("currentUser"))}
        onSave={(e) => handleMessages(e, listUsersBkp)}
      />
    </Wrapper>
  );
}
