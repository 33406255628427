import styled from "styled-components";
import { usePapaParse } from "react-papaparse";
import axios, { all } from "axios";
import {
  Tour,
  Col,
  Row,
  Button,
  notification,
  Form,
  Input,
  Dropdown,
  message,
  Space,
  Tooltip,
  Radio,
  Checkbox,
  Modal,
  Message,
  Table,
  Upload,
  Select,
  Pagination,
  Divider,
} from "antd";
import {
  DeleteOutlined,
  DownOutlined,
  UserOutlined,
  CheckOutlined,
  LoadingOutlined,
  PlusOutlined,
  InboxOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import authService from "../../../Services/auth.service";
import React, { useRef, useState, useEffect } from "react";
import Sidebar from "../../../Components/Backoffice/Sidebar";
import Card from "../../../Components/Backoffice/Card";
import Title from "../../../Components/Backoffice/Title";
import UserService from "../../../Services/user.service";
import Header from "../../../Components/Backoffice/Header";
import Footer from "../../../Components/Backoffice/Footer";

const { Dragger } = Upload;
import { ReactComponent as IconUpload } from "../../../Assets/Images/Backoffice/Upload-off.svg";
import { ReactComponent as IconSearch } from "../../../Assets/Images/Backoffice/Search.svg";
import { ReactComponent as IconOptions } from "../../../Assets/Images/Backoffice/Options.svg";
import ExportButton from "../../../Components/Backoffice/ExportButton";

const Wrapper = styled.main`
  background-color: #e8e9f3;
  display: grid;
  grid-template-columns: 7.083vw 1fr;
  min-height: 100vh;

  p,
  label,
  .ant-form-item-label > label,
  .gray,
  span > i {
    color: #809fb8;
  }

  h4 {
    font-size: 18px;
    font-weight: bold;
    line-height: 25px;
    text-align: center;
    margin-bottom: 0%;
  }

  .p-maxupload {
    font-size: 14px;
    line-height: 25px;
    text-align: center;
  }

  .csv-uploader .ant-upload.ant-upload-select {
    height: 200px;
    width: 100%;
  }

  @media (max-width: 992px) {
  }
`;

const Content = styled.div`
  padding: 1.771vw;

  .table-section {
    margin-top: 70px;
  }
`;

const CardHeader = styled.div`
  align-items: center;
  display: grid;
  // gap: 60px;
  grid-template-columns: 1fr auto auto;
`;

const Search = styled.div`
  position: relative;

  input {
    background-color: #f1f5f700;
    border: 2px solid #d9e1e7;
    border-radius: 16px;
    color: #8688a8;
    font-size: 16px;
    font-style: italic;
    letter-spacing: 0px;
    line-height: 18px;
    margin: 0;
    padding: 12px 58px 12px 26px;
    outline: 0;
  }

  .icon {
    position: absolute;
    right: 26px;
    top: 8px;
  }
`;

const CardContent = styled.div`
  margin: 40px 0;
  position: relative;

  input,
  button {
    min-height: 46px;
  }

  table th {
    color: #809fb8 !important;
  }

  table td {
    color: #494d7d;
  }

  .ant-checkbox-checked span.ant-checkbox-inner,
  .ant-checkbox-checked:hover span.ant-checkbox-inner {
    background-color: #1ad598 !important;
    border-color: #1ad598 !important;
  }

  .ant-checkbox:hover span.ant-checkbox-inner {
    border-color: #1ad598 !important;
  }

  li.ant-pagination-item {
    border: 1px solid #d9e1e7cc;
  }

  li.ant-pagination-item.ant-pagination-item-active {
    background-color: #8688a8;
    border-color: #8688a8;
    color: #ffffff;
  }
`;

const TableFooter = styled.div`
  bottom: 16px;
  left: 0;
  position: absolute;
`;

const RadioGroup = styled.div`
  button {
    background-color: #494d7d;
    border: none;
    color: #ffffff;
    transition: 0.3s ease;
  }

  button:hover {
    background-color: #1ad598;
    color: #ffffff !important;
  }
`;

const UploadButtonStyle = styled.button`
  background-color: #494d7d;
  border: none;
  color: #ffffff;
  transition: 0.3s ease;
  font-size: 14px;
  height: 32px;
  padding: 4px 15px;
  border-radius: 6px;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  width: 100%;

  :hover {
    background-color: #1ad598;
    color: #ffffff !important;
  }

  &.margin {
    margin-bottom: 24px;
  }
`;
var styles = {
  h6_label: {
    color: "#809FB8",
    fontFamily: "Roboto",
    fontSize: "15px",
    marginTop: "1%",
  },
  input_class: {
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
    marginBottom: "1%",
  },
  switch_label: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    borderRadius: "8px",
    padding: "0px 16px",
    color: "#809FB8",
    border: "1px solid #809FB8",
    textAlign: "center",
  },
  upload: {
    width: "100%",
  },
};
const MyFormItemContext = React.createContext([]);
function toArr(str) {
  return Array.isArray(str) ? str : [str];
}

export default function Anticorpos(props) {
  document.title = "SPAP - Anticorpos";

  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const profile = UserService.getUserProfile();
  const { readString } = usePapaParse();
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const [selectedClone, setSelectedClone] = useState("");
  const [selectedMarca, setSelectedMarca] = useState("");
  const [selectedInstituicao, setSelectedInstituicao] = useState("");
  const [clones, setClones] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [instituicoes, setInstituicoes] = useState([]);
  const [modalInstituicaoOpen, setModalInstituicaoOpen] = useState(false);
  const [modalCloneOpen, setModalCloneOpen] = useState(false);
  const [modalMarcaOpen, setModalMarcaOpen] = useState(false);
  const [modalUploadAnticorposOpen, setModalUploadAnticorposOpen] =
    useState(false);
  const [listaAnticorposBkp, setListaAnticorposBkp] = useState("");
  const [listaAnticorpos, setListaAnticorpos] = useState([]);
  const [dataToShow, setDataToShow] = useState([]);
  const [open, setOpen] = useState(false);

  const getAnticorpos = (data) => {
    setInstituicoes(data.instituicoes);
    setMarcas(data.marcas);
    setClones(data.clones);

    let allAnticorpos = data.anticorpos;
    allAnticorpos.forEach((anticorpo) => {
      let nomeClone = data.clones
        .filter((x) => x.id === Number(anticorpo.clone))
        .map((x) => x.nome);
      let nomeMarca = data.marcas
        .filter((x) => x.id === Number(anticorpo.marca))
        .map((x) => x.nome);
      let nomeInstituicao = data.instituicoes
        .filter((x) => x.id === Number(anticorpo.instituicao))
        .map((x) => x.nome);

      anticorpo.nomeClone = nomeClone[0];
      anticorpo.nomeMarca = nomeMarca[0];
      anticorpo.nomeInstituicao = nomeInstituicao[0];
    });

    setListaAnticorpos(allAnticorpos);
    setDataToShow(() => getDataToShow(allAnticorpos));
  };

  useEffect(() => {
    fetch(`https://spapapi.phormuladev.com/api/all-anticorpos`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar anticorpos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          console.log("nenhum evento");
        }

        getAnticorpos(data);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleSearch = (event) => {
    let allAnticorpos = listaAnticorpos;

    if (event.length == 0) {
      setDataToShow(() => getDataToShow());
      return;
    }

    let input = event.toLowerCase();

    let novosAnticorposAListar = allAnticorpos.filter((anticorpo) => {
      if (
        (anticorpo.nomeClone &&
          anticorpo.nomeClone.toLowerCase().includes(input)) ||
        anticorpo.nomeInstituicao.toLowerCase().includes(input) ||
        (anticorpo.nomeMarca &&
          anticorpo.nomeMarca.toLowerCase().includes(input)) ||
        anticorpo.nome.toLowerCase().includes(input)
      ) {
        return anticorpo;
      }
    });

    setDataToShow(() => getDataToShow(novosAnticorposAListar));
  };

  notification.config({
    placement: "bottomRight",
    bottom: 10,
    duration: 5,
    rtl: false,
  });

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  // const uploadButton = (
  //   <div>
  //     {loading ? <LoadingOutlined /> : <PlusOutlined />}
  //     <div
  //       style={{
  //         marginTop: 8,
  //       }}
  //     >
  //       Upload
  //     </div>
  //   </div>
  // );

  /* Checkbox */

  const checkChange = (e) => {
    console.log(`checked = ${e.target.value}`);
  };

  /* Radio Buttons */

  const [value, setValue] = useState(1);
  const radioChange = (e) => {
    setValue(e.target.value);
  };

  const handleMenuClick = (e) => {
    message.info("Click on menu item.");
    console.log("click", e);
  };

  const handleCancelModalInstituicao = () => {
    setModalInstituicaoOpen(false);
  };

  const handleCancelModalMarca = () => {
    setModalMarcaOpen(false);
  };

  const handleCancelModalClone = () => {
    setModalCloneOpen(false);
  };

  const handleCancelModalUploadAnticorpos = () => {
    setModalUploadAnticorposOpen(false);
  };

  const handleCancelAnticorpo = () => {
    setModalCloneOpen(false);
  };

  const storeAnticorpo = (e) => {
    e.preventDefault();

    if (
      selectedInstituicao.length == 0 ||
      selectedMarca.length == 0 ||
      selectedInstituicao.length == 0
    ) {
      notification.error({
        description:
          "Para criar um novo anticorpo tem de inserir todos os campos.",
      });
      return;
    }
    let marcaSel = selectedMarca;

    var bodyFormData = new FormData();

    bodyFormData.append("nome", e.target.nome_anticorpo.value);
    bodyFormData.append("marca", selectedMarca);
    bodyFormData.append("instituicao", selectedInstituicao);
    bodyFormData.append("clone", selectedClone);

    axios
      .post(
        "https://spapapi.phormuladev.com/api/admin/store-anticorpo",
        bodyFormData,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        if (res.data.anticorpos.length) {
          getAnticorpos(res.data);
          notification.success({
            description:
              'O anticorpo "' +
              e.target.nome_anticorpo.value +
              '" foi criado com sucesso',
          });
        }
      })
      .catch((error) => {
        notification.error({
          description:
            'Erro ao criar o anticorpo "' +
            e.target.nome_anticorpo.value +
            '". Por favor, tente de novo.',
        });
      });
  };

  const handleFormInstituicao = (e) => {
    e.preventDefault();

    var bodyFormData = new FormData();

    bodyFormData.append("nome", e.target.nome.value);
    bodyFormData.append("email", e.target.email.value);
    bodyFormData.append("telefone", e.target.telefone.value);
    bodyFormData.append("responsavel", e.target.responsavel.value);

    axios
      .post(
        "https://spapapi.phormuladev.com/api/admin/store-instituicao",
        bodyFormData,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        if (res.data.instituicoes.length) {
          let storage = JSON.parse(localStorage.getItem("session_data"));
          storage.all_data.instituicoes = res.data.instituicoes;
          localStorage.setItem("session_data", JSON.stringify(storage));
          setInstituicoes(res.data.instituicoes);
          setModalInstituicaoOpen(false);
          notification.success({
            description:
              'A instituição "' +
              e.target.nome.value +
              '" foi criada com sucesso',
          });
        }
      })
      .catch((error) => {
        setModalMarcaOpen(false);
        notification.error({
          description:
            'Erro ao criar a instituição "' +
            e.target.nome.value +
            '". Por favor, tente de novo.',
        });
      });
  };

  const handleFormMarca = (e) => {
    e.preventDefault();

    var bodyFormData = new FormData();

    bodyFormData.append("nome", e.target.nome.value);

    axios
      .post(
        "https://spapapi.phormuladev.com/api/admin/store-marca",
        bodyFormData,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        if (res.data.marcas.length) {
          setMarcas(res.data.marcas);
          setModalMarcaOpen(false);
          notification.success({
            description:
              'A marca "' + e.target.nome.value + '" foi criada com sucesso',
          });
        }
      })
      .catch((error) => {
        setModalMarcaOpen(false);
        notification.error({
          description:
            'Erro ao criar a marca "' +
            e.target.nome.value +
            '". Por favor, tente de novo.',
        });
      });
  };

  const handleFormClone = (e) => {
    e.preventDefault();

    var bodyFormData = new FormData();

    bodyFormData.append("nome", e.target.nome.value);

    axios
      .post(
        "https://spapapi.phormuladev.com/api/admin/store-clone",
        bodyFormData,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        if (res.data.clones.length) {
          setClones(res.data.clones);
          setModalCloneOpen(false);
          notification.success({
            description:
              'O clone "' + e.target.nome.value + '" foi criado com sucesso',
          });
        }
      })
      .catch((error) => {
        setModalCloneOpen(false);
        notification.error({
          description:
            'Erro ao criar o clone "' +
            e.target.nome.value +
            '". Por favor, tente de novo.',
        });
      });
  };

  const items = [
    {
      label: (
        <RadioGroup>
          <Radio.Group onChange={radioChange} value={value}>
            <Space direction="vertical" className="space-align-block">
              <Radio value={1}>Aviso de cobrança</Radio>
              <Radio value={2}>Aviso de certificado</Radio>
              <Radio value={3}>Recibo de compras</Radio>
              <Radio value={4}>Ativar utilizador</Radio>
              <Radio value={5}>Desativar utilizador</Radio>
              <Button onClick={handleMenuClick}>
                <Space>
                  Enviar
                  <CheckOutlined />
                </Space>
              </Button>
            </Space>
          </Radio.Group>
        </RadioGroup>
      ),
    },
  ];
  const menuProps = {
    items,
    onClick: false,
  };

  const [tablePagination, setTablePagination] = useState(20);
  const selectHandleChange = (value) => {
    setTablePagination(value);
  };

  /* Table */

  const columns = [
    {
      title: "Anticorpo",
      dataIndex: "name",
      sorter: true,
    },
    {
      title: "Marca",
      dataIndex: "brand",
      sorter: true,
    },
    {
      title: "Clone",
      dataIndex: "clone",
      sorter: true,
    },
    {
      title: "Instituição",
      dataIndex: "institution",
      sorter: true,
    },
    // {
    //   title: (<Dropdown menu={menuProps}>
    //   <Button>
    //     <Space>
    //       Ações
    //       <DownOutlined />
    //     </Space>
    //   </Button>
    // </Dropdown>),
    //   dataIndex: 'action'
    // }
  ];

  const t = 1;

  const getDataToShow = (anticorposAListar = []) => {
    if (anticorposAListar?.length == 0) {
      anticorposAListar = listaAnticorpos;
    }

    console.log(anticorposAListar);

    let data = [];
    for (let i = 0; i < anticorposAListar.length; i++) {
      data.push({
        key: anticorposAListar[i].id,
        name: <b>{anticorposAListar[i].nome}</b>,
        brand: <b>{anticorposAListar[i].nomeMarca}</b>,
        clone: <b>{anticorposAListar[i].nomeClone}</b>,
        institution: <b>{anticorposAListar[i].nomeInstituicao}</b>,
      });
    }

    return data;
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  const tableOnChange = (pagination, filters, sorter, extra) => {};

  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleUpload = () => {
    readString(fileList[0], {
      worker: true,
      complete: (results) => {
        results.data.shift();

        if (results.data.length == 0) {
          notification.error({
            description: "O Ficheiro não tem anticorpos para inserir.",
          });
        } else {
          var bodyFormData = new FormData();
          bodyFormData.append("array", JSON.stringify(results.data));

          setUploading(true);

          fetch("https://spapapi.phormuladev.com/api/admin/upload-anticorpo", {
            method: "POST",
            body: bodyFormData,
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + session_storage.token,
            },
          }).then((response) => {
            response.json().then((data) => {
              if (data.message == "array_error") {
                notification.error({
                  description: "ERRO! O Ficheiro tem campos por preencher.",
                });
              } else {
                getAnticorpos(data);
                notification.success({
                  description: "O Ficheiro foi inserido com sucesso!",
                });
              }
              setFileList([]);
              setUploading(false);
            });
          });
        }
      },
    });
  };

  const prop = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const isCSV = file.type === "text/csv";
      if (!isCSV) {
        message.error(`${file.name} não é um ficheiro CSV`);
        setFileList([...fileList, ""]);
        return isCSV || Upload.LIST_IGNORE;
      }
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState("");

  const deleteColumn = {
    title: "",
    key: "delete",
    render: (_, record) => (
      <Button
        type="link"
        icon={<DeleteOutlined />}
        onClick={(e) => {
          setDeleteRecord(record.key);
          setModalDeleteOpen(true);
        }}
      />
    ),
  };

  const handleDelete = () => {
    if (selectedRowKeys.length > 1) {
      const ids = selectedRowKeys;
      // exclusão das linhas selecionadas
      axios
        .post(
          "https://spapapi.phormuladev.com/api/admin/remove-anticorpo",
          { ids },
          {
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + session_storage.token,
            },
          }
        )
        .then((res) => {
          if (res.length === 0) {
            console.log("nenhum anticorpos");
          }
          notification.success({
            description: "Os anticorpos foram removidos com sucesso!",
          });
          setSelectedRowKeys([]);
          setDeleteRecord("");
          setModalDeleteOpen(false);
          getAnticorpos(res.data);
        })
        .catch((error) => {
          console.error("Erro ao excluir registros:", error);
        });
    } else {
      const id = deleteRecord;
      axios
        .post(
          "https://spapapi.phormuladev.com/api/admin/remove-anticorpo",
          { id },
          {
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + session_storage.token,
            },
          }
        )
        .then((res) => {
          if (res.length === 0) {
            console.log("nenhum anticorpos");
          }
          notification.success({
            description: "O anticorpo foi removido com sucesso!",
          });
          setSelectedRowKeys([]);
          setDeleteRecord("");
          setModalDeleteOpen(false);
          getAnticorpos(res.data);
        })
        .catch((error) => {
          console.error("Erro ao excluir registro:", error);
        });
    }
  };

  const steps = [
    {
      title: "Como fazer o upload dos anticorpos?",
      description:
        "É necessário seguir a ordem das colunas do CSV em formato UTF-8 do ficheiro fornecido em cima.",
      cover: (
        <div style={{ marginBottom: "10px" }}>
          <a
            href="https://spapapi.phormuladev.com/uploads/ficheiro-template-anticorpos.csv"
            style={{
              padding: "12px 40px",
              textDecoration: "none",
              borderRadius: "6px",
              backgroundColor: "#494D7D",
              color: "#fff",
            }}
            download
          >
            Download do ficheiro template
          </a>
        </div>
      ),
      target: () => ref1.current,
    },
    {
      title: "Momento de fazer upload",
      description:
        "Assim que desejar fazer o upload dos anticorpos, basta clicar neste botão e será aberta uma modal com um campo para arrastar o ficheiro ou clicar para o introduzir manualmente.",
      target: () => ref2.current,
    },
    {
      title: "Fim",
      description: "Espero que tenha sido util!",
      target: () => ref2.current,
    },
  ];

  return (
    <Wrapper>
      <div>
        <Sidebar />
      </div>
      <Content>
        <Header />
        <Card>
          <CardHeader>
            <Col span={12}>
              <Title>Adicionar Anticorpo</Title>
              <span>
                <i>
                  A listagem dos Anticorpos disponíveis para consultar na página
                  Anticorpos
                </i>
              </span>
            </Col>
            <Col span={12} align="end">
              <Space>
                <IconOptions
                  className="icon icon-options"
                  ref={ref1}
                  onClick={() => setOpen(true)}
                />

                <IconUpload
                  className="icon icon-upload"
                  ref={ref2}
                  onClick={() => setModalUploadAnticorposOpen(true)}
                />
              </Space>
            </Col>
          </CardHeader>
          <Divider />
          <Col align="top">
            <form
              name="form_item_path"
              layout="vertical"
              onSubmit={storeAnticorpo}
            >
              <Row align="top" style={{ marginBottom: "1%" }}>
                <Col span={12} style={{ paddingRight: "10px" }}>
                  <h6 style={styles.h6_label}>Nome do anticorpo</h6>
                  <Input
                    name="nome_anticorpo"
                    style={styles.input_class}
                    placeholder="Nome do anticorpo"
                    required
                  />
                  {/* <span><i>Selecione na dropdown o nome do Anticorpo, caso não esteja na listagem, pode sempre criar um novo Anticorpo.</i></span> */}
                </Col>
                <Col span={12} style={{ paddingLeft: "10px" }}>
                  <h6 style={styles.h6_label}>Clone</h6>
                  <Select
                    style={{ lineheight: "45px", marginBottom: "10px" }}
                    onChange={(e) => setSelectedClone(e)}
                    name="clone"
                    showSearch
                    placeholder="Selecione um clone"
                    optionFilterProp="children"
                    required
                  >
                    {clones.map((clone) => (
                      <Select.Option value={clone.id}>
                        {" "}
                        {clone.nome}{" "}
                      </Select.Option>
                    ))}
                  </Select>
                  <span>
                    <i>
                      Selecione na dropdown o clone, caso não esteja na
                      listagem, pode sempre{" "}
                      <a
                        style={{ textDecoration: "underline" }}
                        onClick={() => setModalCloneOpen(true)}
                      >
                        criar um novo clone
                      </a>
                      .
                    </i>
                  </span>
                </Col>
              </Row>

              <Row align="top" style={{ marginBottom: "1%" }}>
                <Col span={12} style={{ paddingRight: "10px" }}>
                  <h6 style={styles.h6_label}>Marca</h6>
                  <Select
                    style={{ lineheight: "45px", marginBottom: "10px" }}
                    name="marca"
                    onChange={(e) => setSelectedMarca(e)}
                    showSearch
                    placeholder="Selecione uma marca"
                    optionFilterProp="children"
                    required
                  >
                    {marcas.map((marca) => (
                      <Select.Option value={marca.id}>
                        {" "}
                        {marca.nome}{" "}
                      </Select.Option>
                    ))}
                  </Select>
                  <span>
                    <i>
                      Selecione na dropdown a marca, caso não esteja na
                      listagem, pode sempre{" "}
                      <a
                        style={{ textDecoration: "underline" }}
                        onClick={() => setModalMarcaOpen(true)}
                      >
                        criar uma nova marca
                      </a>
                      .
                    </i>
                  </span>
                </Col>
                <Col span={12} style={{ paddingLeft: "10px" }}>
                  <h6 style={styles.h6_label}>Instituição</h6>
                  <Select
                    name="instituicao"
                    style={{ lineheight: "45px", marginBottom: "10px" }}
                    onChange={(e) => setSelectedInstituicao(e)}
                    showSearch
                    placeholder="Selecione uma instituição"
                    optionFilterProp="children"
                    required
                  >
                    {instituicoes.map((instituicao) => (
                      <Select.Option value={instituicao.id}>
                        {" "}
                        {instituicao.nome}{" "}
                      </Select.Option>
                    ))}
                  </Select>
                  <span>
                    <i>
                      Selecione na dropdown a Instituição, caso não esteja na
                      listagem, pode sempre{" "}
                      <a
                        style={{ textDecoration: "underline" }}
                        onClick={() => setModalInstituicaoOpen(true)}
                      >
                        criar uma nova Instituição
                      </a>
                      .
                    </i>
                  </span>
                </Col>
              </Row>
              <Divider />
              <Row gutter={16} justify="end">
                <Col className="gutter-row" span={12} align="end">
                  <Space>
                    <Input
                      onClick={handleCancelAnticorpo}
                      type="button"
                      value="Cancelar"
                      style={{
                        textAlign: "center",
                        marginInlineStart: "0",
                        background: "#fff",
                        border: "1px solid #494D7D",
                        color: "#494D7D",
                        borderRadius: "6px",
                        width: "100%",
                        height: "45px",
                        marginTop: "2%",
                        textAlign: "center",
                        padding: "0px 20px",
                      }}
                    />

                    <Input
                      type="submit"
                      value="Publicar"
                      style={{
                        marginInlineStart: "0",
                        background: "#494D7D",
                        color: "#fff",
                        borderRadius: "6px",
                        width: "100%",
                        height: "45px",
                        marginTop: "2%",
                        padding: "0px 50px",
                      }}
                    />
                  </Space>
                </Col>
              </Row>
            </form>
          </Col>
        </Card>
        <Card className="table-section">
          <CardHeader>
            <Title>Lista de Anticorpos</Title>
            <Space size={30}>
              <Search>
                <input
                  type="text"
                  placeholder="Procurar..."
                  onChange={(e) => handleSearch(e.target.value)}
                />
                <IconSearch className="icon icon-search" />
              </Search>
              {/*}
              <IconOptions className='icon icon-options' />
              {*/}
              <ExportButton
                data={dataToShow}
                columns={columns}
                fileName="anticorpos"
              />
            </Space>
          </CardHeader>
          <CardContent>
            <Table
              rowSelection={rowSelection}
              columns={[...columns, deleteColumn]}
              dataSource={dataToShow}
              onChange={() => tableOnChange}
              pagination={{ pageSize: tablePagination, showSizeChanger: false }}
            />
            <TableFooter>
              <Select
                defaultValue={tablePagination}
                style={{
                  width: 120,
                }}
                onChange={selectHandleChange}
                options={[
                  {
                    value: "5",
                    label: "5",
                  },
                  {
                    value: "10",
                    label: "10",
                  },
                  {
                    value: "20",
                    label: "20",
                  },
                  {
                    value: "50",
                    label: "50",
                  },
                ]}
              />
            </TableFooter>
          </CardContent>
        </Card>
        <Modal
          className="modal-anticorpos"
          open={modalInstituicaoOpen}
          title="Adicionar nova instituição"
          onCancel={handleCancelModalInstituicao}
        >
          <form onSubmit={handleFormInstituicao}>
            <h6 style={styles.h6_label}>Nome da instituição</h6>
            <Input
              type="text"
              required
              name="nome"
              style={styles.input_class}
              placeholder="Nome"
            />
            <h6 style={styles.h6_label}>Email</h6>
            <Input
              type="email"
              required
              name="email"
              style={styles.input_class}
              placeholder="Email"
            />
            <h6 style={styles.h6_label}>Telefone</h6>
            <Input
              type="text"
              required
              name="telefone"
              style={styles.input_class}
              placeholder="Telefone"
            />
            <h6 style={styles.h6_label}>Responsável</h6>
            <Input
              type="text"
              required
              name="responsavel"
              style={styles.input_class}
              placeholder="Responsável"
            />
            <Input
              type="submit"
              value="Guardar"
              style={{
                marginInlineStart: "0",
                background: "#494D7D",
                color: "#fff",
                borderRadius: "6px",
                width: "100%",
                height: "45px",
                marginTop: "2%",
              }}
            />
          </form>
          <Button
            onClick={handleCancelModalInstituicao}
            style={{
              marginInlineStart: "0",
              background: "#fff",
              color: "#494D7D",
              border: "1px solid #494D7D",
              borderRadius: "6px",
              width: "100%",
              height: "45px",
              marginTop: "2%",
            }}
          >
            Voltar
          </Button>
        </Modal>
        <Modal
          className="modal-anticorpos"
          open={modalCloneOpen}
          title="Adicionar novo Clone"
          onCancel={handleCancelModalClone}
        >
          {" "}
          <form onSubmit={handleFormClone}>
            <Input
              type="text"
              required
              name="nome"
              style={styles.input_class}
              placeholder="Nome"
            />
            <Input
              type="submit"
              value="Guardar"
              style={{
                marginInlineStart: "0",
                background: "#494D7D",
                color: "#fff",
                borderRadius: "6px",
                width: "100%",
                height: "45px",
                marginTop: "2%",
              }}
            />
          </form>
          <Button
            onClick={handleCancelModalClone}
            style={{
              marginInlineStart: "0",
              background: "#fff",
              color: "#494D7D",
              border: "1px solid #494D7D",
              borderRadius: "6px",
              width: "100%",
              height: "45px",
              marginTop: "2%",
            }}
          >
            Voltar
          </Button>
        </Modal>
        <Modal
          className="modal-anticorpos"
          open={modalMarcaOpen}
          title="Adicionar nova Marca"
          onCancel={handleCancelModalMarca}
        >
          <form onSubmit={handleFormMarca}>
            <Input
              type="text"
              required
              name="nome"
              style={styles.input_class}
              placeholder="Nome"
            />
            <Input
              type="submit"
              value="Guardar"
              style={{
                marginInlineStart: "0",
                background: "#494D7D",
                color: "#fff",
                borderRadius: "6px",
                width: "100%",
                height: "45px",
                marginTop: "2%",
              }}
            />
          </form>
          <Button
            onClick={handleCancelModalMarca}
            style={{
              marginInlineStart: "0",
              background: "#fff",
              color: "#494D7D",
              border: "1px solid #494D7D",
              borderRadius: "6px",
              width: "100%",
              height: "45px",
              marginTop: "2%",
            }}
          >
            Voltar
          </Button>
        </Modal>
        <Tour open={open} onClose={() => setOpen(false)} steps={steps} />
        <Modal
          open={modalUploadAnticorposOpen}
          width={300}
          onCancel={handleCancelModalUploadAnticorpos}
          footer={[
            <Button
              type="primary"
              onClick={handleUpload}
              disabled={fileList.length === 0}
              loading={uploading}
              style={{
                borderRadius: "12px",
                background: "#494D7D",
                width: "100%",
                height: "45px",
              }}
            >
              {uploading ? "Uploading" : "Start Upload"}
            </Button>,
          ]}
        >
          <h5
            style={{
              marginBottom: 0,
              fontSize: "18px",
              textAlign: "center",
              paddingTop: "20px",
              paddingBottom: "10px",
            }}
          >
            Faça upload do ficheiro
          </h5>
          <p
            style={{
              marginBottom: 0,
              fontSize: "14px",
              textAlign: "center",
              color: "#809FB8",
              paddingBottom: "20px",
            }}
          >
            Tamanho máximo de upload (2 MB)
          </p>
          <Dragger {...prop} style={{ borderRadius: "12px", padding: "10px" }}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Clique ou arraste para aqui o ficheiro relativo aos anticorpos que
              pretende adicionar.
            </p>
          </Dragger>
        </Modal>
        <Modal
          open={modalDeleteOpen}
          onCancel={() => {
            setDeleteRecord("");
            setModalDeleteOpen(false);
          }}
          footer={[
            <Button
              key="cancel"
              onClick={() => {
                setDeleteRecord("");
                setModalDeleteOpen(false);
              }}
            >
              Cancelar
            </Button>,
            <Button
              key="delete"
              type="primary"
              danger
              onClick={() => handleDelete()}
            >
              Confirmar
            </Button>,
          ]}
        >
          <h4>Confirmação de exclusão</h4>
          <p style={{ marginBottom: "40px" }}>
            Tem certeza de que deseja excluir os registros selecionados?
          </p>
        </Modal>
        <Footer />
      </Content>
    </Wrapper>
  );
}
