import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Sidebar from "../../../Components/Backoffice/Sidebar";
import Header from "../../../Components/Backoffice/Header";
import Footer from "../../../Components/Backoffice/Footer";
import Card from "../../../Components/Backoffice/Card";
import Title from "../../../Components/Backoffice/Title";
import {
  Button,
  Divider,
  Space,
  Switch,
  Upload,
  Input,
  Radio,
  Select,
  Drawer,
  Col,
  Modal,
  Row,
  DatePicker,
  notification,
  Form,
  Checkbox,
} from "antd";
import { ReactComponent as IconOk } from "../../../Assets/Images/Backoffice/Ok.svg";
import { ReactComponent as IconCalendar } from "../../../Assets/Images/Backoffice/Calendar.svg";
import { ReactComponent as IconClose } from "../../../Assets/Images/Backoffice/Close.svg";
import { ReactComponent as IconEdit } from "../../../Assets/Images/Backoffice/Edit.svg";
import { ReactComponent as IconUpload } from "../../../Assets/Images/Backoffice/Upload.svg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import { EyeOutlined } from "@ant-design/icons";
import axios, { all } from "axios";

const { Dragger } = Upload;

const Wrapper = styled.main`
  background-color: #e8e9f3;
  display: grid;
  grid-template-columns: 7.083vw 1fr;
  min-height: 100vh;

  .btn-descricao {
    margin-top: 10px;
    height: 45px !important;
    padding: 0 15px;
    font-weight: bold;
  }

  .div-banner-img {
    height: 150px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-size: cover;
    background-position: center center;
  }

  .icon-edit,
  .icon-close {
    cursor: pointer;
  }

  .cta.publish {
    margin-right: 0;

    svg path {
      fill: #fff;
    }
  }

  @media (max-width: 992px) {
  }
`;

const Content = styled.div`
  padding: 1.771vw;
`;

const CardHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 60px;
  grid-template-columns: 1fr auto;
`;

const WrapperCardList = styled.section`
  // display: flex;
  width: 100% !important;
  flex-direction: row;

  p {
    color: #c2c4d4;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 20px;
    margin-bottom: 0% !important;
    text-align: left;
  }

  .text {
    color: #809fb8;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0px;
  }

  .css-dev-only-do-not-override-j0nf2s .text {
    margin-bottom: 0 !important;
  }

  .action-edit {
    cursor: pointer;
    color: #494d7d;
  }

  .flex-wrap {
    flex-wrap: wrap !important;
  }
`;

const WrapperCardEvento = styled.section`
  display: flex;
  flex-direction: column;

  .grid {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 20px;
  }

  .ant-upload-btn {
  min-height: 190px;
}

  .ant-radio-button-wrapper {
    height: 45px;
    border: 1px solid rgb(128, 159, 184);
  }

  .ant-radio-button-wrapper span {
    line-height: 45px; 
    font-size: 14px;s
  }
  .text {
    margin-bottom: 0% !important;
  }

  h4 {
    text-align: center;
    font: normal normal bold 20px/20px Roboto;
    letter-spacing: 0px;
    color: #050505;
  }
  

  .text {
    color: #809FB8;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0px;
  }

  .center {
    text-align: center;
  }

  .ant-upload-wrapper .ant-upload-drag {
    max-height: -webkit-fill-available;
    height: 91%;
    display: flex;
    justify-content: center;
  }

  .ant-upload-wrapper .ant-upload-drag .ant-upload-btn  {
    height: -webkit-fill-available;
  }

  .cta {
    display: block !important;
    font-size: 15px;
    font-weight: bold;
    height: 45px !important;
    line-height: 18px;

    i { margin: 0 3px; }

    a { text-decoration: none; }
  }

  .cta.publish {
    margin-right: 0;

    svg path {
      fill: #FFF;
    }
  }
`;

var styles = {
  h6_label: {
    color: "#809FB8",
    fontFamily: "Roboto",
    fontSize: "15px",
    marginTop: "1%",
  },
  input_class_disabled: {
    marginBottom: "2%",
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
    opacity: 0.6,
    pointerEvents: "none",
  },
  input_class: {
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
    width: "100%",
  },
  select_class: {
    borderRadius: "6px",
    height: "45px",
    color: "#0C1151",
    width: "100%",
  },
  textarea_class: {
    borderRadius: "6px",
    border: "1px solid #809FB8",
    color: "#0C1151",
  },
  switch_label: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    borderRadius: "8px",
    padding: "0px 16px",
    color: "#809FB8",
    border: "1px solid #809FB8",
    textAlign: "center",
  },
  upload: {
    width: "100%",
  },
};

export default function DashboardCasoDoMes(props) {
  document.title = "SPAP - Caso do Mês";
  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const formRef = useRef();
  const formRefEdit = useRef();

  const [fileCasoList, setfileCasoList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [areaEvent, setAreaEvent] = useState([]);
  const [descricaoEvent, setDescricaoEvent] = useState("");
  const [tituloEvent, setTituloEvent] = useState("");
  const [agendarPost, setAgendarPostEvent] = useState("");
  const [autorEvent, setAutorEvent] = useState("");
  const [instituicaoEvent, setInstituicaoEvent] = useState("");
  const [questaoEvent, setQuestaoEvent] = useState("");
  const [respostasEvent, setRespostasEvent] = useState("");
  const [showEventDrawer, setShowEventDrawer] = useState(false);
  // editar evento
  const [fileEventListEdit, setfileEventListEdit] = useState([]);
  const [eventIdEdit, setEventIdEdit] = useState("");
  const [dataEditEvent, setDataEditEvent] = useState([]);
  const [descricaoEventEdit, setDescricaoEventEdit] = useState("");
  const [tituloEventEdit, setTituloEventEdit] = useState("");
  const [tipoEventEdit, setTipoEventEdit] = useState("");
  const [autorEventEdit, setAutorEventEdit] = useState("");
  const [instituicaoEventEdit, setInstituicaoEventEdit] = useState("");
  const [questaoEventEdit, setQuestaoEventEdit] = useState("");
  const [respostasEventEdit, setRespostasEventEdit] = useState("");

  const [areaEdit, setAreaEdit] = useState("");
  const [imagemDestaqueEdit, setImagemDestaqueEdit] = useState("");
  const [noticiaEvent, setNoticiaEvent] = useState(false);
  const [bolsaPremioEvent, setBolsaPremioEvent] = useState(false);
  const [removeCasoModalOpen, setRemoveCasoModalOpen] = useState(false);
  const [idToRemove, setIdToRemove] = useState(false);
  const [titleToRemove, setTitleToRemove] = useState(false);

  const dateFormat = "DD/MM/YYYY";

  const [allItems, setAllItems] = useState([]);
  const [currentItemsEvent, setCurrentItemsEvent] = useState(
    allItems?.slice(0, 2)
  );

  const [itemOffsetEvent, setItemOffsetEvent] = useState(0);
  const itemsPerPageEvent = 2;

  const formatDate = (date) => {
    const parts = date.split("T")[0].split("-");

    // Obtendo os componentes da data
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];

    // Formatando a data como "DD/MM/YYYY"
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  const getCasos = (allItens) => {
    if (allItens?.length > 0) {
      let newArray = [];
      allItens.map((item) => {
        item.url = item.imagem_destaque
          ? "https://spapapi.phormuladev.com/caso_do_mes/" +
            item.id +
            "/" +
            item.imagem_destaque
          : "https://spapapi.phormuladev.com/clubes/clube-estudo-default.svg";
        item.date = formatDate(item.created_at);
        newArray.push(item);
      });

      setAllItems(newArray);
    }
  };

  useEffect(() => {
    fetch(`https://spapapi.phormuladev.com/api/all-casos-do-mes`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          console.log("nenhum evento");
        }
        getCasos(data.caso_do_mes);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const endOffset = itemOffsetEvent + itemsPerPageEvent;
    setCurrentItemsEvent(allItems?.slice(itemOffsetEvent, endOffset));
  }, [itemOffsetEvent, allItems]);

  notification.config({
    placement: "bottomRight",
    bottom: 10,
    duration: 5,
    rtl: false,
  });

  const showModal = (e) => {
    setQuotaToUpdate(e);
    setIsModalOpen(true);
  };

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  const clickToEventDrawer = async (item) => {
    setEventIdEdit(item.id);
    setDescricaoEventEdit(item.descricao);
    setTituloEventEdit(item.titulo);
    setTipoEventEdit(item.is_spap);
    setImagemDestaqueEdit(item.imagem_destaque);
    setAreaEdit(item.area_subespecializacao);
    setAutorEventEdit(item.autor);
    setInstituicaoEventEdit(item.instituicao);
    setQuestaoEventEdit(item.questao);
    setRespostasEventEdit(item.respostas);

    setRespostasCheckEdit("");
    if (formRefEdit?.current?.getFieldValue("respostas")?.length > 0) {
      formRefEdit.current.resetFields();
    }

    if (JSON.parse(item.respostas)?.length > 0) {
      formRefEdit?.current?.setFieldsValue({
        respostas: JSON.parse(item.respostas),
      });
      for (let index = 0; index < JSON.parse(item.respostas)?.length; index++) {
        if (JSON.parse(item.respostas)[index].resposta === true)
          setRespostasCheckEdit(index);
      }
    }

    setShowEventDrawer(true);
  };

  const propEvent = {
    onRemove: (file) => {
      const index = fileCasoList.indexOf(file);
      const newfileEventList = fileCasoList.slice();
      newfileEventList.splice(index, 1);
      setfileCasoList(newfileEventList);
    },
    beforeUpload: (file) => {
      const isImage =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml";
      if (!isImage) {
        notification.error({
          description: "Apenas é possível fazer upload de imagens.",
        });

        return isImage || Upload.LIST_IGNORE;
      }
      setfileCasoList([...fileCasoList, file]);
      return false;
    },
    fileCasoList,
  };

  const t = 1;
  const data = [];

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const onChangeDatePost = (date, dateString) => {
    setAgendarPostEvent(dateString);
  };

  const handleEvent = (e) => {
    if (
      tituloEvent.length == 0 ||
      descricaoEvent.length == 0 ||
      fileCasoList.length == 0
    ) {
      notification.error({
        description: "Para criar um novo evento, preencha todos os campos.",
      });
      return;
    }

    /* pegar respostas */
    const respostasValues = formRef.current.getFieldsValue();
    let newRespostas = [];
    for (let index = 0; index < respostasValues.respostas?.length; index++) {
      let arrayToPush = [];
      if (respostasValues.respostas[index] !== undefined) {
        arrayToPush.texto = respostasValues.respostas[index].texto;
      }
      index === respostasCheck
        ? (arrayToPush.resposta = true)
        : (arrayToPush.resposta = false);
      newRespostas.push({
        texto: arrayToPush.texto,
        resposta: arrayToPush.resposta,
      });
    }

    const formData = new FormData();

    formData.append("titulo", tituloEvent);
    formData.append("file", fileCasoList[0]);
    formData.append("descricao", descricaoEvent);
    formData.append("agendarPost", agendarPost);
    formData.append("area", areaEvent);
    formData.append("autor", autorEvent);
    formData.append("instituicao", instituicaoEvent);
    formData.append("questao", questaoEvent);
    formData.append("respostas", JSON.stringify(newRespostas));

    // You can use any AJAX library you like
    fetch("https://spapapi.phormuladev.com/api/admin/store-caso-do-mes", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            if (data.message == "already_exists") {
              notification.error({
                description: "Já existe um caso do mês com o mesmo nome!",
              });
            } else {
              getCasos(data.caso_do_mes);
              const endOffset = itemOffsetEvent + itemsPerPageEvent;
              setCurrentItemsEvent(allItems.slice(itemOffsetEvent, endOffset));
              setTituloEvent("");
              setDescricaoEvent("");
              setAreaEvent("");
              setAutorEvent("");
              setInstituicaoEvent("");
              setQuestaoEvent("");
              setRespostasEvent("");
              setRespostasCheck("");
              formRef.current.resetFields();
              notification.success({
                description: "O evento foi criado com sucesso!",
              });
            }
          })
          .catch((err) => {});
      })
      .then(() => {})
      .catch(() => {})
      .finally(() => {});
  };

  const propEventEdit = {
    onRemove: (file) => {
      fileEventListEdit([]);
    },
    beforeUpload: (file) => {
      const isImage =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml";
      console.log(isImage);
      if (!isImage) {
        notification.error({
          description: "Apenas é possível fazer upload de imagens.",
        });
        setfileEventListEdit((current) => []);
        return Upload.LIST_IGNORE;
      }
      setfileEventListEdit([...fileEventListEdit, file]);
    },
    fileEventListEdit,
  };

  const handleEditEvent = () => {
    if (descricaoEventEdit.length == 0 || tituloEventEdit.length == 0) {
      notification.error({
        description: "Para editar o evento, preencha os campos respetivos.",
      });
      return;
    }

    /* pegar respostas */
    const respostasValues = formRefEdit.current.getFieldsValue();
    let newRespostas = [];
    for (let index = 0; index < respostasValues.respostas?.length; index++) {
      let arrayToPush = [];
      if (respostasValues.respostas[index] !== undefined) {
        arrayToPush.texto = respostasValues.respostas[index].texto;
      }
      index === respostasCheckEdit
        ? (arrayToPush.resposta = true)
        : (arrayToPush.resposta = false);
      newRespostas.push({
        texto: arrayToPush.texto,
        resposta: arrayToPush.resposta,
      });
    }

    const formData = new FormData();

    if (fileEventListEdit.length) {
      formData.append("file", fileEventListEdit[0]);
    }

    formData.append("titulo", tituloEventEdit);
    formData.append("casoId", eventIdEdit);
    formData.append("descricao", descricaoEventEdit);
    formData.append("area", areaEdit);
    formData.append("autor", autorEventEdit);
    formData.append("instituicao", instituicaoEventEdit);
    formData.append("questao", questaoEventEdit);
    formData.append("respostas", JSON.stringify(newRespostas));

    setUploading(true);

    // You can use any AJAX library you
    fetch("https://spapapi.phormuladev.com/api/admin/update-caso-do-mes", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            getCasos(data.caso_do_mes);
            allItems.map((item) => {
              setfileEventListEdit([]);
              if (item.id == casoIdEdit) {
                const newImagemDestaque = item.imagem_destaque;
                setImagemDestaqueEdit(newImagemDestaque);
              }
            });
            const endOffset = itemOffsetEvent + itemsPerPageEvent;
            setCurrentItemsEvent(allItems.slice(itemOffsetEvent, endOffset));
          })
          .catch((err) => {});
      })
      .then(() => {
        setfileEventListEdit([]);
        notification.success({
          description: "O caso do mês foi atualizado com sucesso!",
        });
      })
      .catch(() => {
        notification.error({
          description: "Já existe um caso do mês com o mesmo nome neste clube!",
        });
      })
      .finally(() => {});
  };

  const handleRemoveEvent = () => {
    axios
      .post(
        "https://spapapi.phormuladev.com/api/admin/remove-caso-do-mes",
        { idToRemove },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        setIdToRemove("");
        setRemoveCasoModalOpen(false);
        console.log(res);
        if (res.status == 200) {
          getCasos(res.data.caso_do_mes);
          const endOffset = itemOffsetEvent + itemsPerPageEvent;
          setCurrentItemsEvent(allItems.slice(itemOffsetEvent, endOffset));
          notification.success({
            description: "O caso do mês foi removido com sucesso!",
          });
        }
      })
      .catch((error) => {
        // notification.error({description: 'Erro ao remover a imagem da galeria! Tente novamente.'});
      });
  };

  const openRemoveCasoModal = (id, titulo) => {
    setIdToRemove(id);
    setTitleToRemove(titulo);
    setRemoveCasoModalOpen(true);
  };

  /* Send form questions */
  const onFinish = (values) => {
    console.log("Questões:", values.questoes);
  };

  const validateVerdadeiraRadio = (_, value, callback) => {
    console.log(value);
    if (!value) {
      callback("Selecione uma única questão como verdadeira");
    } else {
      callback();
    }
  };

  const [respostasCheck, setRespostasCheck] = useState([]);
  const handleRemoveCheckbox = (remove, field, index) => {
    remove(field.name);
    // Chame sua função aqui após remover a linha
    if (respostasCheck === index) setRespostasCheck("");
  };

  const [respostasCheckEdit, setRespostasCheckEdit] = useState([]);
  const handleRemoveCheckboxEdit = (remove, field, index) => {
    remove(field.name);
    // Chame sua função aqui após remover a linha
    if (respostasCheckEdit === index) setRespostasCheckEdit("");
  };

  return (
    <Wrapper>
      <div>
        <Sidebar />
      </div>
      <Content>
        <Header />
        <WrapperCardEvento>
          <Card>
            <CardHeader>
              <Title>Caso do Mês</Title>
              <DatePicker
                placement="bottomRight"
                style={{
                  width: "200px",
                  height: "45px",
                  border: "1px solid rgb(128, 159, 184)",
                }}
                format={dateFormat}
                placeholder="Agendar Publicação"
                onChange={onChangeDatePost}
              />
            </CardHeader>
            <p className="text">
              <i>Casos disponíveis para consultar na página Caso do Mês</i>
            </p>
            <Divider />
            <Row gutter={[12, 18]} align="bottom">
              <Col span={14}>
                <label className="text">Título do Caso</label>
                <Input
                  key={"titulo-event"}
                  placeholder="Título"
                  name="titulo"
                  style={styles.input_class}
                  value={tituloEvent}
                  onChange={(e) => setTituloEvent(e.target.value)}
                />
              </Col>
              <Col span={10}>
                <label className="text">Área de Diferenciação</label>
                <Select
                  key={"area-evento"}
                  placeholder="Área de Diferenciação"
                  value={areaEvent ? areaEvent : ""}
                  style={styles.select_class}
                  onChange={(e) => setAreaEvent(e)}
                  options={[
                    { value: "Patologia geral", label: "Patologia geral" },
                    { value: "Citologia", label: "Citologia" },
                    { value: "Patologia cutânea", label: "Patologia cutânea" },
                    {
                      value: "Patologia da cabeça e pescoço",
                      label: "Patologia da cabeça e pescoço",
                    },
                    {
                      value: "Patologia das partes moles",
                      label: "Patologia das partes moles",
                    },
                    {
                      value: "Patologia do sistema nervosa",
                      label: "Patologia do sistema nervoso",
                    },
                    { value: "Patologia digital", label: "Patologia digital" },
                    {
                      value: "Patologia endócrina",
                      label: "Patologia endócrina",
                    },
                    {
                      value: "Patologia fetoplacentar",
                      label: "Patologia fetoplacentar",
                    },
                    {
                      value: "Patologia gastrointestinal",
                      label: "Patologia gastrointestinal",
                    },
                    {
                      value: "Patologia ginecológica",
                      label: "Patologia ginecológica",
                    },
                    {
                      value: "Patologia hematolinfoide",
                      label: "Patologia hematolinfoide",
                    },
                    {
                      value: "Patologia hepatobiliar",
                      label: "Patologia hepatobiliar",
                    },
                    { value: "Patologia mamária", label: "Patologia mamária" },
                    {
                      value: "Patologia molecular",
                      label: "Patologia molecular",
                    },
                    {
                      value: "Patologia osteoarticular",
                      label: "Patologia osteoarticular",
                    },
                    {
                      value: "Patologia pancreática",
                      label: "Patologia pancreática",
                    },
                    {
                      value: "Patologia pulmonar",
                      label: "Patologia pulmonar",
                    },
                    {
                      value: "Patologia urogenital",
                      label: "Patologia urogenital",
                    },
                  ]}
                />
              </Col>
            </Row>

            <Row gutter={[12, 12]} style={{ marginTop: "10px" }} align={"top"}>
              <Col span={6}>
                <p
                  className="text"
                  style={{ marginBottom: "0px" }}
                  align="center"
                >
                  Tamanho máximo de upload (1 MB)
                </p>
                <Dragger
                  {...propEvent}
                  style={{ height: "100%" }}
                  fileList={[...fileCasoList]}
                >
                  <p className="ant-upload-drag-icon">
                    <IconUpload />
                  </p>
                  <p className="ant-upload-text text">
                    Upload da imagem do evento
                  </p>
                  <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                </Dragger>
              </Col>
              <Col span={18}>
                <Row
                  gutter={[12, 12]}
                  style={{ marginTop: "10px" }}
                  align={"top"}
                >
                  <Col span={12}>
                    <label className="text" style={{ marginBottom: "0px" }}>
                      Autor
                    </label>
                    <Input
                      placeholder="Autor"
                      name="autor"
                      style={styles.input_class}
                      value={autorEvent}
                      onChange={(e) => setAutorEvent(e.target.value)}
                    />
                  </Col>
                  <Col span={12}>
                    <label className="text" style={{ marginBottom: "0px" }}>
                      Instituição
                    </label>
                    <Input
                      placeholder="Instituição"
                      name="instituicao"
                      style={styles.input_class}
                      value={instituicaoEvent}
                      onChange={(e) => setInstituicaoEvent(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "10px" }} align={"top"}>
                  <Col span={24}>
                    <label className="text" style={{ marginBottom: "0px" }}>
                      Descrição do Evento
                    </label>

                    <ReactQuill
                      key={"descricao-evento"}
                      placeholder="Descrição"
                      modules={modules}
                      formats={formats}
                      theme="snow"
                      name="descricao"
                      onChange={(e) => setDescricaoEvent(e)}
                      value={descricaoEvent}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "10px" }} align={"top"}>
                  <Col span={24}>
                    <label className="text" style={{ marginBottom: "0px" }}>
                      Questão
                    </label>
                    <Input
                      placeholder="Questão"
                      name="questao"
                      style={styles.input_class}
                      value={questaoEvent}
                      onChange={(e) => setQuestaoEvent(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "10px" }} align={"middle"}>
                  <Col span={24}>
                    <Form ref={formRef}>
                      <Form.List
                        name="respostas"
                        rules={[{ validator: validateVerdadeiraRadio }]}
                      >
                        {(fields, { add, remove }) => (
                          <Row gutter={[12, 12]}>
                            <Col span={6}>
                              <Form.Item>
                                <Button
                                  type="primary"
                                  className="cta"
                                  onClick={() => add()}
                                  block
                                >
                                  Adicionar Resposta
                                </Button>
                              </Form.Item>
                            </Col>
                            <Col span={18}>
                              {fields.map((field, index) => (
                                <Form.Item key={field.key}>
                                  <Row gutter={[12, 12]} align={"middle"}>
                                    <Col span={18}>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "texto"]}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Digite o texto da questão",
                                          },
                                        ]}
                                      >
                                        <Input
                                          placeholder={
                                            "Resposta " + (index + 1)
                                          }
                                          style={styles.input_class}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col span={4} align="">
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "resposta"]}
                                      >
                                        <Checkbox
                                          checked={
                                            respostasCheck === index && true
                                          }
                                          onClick={(e) => {
                                            setRespostasCheck(index);
                                          }}
                                        >
                                          Verdadeira
                                        </Checkbox>
                                      </Form.Item>
                                    </Col>
                                    <Col span={2} align="">
                                      <IconClose
                                        className="icon icon-close"
                                        onClick={() =>
                                          handleRemoveCheckbox(
                                            remove,
                                            field,
                                            index
                                          )
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </Form.Item>
                              ))}
                            </Col>
                          </Row>
                        )}
                      </Form.List>
                    </Form>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider style={{ margin: "8px 0" }} />
            <Button
              onClick={handleEvent}
              type="primary"
              className="cta publish"
              align="end"
            >
              Publicar <IconOk className="icon icon-ok" />
            </Button>
          </Card>

          <WrapperCardList>
            <Row gutter={[8, 8]} className="flex-wrap">
              {allItems?.map((item, index) => {
                let textPreview = item.descricao
                  .replace(/<(h2|h3|img|b|strong)[^>]*>/g, "<p>")
                  .slice(0, 120);
                if (item.descricao.length > 120) textPreview += " (...)";
                return (
                  <Col span={12}>
                    <Card key={"caso_do_mes-" + item.id}>
                      <CardHeader>
                        <Title>{item.titulo}</Title>
                        <IconClose
                          className="icon icon-close"
                          onClick={() =>
                            openRemoveCasoModal(item.id, item.titulo)
                          }
                        />
                      </CardHeader>
                      <Space direction="vertical" style={{ width: "100%" }}>
                        <div
                          style={{ fontSize: "12px", lineHeight: "1.4" }}
                          dangerouslySetInnerHTML={{ __html: textPreview }}
                        ></div>
                        <Space
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            alignContent: "center",
                          }}
                        >
                          <Space size={20}>
                            <Space>
                              <IconCalendar className="icon icon-calendar" />
                              <span className="text">{item.date}</span>
                            </Space>
                            <Space
                              className="action-edit"
                              key={"evento-" + index}
                              onClick={() => clickToEventDrawer(item)}
                            >
                              <IconEdit className="icon icon-edit" />
                              <span>Editar</span>
                            </Space>
                          </Space>
                          <div>
                            <Space className="action-edit">
                              <a
                                target="_blank"
                                href={
                                  "https://spap.phormuladev.com/ferramentas-uteis/caso-do-mes/" +
                                  item.id
                                }
                              >
                                <EyeOutlined style={{ paddingRight: "5px" }} />
                              </a>
                            </Space>
                          </div>
                        </Space>
                      </Space>
                    </Card>
                  </Col>
                );
              })}
            </Row>

            <Drawer
              key={"editar-evento-" + eventIdEdit}
              title="Editar Evento"
              width={700}
              onClose={() => setShowEventDrawer(false)}
              open={showEventDrawer}
              bodyStyle={{
                paddingBottom: 80,
              }}
              extra={
                <Space>
                  <Button onClick={() => setShowEventDrawer(false)}>
                    Cancelar
                  </Button>
                  <Button
                    onClick={handleEditEvent}
                    type="submit"
                    style={{
                      borderRadius: "6px",
                      background: "#494D7D",
                      color: "#fff",
                    }}
                  >
                    Guardar
                  </Button>
                </Space>
              }
            >
              <form layout="vertical" id="update-event-form">
                <Col>
                  <Row align={"middle"}>
                    <Col span={12}>
                      <h5 style={{ marginBottom: "10px", fontSize: "18px" }}>
                        Upload da imagem
                      </h5>
                      <p>
                        Poderá fazer o upload de uma nova imagem de destaque
                        clicando na imagem do lado direito.
                      </p>
                    </Col>
                    <Col span={12}>
                      <Dragger
                        {...propEventEdit}
                        fileList={[...fileEventListEdit]}
                        style={{
                          marginBottom: "5px",
                          width: "100%",
                          backgroundImage:
                            "url(https://spapapi.phormuladev.com/caso_do_mes/" +
                            eventIdEdit +
                            "/" +
                            imagemDestaqueEdit +
                            ")",
                          backgroundSize: "cover",
                        }}
                      >
                        <div style={{ opacity: 0 }}>
                          <p className="ant-upload-drag-icon">
                            <IconUpload />
                          </p>
                          <p className="ant-upload-text text">
                            Upload da imagem{" "}
                          </p>
                          <p className="ant-upload-hint">
                            Faça Drag and drop aqui
                          </p>
                        </div>
                      </Dragger>
                    </Col>
                  </Row>
                  <Divider
                    style={{ background: "#fff", margin: "12px 0" }}
                  ></Divider>
                  <Row
                    className="row-drawer"
                    gutter={[8, 8]}
                    style={{ flexFlow: "column !important" }}
                  >
                    <Col span={24}>
                      <h6 style={styles.h6_label}>Título</h6>
                      <Input
                        key={"titulo-evento-" + eventIdEdit}
                        placeholder="Título"
                        name="titulo"
                        style={styles.input_class}
                        defaultValue={tituloEventEdit}
                        onChange={(e) => setTituloEventEdit(e.target.value)}
                        required
                      />
                    </Col>
                    <Col span={24}>
                      <h6 style={styles.h6_label}>Área de Diferenciação</h6>
                      <Select
                        key={"area-evento-" + eventIdEdit}
                        value={areaEdit}
                        style={styles.select_class}
                        placeholder="Área de Diferenciação"
                        onChange={(e) => setAreaEdit(e)}
                        options={[
                          {
                            value: "Patologia geral",
                            label: "Patologia geral",
                          },
                          { value: "Citologia", label: "Citologia" },
                          {
                            value: "Patologia cutânea",
                            label: "Patologia cutânea",
                          },
                          {
                            value: "Patologia da cabeça e pescoço",
                            label: "Patologia da cabeça e pescoço",
                          },
                          {
                            value: "Patologia das partes moles",
                            label: "Patologia das partes moles",
                          },
                          {
                            value: "Patologia do sistema nervosa",
                            label: "Patologia do sistema nervoso",
                          },
                          {
                            value: "Patologia digital",
                            label: "Patologia digital",
                          },
                          {
                            value: "Patologia endócrina",
                            label: "Patologia endócrina",
                          },
                          {
                            value: "Patologia fetoplacentar",
                            label: "Patologia fetoplacentar",
                          },
                          {
                            value: "Patologia gastrointestinal",
                            label: "Patologia gastrointestinal",
                          },
                          {
                            value: "Patologia ginecológica",
                            label: "Patologia ginecológica",
                          },
                          {
                            value: "Patologia hematolinfoide",
                            label: "Patologia hematolinfoide",
                          },
                          {
                            value: "Patologia hepatobiliar",
                            label: "Patologia hepatobiliar",
                          },
                          {
                            value: "Patologia mamária",
                            label: "Patologia mamária",
                          },
                          {
                            value: "Patologia molecular",
                            label: "Patologia molecular",
                          },
                          {
                            value: "Patologia osteoarticular",
                            label: "Patologia osteoarticular",
                          },
                          {
                            value: "Patologia pancreática",
                            label: "Patologia pancreática",
                          },
                          {
                            value: "Patologia pulmonar",
                            label: "Patologia pulmonar",
                          },
                          {
                            value: "Patologia urogenital",
                            label: "Patologia urogenital",
                          },
                        ]}
                      />{" "}
                    </Col>
                    <Col span={24}>
                      <h6 style={styles.h6_label}>Autor</h6>
                      <Input
                        placeholder="Autor"
                        name="autor"
                        style={styles.input_class}
                        value={autorEventEdit}
                        onChange={(e) => setAutorEventEdit(e.target.value)}
                      />
                    </Col>
                    <Col span={24}>
                      <h6 style={styles.h6_label}>Instituição</h6>
                      <Input
                        placeholder="Instituição"
                        name="instituicao"
                        style={styles.input_class}
                        value={instituicaoEventEdit}
                        onChange={(e) =>
                          setInstituicaoEventEdit(e.target.value)
                        }
                      />
                    </Col>
                    <Col span={24}>
                      <h6 style={styles.h6_label}>Descrição</h6>
                      <ReactQuill
                        key={"descricao-evento-" + eventIdEdit}
                        modules={modules}
                        formats={formats}
                        theme="snow"
                        name="descricao"
                        onChange={(e) => setDescricaoEventEdit(e)}
                        defaultValue={descricaoEventEdit}
                      />
                    </Col>
                    <Col span={24}>
                      <h6 style={styles.h6_label}>Questão</h6>
                      <Input
                        placeholder="Questão"
                        name="questao"
                        style={styles.input_class}
                        value={questaoEventEdit}
                        onChange={(e) => setQuestaoEventEdit(e.target.value)}
                      />
                    </Col>
                    <Col span={24}>
                      <Form ref={formRefEdit}>
                        <Form.List
                          name="respostas"
                          rules={[{ validator: validateVerdadeiraRadio }]}
                        >
                          {(fields, { add, remove }) => (
                            <Row gutter={[12, 12]}>
                              <Col span={6}>
                                <Form.Item>
                                  <Button
                                    type="primary"
                                    className="cta"
                                    onClick={() => add()}
                                    block
                                  >
                                    Adicionar Resposta
                                  </Button>
                                </Form.Item>
                              </Col>
                              <Col span={18}>
                                {fields.map((field, index) => (
                                  <Form.Item key={field.key}>
                                    <Row gutter={[12, 12]}>
                                      <Col span={18}>
                                        <Form.Item
                                          {...field}
                                          name={[field.name, "texto"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Digite o texto da questão",
                                            },
                                          ]}
                                        >
                                          <Input
                                            placeholder={
                                              "Resposta " + (index + 1)
                                            }
                                            style={styles.input_class}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col span={4} align="center">
                                        <Form.Item
                                          {...field}
                                          name={[field.name, "resposta"]}
                                        >
                                          <Checkbox
                                            checked={
                                              respostasCheckEdit === index &&
                                              true
                                            }
                                            onClick={(e) => {
                                              setRespostasCheckEdit(index);
                                            }}
                                          >
                                            Verdadeira
                                          </Checkbox>
                                        </Form.Item>
                                      </Col>
                                      <Col span={2} align="center">
                                        <IconClose
                                          className="icon icon-close"
                                          onClick={() =>
                                            handleRemoveCheckboxEdit(
                                              remove,
                                              field,
                                              index
                                            )
                                          }
                                        />
                                      </Col>
                                    </Row>
                                  </Form.Item>
                                ))}
                              </Col>
                            </Row>
                          )}
                        </Form.List>
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </form>
            </Drawer>

            <Modal
              key={"remove-event-" + idToRemove}
              title="Está prestes a eliminar um caso"
              open={removeCasoModalOpen}
              onOk={handleRemoveEvent}
              onCancel={() => setRemoveCasoModalOpen(false)}
              okText="Confirmar"
              cancelText="Cancelar"
            >
              <p>
                Tem a certeza que pretende remover o caso{" "}
                <b>'{titleToRemove}'</b>?
              </p>
            </Modal>
          </WrapperCardList>
        </WrapperCardEvento>
        <Footer />
      </Content>
    </Wrapper>
  );
}
