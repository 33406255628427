import styled from "styled-components";
import React, { useState, useEffect } from "react";
import Sidebar from "../../../Components/Backoffice/Sidebar";
import Cards from "../../../Components/Backoffice/Card";
import Title from "../../../Components/Backoffice/Title";
import Header from "../../../Components/Backoffice/Header";
import Footer from "../../../Components/Backoffice/Footer";
import {
  DeleteOutlined,
  EditOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Card,
  Divider,
  Radio,
  notification,
  Drawer,
  Upload,
  Row,
  Input,
  Col,
  Button,
  Modal,
  Space,
} from "antd";
import { ReactComponent as IconUpload } from "../../../Assets/Images/Backoffice/Upload.svg";
import axios, { all } from "axios";
import { ReactComponent as IconInfo } from "../../../Assets/Images/Backoffice/Notifications/Info.svg";

import { ReactSortable } from "react-sortablejs";
const { Meta } = Card;
import { Link } from "react-router-dom";
const { Dragger } = Upload;

const Wrapper = styled.main`
  background-color: #e8e9f3;
  display: grid;
  grid-template-columns: 7.083vw 1fr;
  min-height: 100vh;

  .btn-descricao {
    margin-top: 10px;
    height: 45px !important;
    padding: 0 15px;
    font-weight: bold;
  }

  .div-banner-img {
    height: 150px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-size: cover;
    background-position: center center;
  }

  #newClube {
    background: #e8e9f3 0% 0% no-repeat padding-box;
    border: 4px dashed #d6d7e2;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;

    .cta {
      border-radius: 12px;
      height: 46px;
    }
  }

  .icon-edit {
    cursor: pointer;
  }

  .cta.publish {
    margin-right: 0;

    svg path {
      fill: #fff;
    }
  }

  @media (max-width: 992px) {
  }
`;

const Content = styled.div`
  padding: 1.771vw;
`;

const Files = styled.section`
  display: flex;
  flex-direction: column;

  h4 {
    text-align: left;
    font: normal normal bold 20px/20px Roboto;
    letter-spacing: 0px;
    color: #050505;
  }

  .text {
    color: #809fb8;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0px;
  }

  .center {
    text-align: center;
  }

  .cta {
    display: block !important;
    font-size: 15px;
    font-weight: bold;
    height: 45px;
    line-height: 18px;

    i {
      margin: 0 3px;
    }

    a {
      text-decoration: none;
    }
  }

  .cta.publish {
    margin-right: 0;

    svg path {
      fill: #fff;
    }
  }
`;

const CardHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  // gap: 60px;
  grid-template-columns: 1fr auto auto;
`;

const Grid = styled.div`
  display: grid;
  gap: 18px;
  margin: 5px 0;

  .item {
    background-color: #d6d7e2;
    border: none;
    border-radius: 16px;
    color: #8688a8;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 12px 0.625vw;
  }

  .space-gestor {
    min-height: 100px !important;
  }

  .item.disable {
    opacity: 0.5;
  }

  .item__image {
    position: relative;
    align-items: center;
    justify-content: center;
    border: 3px solid #0c1151;
    border-radius: 16px;
    max-height: 180px;
    display: flex;
    padding: 0;

    * {
      color: #0c1151;
      font-family: "Fira Sans";
      font-size: 20px;
      font-weight: bold;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .item__status {
    background-color: #ea3a3d;
    color: #ffffff;
    padding: 6px 15px;
    border-radius: 8px;
    place-self: flex-start;
  }

  .item__status.active {
    background-color: #1ad598;
  }

  .item__cta {
    margin-right: auto;
  }

  .link {
    text-decoration: none;
  }

  .card-banner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 23%;
    margin: 1%;
  }

  .div-sortable {
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
  }
`;

const AlertMesssage = styled.div`
  background: #f1f5f7;
  border: 2px solid #ffffff;
  margin-top: 10px;
  border-radius: 16px;
  text-align: left;
  letter-spacing: 0px;
  padding: 10px;
  color: #0c1151;
  font-style: italic;

  circle {
    fill: #0c1151;
  }

  svg path {
    fill: #fff;
  }
`;

var styles = {
  h6_label: {
    color: "#809FB8",
    fontFamily: "Roboto",
    fontSize: "14px",
    marginTop: "1%",
    marginBottom: "0%",
  },
  input_class: {
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
    marginBottom: "1%",
  },
  text_class: {
    borderRadius: "6px",
    minHeight: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
    marginBottom: "1%",
  },
  switch_label: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    borderRadius: "8px",
    padding: "0px 16px",
    color: "#809FB8",
    border: "1px solid #809FB8",
    textAlign: "center",
  },
  upload: {
    width: "100%",
  },
};

export default function DashboardBanner() {
  document.title = "SPAP - Banners";
  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const [modalCriaBannerOpen, setModalCriaBannerOpen] = useState(false);
  const [fileBanner, setfileBanner] = useState([]);
  const [fileBannerHover, setfileBannerHover] = useState([]);
  const [banners, setBanners] = useState([]);
  const [imagem, setImagem] = useState([]);
  const [tipo, setTipo] = useState([]);
  const [imagemHover, setImagemHover] = useState([]);
  const [titulo, setTitulo] = useState([]);
  const [descricao, setDescricao] = useState([]);
  const [descricaoAlt, setDescricaoAlt] = useState([]);
  const [link, setLink] = useState([]);
  const [showBannerDrawer, setShowBannerDrawer] = useState(false);
  const [bannerIdEdit, setBannerIdEdit] = useState(false);
  const [idToRemove, setIdToRemove] = useState(false);
  const [titleToRemove, setTitleToRemove] = useState(false);
  const [removeBannerModalOpen, setRemoveBannerModalOpen] = useState(false);
  const [stateSPAP, setStateSPAP] = useState([]);
  const [stateNISPAP, setStateNISPAP] = useState([]);
  useEffect(() => {
    fetch(`https://spapapi.phormuladev.com/api/all-banners`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar banners");
        }
        return response.json();
      })
      .then((data) => {
        if (data.banners.length === 0) {
          console.log("nenhum banner");
          return;
        }
        setBanners(data.banners);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    setStateSPAP(() => getBannersOrder(0));
    setStateNISPAP(() => getBannersOrder(1));
  }, [banners]);

  notification.config({
    placement: "bottomRight",
    bottom: 10,
    duration: 5,
    rtl: false,
  });

  const propBanner = {
    onRemove: (file) => {
      const index = fileBanner.indexOf(file);
      const newfileBannerList = fileBanner.slice();
      newfileBannerList.splice(index, 1);
      setfileBanner(newfileBannerList);
    },
    beforeUpload: (file) => {
      const isImage =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml";
      if (!isImage) {
        notification.error({
          description: "Apenas é possível fazer upload de imagens.",
        });

        return isImage || Upload.LIST_IGNORE;
      }
      setfileBanner([...fileBanner, file]);
      return false;
    },
    fileBanner,
  };

  const clickToBannerDrawer = (item) => {
    setBannerIdEdit(item.id);
    setTitulo(item.titulo);
    setTipo(item.type);
    setDescricao(item.descricao);
    setLink(item.link);
    setImagem(item.imagem);
    setImagemHover(item.imagem_hover);
    setShowBannerDrawer(true);
  };

  const propBannerHover = {
    onRemove: (file) => {
      const index = fileBanner.indexOf(file);
      const newfileBannerList = fileBanner.slice();
      newfileBannerList.splice(index, 1);
      setfileBannerHover(newfileBannerList);
    },
    beforeUpload: (file) => {
      const isImage =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml";
      if (!isImage) {
        notification.error({
          description: "Apenas é possível fazer upload de imagens.",
        });

        return isImage || Upload.LIST_IGNORE;
      }
      setfileBannerHover([...fileBannerHover, file]);
      return false;
    },
    fileBannerHover,
  };

  const handleFormBanner = (e) => {
    e.preventDefault();

    if (!fileBanner?.length) return;

    var bodyFormData = new FormData();

    bodyFormData.append("titulo", titulo);
    bodyFormData.append("type", tipo);
    bodyFormData.append("descricao", descricao);
    bodyFormData.append("link", link);
    bodyFormData.append("imagem", fileBanner[0]);
    bodyFormData.append("imagem_hover", fileBannerHover[0]);

    axios
      .post(
        "https://spapapi.phormuladev.com/api/admin/store-banner",
        bodyFormData,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          setBanners(res.data.banners);
          setStateSPAP(() => getBannersOrder(0));
          setStateNISPAP(() => getBannersOrder(1));
          notification.success({
            description: "O banner foi criado com sucesso",
          });
          setModalCriaBannerOpen(false);
          setfileBanner([]);
          setfileBannerHover([]);
          setTitulo("");
          setDescricao("");
          setDescricaoAlt("");
        }
      })
      .catch((error) => {
        setModalCriaBannerOpen(false);
        notification.error({
          description:
            'Erro ao criar o banner "' +
            e.target.titulo.value +
            '". Por favor, tente de novo.',
        });
      });
  };

  const handleFormEditBanner = () => {
    if (!titulo?.length || !descricao?.length) return;

    var bodyFormData = new FormData();

    bodyFormData.append("id", bannerIdEdit);
    bodyFormData.append("type", tipo);
    bodyFormData.append("titulo", titulo);
    bodyFormData.append("descricao", descricao);
    bodyFormData.append("link", link);
    bodyFormData.append("imagem", fileBanner[0]);
    bodyFormData.append("imagem_hover", fileBannerHover[0]);

    axios
      .post(
        "https://spapapi.phormuladev.com/api/admin/update-banner",
        bodyFormData,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          setBanners(res.data.banners);
          setStateSPAP(() => getBannersOrder(0));
          setStateNISPAP(() => getBannersOrder(1));
          setShowBannerDrawer(false);
          setfileBanner([]);
          setfileBannerHover([]);
          setTitulo("");
          setTipo("");
          setDescricao("");
          setDescricaoAlt("");
          notification.success({
            description: "O banner foi atualizado com sucesso.",
          });
        }
      })
      .catch((error) => {
        notification.error({
          description: "Erro ao criar o banner. Por favor, tente de novo.",
        });
      });
  };

  const getBannersOrder = (tipo) => {
    let thisBanners = banners?.filter((banner) => {
      if (banner.type == tipo) return banner;
    });
    if (thisBanners?.length > 0) {
      thisBanners.sort((a, b) => a.ordem - b.ordem);
    }
    return thisBanners;
  };

  const handleOrdem = (array, tipo) => {
    if (tipo == 0) {
      setStateSPAP(array);
    } else {
      setStateNISPAP(array);
    }

    let ordemBanners = [];
    array.map(function (item, index) {
      ordemBanners.push({ ordem: index, id: item.id });
    });

    var bodyFormData = new FormData();

    bodyFormData.append("array", JSON.stringify(ordemBanners));

    fetch("https://spapapi.phormuladev.com/api/admin/set-ordem-banner", {
      method: "POST",
      body: bodyFormData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    }).then((response) => {
      response.json().then((data) => {
        setBanners(data.banners);
      });
    });
  };

  const openRemoveBannerModal = (id, titulo) => {
    setIdToRemove(id);
    setTitleToRemove(titulo);
    setRemoveBannerModalOpen(true);
  };

  const handleRemoveBanner = () => {
    axios
      .post(
        "https://spapapi.phormuladev.com/api/admin/remove-banner",
        { idToRemove },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        setIdToRemove("");
        setRemoveBannerModalOpen(false);
        if (res.status == 200) {
          setBanners(res.data.banners);
          setStateSPAP(() => getBannersOrder(0));
          setStateNISPAP(() => getBannersOrder(1));
          notification.success({
            description: "O banner foi removido com sucesso!",
          });
        }
      })
      .catch((error) => {
        // notification.error({description: 'Erro ao remover a imagem da galeria! Tente novamente.'});
      });
  };

  return (
    <Wrapper>
      <div>
        <Sidebar />
      </div>
      <Content>
        <Header />
        <Cards>
          <CardHeader>
            <Title>Banners</Title>

            <Button
              onClick={setModalCriaBannerOpen}
              style={{
                background: "#494D7D",
                color: "#fff",
                borderRadius: "6px",
                height: "42px",
                marginRight: "inherit",
              }}
            >
              Criar novo banner
            </Button>
          </CardHeader>
          <AlertMesssage>
            <Space size={8}>
              <IconInfo className="icon icon-info" />
              <span>
                Para poder alterar a ordem dos banners basta fazer "drag and
                drop" nas listagens respetivas.
              </span>
            </Space>
          </AlertMesssage>
          <Grid>
            {/* <Col span={6} >
          <Link id="newClube">
            <Button type='primary' className='cta'>
              Criar novo banner
            </Button>
          </Link>
          </Col> */}
            {stateSPAP?.length == 0 && (
              <span style={{ textAlign: "center" }}>
                Não existem banners SPAP disponíveis.
              </span>
            )}
            {stateSPAP?.length > 0 && (
              <>
                <Divider>SPAP</Divider>
                <ReactSortable
                  list={stateSPAP}
                  setList={(e) => handleOrdem(e, 0)}
                  className="div-sortable"
                >
                  {stateSPAP?.map((item) => (
                    <Card
                      className="card-banner"
                      cover={
                        <div
                          className="div-banner-img"
                          style={{
                            backgroundImage:
                              "url(https://spapapi.phormuladev.com/banners/" +
                              item.imagem +
                              ")",
                          }}
                        ></div>
                      }
                      actions={[
                        <EditOutlined
                          key="edit"
                          onClick={() => clickToBannerDrawer(item)}
                        />,
                        <DeleteOutlined
                          onClick={() =>
                            openRemoveBannerModal(item.id, item.titulo)
                          }
                        />,
                      ]}
                    >
                      <Meta
                        title={item.titulo}
                        description={
                          <div
                            dangerouslySetInnerHTML={{ __html: item.descricao }}
                          ></div>
                        }
                      />
                    </Card>
                  ))}
                </ReactSortable>
              </>
            )}

            <Divider>NISPAP</Divider>
            {stateNISPAP?.length == 0 && (
              <span style={{ textAlign: "center" }}>
                Não existem banners NISPAP disponíveis.
              </span>
            )}
            {stateNISPAP?.length > 0 && (
              <>
                <ReactSortable
                  list={stateNISPAP}
                  setList={(e) => handleOrdem(e, 1)}
                  className="div-sortable"
                >
                  {stateNISPAP.map((item) => (
                    <Card
                      className="card-banner"
                      cover={
                        <div
                          className="div-banner-img"
                          style={{
                            backgroundImage:
                              "url(https://spapapi.phormuladev.com/banners/" +
                              item.imagem +
                              ")",
                          }}
                        ></div>
                      }
                      actions={[
                        <EditOutlined
                          key="edit"
                          onClick={() => clickToBannerDrawer(item)}
                        />,
                        <DeleteOutlined
                          onClick={() =>
                            openRemoveBannerModal(item.id, item.titulo)
                          }
                        />,
                      ]}
                    >
                      <Meta
                        title={item.titulo}
                        description={
                          <div
                            dangerouslySetInnerHTML={{ __html: item.descricao }}
                          ></div>
                        }
                      />
                    </Card>
                  ))}
                </ReactSortable>
              </>
            )}
          </Grid>
        </Cards>
        <Footer />
      </Content>
      <Modal
        className="modal-anticorpos"
        open={modalCriaBannerOpen}
        title="Adicionar novo banner"
        onCancel={() => setModalCriaBannerOpen(false)}
      >
        <form id="create-banner" onSubmit={handleFormBanner}>
          <h6 style={styles.h6_label}>Onde vai ser inserido este banner?</h6>
          <Radio.Group
            key={"tipo-banner"}
            defaultValue={tipo}
            size="large"
            style={{ marginBottom: "10px" }}
            onChange={(e) => setTipo(e.target.value)}
          >
            <Radio.Button value={0}>SPAP</Radio.Button>
            <Radio.Button value={1}>NISPAP</Radio.Button>
          </Radio.Group>
          <Row gutter={[8, 8]}>
            <Col
              span={12}
              style={{
                height: "-webkit-fill-available",
              }}
            >
              <h6 style={styles.h6_label}>Imagem Principal*</h6>
              <Dragger
                key={"imagem-banner"}
                {...propBanner}
                fileList={[...fileBanner]}
              >
                <div>
                  <p className="ant-upload-drag-icon">
                    <IconUpload />
                  </p>
                  <p className="ant-upload-text text">
                    Upload da imagem de fundo
                  </p>
                  <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                </div>
              </Dragger>
            </Col>
            <Col
              span={12}
              style={{
                height: "-webkit-fill-available",
              }}
            >
              <h6 style={styles.h6_label}>Imagem Responsivo (Opcional)</h6>

              <Dragger
                key={"imagem-banner"}
                {...propBannerHover}
                fileList={[...fileBannerHover]}
              >
                <div>
                  <p className="ant-upload-drag-icon">
                    <IconUpload />
                  </p>
                  <p className="ant-upload-text text">
                    Upload da imagem de fundo
                  </p>
                  <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                </div>
              </Dragger>
            </Col>
          </Row>
          <h6 style={styles.h6_label}>Título</h6>
          <Input
            value={titulo}
            key={"titulo-banner"}
            onChange={(e) => setTitulo(e.target.value)}
            type="text"
            name="titulo"
            style={styles.input_class}
            placeholder="Título"
          />
          <h6 style={styles.h6_label}>Descrição</h6>
          <Input
            value={descricao}
            key={"descricao-banner"}
            type="text"
            onChange={(e) => setDescricao(e.target.value)}
            name="descricao"
            style={styles.input_class}
            placeholder="Descrição"
          />
          <h6 style={styles.h6_label}>Link</h6>
          <Input
            value={link}
            key={"link-banner"}
            type="text"
            onChange={(e) => setLink(e.target.value)}
            name="link"
            style={styles.input_class}
            placeholder="Link"
          />

          <Input
            type="submit"
            value="Guardar"
            style={{
              marginInlineStart: "0",
              background: "#494D7D",
              color: "#fff",
              borderRadius: "6px",
              width: "100%",
              height: "45px",
              marginTop: "2%",
            }}
          />
        </form>

        <Button
          onClick={() => setModalCriaBannerOpen(false)}
          style={{
            marginInlineStart: "0",
            background: "#fff",
            color: "#494D7D",
            border: "1px solid #494D7D",
            borderRadius: "6px",
            width: "100%",
            height: "45px",
            marginTop: "2%",
          }}
        >
          Voltar
        </Button>
      </Modal>

      <Drawer
        key={"editar-banner-" + bannerIdEdit}
        title="Editar Banner"
        width={500}
        onClose={() => setShowBannerDrawer(false)}
        open={showBannerDrawer}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button onClick={() => setShowBannerDrawer(false)}>Cancelar</Button>
            <Button
              onClick={handleFormEditBanner}
              type="submit"
              style={{
                borderRadius: "6px",
                background: "#494D7D",
                color: "#fff",
              }}
            >
              Guardar
            </Button>
          </Space>
        }
      >
        <form layout="vertical" id="update-event-form">
          <Col>
            <h6 style={styles.h6_label}>Onde vai ser inserido este banner?</h6>
            <Radio.Group
              key={"tipo-banner-" + bannerIdEdit}
              defaultValue={tipo}
              size="large"
              style={{ marginBottom: "10px" }}
              onChange={(e) => setTipo(e.target.value)}
            >
              <Radio.Button value={0}>SPAP</Radio.Button>
              <Radio.Button value={1}>NISPAP</Radio.Button>
            </Radio.Group>
            <Row gutter={[8, 8]}>
              <Col
                span={12}
                style={{
                  height: "-webkit-fill-available",
                }}
              >
                <h6 style={styles.h6_label}>Imagem Principal*</h6>
                <Dragger
                  key={"imagem-banner-edit-" + bannerIdEdit}
                  {...propBanner}
                  fileList={[...fileBanner]}
                  style={{
                    height: "150px",
                    backgroundImage:
                      "url(https://spapapi.phormuladev.com/banners/" +
                      imagem +
                      ")",
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                  }}
                >
                  <div>
                    <p className="ant-upload-drag-icon">
                      <IconUpload />
                    </p>
                    <p
                      className="ant-upload-text text"
                      style={{ fontSize: "14px" }}
                    >
                      Upload da imagem de fundo
                    </p>
                    <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                  </div>
                </Dragger>
              </Col>
              <Col
                span={12}
                style={{
                  height: "-webkit-fill-available",
                }}
              >
                <h6 style={styles.h6_label}>Imagem Responsivo (Opcional)</h6>

                <Dragger
                  key={"imagem-banner-hover-edit-" + bannerIdEdit}
                  {...propBannerHover}
                  fileList={[...fileBannerHover]}
                  style={{
                    height: "150px",
                    backgroundImage:
                      "url(https://spapapi.phormuladev.com/banners/" +
                      imagemHover +
                      ")",
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                  }}
                >
                  <div>
                    <p className="ant-upload-drag-icon">
                      <IconUpload />
                    </p>
                    <p
                      className="ant-upload-text text"
                      style={{ fontSize: "14px" }}
                    >
                      Upload da imagem de fundo
                    </p>
                    <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                  </div>
                </Dragger>
              </Col>
            </Row>
            <h6 style={styles.h6_label}>Título</h6>
            <Input
              value={titulo}
              key={"titulo-banner-edit-" + bannerIdEdit}
              onChange={(e) => setTitulo(e.target.value)}
              type="text"
              name="titulo"
              style={styles.input_class}
              placeholder="Título"
            />
            <h6 style={styles.h6_label}>Descrição</h6>
            <Input
              value={descricao}
              key={"descricao-banner-edit-" + bannerIdEdit}
              type="text"
              onChange={(e) => setDescricao(e.target.value)}
              name="descricao"
              style={styles.input_class}
              placeholder="Descrição"
            />
            <h6 style={styles.h6_label}>Link</h6>
            <Input
              value={link}
              key={"link-banner-edit-" + bannerIdEdit}
              type="text"
              onChange={(e) => setLink(e.target.value)}
              required
              name="link"
              style={styles.input_class}
              placeholder="Link"
            />
          </Col>
        </form>
      </Drawer>
      <Modal
        key={"remove-banner-" + idToRemove}
        title="Está prestes a eliminar um banner"
        open={removeBannerModalOpen}
        onOk={handleRemoveBanner}
        onCancel={() => setRemoveBannerModalOpen(false)}
        okText="Confirmar"
        cancelText="Cancelar"
      >
        <p>
          Tem a certeza que pretende remover o banner <b>'{titleToRemove}'</b>?
        </p>
      </Modal>
    </Wrapper>
  );
}
