import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import Container from "../../../Components/Container";
import FlexInRow from "../../../Components/FlexInRow";
import FlexInColumn from "../../../Components/FlexInColumn";
import PageTitle from "../../../Components/PageTitle";
import PageText from "../../../Components/PageText";
import RecommendedLinks from "../../../Components/RecommendedLinks";
import { Button, Modal, Result } from "antd";
import { Helmet } from "react-helmet";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  > .content {
    margin-bottom: 2.604vw;
  }

  img {
    width: 100%;
  }

  @media (max-width: 992px) {
    margin-top: 120px;
  }
`;

const SectionForm = styled.section`
  margin-top: 50px;
  gap: 4.167vw;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;

    .image-projeto-futuro {
      display: none;
    }
  }
`;

const FormInputs = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;

  > h3 {
    color: #000000;
    font-size: 1.138vw;
    line-height: 1.45vw;
    margin-bottom: 0;
    text-align: left;
  }

  label {
    align-items: center;
    color: #000000;
    cursor: pointer;
    display: flex;
    font-size: 0.938vw;
    font-weight: bold;
    line-height: 1.25vw;
    text-align: left;

    input {
      margin-right: 1.042vw;
    }
  }

  .error {
    color: red;
    font-weight: normal;
  }

  input[type="text"] {
    background-color: #ffffff;
    border: 1px solid #707070;
    border-radius: 0.521vw;
    padding: 1.146vw 1.302vw;
    width: 100%;
    outline: 0;
    transition: 0.3s ease;
  }

  input[type="text"]:focus {
    box-shadow: 0 8px 24px #00000025;
  }

  @media (max-width: 992px) {
    h3,
    label {
    }
  }

  @media (min-width: 1920px) {
    h3,
    label {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

const Submit = styled.button`
  max-width: 6.302vw;
  background-color: ${(props) => props.theme.orange};
  border: none;
  border-radius: 0.521vw;
  font-size: 1.146vw;
  font-weight: bold;
  line-height: 1.51vw;
  letter-spacing: 0px;
  color: ${(props) => props.theme.white};
  opacity: 1;
  transition: 0.3s ease;
  padding: 0.781vw 1.563vw;

  :hover {
    background-color: ${(props) => props.theme.orangeHover};
    color: ${(props) => props.theme.whiteHover};
  }

  @media (max-width: 992px) {
  }
`;

const FormScore = styled.div`
  align-items: flex-start;
  display: grid;
  grid-column-gap: 3.177vw;
  grid-template-columns: 3.646vw 3.646vw 3.646vw;
  justify-content: flex-start;
  /*height: 22.396vw;*/
`;

const ScoreValue = styled.h3`
  text-align: center;
  font-size: 0.938vw;
  font-weight: bold;
  line-height: 1.25vw;
  letter-spacing: 0px;
  color: #000000;
  margin: 0;
  transition: 0.3s ease;

  @media (max-width: 992px) {
  }
`;

const ScoreBar = styled.div`
  background-color: ${(props) => props.color};
  height: ${(props) => props.height};
  transition: 0.3s ease;
`;

const ScoreName = styled.h4`
  text-align: center;
  font-size: 0.625vw;
  line-height: 0.833vw;
  letter-spacing: 0px;
  color: #000000;
  height: 2.5vw;
  margin: 0;

  @media (max-width: 992px) {
    font-size: 1.555vw;
    line-height: 2.222vw;
  }
`;

export default function Revista(props) {
  const [revista, setRevista] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [nameValue, setNameValue] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [formScore, setFormScore] = useState({
    "Muito interessado": 0,
    "Talvez leia de vezem quando": 0,
    "Não quero saber": 0,
  });
  const [totalScore, setTotalScore] = useState(100);
  // modal message
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  useEffect(() => {
    fetch(`https://spapapi.phormuladev.com/api/all-revistas`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar revista");
        }
        return response.json();
      })
      .then((data) => {
        if (data?.length === 0) {
          console.log("nenhum dado");
        }
        setRevista(data.revistas);
        setTotalScore(data.revistas.length);
        let newFormScore = { 0: 0, 1: 0, 2: 0 };
        for (let index = 0; index < data.revistas.length; index++) {
          if (data.revistas[index].resposta === "Muito interessado") {
            newFormScore[0]++;
          }
          if (data.revistas[index].resposta === "Talvez leia de vezem quando") {
            newFormScore[1]++;
          }
          if (data.revistas[index].resposta === "Não quero saber") {
            newFormScore[2]++;
          }
        }
        setFormScore({
          "Muito interessado": newFormScore[0],
          "Talvez leia de vezem quando": newFormScore[1],
          "Não quero saber": newFormScore[2],
        });
        console.log("formScore");
        console.log(formScore);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleOnChange = (e) => {
    setIsChecked(e.target.value);
    setErrorMessage(false);
  };

  function clearInputs() {
    setIsChecked("");
    setNameValue("");
  }

  function sendPost() {
    /* send to API */
    axios
      .post("https://spapapi.phormuladev.com/api/set-revista", {
        resposta: isChecked,
        nome: nameValue,
      })
      .then((res) => {
        if (res.status == 200) {
          formScore[isChecked]++;
          setFormScore(formScore);
          setTotalScore(totalScore + 1);
          clearInputs();

          setSuccessModalOpen(true);
          setTimeout(() => {
            setSuccessModalOpen(false);
          }, 3000);
        }
      })
      .catch((error) => {
        console.log("erro ao responder");
      });
  }

  return (
    <Wrapper>
      <Helmet>
        <title>
          Revista | Sociedade Portuguesa de Anatomia Patológica - SPAP
        </title>
        <meta
          name="description"
          content="Projecto futuro | Sociedade Portuguesa de Anatomia Patológica - SPAP"
        />
        <meta
          name="keywords"
          content="spap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>

      <Container className="content">
        <PageTitle
          title="Revista"
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "Ferramentas úteis",
              link: "/ferramentas-uteis",
            },
            {
              text: "Revista",
            },
          ]}
          imageDesktop={require("../../../Assets/Images/Revista@2x.png")}
          imageMobile={require("../../../Assets/Images/Revista.png")}
          imageAlt="Revista"
        />

        <SectionForm>
          <picture className="image-projeto-futuro">
            <source
              srcSet={require("../../../Assets/Images/Projeto-de-Futuro@2x.png")}
              media="(min-width: 990px)"
            />
            <img
              src={require("../../../Assets/Images/Projeto-de-Futuro.png")}
              alt="Revista - Future Project"
            />
          </picture>
          <FormInputs
            onSubmit={(e) => {
              e.preventDefault();
              if (isChecked) sendPost();
              else setErrorMessage(true);
            }}
          >
            <h3>Dê-nos a sua opinião!</h3>
            <FlexInRow gap="30px">
              <label>
                <input
                  type="checkbox"
                  value="Muito interessado"
                  className="checkbox"
                  checked={isChecked === "Muito interessado" ? true : false}
                  onChange={(e) => handleOnChange(e)}
                />
                Muito interessado
              </label>
              <label>
                <input
                  type="checkbox"
                  value="Talvez leia de vezem quando"
                  className="checkbox"
                  checked={
                    isChecked === "Talvez leia de vezem quando" ? true : false
                  }
                  onChange={(e) => handleOnChange(e)}
                />
                Talvez leia de vezem quando
              </label>
              <label>
                <input
                  type="checkbox"
                  value="Não quero saber"
                  className="checkbox"
                  checked={isChecked === "Não quero saber" ? true : false}
                  onChange={(e) => handleOnChange(e)}
                />
                Não quero saber
              </label>
            </FlexInRow>
            {errorMessage && (
              <label className="error">Selecione uma opção acima</label>
            )}
            <FormScore>
              <FlexInRow gap="0.625vw" placeself="flex-end">
                {formScore["Muito interessado"] !== 0 ? (
                  <ScoreValue>
                    {(
                      (formScore["Muito interessado"] / totalScore) *
                      100
                    ).toFixed(2)}
                    %
                  </ScoreValue>
                ) : (
                  <ScoreValue>0%</ScoreValue>
                )}
                <ScoreBar
                  color={"#D6D7E2"}
                  height={
                    (formScore["Muito interessado"] / totalScore) * 100 * 0.28 +
                    "vw"
                  }
                ></ScoreBar>
                <ScoreName>Muito interessado</ScoreName>
              </FlexInRow>
              <FlexInRow gap="0.625vw" placeself="flex-end">
                {formScore["Talvez leia de vezem quando"] !== 0 ? (
                  <ScoreValue>
                    {(
                      (formScore["Talvez leia de vezem quando"] / totalScore) *
                      100
                    ).toFixed(2)}
                    %
                  </ScoreValue>
                ) : (
                  <ScoreValue>0%</ScoreValue>
                )}
                <ScoreBar
                  color={"#8688A8"}
                  height={
                    (formScore["Talvez leia de vezem quando"] / totalScore) *
                      100 *
                      0.28 +
                    "vw"
                  }
                ></ScoreBar>
                <ScoreName>Talvez leia de vez em quando</ScoreName>
              </FlexInRow>
              <FlexInRow gap="0.625vw" placeself="flex-end">
                {formScore["Não quero saber"] !== 0 ? (
                  <ScoreValue>
                    {(
                      (formScore["Não quero saber"] / totalScore) *
                      100
                    ).toFixed(2)}
                    %
                  </ScoreValue>
                ) : (
                  <ScoreValue>0%</ScoreValue>
                )}
                <ScoreBar
                  color={"#494D7D"}
                  height={
                    (formScore["Não quero saber"] / totalScore) * 100 * 0.28 +
                    "vw"
                  }
                ></ScoreBar>
                <ScoreName>Não quero saber</ScoreName>
              </FlexInRow>
            </FormScore>
            <h3>Sugira um nome!</h3>
            <input
              type="text"
              name="name"
              placeholder="Escrever..."
              onChange={(e) => {
                setNameValue(e.target.value);
              }}
              value={nameValue}
            />

            <Submit type="submit">Enviar</Submit>
          </FormInputs>
        </SectionForm>
      </Container>
      <Modal
        centered
        open={successModalOpen}
        footer={null}
        onCancel={() => setSuccessModalOpen(false)}
      >
        <Result
          style={{ background: "#fff" }}
          status="success"
          title="Obrigado por responder!"
        />
      </Modal>
      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={[
          {
            title: "História",
            link: "/spap/historia",
          },
          {
            title: "Órgãos sociais",
            link: "/spap/orgaos-sociais",
          },
          {
            title: "Bolsas e prémios",
            link: "/spap/bolsas-e-premios",
          },
          {
            title: "Vantagens de ser sócio",
            link: "/socios/conheca-as-vantagens",
          },
        ]}
      />
    </Wrapper>
  );
}
