import styled from "styled-components";
import React, { useState, useEffect } from "react";
import Sidebar from "../../../Components/Backoffice/Sidebar";
import Title from "../../../Components/Backoffice/Title";
import Header from "../../../Components/Backoffice/Header";
import Footer from "../../../Components/Backoffice/Footer";
import Card from "../../../Components/Backoffice/Card";
import {
  Col,
  Row,
  Button,
  notification,
  Form,
  Input,
  Dropdown,
  message,
  Space,
  Tooltip,
  Radio,
  Checkbox,
  Modal,
  Table,
  Select,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { ReactComponent as IconSearch } from "../../../Assets/Images/Backoffice/Search.svg";
import { ReactComponent as IconOptions } from "../../../Assets/Images/Backoffice/Options.svg";
import ExportButton from "../../../Components/Backoffice/ExportButton";

const Wrapper = styled.main`
  background-color: #e8e9f3;
  display: grid;
  grid-template-columns: 7.083vw 1fr;
  min-height: 100vh;

  .btn-descricao {
    margin-top: 10px;
    height: 45px !important;
    padding: 0 15px;
    font-weight: bold;
  }

  .div-banner-img {
    height: 150px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-size: cover;
    background-position: center center;
  }

  #newClube {
    background: #e8e9f3 0% 0% no-repeat padding-box;
    border: 4px dashed #d6d7e2;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;

    .cta {
      border-radius: 12px;
      height: 46px;
    }
  }

  .icon-edit {
    cursor: pointer;
  }

  .cta.publish {
    margin-right: 0;

    svg path {
      fill: #fff;
    }
  }

  @media (max-width: 992px) {
  }
`;

const TableFooter = styled.div`
  bottom: 16px;
  left: 0;
  position: absolute;
`;

const Search = styled.div`
  position: relative;

  input {
    background-color: #f1f5f700;
    border: 2px solid #d9e1e7;
    border-radius: 16px;
    color: #8688a8;
    font-size: 16px;
    font-style: italic;
    letter-spacing: 0px;
    line-height: 18px;
    margin: 0;
    padding: 12px 58px 12px 26px;
    outline: 0;
  }

  .icon {
    position: absolute;
    right: 26px;
    top: 8px;
  }
`;

const Content = styled.div`
  padding: 1.771vw;
`;

const CardHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  // gap: 60px;
  grid-template-columns: 1fr auto auto;
`;

const CardContent = styled.div`
  margin: 40px 0;
  position: relative;

  input,
  button {
    min-height: 46px;
  }

  table th {
    color: #809fb8 !important;
  }

  table td {
    color: #494d7d;
  }

  .ant-checkbox-checked span.ant-checkbox-inner,
  .ant-checkbox-checked:hover span.ant-checkbox-inner {
    background-color: #1ad598 !important;
    border-color: #1ad598 !important;
  }

  .ant-checkbox:hover span.ant-checkbox-inner {
    border-color: #1ad598 !important;
  }

  li.ant-pagination-item {
    border: 1px solid #d9e1e7cc;
  }

  li.ant-pagination-item.ant-pagination-item-active {
    background-color: #8688a8;
    border-color: #8688a8;
    color: #ffffff;
  }
`;

export default function DashboardRevista() {
  document.title = "SPAP - Revista";
  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const [revista, setRevista] = useState([]);
  const [dataToShow, setDataToShow] = useState([]);
  const [tablePagination, setTablePagination] = useState(20);
  const selectHandleChange = (value) => {
    setTablePagination(value);
  };

  const getRevista = (revista) => {
    revista?.map((item) => {
      let dataCriacao = new Date(item.created_at);
      let dia = dataCriacao.toLocaleDateString();
      let hora = dataCriacao.getHours();
      let minutos = dataCriacao.getMinutes();

      item.data = dia + " " + hora + "h" + minutos + "m";
    });

    revista?.sort((a, b) => b.id - a.id);

    let data = [];

    for (let i = 0; i < revista?.length; i++) {
      data.push({
        key: revista[i].id,
        nome: <b>{revista[i].nome}</b>,
        resposta: revista[i].resposta,
        created: revista[i].data,
      });
    }

    return data;
  };

  useEffect(() => {
    fetch(`https://spapapi.phormuladev.com/api/all-revistas`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar revista");
        }
        return response.json();
      })
      .then((data) => {
        if (data?.length === 0) {
          console.log("nenhum dado");
        }
        setRevista(data.revistas);
        setDataToShow(() => getRevista(data.revistas));
      })
      .catch((error) => console.error(error));
  }, []);

  const handleSearch = (item) => {
    let allRevistas = getRevista(revista);

    if (item?.length == 0) {
      setDataToShow(allRevistas);
      return;
    }

    let input = item.toLowerCase();

    let newList = allRevistas.filter((revistas) => {
      console.log(revistas);
      if (
        (revistas.name &&
          revistas.name.props?.children.toLowerCase().includes(input)) ||
        (revistas.resposta && revistas.respota.toLowerCase().includes(input))
      ) {
        return revistas;
      }
    });

    setDataToShow(newList);
  };

  const columns = [
    {
      title: "Nome",
      dataIndex: "nome",
      sorter: false,
    },
    {
      title: "Resposta",
      dataIndex: "resposta",
      sorter: false,
    },
    {
      title: "Data",
      dataIndex: "created",
      sorter: true,
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState("");

  const deleteColumn = {
    title: "",
    key: "delete",
    render: (_, record) => (
      <Button
        type="link"
        icon={<DeleteOutlined />}
        onClick={() => {
          setDeleteRecord(record);
          setModalDeleteOpen(true);
        }}
      />
    ),
  };

  const handleDelete = () => {
    if (selectedRowKeys.length > 1) {
      const ids = selectedRowKeys;
      // exclusão das linhas selecionadas
      axios
        .post(
          "https://spapapi.phormuladev.com/api/admin/remove-revistas",
          { ids },
          {
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + session_storage.token,
            },
          }
        )
        .then((res) => {
          if (res.length === 0) {
            console.log("nenhuma revista");
          }
          notification.success({
            description: "Os anticorpos foram removidos com sucesso!",
          });
          setSelectedRowKeys([]);
          setDeleteRecord("");
          setModalDeleteOpen(false);
          getRevista(res.data);
        })
        .catch((error) => {
          console.error("Erro ao excluir registros:", error);
        });
    } else {
      const id = deleteRecord;
      axios
        .post(
          "https://spapapi.phormuladev.com/api/admin/remove-revistas",
          { id },
          {
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + session_storage.token,
            },
          }
        )
        .then((res) => {
          if (res.length === 0) {
            console.log("nenhuma revista");
          }
          notification.success({
            description: "O anticorpo foi removido com sucesso!",
          });
          setSelectedRowKeys([]);
          setDeleteRecord("");
          setModalDeleteOpen(false);
          getRevista(res.data);
        })
        .catch((error) => {
          console.error("Erro ao excluir registro:", error);
        });
    }
  };

  return (
    <Wrapper>
      <div>
        <Sidebar />
      </div>
      <Content>
        <Header />
        <Card className="table-section">
          <CardHeader>
            <Title>Revistas</Title>
            <Space size={30}>
              <Search>
                <input
                  type="text"
                  placeholder="Procurar..."
                  onChange={(e) => handleSearch(e.target.value)}
                />
                <IconSearch className="icon icon-search" />
              </Search>
              <ExportButton
                data={dataToShow}
                columns={columns}
                fileName="revista"
              />
            </Space>
          </CardHeader>
          <CardContent>
            <Table
              rowSelection={rowSelection}
              columns={[...columns, deleteColumn]}
              dataSource={dataToShow}
              onChange={() => tableOnChange}
              pagination={{ pageSize: tablePagination }}
            />
            <TableFooter>
              <Select
                defaultValue={tablePagination}
                style={{
                  width: 120,
                }}
                onChange={selectHandleChange}
                options={[
                  {
                    value: "5",
                    label: "5",
                  },
                  {
                    value: "10",
                    label: "10",
                  },
                  {
                    value: "20",
                    label: "20",
                  },
                  {
                    value: "50",
                    label: "50",
                  },
                ]}
              />
            </TableFooter>
          </CardContent>
        </Card>
        <Modal
          open={modalDeleteOpen}
          onCancel={() => {
            setDeleteRecord("");
            setModalDeleteOpen(false);
          }}
          footer={[
            <Button
              key="cancel"
              onClick={() => {
                setDeleteRecord("");
                setModalDeleteOpen(false);
              }}
            >
              Cancelar
            </Button>,
            <Button
              key="delete"
              type="primary"
              danger
              onClick={() => handleDelete()}
            >
              Confirmar
            </Button>,
          ]}
        >
          <h2>Confirmação de exclusão</h2>
          <p style={{ marginBottom: "40px" }}>
            Tem certeza de que deseja excluir os registros selecionados?
          </p>
        </Modal>
        <Footer />
      </Content>
    </Wrapper>
  );
}
